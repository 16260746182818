import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Typography
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutAndClearToken } from '../redux/reducers/authSlice';
import { setInitialSession } from '../redux/reducers/sessionSlice';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ title, children }) => {
  const closeDialog = handleClose => {
    handleClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={true}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDialog(event, reason);
        }
      }}
      TransitionComponent={Transition}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      <DialogTitle id="max-width-dialog-title">
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              marginTop={2}
              align="left"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="MuiDialogContentRootc">
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

function SessionExpired() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReLogin = () => {
    dispatch(setInitialSession());
    dispatch(logoutAndClearToken());
    // navigate('/login');
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  };

  return (
    <>
      <Modal key="sessionExpired" title="Session Expired">
        <Grid container spacing={0} marginTop={3}>
          <Typography variant="subtitle2" align="left" fontWeight={600}>
            Your session has expired. Please Re-Login to continue!
          </Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          marginTop={3}
          style={{ justifyContent: 'center' }}
        >
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              type="button"
              size="small"
              onClick={handleReLogin}
            >
              Re-Login
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default SessionExpired;
