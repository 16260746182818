import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Autocomplete,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { isArrayNotEmpty } from '../../utils/utils';
import { useLocation } from 'react-router-dom';

function BasicDetails({ formik, allConstitutionTypes, allProfiles }) {
  const isOtherConstitutionType = formik.values.constitutionType === 6;
  const isOtherProfile = formik.values.profile === 5;
  const location = useLocation();

  const handleOnChange = (e, newValue) => {
    formik.setValues({
      ...formik.values,
      constitutionType: e.target.value,
      dob: '',
      dateOfRegistration: '',
      dateOfIncorporation: ''
    });
  };

  const handleOnChangeType = newValue => {
    formik.setValues({
      ...formik.values,
      connectorType: newValue
    });
  };

  const connectorTypeList = [
    {
      id: 'C',
      name: 'Common'
    },
    {
      id: 'S',
      name: 'Special'
    }
  ];
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Basic Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <TextField
              label="Name *"
              name="name"
              placeholder="Enter Name"
              size="small"
              fullWidth
              value={formik?.values?.name}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik?.values?.name !== '' }}
              error={Boolean(formik?.touched?.name && formik?.errors?.name)}
              helperText={formik?.touched?.name && formik?.errors?.name}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              select
              label="Constitution Type *"
              name="constitutionType"
              size="small"
              fullWidth
              value={formik?.values?.constitutionType}
              onChange={e => handleOnChange(e)}
              InputLabelProps={{
                shrink: formik?.values?.constitutionType !== ''
              }}
              error={
                formik?.touched?.constitutionType &&
                Boolean(formik?.errors?.constitutionType)
              }
              helperText={
                formik?.touched?.constitutionType &&
                formik?.errors?.constitutionType
              }
            >
              {allConstitutionTypes?.map(type => (
                <MenuItem key={type?.id} value={type?.id}>
                  {type?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {isOtherConstitutionType && (
            <Grid item lg={4}>
              <TextField
                label="Specify Constitution Type *"
                name="otherConstitutionType"
                placeholder="Specify Constitution Type"
                size="small"
                fullWidth
                value={formik?.values?.otherConstitutionType}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik?.touched?.otherConstitutionType &&
                  Boolean(formik?.errors?.otherConstitutionType)
                }
                helperText={
                  formik?.touched?.otherConstitutionType &&
                  formik?.errors?.otherConstitutionType
                }
              />
            </Grid>
          )}
          <Grid item lg={4}>
            <TextField
              select
              label="Profile *"
              name="profile"
              size="small"
              fullWidth
              value={formik?.values?.profile}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik?.values?.profile !== ''
              }}
              error={
                formik?.touched?.profile && Boolean(formik?.errors?.profile)
              }
              helperText={formik?.touched?.profile && formik?.errors?.profile}
            >
              {allProfiles?.map(type => (
                <MenuItem key={type?.id} value={type?.id}>
                  {type?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {isOtherProfile && (
            <Grid item lg={4}>
              <TextField
                label="Specify Profile *"
                name="otherProfile"
                placeholder="Specify Profile"
                size="small"
                fullWidth
                value={formik?.values?.otherProfile}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik?.touched?.otherProfile &&
                  Boolean(formik?.errors?.otherProfile)
                }
                helperText={
                  formik?.touched?.otherProfile && formik?.errors?.otherProfile
                }
              />
            </Grid>
          )}
          {formik?.values?.constitutionType === 1 ? (
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  value={
                    formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                  }
                  size="small"
                  fullWidth
                  format="DD-MM-YYYY"
                  // maxDate={dayjs().subtract(18, 'year')} // Restrict to 18 years back
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        borderRadius: '8px',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }
                    }
                  }}
                  onChange={value => formik.setFieldValue('dob', value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="dob"
                      size="small"
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik?.touched?.dob && Boolean(formik?.errors?.dob)
                      }
                      helperText={formik?.touched?.dob && formik?.errors?.dob}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.dob && formik.errors.dob && (
                <FormHelperText>{formik.errors.dob}</FormHelperText>
              )}
            </Grid>
          ) : (
            <>
              <Grid item lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Registration"
                    value={
                      formik.values?.dateOfRegistration
                        ? dayjs(formik.values?.dateOfRegistration)
                        : null
                    }
                    size="small"
                    fullWidth
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        size: 'small',
                        sx: {
                          borderRadius: '8px',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&:hover fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            }
                          }
                        }
                      }
                    }}
                    onChange={value =>
                      formik.setFieldValue('dateOfRegistration', value)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="dateOfRegistration"
                        size="small"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&:hover fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor:
                                formik?.touched?.dateOfRegistration &&
                                formik?.errors?.dateOfRegistration
                                  ? 'red'
                                  : 'inherit'
                            }
                          }
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik.touched.dateOfRegistration &&
                          Boolean(formik.errors.dateOfRegistration)
                        }
                        helperText={
                          formik.touched.dateOfRegistration &&
                          formik.errors.dateOfRegistration
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.dateOfRegistration &&
                  formik.errors.dateOfRegistration && (
                    <FormHelperText>
                      {formik.errors.dateOfRegistration}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Incorporation"
                    value={
                      formik.values?.dateOfIncorporation
                        ? dayjs(formik.values?.dateOfIncorporation)
                        : null
                    }
                    size="small"
                    fullWidth
                    format="DD-MM-YYYY"
                    slotProps={{
                      textField: {
                        size: 'small',

                        sx: {
                          borderRadius: '8px',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&:hover fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            }
                          }
                        }
                      }
                    }}
                    onChange={value =>
                      formik.setFieldValue('dateOfIncorporation', value)
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="dateOfIncorporation"
                        size="small"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&:hover fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor:
                                formik?.touched?.dateOfIncorporation &&
                                formik?.errors?.dateOfIncorporation
                                  ? 'red'
                                  : 'inherit'
                            }
                          }
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik.touched.dateOfIncorporation &&
                          Boolean(formik.errors.dateOfIncorporation)
                        }
                        helperText={
                          formik.touched.dateOfIncorporation &&
                          formik.errors.dateOfIncorporation
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                {formik.touched.dateOfIncorporation &&
                  formik.errors.dateOfIncorporation && (
                    <FormHelperText>
                      {formik.errors.dateOfIncorporation}
                    </FormHelperText>
                  )}
              </Grid>
            </>
          )}
          <Grid item lg={4}>
            <Autocomplete
              disabled={
                location.pathname === '/cwaf/connector-onboarding/edit'
                  ? true
                  : false
              }
              options={
                isArrayNotEmpty(connectorTypeList)
                  ? connectorTypeList?.map(c => {
                      return { id: c.id, name: c.name };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.name || ''}
              value={formik.values?.connectorType}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                handleOnChangeType(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Connector Type *"
                  placeholder="Select Connector Type *"
                  // style={{ marginRight: '24px' }}
                  error={
                    formik.touched.connectorType &&
                    Boolean(formik.errors.connectorType)
                  }
                  helperText={
                    formik.touched.connectorType && formik.errors.connectorType
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default BasicDetails;
