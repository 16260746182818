import { lightTheme } from './config/theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Forbidden from './pages/Forbidden';
import Layout from './components/Layout';
import ProtectedRoutes from './components/ProtectedRoutes';
import SessionExpired from '../src/components/SessionExpired';
import { ADMIN_MENUS_LIST } from './utils/constant';
import ResetPassword from './containers/auth/reset/ResetPassword';
import { accessibleRoutes } from './routes/accesibleRoutes';
import DashboardPage from './pages/Dashboard';
import Login from './pages/Login';
import UserManagementPage from './pages/UserManagement';
import RoleManagementPage from './pages/RoleManagement';
import EditUser from './pages/EditUser';
import ComingSoon from './pages/ComingSoon';
import AddRole from './pages/AddRole';
import ConnectorApprovalPage from './pages/ConnectorApproval';
import ProductMaster from './pages/MasterManagement/ProductMaster';
import CWADashboard from './pages/CWADashboard';
import CWALead from './pages/CWALead';
import CWAHierarchy from './pages/CWAHierarchy';
import { createContext, useEffect, useState } from 'react';
import CWAMyProfile from './pages/CWAMyProfile';
import CWAConnectorOnboarding from './pages/CWAConnectorOnboarding';
import CWALeadEdit from './pages/CWALeadEdit';
import { useDispatch, useSelector } from 'react-redux';
import CWALeadViewAuditLogs from './pages/CWALeadViewAuditLogs';
import CWAConnectorViewAuditLogs from './pages/CWAConnectorViewAuditLogs';
import CWAConnectorEdit from './pages/CWAConnectorEdit';
import {
  getCwaAccessibleMenuData,
  getMenuList,
  getToken,
  setCwaAccessibleMenuData,
  setCwaMenuData
} from './config/cookie';
import CWAReports from './pages/CWAReports';
import CWAConnectorViewDetails from './pages/CWAConnectorViewDetails';
import CWAConnectorApprovalCo from './pages/CWAConnectorApprovalCo';
import CWAConnectorApprovalHo from './pages/CWAConnectorApprovalHo';
import AdminConnectorView from './forms/AdminConnectorView';
import AdminConnectorViewAuditLog from './forms/AdminConnectorViewAuditLog';
import AddEditMaster from './pages/MasterManagement/AddEditMaster';
import PlanMaster from './pages/MasterManagement/PlanMaster';
import ChangePassword from './pages/ChangePassword';
import CWALeadFdForm from './pages/CWALeadEditPages/CWALeadFdForm';
import CWAGILeadEdit from './pages/CWALeadEditPages/CWAGILeadEdit';
import CWAFDViewAuditLogs from './pages/CWAViewAuditLogs/CWAFDViewAuditLogs';
import CWAGIAuditLog from './pages/CWAViewAuditLogs/CWAGIAuditLog';
import CWANPSLeadEdit from './pages/CWALeadEditPages/CWANPSLeadEdit';
import CWANPSViewAuditLogs from './pages/CWAViewAuditLogs/CWANPSViewAudiLogs';
import CWAMFLeadEdit from './pages/CWALeadEditPages/CWAMFLeadEdit';
import CWAMFViewAuditLogs from './pages/CWAViewAuditLogs/CWAMFViewAuditLogs';
import CWAIDBILeadEdit from './pages/CWALeadEditPages/CWAIDBILeadEdit';
import CWALILeadEdit from './pages/CWALeadEditPages/CWALILeadEdit';
import CWALIAuditLog from './pages/CWAViewAuditLogs/CWALIAuditLog';
import CWAIDBIViewAuditLogs from './pages/CWAViewAuditLogs/CWAIDBIViewAuditLogs';
import AdminHierarchy from './forms/AdminHierarchy';
import View360 from './pages/View360';
import ResetNewPassword from './containers/auth/reset/ResetNewPassword';
import IdleTimer from './pages/IdleTime';
import { setSessionExpired } from './redux/reducers/sessionSlice';
import { logoutAndClearToken } from './redux/reducers/authSlice';

export const CaseContext = createContext();
function App() {
  const { isSessionExpired } = useSelector(state => state.session);
  const [basicDetailsContext, setBasicDetailsContext] = useState({
    userImage: '',
    name: ''
  });
  const menuDetails = getMenuList();
  const dispatch = useDispatch();
  const [cwaMenuList, setCWAMenuList] = useState(
    menuDetails?.roleModule?.module
  );
  const [cwaAccessibleRoute, setCwaAccessibleRoute] = useState(
    menuDetails?.roleModule?.module
  );

  const { isLoggedIn } = useSelector(state => state.auth);
  const tokenId = getToken();
  const accessibleMenuData = getCwaAccessibleMenuData();

  useEffect(() => {
    if (menuDetails?.roleModule?.module?.length > 0) {
      let tempMenuList = [...menuDetails.roleModule.module];
      const finalMenuList = tempMenuList?.map(item => ({
        ...item,
        hasChild: false
        // hasChild: item?.dashboardChart?.length > 0 ? true : false,
        // submenus: item?.dashboardChart || []
      }));
      setCWAMenuList(finalMenuList);
      setCwaMenuData(finalMenuList);

      let tempCwaAccessibleRoute = [...menuDetails.roleModule.module];

      const finalCwaAccessibleRoute = tempCwaAccessibleRoute?.map(item => {
        const { route, ...rest } = item;
        return { path: route, ...rest };
      });
      let newObj = [
        {
          path: '/cwaf/my-profile',
          id: 100,
          name: 'Lead',
          read: 1,
          write: 1,
          dashboardChart: null
        },
        {
          path: '/cwaf/change-password',
          id: 100,
          name: 'Lead',
          read: 1,
          write: 1,
          dashboardChart: null
        }
      ];
      finalCwaAccessibleRoute.push(...newObj);

      setCwaAccessibleRoute(finalCwaAccessibleRoute);
      setCwaAccessibleMenuData(finalCwaAccessibleRoute);
      console.log(finalCwaAccessibleRoute, 'finalCwaAccessibleRoute');
    }
  }, [isLoggedIn]);

  const handleLogout = () => {
    if (tokenId) {
      dispatch(logoutAndClearToken());
      dispatch(setSessionExpired());
    }
  };

  // Use the custom hook with a 10-minute (600000ms) idle timeout
  IdleTimer(600000, handleLogout);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CaseContext.Provider
          value={{
            setBasicDetailsContext,
            basicDetailsContext
          }}
        >
          <CssBaseline />
          <Box>
            <>
              <Router>
                {isSessionExpired && <SessionExpired />}
                <Routes>
                  {process.env.REACT_APP_PORTAL_TYPE === 'ADMIN' && (
                    <Route
                      element={
                        <ProtectedRoutes accessibleRoutes={accessibleRoutes} />
                      }
                    >
                      <Route
                        path="/adminf/dashboard"
                        element={
                          <Layout title="Dashboard" menulist={ADMIN_MENUS_LIST}>
                            <DashboardPage />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/hierarchy-management"
                        element={
                          <Layout
                            title="Hierarchy Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <AdminHierarchy />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/user-management"
                        element={
                          <Layout
                            title="User Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <UserManagementPage />
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/user-management/edit-user"
                        element={
                          <Layout
                            title="User Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <EditUser />
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/user-management/view-audit-logs"
                        element={
                          <Layout
                            title="User Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <EditUser />
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/role-management"
                        element={
                          <Layout
                            title="Role Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <RoleManagementPage />
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/role-management/add-role"
                        element={
                          <Layout
                            title="Role Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <AddRole />
                            {/* <RoleManagementPage /> */}
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/role-management/edit-role"
                        element={
                          <Layout
                            title="Role Management"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <AddRole />
                            {/* <RoleManagementPage /> */}
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/connector-approval"
                        element={
                          <Layout
                            title="Connector Approval"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            {/* <ComingSoon /> */}
                            <ConnectorApprovalPage />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/connector-approval/view"
                        element={
                          <Layout
                            title="Connector Approval"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            {/* <ComingSoon /> */}
                            {/* <ConnectorApprovalPage /> */}
                            <AdminConnectorView />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/connector-approval/view-audit-logs"
                        element={
                          <Layout
                            title="Connector Approval"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <AdminConnectorViewAuditLog />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/reports"
                        element={
                          <Layout title="Reports" menulist={ADMIN_MENUS_LIST}>
                            {/* <ComingSoon /> */}
                            <CWAReports />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/master-management/product-master"
                        element={
                          <Layout
                            title="Product Master"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            {/* <ComingSoon /> */}
                            <ProductMaster />
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/master-management/product-master/add-product"
                        element={
                          <Layout
                            title="Product Master"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            <ComingSoon />
                            {/* <ProductMaster /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/adminf/master-management/plan-master"
                        element={
                          <Layout
                            title="Plan Master"
                            menulist={ADMIN_MENUS_LIST}
                          >
                            {/* <ComingSoon /> */}
                            <PlanMaster />
                          </Layout>
                        }
                      />

                      <Route
                        path="/adminf/master-management/add"
                        element={
                          <Layout title="Master" menulist={ADMIN_MENUS_LIST}>
                            <AddEditMaster />
                          </Layout>
                        }
                      />

                      <Route
                        path="/adminf/master-management/edit"
                        element={
                          <Layout title="Master" menulist={ADMIN_MENUS_LIST}>
                            <AddEditMaster />
                          </Layout>
                        }
                      />
                    </Route>
                  )}

                  {process.env.REACT_APP_PORTAL_TYPE === 'CWA' && (
                    <Route
                      element={
                        <ProtectedRoutes
                          accessibleRoutes={
                            accessibleMenuData // due to refresh page got error
                              ? accessibleMenuData
                              : cwaAccessibleRoute
                          }
                          // accessibleRoutes={cwaAccessibleRoutes}
                        />
                      }
                    >
                      <Route
                        path="/cwaf/dashboard"
                        element={
                          <Layout title="Dashboard" menulist={cwaMenuList}>
                            {menuDetails?.roleModule?.id === 1 ? (
                              <DashboardPage />
                            ) : (
                              <CWADashboard />
                            )}
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/360-view"
                        element={
                          <Layout title="360° View" menulist={cwaMenuList}>
                            <View360 />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead"
                        element={
                          <Layout title="Lead" menulist={cwaMenuList}>
                            <CWALead />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/lead/HL/edit"
                        element={
                          <Layout title="Home Loan" menulist={cwaMenuList}>
                            <CWALeadEdit />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/lead/HL/view-audit-logs"
                        element={
                          <Layout title="Home Loan" menulist={cwaMenuList}>
                            <CWALeadViewAuditLogs />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/LI/edit"
                        element={
                          <Layout title="Life Insurance" menulist={cwaMenuList}>
                            <CWALILeadEdit />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/lead/LI/view-audit-logs"
                        element={
                          <Layout title="Life Insurance" menulist={cwaMenuList}>
                            <CWALIAuditLog />
                            {/* <ComingSoon /> */}
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/GI/edit"
                        element={
                          <Layout
                            title="General Insurance"
                            menulist={cwaMenuList}
                          >
                            <CWAGILeadEdit />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/lead/GI/view-audit-logs"
                        element={
                          <Layout
                            title="General Insurance"
                            menulist={cwaMenuList}
                          >
                            <CWAGIAuditLog />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/FD/edit"
                        element={
                          <Layout title="Fixed Deposit" menulist={cwaMenuList}>
                            <CWALeadFdForm />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/FD/view-audit-logs"
                        element={
                          <Layout title="Fixed Deposit" menulist={cwaMenuList}>
                            <CWAFDViewAuditLogs />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/NPS/edit"
                        element={
                          <Layout
                            title="National Pension Scheme"
                            menulist={cwaMenuList}
                          >
                            <CWANPSLeadEdit />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/NPS/view-audit-logs"
                        element={
                          <Layout
                            title="National Pension Scheme"
                            menulist={cwaMenuList}
                          >
                            <CWANPSViewAuditLogs />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/MF/edit"
                        element={
                          <Layout title="Mutual Fund" menulist={cwaMenuList}>
                            <CWAMFLeadEdit />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/MF/view-audit-logs"
                        element={
                          <Layout title="Mutual Fund" menulist={cwaMenuList}>
                            <CWAMFViewAuditLogs />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/IDBI/edit"
                        element={
                          <Layout title="IDBI" menulist={cwaMenuList}>
                            <CWAIDBILeadEdit />
                          </Layout>
                        }
                      />

                      <Route
                        path="/cwaf/lead/IDBI/view-audit-logs"
                        element={
                          <Layout title="IDBI" menulist={cwaMenuList}>
                            <CWAIDBIViewAuditLogs />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/reports"
                        element={
                          <Layout title="Reports" menulist={cwaMenuList}>
                            {/* <ComingSoon /> */}
                            <CWAReports />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-onboarding"
                        element={
                          <Layout
                            title="Connector Onboarding"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorOnboarding />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-onboarding/add"
                        element={
                          <Layout
                            title="Connector Onboarding"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorEdit />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-onboarding/edit"
                        element={
                          <Layout
                            title="Connector Onboarding"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorEdit />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-onboarding/view"
                        element={
                          <Layout
                            title="Connector Onboarding"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewDetails />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-onboarding/view-audit-logs"
                        element={
                          <Layout
                            title="Connector Onboarding"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewAuditLogs />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-co"
                        element={
                          <Layout
                            title="Connector Approval CO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorApprovalCo />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-co/view"
                        element={
                          <Layout
                            title="Connector Approval CO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewDetails />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-co/view-audit-logs"
                        element={
                          <Layout
                            title="Connector Approval CO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewAuditLogs />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-ho"
                        element={
                          <Layout
                            title="Connector Approval HO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorApprovalHo />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-ho/view"
                        element={
                          <Layout
                            title="Connector Approval HO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewDetails />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/connector-approval-ho/view-audit-logs"
                        element={
                          <Layout
                            title="Connector Approval HO"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <CWAConnectorViewAuditLogs />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/hierarchy"
                        element={
                          <Layout title="Hierarchy" menulist={cwaMenuList}>
                            {/* <ComingSoon /> */}
                            <CWAHierarchy />
                            {/* <EmployeeHierarchy /> */}
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/my-profile"
                        element={
                          <Layout title="My Profile" menulist={cwaMenuList}>
                            {/* <ComingSoon /> */}
                            <CWAMyProfile />
                          </Layout>
                        }
                      />
                      <Route
                        path="/cwaf/change-password"
                        element={
                          <Layout
                            title="Change Password"
                            menulist={cwaMenuList}
                          >
                            {/* <ComingSoon /> */}
                            <ChangePassword />
                          </Layout>
                        }
                      />
                    </Route>
                  )}
                  <Route path="*" element={<NotFound />} />
                  <Route path="maintenance" element={<Maintenance />} />
                  <Route path="/forbidden" element={<Forbidden />} />
                  {process.env.REACT_APP_PORTAL_TYPE === 'ADMIN' && (
                    <>
                      <Route path="adminf/login" Component={Login} />
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="/adminf/login" />}
                      />
                    </>
                  )}
                  {process.env.REACT_APP_PORTAL_TYPE === 'CWA' && (
                    <>
                      <Route path="cwaf/login" Component={Login} />
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="/cwaf/login" />}
                      />
                    </>
                  )}
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route
                    path="/reset-new-password"
                    element={<ResetNewPassword />}
                  />
                </Routes>
              </Router>
            </>
          </Box>
        </CaseContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
