import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import DownloadButton from '../components/DownloadAsButton';
import CustomDataTable from '../components/CustomDataTable';
import { FileDownloadOutlined, FilterAltOutlined } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  getProductNames,
  getSubProductNames,
  getReportsPageList
} from '../redux/reducers/reportSlice';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getMenuList } from '../config/cookie';

export default function CWAReports() {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    product: '',
    reports: '',
    page: 0,
    limit: 10,
    // searchFilter: '',
    fromDate: '',
    toDate: ''
    // empIds: [authData?.empId]
  });
  const [columnVisibility, setColumnVisibility] = useState({
    applicantID: true,
    spName: true,
    spId: true,
    reporterName: true,
    reporterDesignation: true,
    reporterId: true,
    apName: true,
    apDesignation: true,
    apId: true,
    office: true,
    branch: true,
    applicationNo: true,
    applicant: true,
    loginDate: true,
    loginAmt: true,
    mobile: true,
    leadSource: true,
    connectorCode: true,
    loanScheme: true,
    interestType: true,
    action: true // Always true as this column should not be filtered
  });
  const [tempColumnVisibility, setTempColumnVisibility] =
    useState(columnVisibility);
  const [openModal, setOpenModal] = useState(false);
  // const [product, setProduct] = useState('');
  // const [subProduct, setSubProduct] = useState('');
  const designation = getMenuList();

  const { authData } = useSelector(state => state.auth);
  const { productNames, subProductNames, reportData } = useSelector(
    state => state.report
  );

  useEffect(() => {
    document.title = createTitle('Reports');
    dispatch(setActiveMenu('Reports'));
    dispatch(getProductNames());
    // setProduct(productNames);
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  const defaultFromDate = dayjs().subtract(367, 'day');
  const defaultToDate = dayjs();
  const defaultMonth = dayjs();

  const validationSchema = yup.object().shape({
    productName: yup
      .object()
      .shape({
        id: yup.string().required('Product name is required')
      })
      .required('Product name is required'),
    reports: yup
      .object()
      .shape({
        id: yup.string().required('Please select report type')
      })
      .required('Please select report type'),
    fromDate: yup.date().required('From Date is required'),
    toDate: yup
      .date()
      .required('To Date is required')
      .min(yup.ref('fromDate'), 'To Date cannot be before From Date'),
    selectMonth: yup.date().required('Month is required')
  });

  const formik = useFormik({
    initialValues: {
      productName: '',
      reports: '',
      fromDate: defaultFromDate,
      toDate: defaultToDate,
      selectMonth: defaultMonth
    },
    validationSchema: validationSchema
  });

  useEffect(() => {
    if (productNames?.length > 0) {
      if (formik?.values?.productName === '') {
        formik.setFieldValue('productName', productNames[0]);
        dispatch(
          getSubProductNames({
            prodCode: productNames[0]?.reportCode,
            designation: designation?.designation
          })
        );
      } else {
        dispatch(
          getSubProductNames({
            prodCode: formik?.values?.productName?.reportCode,
            designation: designation?.designation
          })
        );
      }
    }
  }, [productNames, formik?.values?.productName]);

  useEffect(() => {
    if (subProductNames?.length > 0) {
      formik.setFieldValue('reports', subProductNames[0]);
    }
  }, [subProductNames]);

  useEffect(() => {
    if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLLR'
    ) {
      const tempColumn = {
        applicantID: true,
        spName: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        apName: true,
        apDesignation: true,
        apId: true,
        office: true,
        branch: true,
        applicationNo: true,
        applicant: true,
        loginDate: true,
        loginAmt: true,
        mobile: true,
        leadSource: true,
        connectorCode: true,
        loanScheme: true,
        interestType: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLDR'
    ) {
      const tempColumn = {
        disbId: true,
        spName: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        apName: true,
        apDesignation: true,
        apId: true,
        areaOffice: true,
        to: true,
        applicationNo: true,
        disbDate: true,
        loanNo: true,
        leadName: true,
        leadId: true,
        disbAmt: true,
        cancelledAmt: true,
        disbType: true,
        loanScheme: true,
        chequeNo: true,
        sancAmt: true,
        coreNoncore: true,
        leadSource: true,
        connectorCode: true,
        validated: true,
        disbCommissionAmt: true,
        leadMobile: true,
        leadEmail: true,
        connectorPer: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLSR'
    ) {
      const tempColumn = {
        applicantId: true,
        spId: true,
        spName: true,
        spDesignation: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        apName: true,
        apDesignation: true,
        apId: true,
        office: true,
        to: true,
        applicationNo: true,
        applicant: true,
        mobile: true,
        leadSource: true,
        connectorCode: true,
        loanScheme: true,
        interestType: true,
        sanctionDate: true,
        sactionAmount: true,
        sanctionCancelled: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'FD' &&
      formik?.values?.reports?.reportSubTypeCode === 'FDBR'
    ) {
      const tempColumn = {
        receiptNo: true,
        revCredit: true,
        toOffice: true,
        employeeName: true,
        employeeDesignation: true,
        employeeId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        dateOfSale: true,
        leadName: true,
        mobile: true,
        email: true,
        amount: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIBR'
    ) {
      const tempColumn = {
        toOffice: true,
        spName: true,
        spDesignation: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        apName: true,
        apDesignation: true,
        apId: true,
        dateOfSale: true,
        leadName: true,
        mobileNo: true,
        email: true,
        product: true,
        eligiblePremium: true,
        serviceTax: true,
        totalPremium: true,
        policyNo: true,
        branchDiv: true,
        commission: true,
        partner: true,
        cancelled: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIRBR'
    ) {
      const tempColumn = {
        toOffice: true,
        spName: true,
        spDesignation: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterID: true,
        apName: true,
        apDesignation: true,
        apId: true,
        dateOfSale: true,
        leadName: true,
        mobileNo: true,
        email: true,
        product: true,
        eligiblePremium: true,
        serviceTax: true,
        totalPremium: true,
        policyNo: true,
        branchDiv: true,
        commission: true,
        partner: true,
        cancelled: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'IDBI' &&
      formik?.values?.reports?.reportSubTypeCode === 'IDBIDR'
    ) {
      const tempColumn = {
        spName: true,
        spDesignation: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterID: true,
        apName: true,
        apDesignation: true,
        apId: true,
        to: true,
        areaOffice: true,
        applicationNo: true,
        disbDate: true,
        loanNo: true,
        leadName: true,
        leadId: true,
        disbAmt: true,
        cancelledAmt: true,
        disbType: true,
        chequeNo: true,
        sancAmt: true,
        coreNoncore: true,
        leadSource: true,
        connectorCode: true,
        validated: true,
        disbCommissionAmt: true,
        product: true,
        connectorPer: true,
        disbId: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIBR'
    ) {
      const tempColumn = {
        toOffice: true,
        spName: true,
        spDesignation: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterID: true,
        apName: true,
        apDesignation: true,
        apId: true,
        dateOfSale: true,
        leadName: true,
        mobileNo: true,
        email: true,
        dateOfCommencement: true,
        singlePremium: true,
        nonSinglePremium: true,
        serviceTax: true,
        totalPremium: true,
        liPlan: true,
        term: true,
        premiumPayingMode: true,
        policyNo: true,
        branchLoggedIn: true,
        firstCommission: true,
        firstBonus: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIRBR'
    ) {
      const tempColumn = {
        toOffice: true,
        spName: true,
        spDesignation: true,
        spId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterID: true,
        apName: true,
        apDesignation: true,
        apId: true,
        dateOfSale: true,
        leadName: true,
        mobileNo: true,
        email: true,
        dateOfCommencement: true,
        premiumAmount: true,
        serviceTax: true,
        totalPremium: true,
        liPlan: true,
        term: true,
        premiumPayingMode: true,
        policyNo: true,
        branchLoggedIn: true,
        commission: true,
        bonus: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'MF' &&
      formik?.values?.reports?.reportSubTypeCode === 'MFBR'
    ) {
      const tempColumn = {
        toOffice: true,
        employeeName: true,
        employeeDesignation: true,
        employeeId: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        dateOfInvestment: true,
        leadName: true,
        mobileNo: true,
        email: true,
        type: true,
        amount: true,
        schemeName: true,
        fundHouseName: true,
        folioNo: true,
        chequeNo: true,
        nameOfBank: true,
        commission: true,
        mfType: true,
        sipStopped: true,
        sipStopDate: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCSR'
    ) {
      const tempColumn = {
        connectorCode: true,
        connectorName: true,
        to: true,
        coreDisb: true,
        corePayout: true,
        nonCoreDisb: true,
        nonCorePayout: true,
        payout: true,
        cancelledAmt: true,
        payoutAfterCancellation: true,
        excessRecovery: true,
        revAfterRecovery: true,
        tdsTaxPer: true,
        taxToBeDeducted: true,
        amountAfterTax: true,
        tdsRecovery: true,
        finalPayout: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCDR'
    ) {
      const tempColumn = {
        connectorCode: true,
        connectorName: true,
        to: true,
        spId: true,
        spName: true,
        spDesignation: true,
        reporterName: true,
        reporterDesignation: true,
        reporterId: true,
        apName: true,
        apDesignation: true,
        apId: true,
        lead: true,
        leadMobile: true,
        disbDate: true,
        loanNo: true,
        sancAmt: true,
        disbAmt: true,
        cancelledAmt: true,
        disbType: true,
        coreNoncore: true,
        validated: true,
        disbCancDate: true,
        action: true
      };
      setColumnVisibility(prevstate => ({
        ...prevstate,
        ...tempColumn
      }));
      setTempColumnVisibility(prevState => ({
        ...prevState,
        ...tempColumn
      }));
    }
  }, [formik.values.reports, formik?.values?.productName]);

  useEffect(() => {
    if (formik.values.reports?.reportSubTypeCode) {
      if (
        formik?.values?.productName?.reportCode === 'OT' &&
        formik?.values?.reports?.reportSubTypeCode === 'OTCSR'
      ) {
        console.log('selectMonth', formik?.values?.selectMonth);
        let payload = {
          employeeId: authData?.empId,
          reportSubType: formik?.values?.reports?.reportSubTypeCode,
          pageNumber: initialValues.page + 1,
          pageSize: initialValues.limit,
          monthDate: dayjs(formik?.values?.selectMonth).format('YYYY-MM-DD')
          // fromDate: dayjs(formik?.values?.fromDate).format('YYYY-MM-DD'),
          // toDate: dayjs(formik?.values?.toDate).format('YYYY-MM-DD')
        };
        dispatch(
          getReportsPageList({
            payload: payload,
            prodCode: formik?.values?.productName?.reportCode
          })
        );
      } else {
        let payload = {
          employeeId: authData?.empId,
          reportSubType: formik?.values?.reports?.reportSubTypeCode,
          pageNumber: initialValues.page + 1,
          pageSize: initialValues.limit,
          fromDate: dayjs(formik?.values?.fromDate).format('YYYY-MM-DD'),
          toDate: dayjs(formik?.values?.toDate).format('YYYY-MM-DD')
        };
        dispatch(
          getReportsPageList({
            payload: payload,
            prodCode: formik?.values?.productName?.reportCode
          })
        );
      }
    }
  }, [
    formik.values.reports,
    formik?.values?.productName,
    formik?.values?.toDate,
    formik?.values?.fromDate,
    formik?.values?.selectMonth,
    initialValues.page,
    initialValues.limit
  ]);

  const handleTempColumnChange = (column, isVisible) => {
    setTempColumnVisibility(prev => ({
      ...prev,
      [column]: isVisible
    }));
  };

  const handleDone = () => {
    setColumnVisibility(tempColumnVisibility); // Apply the changes to the main state
    setOpenModal(false);
  };

  const handleCancel = () => {
    setTempColumnVisibility(columnVisibility); // Reset temp state to main state
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleIndividualDownloadClick = row => {
    // Filter the columns to include only those that are visible
    const filteredColumns = columns.filter(
      column => columnVisibility[column.id]
    );

    // Create an array for the header row
    const header = filteredColumns?.map(column => column.label);

    // Create an array for the row data
    const data = filteredColumns?.map(column => row[column.id]);

    // Combine header and data into a single array
    const ws_data = [header, data];

    // Create a worksheet from the data array
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Create a new workbook and add the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    // Generate the Excel file and trigger the download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLLR' &&
      row?.applicantID
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.applicantID}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLDR' &&
      row?.disbId
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.disbId}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLSR' &&
      row?.applicantId
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.applicantId}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'FD' &&
      formik?.values?.reports?.reportSubTypeCode === 'FDBR' &&
      row?.receiptNo
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.receiptNo}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIBR' &&
      row?.policyNo
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.policyNo}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIRBR' &&
      row?.policyNo
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.policyNo}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'IDBI' &&
      formik?.values?.reports?.reportSubTypeCode === 'IDBIDR'
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIBR' &&
      row?.policyNo
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.policyNo}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIRBR' &&
      row?.policyNo
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}_${row?.policyNo}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'MF' &&
      formik?.values?.reports?.reportSubTypeCode === 'MFBR'
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCSR'
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}.xlsx`
      );
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCDR'
    ) {
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        `Report_${formik?.values?.productName?.reportCode}_${formik?.values?.reports?.reportSubTypeCode}.xlsx`
      );
    }
  };

  const columns = (() => {
    if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLLR'
    ) {
      return [
        {
          uniqueKey: 'applicantID',
          id: 'applicantID',
          label: 'Applicant ID',
          width: '140px'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'office',
          id: 'office',
          label: 'Office'
        },
        {
          uniqueKey: 'branch',
          id: 'branch',
          label: 'Branch'
        },
        {
          uniqueKey: 'applicationNo',
          id: 'applicationNo',
          label: 'Application No'
        },
        {
          uniqueKey: 'applicant',
          id: 'applicant',
          label: 'Applicant'
        },
        {
          uniqueKey: 'loginDate',
          id: 'loginDate',
          label: 'Login Date',
          width: '130px'
        },
        {
          uniqueKey: 'loginAmt',
          id: 'loginAmt',
          label: 'Login Amt',
          width: '120px'
        },
        {
          uniqueKey: 'mobile',
          id: 'mobile',
          label: 'Mobile'
        },
        {
          uniqueKey: 'leadSource',
          id: 'leadSource',
          label: 'Lead Source',
          width: '130px'
        },
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Connector Code'
        },
        {
          uniqueKey: 'loanScheme',
          id: 'loanScheme',
          label: 'Loan Scheme',
          width: '150px'
        },
        {
          uniqueKey: 'interestType',
          id: 'interestType',
          label: 'Interest Type'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLDR'
    ) {
      return [
        {
          uniqueKey: 'disbId',
          id: 'disbId',
          label: 'Disb ID',
          width: '120px'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'areaOffice',
          id: 'areaOffice',
          label: 'Office'
        },
        {
          uniqueKey: 'to',
          id: 'to',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'applicationNo',
          id: 'applicationNo',
          label: 'Application No'
        },
        {
          uniqueKey: 'disbDate',
          id: 'disbDate',
          label: 'Disb Date'
        },
        {
          uniqueKey: 'loanNo',
          id: 'loanNo',
          label: 'Loan No',
          width: '130px'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '150px'
        },
        {
          uniqueKey: 'leadId',
          id: 'leadId',
          label: 'Lead Id'
        },
        {
          uniqueKey: 'disbAmt',
          id: 'disbAmt',
          label: 'Disb Amount',
          width: '130px'
        },
        {
          uniqueKey: 'cancelledAmt',
          id: 'cancelledAmt',
          label: 'Cancelled Amt'
        },
        {
          uniqueKey: 'disbType',
          id: 'disbType',
          label: 'Disb Type'
        },
        {
          uniqueKey: 'loanScheme',
          id: 'loanScheme',
          label: 'Loan Scheme'
        },
        {
          uniqueKey: 'chequeNo',
          id: 'chequeNo',
          label: 'Cheque No.'
        },
        {
          uniqueKey: 'sancAmt',
          id: 'sancAmt',
          label: 'Sanc Amt.'
        },
        {
          uniqueKey: 'coreNoncore',
          id: 'coreNoncore',
          label: 'Core/Non core'
        },
        {
          uniqueKey: 'leadSource',
          id: 'leadSource',
          label: 'Lead Source'
        },
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Connector Code'
        },
        {
          uniqueKey: 'validated',
          id: 'validated',
          label: 'Validated'
        },
        {
          uniqueKey: 'disbCommissionAmt',
          id: 'disbCommissionAmt',
          label: 'Disb Commission Amt.',
          width: '170px'
        },
        {
          uniqueKey: 'leadMobile',
          id: 'leadMobile',
          label: 'Lead Mobile'
        },
        {
          uniqueKey: 'leadEmail',
          id: 'leadEmail',
          label: 'Lead Email'
        },
        {
          uniqueKey: 'connectorPer',
          id: 'connectorPer',
          label: 'Conn. %'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'HL' &&
      formik?.values?.reports?.reportSubTypeCode === 'HLSR'
    ) {
      return [
        {
          uniqueKey: 'applicantId',
          id: 'applicantId',
          label: 'Applicant ID',
          width: '120px'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID',
          width: '120px'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'office',
          id: 'office',
          label: 'Office'
        },
        {
          uniqueKey: 'to',
          id: 'to',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'applicationNo',
          id: 'applicationNo',
          label: 'Application No'
        },
        {
          uniqueKey: 'applicant',
          id: 'applicant',
          label: 'Applicant'
        },
        {
          uniqueKey: 'mobile',
          id: 'mobile',
          label: 'Mobile No.'
        },
        {
          uniqueKey: 'leadSource',
          id: 'leadSource',
          label: 'Lead Source'
        },
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Connector Code'
        },
        {
          uniqueKey: 'loanScheme',
          id: 'loanScheme',
          label: 'Loan Scheme'
        },
        {
          uniqueKey: 'interestType',
          id: 'interestType',
          label: 'Interest Type'
        },
        {
          uniqueKey: 'sanctionDate',
          id: 'sanctionDate',
          label: 'Sanction Date'
        },
        {
          uniqueKey: 'sactionAmount',
          id: 'sactionAmount',
          label: 'Saction Amount',
          width: '170px'
        },
        {
          uniqueKey: 'sanctionCancelled',
          id: 'sanctionCancelled',
          label: 'Sanction Cancelled'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'FD' &&
      formik?.values?.reports?.reportSubTypeCode === 'FDBR'
    ) {
      return [
        {
          uniqueKey: 'receiptNo',
          id: 'receiptNo',
          label: 'Receipt No'
        },
        {
          uniqueKey: 'revCredit',
          id: 'revCredit',
          label: 'Rev Credit'
        },
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'To Office'
        },
        {
          uniqueKey: 'employeeName',
          id: 'employeeName',
          label: 'Employee Name',
          width: '150px'
        },
        {
          uniqueKey: 'employeeDesignation',
          id: 'employeeDesignation',
          label: 'Employee Designation'
        },
        {
          uniqueKey: 'employeeId',
          id: 'employeeId',
          label: 'employee ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'dateOfSale',
          id: 'dateOfSale',
          label: 'Date Of Sale'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '150px'
        },
        {
          uniqueKey: 'mobile',
          id: 'mobile',
          label: 'Mobile'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email ID'
        },
        {
          uniqueKey: 'amount',
          id: 'amount',
          label: 'Amount'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIBR'
    ) {
      return [
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'dateOfSale',
          id: 'dateOfSale',
          label: 'Date of sale'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '150px'
        },
        {
          uniqueKey: 'mobileNo',
          id: 'mobileNo',
          label: 'Mobile No'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email ID'
        },
        {
          uniqueKey: 'product',
          id: 'product',
          label: 'Product',
          width: '180px'
        },
        {
          uniqueKey: 'eligiblePremium',
          id: 'eligiblePremium',
          label: 'Eligible Premium',
          width: '120px'
        },
        {
          uniqueKey: 'serviceTax',
          id: 'serviceTax',
          label: 'Service Tax'
        },
        {
          uniqueKey: 'totalPremium',
          id: 'totalPremium',
          label: 'Total Premium',
          width: '130px'
        },
        {
          uniqueKey: 'policyNo',
          id: 'policyNo',
          label: 'Policy No.'
        },
        {
          uniqueKey: 'branchDiv',
          id: 'branchDiv',
          label: 'Branch Div'
        },
        {
          uniqueKey: 'commission',
          id: 'commission',
          label: 'Commission'
        },
        {
          uniqueKey: 'partner',
          id: 'partner',
          label: 'Partner',
          width: '150px'
        },
        {
          uniqueKey: 'cancelled',
          id: 'cancelled',
          label: 'Cancelled'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'GI' &&
      formik?.values?.reports?.reportSubTypeCode === 'GIRBR'
    ) {
      return [
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterID',
          id: 'reporterID',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'dateOfSale',
          id: 'dateOfSale',
          label: 'Date of sale'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '150px'
        },
        {
          uniqueKey: 'mobileNo',
          id: 'mobileNo',
          label: 'Mobile No'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email ID'
        },
        {
          uniqueKey: 'product',
          id: 'product',
          label: 'Product',
          width: '180px'
        },
        {
          uniqueKey: 'eligiblePremium',
          id: 'eligiblePremium',
          label: 'Eligible Premium',
          width: '120px'
        },
        {
          uniqueKey: 'serviceTax',
          id: 'serviceTax',
          label: 'Service Tax'
        },
        {
          uniqueKey: 'totalPremium',
          id: 'totalPremium',
          label: 'Total Premium',
          width: '130px'
        },
        {
          uniqueKey: 'policyNo',
          id: 'policyNo',
          label: 'Policy No.'
        },
        {
          uniqueKey: 'branchDiv',
          id: 'branchDiv',
          label: 'Branch Div'
        },
        {
          uniqueKey: 'commission',
          id: 'commission',
          label: 'Commission'
        },
        {
          uniqueKey: 'partner',
          id: 'partner',
          label: 'Partner',
          width: '150px'
        },
        {
          uniqueKey: 'cancelled',
          id: 'cancelled',
          label: 'Cancelled'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'IDBI' &&
      formik?.values?.reports?.reportSubTypeCode === 'IDBIDR'
    ) {
      return [
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterID',
          id: 'reporterID',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'to',
          id: 'to',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'areaOffice',
          id: 'areaOffice',
          label: 'Area Office'
        },
        {
          uniqueKey: 'applicationNo',
          id: 'applicationNo',
          label: 'Application No.'
        },
        {
          uniqueKey: 'disbDate',
          id: 'disbDate',
          label: 'Disb Date'
        },
        {
          uniqueKey: 'loanNo',
          id: 'loanNo',
          label: 'Loan No',
          width: '150px'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '120px'
        },
        {
          uniqueKey: 'leadId',
          id: 'leadId',
          label: 'Lead Id',
          width: '150px'
        },
        {
          uniqueKey: 'disbAmt',
          id: 'disbAmt',
          label: 'Disb Amt'
        },
        {
          uniqueKey: 'cancelledAmt',
          id: 'cancelledAmt',
          label: 'Cancelled Amt'
        },
        {
          uniqueKey: 'disbType',
          id: 'disbType',
          label: 'Disb Type'
        },
        {
          uniqueKey: 'chequeNo',
          id: 'chequeNo',
          label: 'Cheque No'
        },
        {
          uniqueKey: 'sancAmt',
          id: 'sancAmt',
          label: 'Sanc Amt'
        },
        {
          uniqueKey: 'coreNoncore',
          id: 'coreNoncore',
          label: 'Core Noncore'
        },
        {
          uniqueKey: 'leadSource',
          id: 'leadSource',
          label: 'Lead Source'
        },
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Connector Code',
          width: '130px'
        },
        {
          uniqueKey: 'validated',
          id: 'validated',
          label: 'Validated'
        },
        {
          uniqueKey: 'disbCommissionAmt',
          id: 'disbCommissionAmt',
          label: 'Disb CommissionAmt'
        },
        {
          uniqueKey: 'product',
          id: 'product',
          label: 'Product'
        },
        {
          uniqueKey: 'connectorPer',
          id: 'connectorPer',
          label: 'Connector Per',
          width: '180px'
        },
        {
          uniqueKey: 'disbId',
          id: 'disbId',
          label: 'Disb Id'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIBR'
    ) {
      return [
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'dateOfSale',
          id: 'dateOfSale',
          label: 'Date Of Sale'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '120px'
        },
        {
          uniqueKey: 'mobileNo',
          id: 'mobileNo',
          label: 'Mobile No.'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email'
        },
        {
          uniqueKey: 'dateOfCommencement',
          id: 'dateOfCommencement',
          label: 'Date Of Commencement'
        },
        {
          uniqueKey: 'singlePremium',
          id: 'singlePremium',
          label: 'Single Premium'
        },
        {
          uniqueKey: 'nonSinglePremium',
          id: 'nonSinglePremium',
          label: 'Non Single Premium'
        },
        {
          uniqueKey: 'serviceTax',
          id: 'serviceTax',
          label: 'Service Tax'
        },
        {
          uniqueKey: 'totalPremium',
          id: 'totalPremium',
          label: 'Total Premium'
        },
        {
          uniqueKey: 'liPlan',
          id: 'liPlan',
          label: 'LI Plan'
        },
        {
          uniqueKey: 'term',
          id: 'term',
          label: 'Term'
        },
        {
          uniqueKey: 'premiumPayingMode',
          id: 'premiumPayingMode',
          label: 'Premium Paying Mode'
        },
        {
          uniqueKey: 'policyNo',
          id: 'policyNo',
          label: 'Policy No'
        },
        {
          uniqueKey: 'branchLoggedIn',
          id: 'branchLoggedIn',
          label: 'Branch Logged In'
        },
        {
          uniqueKey: 'firstCommission',
          id: 'firstCommission',
          label: 'First Commission'
        },
        {
          uniqueKey: 'firstBonus',
          id: 'firstBonus',
          label: 'First Bonus'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'LI' &&
      formik?.values?.reports?.reportSubTypeCode === 'LIRBR'
    ) {
      return [
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'dateOfSale',
          id: 'dateOfSale',
          label: 'Date Of Sale'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '120px'
        },
        {
          uniqueKey: 'mobileNo',
          id: 'mobileNo',
          label: 'Mobile No.'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email'
        },
        {
          uniqueKey: 'dateOfCommencement',
          id: 'dateOfCommencement',
          label: 'Date Of Commencement'
        },
        {
          uniqueKey: 'premiumAmount',
          id: 'premiumAmount',
          label: 'Premium Amount'
        },
        {
          uniqueKey: 'serviceTax',
          id: 'serviceTax',
          label: 'Service Tax'
        },
        {
          uniqueKey: 'totalPremium',
          id: 'totalPremium',
          label: 'Total Premium'
        },
        {
          uniqueKey: 'liPlan',
          id: 'liPlan',
          label: 'LI Plan'
        },
        {
          uniqueKey: 'term',
          id: 'term',
          label: 'Term'
        },
        {
          uniqueKey: 'premiumPayingMode',
          id: 'premiumPayingMode',
          label: 'Premium Paying Mode'
        },
        {
          uniqueKey: 'policyNo',
          id: 'policyNo',
          label: 'Policy No'
        },
        {
          uniqueKey: 'branchLoggedIn',
          id: 'branchLoggedIn',
          label: 'Branch Logged In'
        },
        {
          uniqueKey: 'commission',
          id: 'commission',
          label: 'Commission'
        },
        {
          uniqueKey: 'bonus',
          id: 'bonus',
          label: 'Bonus'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'MF' &&
      formik?.values?.reports?.reportSubTypeCode === 'MFBR'
    ) {
      return [
        {
          uniqueKey: 'toOffice',
          id: 'toOffice',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'employeeName',
          id: 'employeeName',
          label: 'Employee Name'
        },
        {
          uniqueKey: 'employeeDesignation',
          id: 'employeeDesignation',
          label: 'Employee Designation'
        },
        {
          uniqueKey: 'employeeId',
          id: 'employeeId',
          label: 'Employee Id',
          width: '150px'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'dateOfInvestment',
          id: 'dateOfInvestment',
          label: 'Date Of Investment'
        },
        {
          uniqueKey: 'leadName',
          id: 'leadName',
          label: 'Lead Name',
          width: '150px'
        },
        {
          uniqueKey: 'mobile',
          id: 'mobile',
          label: 'Mobile No'
        },
        {
          uniqueKey: 'email',
          id: 'email',
          label: 'Email ID'
        },
        {
          uniqueKey: 'type',
          id: 'type',
          label: 'Type',
          width: '130px'
        },
        {
          uniqueKey: 'amount',
          id: 'amount',
          label: 'Amount',
          width: '120px'
        },
        {
          uniqueKey: 'schemeName',
          id: 'schemeName',
          label: 'Scheme Name',
          width: '250px'
        },
        {
          uniqueKey: 'fundHouseName',
          id: 'fundHouseName',
          label: 'Fund House Name',
          width: '130px'
        },
        {
          uniqueKey: 'folioNo',
          id: 'folioNo',
          label: 'Folio No'
        },
        {
          uniqueKey: 'chequeNo',
          id: 'chequeNo',
          label: 'Cheque No'
        },
        {
          uniqueKey: 'nameOfBank',
          id: 'nameOfBank',
          label: 'Bank Name',
          width: '150px'
        },
        {
          uniqueKey: 'commission',
          id: 'commission',
          label: 'Commission'
        },
        {
          uniqueKey: 'mfType',
          id: 'mfType',
          label: 'MF Type'
        },
        {
          uniqueKey: 'sipStopped',
          id: 'sipStopped',
          label: 'SIP Stopped'
        },
        {
          uniqueKey: 'sipStopDate',
          id: 'sipStopDate',
          label: 'SIP Stop Date'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCSR'
    ) {
      return [
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Code'
        },
        {
          uniqueKey: 'connectorName',
          id: 'connectorName',
          label: 'Connector Name'
        },
        {
          uniqueKey: 'to',
          id: 'to',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'coreDisb',
          id: 'coreDisb',
          label: 'Core Disb'
        },
        {
          uniqueKey: 'corePayout',
          id: 'corePayout',
          label: 'Payout'
        },
        {
          uniqueKey: 'cancelledAmt',
          id: 'cancelledAmt',
          label: 'Cancellation Amt'
        },
        {
          uniqueKey: 'payoutAfterCancellation',
          id: 'payoutAfterCancellation',
          label: 'Payout After Cancellation'
        },
        {
          uniqueKey: 'excessRecovery',
          id: 'excessRecovery',
          label: 'Excess Recovery'
        },
        {
          uniqueKey: 'revAfterRecovery',
          id: 'revAfterRecovery',
          label: 'Rev After Recovery'
        },
        {
          uniqueKey: 'tdsTaxPer',
          id: 'tdsTaxPer',
          label: 'TDS Tax %'
        },
        {
          uniqueKey: 'taxToBeDeducted',
          id: 'taxToBeDeducted',
          label: 'Tax To Be Deducted'
        },
        {
          uniqueKey: 'amountAfterTax',
          id: 'amountAfterTax',
          label: 'Amount After Tax'
        },
        {
          uniqueKey: 'tdsRecovery',
          id: 'tdsRecovery',
          label: 'TDS Recovery'
        },
        {
          uniqueKey: 'finalPayout',
          id: 'finalPayout',
          label: 'Final Payout'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else if (
      formik?.values?.productName?.reportCode === 'OT' &&
      formik?.values?.reports?.reportSubTypeCode === 'OTCDR'
    ) {
      return [
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Code'
        },
        {
          uniqueKey: 'connectorName',
          id: 'connectorName',
          label: 'Connector Name'
        },
        {
          uniqueKey: 'to',
          id: 'to',
          label: 'Territory Office'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spDesignation',
          id: 'spDesignation',
          label: 'SP Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'lead',
          id: 'lead',
          label: 'Lead Name'
        },
        {
          uniqueKey: 'leadMobile',
          id: 'leadMobile',
          label: 'Lead Mobile'
        },
        {
          uniqueKey: 'disbDate',
          id: 'disbDate',
          label: 'Disb. Date'
        },
        {
          uniqueKey: 'sancAmt',
          id: 'sancAmt',
          label: 'Sanc. Amt.'
        },
        {
          uniqueKey: 'disbAmt',
          id: 'disbAmt',
          label: 'Disb. Amt.'
        },
        {
          uniqueKey: 'cancelledAmt',
          id: 'cancelledAmt',
          label: 'Cancelled Amt.'
        },
        {
          uniqueKey: 'disbType',
          id: 'disbType',
          label: 'Disb. Type'
        },
        {
          uniqueKey: 'coreNoncore',
          id: 'coreNoncore',
          label: 'Core/Non-Core'
        },
        {
          uniqueKey: 'validated',
          id: 'validated',
          label: 'Validated'
        },
        {
          uniqueKey: 'disbCancDate',
          id: 'disbCancDate',
          label: 'Disb. Cancel Date'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    } else {
      return [
        {
          uniqueKey: 'applicantID',
          id: 'applicantID',
          label: 'Applicant ID',
          width: '140px'
        },
        {
          uniqueKey: 'spName',
          id: 'spName',
          label: 'SP Name',
          width: '150px'
        },
        {
          uniqueKey: 'spId',
          id: 'spId',
          label: 'SP ID'
        },
        {
          uniqueKey: 'reporterName',
          id: 'reporterName',
          label: 'Reporter Name',
          width: '150px'
        },
        {
          uniqueKey: 'reporterDesignation',
          id: 'reporterDesignation',
          label: 'Reporter Designation',
          width: '150px'
        },
        {
          uniqueKey: 'reporterId',
          id: 'reporterId',
          label: 'Reporter ID'
        },
        {
          uniqueKey: 'apName',
          id: 'apName',
          label: 'AP Name',
          width: '150px'
        },
        {
          uniqueKey: 'apDesignation',
          id: 'apDesignation',
          label: 'AP Designation'
        },
        {
          uniqueKey: 'apId',
          id: 'apId',
          label: 'AP ID'
        },
        {
          uniqueKey: 'office',
          id: 'office',
          label: 'Office'
        },
        {
          uniqueKey: 'branch',
          id: 'branch',
          label: 'Branch'
        },
        {
          uniqueKey: 'applicationNo',
          id: 'applicationNo',
          label: 'Application No'
        },
        {
          uniqueKey: 'applicant',
          id: 'applicant',
          label: 'Applicant'
        },
        {
          uniqueKey: 'loginDate',
          id: 'loginDate',
          label: 'Login Date',
          width: '130px'
        },
        {
          uniqueKey: 'loginAmt',
          id: 'loginAmt',
          label: 'Login Amt',
          width: '120px'
        },
        {
          uniqueKey: 'mobile',
          id: 'mobile',
          label: 'Mobile'
        },
        {
          uniqueKey: 'leadSource',
          id: 'leadSource',
          label: 'Lead Source',
          width: '130px'
        },
        {
          uniqueKey: 'connectorCode',
          id: 'connectorCode',
          label: 'Connector Code'
        },
        {
          uniqueKey: 'loanScheme',
          id: 'loanScheme',
          label: 'Loan Scheme'
        },
        {
          uniqueKey: 'interestType',
          id: 'interestType',
          label: 'Interest Type'
        },
        {
          uniqueKey: 'action',
          id: 'action',
          label: 'Action',
          render: row => (
            <>
              <IconButton onClick={() => handleIndividualDownloadClick(row)}>
                <FileDownloadOutlined />
              </IconButton>
            </>
          )
        }
      ];
    }
  })();

  const filteredColumns = columns?.filter(
    column => columnVisibility[column.id]
  );

  const handleFromDateChange = value => {
    const calculatedToDate = dayjs(value).add(367, 'day');
    const newToDate = calculatedToDate.isAfter(dayjs())
      ? dayjs()
      : calculatedToDate;

    formik.setFieldValue('fromDate', value);
    formik.setFieldValue('toDate', newToDate);

    setInitialValues({
      ...initialValues,
      fromDate: value,
      toDate: newToDate,
      page: 0
    });
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px'
        }}
      >
        <form autoComplete="off">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px'
            }}
          >
            <div
              style={{
                display: 'flex'
                // margin: '1rem'
              }}
            >
              <Autocomplete
                options={productNames}
                getOptionLabel={option => option.reportName || ''}
                value={formik.values.productName}
                size="small"
                disableClearable
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'productName',
                    newValue ? newValue : null
                  );
                  setInitialValues({
                    ...initialValues,
                    product: newValue,
                    page: 0
                  });
                  formik.setFieldValue('reports', '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Product *"
                    placeholder=" Select Product"
                    style={{ width: '200px', marginRight: '24px' }}
                    error={
                      formik.touched.productName &&
                      Boolean(formik.errors.productName)
                    }
                    helperText={
                      formik.touched.productName && formik.errors.productName
                    }
                  />
                )}
              />
              <Autocomplete
                options={subProductNames}
                getOptionLabel={option => option.reportSubType || ''}
                value={formik.values.reports}
                size="small"
                disableClearable
                onChange={(event, newValue) => {
                  formik.setFieldValue('reports', newValue ? newValue : null);
                  setInitialValues({
                    ...initialValues,
                    reports: newValue,
                    page: 0
                  });
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Report *"
                    placeholder=" Select Report"
                    style={{ width: '200px', marginRight: '24px' }}
                    error={
                      formik.touched.reports && Boolean(formik.errors.reports)
                    }
                    helperText={formik.touched.reports && formik.errors.reports}
                  />
                )}
              />
            </div>

            {((formik?.values?.productName?.reportCode === 'HL' &&
              formik?.values?.reports?.reportSubTypeCode === 'HLDR') ||
              (formik?.values?.productName?.reportCode === 'HL' &&
                formik?.values?.reports?.reportSubTypeCode === 'HLLR') ||
              (formik?.values?.productName?.reportCode === 'HL' &&
                formik?.values?.reports?.reportSubTypeCode === 'HLSR') ||
              (formik?.values?.productName?.reportCode === 'FD' &&
                formik?.values?.reports?.reportSubTypeCode === 'FDBR') ||
              (formik?.values?.productName?.reportCode === 'GI' &&
                formik?.values?.reports?.reportSubTypeCode === 'GIBR') ||
              (formik?.values?.productName?.reportCode === 'GI' &&
                formik?.values?.reports?.reportSubTypeCode === 'GIRBR') ||
              (formik?.values?.productName?.reportCode === 'IDBI' &&
                formik?.values?.reports?.reportSubTypeCode === 'IDBIDR') ||
              (formik?.values?.productName?.reportCode === 'LI' &&
                formik?.values?.reports?.reportSubTypeCode === 'LIBR') ||
              (formik?.values?.productName?.reportCode === 'LI' &&
                formik?.values?.reports?.reportSubTypeCode === 'LIRBR') ||
              (formik?.values?.productName?.reportCode === 'MF' &&
                formik?.values?.reports?.reportSubTypeCode === 'MFBR') ||
              (formik?.values?.productName?.reportCode === 'OT' &&
                formik?.values?.reports?.reportSubTypeCode === 'OTCDR')) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                  // margin: '1rem'
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From Date *"
                    value={
                      formik?.values?.fromDate
                        ? dayjs(formik?.values?.fromDate)
                        : null
                    }
                    size="small"
                    fullWidth
                    format="DD-MM-YYYY"
                    minDate={dayjs('2010-01-01')}
                    maxDate={dayjs()}
                    slotProps={{
                      textField: {
                        size: 'small',

                        sx: {
                          borderRadius: '8px',
                          width: '200px',
                          marginRight: '24px',
                          border: `1px solid ${
                            formik?.touched?.fromDate &&
                            formik?.errors?.fromDate
                              ? 'red'
                              : 'inherit'
                          }`
                        }
                      }
                    }}
                    onChange={handleFromDateChange}
                    // onChange={value => {
                    //   formik.setFieldValue('fromDate', value);
                    //   setInitialValues({
                    //     ...initialValues,
                    //     fromDate: value,
                    //     page: 0
                    //   });
                    //   formik.setFieldValue('toDate', dayjs().add(90, 'day'));
                    // }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="fromDate"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik?.touched?.fromDate &&
                          Boolean(formik?.errors?.fromDate)
                        }
                        helperText={
                          formik?.touched?.fromDate && formik?.errors?.fromDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To Date *"
                    value={
                      formik?.values?.toDate
                        ? dayjs(formik?.values?.toDate)
                        : null
                    }
                    size="small"
                    fullWidth
                    format="DD-MM-YYYY"
                    minDate={dayjs('2010-01-01')}
                    // maxDate={dayjs()}
                    maxDate={formik?.values?.toDate}
                    slotProps={{
                      textField: {
                        size: 'small',

                        sx: {
                          borderRadius: '8px',
                          width: '200px',
                          // marginRight: '24px',
                          border: `1px solid ${
                            formik?.touched?.toDate && formik?.errors?.toDate
                              ? 'red'
                              : 'inherit'
                          }`
                        }
                      }
                    }}
                    onChange={value => {
                      formik.setFieldValue('toDate', value);
                      setInitialValues({
                        ...initialValues,
                        toDate: value,
                        page: 0
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="toDate"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik?.touched?.toDate &&
                          Boolean(formik?.errors?.toDate)
                        }
                        helperText={
                          formik?.touched?.toDate && formik?.errors?.toDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}

            {formik?.values?.productName?.reportCode === 'OT' &&
              formik?.values?.reports?.reportSubTypeCode === 'OTCSR' && (
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Month *"
                      value={
                        formik?.values?.selectMonth
                          ? dayjs(formik?.values?.selectMonth)
                          : null
                      }
                      views={['month', 'year']}
                      size="small"
                      fullWidth
                      format="MM-YYYY"
                      minDate={dayjs('2010-01')}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          size: 'small',

                          sx: {
                            borderRadius: '8px',
                            width: '200px',
                            // marginRight: '24px',
                            border: `1px solid ${
                              formik?.touched?.selectMonth &&
                              formik?.errors?.selectMonth
                                ? 'red'
                                : 'inherit'
                            }`
                          }
                        }
                      }}
                      onChange={value => {
                        formik.setFieldValue('selectMonth', value);
                        setInitialValues({
                          ...initialValues,
                          selectMonth: value,
                          page: 0
                        });
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="selectMonth"
                          size="small"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.selectMonth &&
                            Boolean(formik?.errors?.selectMonth)
                          }
                          helperText={
                            formik?.touched?.selectMonth &&
                            formik?.errors?.selectMonth
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              )}
          </div>

          {reportData?.reportsList?.length > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // padding: '16px'
                paddingRight: '16px',
                paddingBottom: '16px'
              }}
            >
              <div style={{ marginRight: '12px' }}>
                <IconButton
                  sx={{ marginLeft: '16px' }}
                  onClick={handleOpenModal}
                >
                  <FilterAltOutlined sx={{ color: '#335DAD' }} />
                </IconButton>
              </div>
              <div
                style={
                  {
                    // display: 'flex',
                    // justifyContent: 'space-between',
                    // margin: '1rem'
                  }
                }
              >
                <DownloadButton
                  menu="Reports"
                  // searchData={searchTerm ? searchTerm : ''}
                  // empId={
                  //   selectedEmployees.length > 0
                  //     ? selectedEmployees.map(employee => employee.empId).join(',')
                  //     : authData?.empId
                  // }
                  product={formik.values?.productName?.reportCode}
                  payloadContent={{
                    employeeId: authData?.empId,
                    reportSubType: formik?.values?.reports?.reportSubTypeCode,
                    startDate: dayjs(formik?.values?.fromDate).format(
                      'YYYY-MM-DD'
                    ),
                    endDate: dayjs(formik?.values?.toDate).format('YYYY-MM-DD'),
                    monthDate: dayjs(formik?.values?.selectMonth).format(
                      'YYYY-MM-DD'
                    )
                  }}
                />
              </div>
            </div>
          )}
        </form>
        <CustomDataTable
          columns={filteredColumns || []}
          data={
            reportData?.reportsList?.length > 0 ? reportData?.reportsList : []
          }
          // data={[]}
          onPageChange={val => {
            setInitialValues({ ...initialValues, page: val });
          }}
          onRowsPerPageChange={val => {
            setInitialValues({ ...initialValues, limit: val, page: 0 });
          }}
          defaultPage={initialValues.page}
          defaultRowsPerPage={initialValues.limit}
          totalCount={reportData?.totalCount || 0}
          isShowPagination={true}
        />
        <ColumnFilterModal
          open={openModal}
          onCancel={handleCancel}
          onDone={handleDone}
          columnVisibility={tempColumnVisibility}
          onColumnChange={handleTempColumnChange}
          columns={columns}
        />
      </div>
    </>
  );
}

const ColumnFilterModal = ({
  open,
  onCancel,
  onDone,
  columnVisibility,
  onColumnChange,
  columns
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
  >
    <DialogTitle
      sx={{
        padding: '16px',
        minWidth: '300px',
        mb: 2,
        fontWeight: 'bold',
        fontSize: 'medium',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      Column Selector
      <IconButton onClick={onCancel} sx={{ color: 'grey.800' }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column">
        {columns?.map(
          column =>
            column.id !== 'action' && (
              <FormControlLabel
                sx={{ padding: '4px' }}
                key={column.id}
                control={
                  <Checkbox
                    checked={columnVisibility[column.id]}
                    onChange={e => onColumnChange(column.id, e.target.checked)}
                  />
                }
                label={column.label} // Display the label instead of the id
              />
            )
        )}
      </Box>
    </DialogContent>
    <DialogActions
      sx={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly' }}
    >
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        CANCEL
      </Button>
      <Button
        onClick={onDone}
        variant="contained"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        DONE
      </Button>
    </DialogActions>
  </Dialog>
);
