import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useRef, useState } from 'react';
import UploadPhotoComponent from '../components/UploadPhotoComponent';
import BasicDetails from '../forms/CWALeadComponents/BasicDetails';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import AddressDetails from '../forms/CWALeadComponents/AddressDetails';
import KYCDetails from '../forms/CWALeadComponents/KYCDetails';
import LoanDetails from '../forms/CWALeadComponents/LoanDetails';
import PropertyDetails from '../forms/CWALeadComponents/PropertyDetails';
import EmploymentDetails from '../forms/CWALeadComponents/EmploymentDetails';
import ConnectorMapping from '../forms/CWALeadComponents/ConnectorMapping';
import { createTitle, handleError } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLeadDocuments,
  getAreaOffice,
  getAssistingPerson,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getSourcePerson,
  updateLeadDetails
} from '../redux/reducers/leadSlice';
import LeadCoApplicant from '../forms/CWALeadComponents/LeadCoApplicant';
import {
  setCallValidation,
  setWaitExecution
} from '../redux/reducers/connectorApprovalSlice';
import ConfirmBox from '../components/ConfirmBox';
import UploadDocumentsLead from '../forms/CWALeadComponents/uploadDocumentsLead';
import SnackBarBox from '../components/SnackBarBox';
import LeadSourceDetails from '../forms/CWALeadComponents/LeadSourceDetails';

function CWALeadEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    assistantPersonList,
    isGetLeadDetailsLoading,
    isGetLeadDocLoading,
    leadUpdateErrorContainer
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;
  const [leadApplicantData, setLeadApplicantData] = useState([]);
  const childComponentRef = useRef(null);
  const { isValid, waitForExecution } = useSelector(state => state.connector);
  const [isConnectorId, setIsConnectorId] = useState('');
  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(() => {
    dispatch(getLeadDetails({ leadId, customerId }));
    dispatch(getLeadDocuments({ leadId, customerId }));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadSource({}));
    dispatch(getAreaOffice({}));
  }, [leadId, customerId]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: 1
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  useEffect(() => {
    if (isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: leadDetails?.leadDetails?.sp?.id || 0
        })
      );
    }
  }, [leadDetails?.leadDetails?.sp]);

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };
  const handleSetConnectorId = value => {
    setIsConnectorId(value);
  };
  const [employmentType, setEmploymentType] = useState('');

  const validationSchema = Yup.object().shape({
    // Basic Details
    // applicantId: Yup.string().required('Applicant ID is required'),
    // leadId: Yup.string().required('Lead ID is required'),

    // name: Yup.string().required('Name is required'),
    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    // phoneNo: Yup.string()
    //   .matches(/^[0-9]+$/, 'Phone number must be numeric')
    //   .required('Phone number is required'),
    // dob: Yup.string().required('Date of Birth is required'),

    // Address Details
    addressLine1: Yup.string().required('Address line 1 is required'),
    addressLine2: Yup.string().required('Address line 2 is required'),
    // landmark: Yup.string().required('Landmark is required'),
    pincode: Yup.string().required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    permAddressLine1: Yup.string().required('Address line 1 is required'),
    permAddressLine2: Yup.string().required('Address line 2 is required'),
    // permLandmark: Yup.string().required('Landmark is required'),
    permPincode: Yup.string().required('Pincode is required'),
    permCity: Yup.string().required('City is required'),
    permState: Yup.string().required('State is required'),

    // // KYC Details

    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // aadhaarNumber: Yup.string()
    //   .required('Aadhaar Number is required')
    //   .matches(
    //     /^\d{12}$|^\d{4}\s\d{4}\s\d{4}$/,
    //     'Please enter a valid Aadhaar Number (12 digits or in format XXXX XXXX XXXX)'
    //   ),
    // passportNumber: Yup.string()
    //   .matches(
    //     /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/,
    //     'Please enter a valid Passport Number (e.g., A1234567)'
    //   )
    //   .nullable(),

    // voterIdNumber: Yup.string()
    //   .matches(
    //     /^[A-Z]{3}[0-9]{7}$/,
    //     'Please enter a valid Voter Id (e.g., ABC1234567)'
    //   )
    //   .nullable(),

    // Loan Details
    scheme: Yup.object({
      id: Yup.string().required('Scheme is required')
    }).required('Scheme is required'),

    purpose: Yup.object({
      id: Yup.string().required('Purpose is required')
    }).required('Purpose is required'),

    amountRequested: Yup.string().required('Amount Requested is required'),

    //Employment Details
    constitutionType: Yup.string().required('Constitution Type is required'),
    employerName: Yup.string().required('Name is required'),
    employerAddress: Yup.string().required('Address is required'),
    empPincode: Yup.string().required('Pincode is required'),
    empCity: Yup.string().required('City is required'),
    empState: Yup.string().required('State is required'),
    // sinceYear: Yup.string().required('Year is required'),
    sinceYear: Yup.object({
      id: Yup.string().required('Year is required')
    }).required('Year is required'),
    sinceMonth: Yup.string().required('Month is required'),

    //Connector mapping
    // searchConnectorId: Yup.string().required('Connector ID is required'),
    connectorID:
      isConnectorId == 'Yes' &&
      Yup.object({
        id: Yup.string().required('Connector Code is required')
      }).required('Connector Code is required'),

    //Property details
    // propAddressLine1: Yup.string().required('Address line 1 is required'),
    // propAddressLine2: Yup.string().required('Address line 2 is required'),
    // propLandmark: Yup.string().required('Landmark is required'),
    // propPincode: Yup.string().required('Pincode is required'),
    // propCity: Yup.string().required('City is required'),
    // propState: Yup.string().required('State is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area office is required')
    }).required('Area office is required')
  });

  useEffect(() => {
    setEmploymentType(
      leadDetails?.customerDetails?.employmentDetails?.status || 'Salaried'
    );

    formik.setValues({
      ...formik.values,
      productId: leadDetails?.leadDetails?.productMaster?.id,
      applicantId: leadDetails?.customerDetails?.customerId,
      leadId: leadDetails?.leadDetails?.applicationId || '',
      name: leadDetails?.customerDetails?.name || '',
      emailId: leadDetails?.customerDetails?.emailId || '',
      phoneNo: leadDetails?.customerDetails?.mobileNumber || '',
      dob: leadDetails?.customerDetails?.dob || '',
      addressLine1:
        leadDetails?.customerDetails?.customerAddress?.currentAddressLine1 ||
        '',
      addressLine2:
        leadDetails?.customerDetails?.customerAddress?.currentAddressLine2 ||
        '',
      landmark:
        leadDetails?.customerDetails?.customerAddress?.currentLandmark || '',
      pincode:
        leadDetails?.customerDetails?.customerAddress?.currentPincode || '',
      city: leadDetails?.customerDetails?.customerAddress?.currentCity || '',
      state: leadDetails?.customerDetails?.customerAddress?.currentState || '',
      sameAsCurrent:
        leadDetails?.customerDetails?.customerAddress?.sameAsCurrent || '',
      permAddressLine1:
        leadDetails?.customerDetails?.customerAddress?.permanentAddressLine1 ||
        '',
      permAddressLine2:
        leadDetails?.customerDetails?.customerAddress?.permanentAddressLine2 ||
        '',
      permLandmark:
        leadDetails?.customerDetails?.customerAddress?.permanentLandmark || '',
      permPincode:
        leadDetails?.customerDetails?.customerAddress?.permanentPincode || '',
      permCity:
        leadDetails?.customerDetails?.customerAddress?.permanentCity || '',
      permState:
        leadDetails?.customerDetails?.customerAddress?.permanentState || '',
      panNumber: leadDetails?.customerDetails?.panNumber || '',
      aadhaarNumber: leadDetails?.customerDetails?.aadhaarNumber || '',
      passportNumber: leadDetails?.customerDetails?.passportNumber || '',
      voterIdNumber: leadDetails?.customerDetails?.voterId || '',
      // Connector Mapping
      searchConnectorId:
        leadDetails?.customerDetails?.connector?.connectorId || '',

      connectorID: leadDetails?.customerDetails?.connector?.id
        ? {
            connectorId: leadDetails?.customerDetails?.connector?.connectorId,
            id: leadDetails?.customerDetails?.connector?.id || '',
            name: leadDetails?.customerDetails?.connector?.name || ''
          }
        : null,
      scheme: leadDetails?.leadDetails?.schemeMaster?.id
        ? {
            name: leadDetails?.leadDetails?.schemeMaster?.name || '',
            id: leadDetails?.leadDetails?.schemeMaster?.id || ''
          }
        : null,
      purpose: leadDetails?.leadDetails?.purposeOfLoan?.id
        ? {
            label: leadDetails?.leadDetails?.purposeOfLoan?.purpose || '',
            id: leadDetails?.leadDetails?.purposeOfLoan?.id || ''
          }
        : null,
      amountRequested: leadDetails?.leadDetails?.amountRequested || '',
      // Employement Details for Salaried

      employmentType:
        leadDetails?.customerDetails?.employmentDetails?.status || 'Salaried',
      constitutionType:
        leadDetails?.customerDetails?.employmentDetails?.constitutionType || '',
      employerName: leadDetails?.customerDetails?.employmentDetails?.name || '',
      employerAddress:
        leadDetails?.customerDetails?.employmentDetails?.address || '',
      // empVoterIdNumber:
      //   leadDetails?.customerDetails?.employmentDetails?.voterId || '',
      empPincode:
        leadDetails?.customerDetails?.employmentDetails?.pincode || '',
      empCity: leadDetails?.customerDetails?.employmentDetails?.city || '',
      empState: leadDetails?.customerDetails?.employmentDetails?.state || '',
      sinceYear: leadDetails?.customerDetails?.employmentDetails?.sinceYear
        ? {
            value: leadDetails?.customerDetails?.employmentDetails?.sinceYear,
            id: leadDetails?.customerDetails?.employmentDetails?.sinceYear
          }
        : null,
      sinceMonth:
        leadDetails?.customerDetails?.employmentDetails?.sinceMonth || '',
      propAddressLine1: leadDetails?.leadDetails?.addressLine1 || '',
      propAddressLine2: leadDetails?.leadDetails?.addressLine2 || '',
      propLandmark: leadDetails?.leadDetails?.landmark || '',
      propPincode: leadDetails?.leadDetails?.pincode || '',
      propCity: leadDetails?.leadDetails?.city || '',
      propState: leadDetails?.leadDetails?.state || '',
      sourcePerson: leadDetails?.leadDetails?.sp || null,
      assistantPerson: leadDetails?.leadDetails?.ap || null,
      leadSource: leadDetails?.leadDetails?.leadSource || null,
      areaOffice: leadDetails?.leadDetails?.areaOffice || null
    });
    //  For Update API
    const updatedCoapplicantDetails = leadDetails?.coapplicantDetails?.map(
      coapplicant => {
        return {
          ...coapplicant,
          isPhoto: 'true'
        };
      }
    );

    setLeadApplicantData(updatedCoapplicantDetails);
  }, [leadDetails]);

  const formik = useFormik({
    initialValues: {
      productId: '',
      file: '',
      isPhotoChange: false,

      //Basic Details
      applicantId: '',
      leadId: '',
      name: '',
      emailId: '',
      phoneNo: '',
      dob: '',

      // Address Details
      addressLine1: '',
      addressLine2: '',
      landmark: '',
      pincode: '',
      city: '',
      state: '',
      permAddressLine1: '',
      permAddressLine2: '',
      permLandmark: '',
      permPincode: '',
      permCity: '',
      permState: '',
      sameAsCurrent: '',

      // KYC Details
      panNumber: '',
      aadhaarNumber: '',
      passportNumber: '',
      voterIdNumber: '',

      //Loan Details
      scheme: null,
      purpose: null,
      amountRequested: '',

      //Employment Details
      employmentType: leadDetails?.customerDetails?.employmentDetails?.status,
      constitutionType: '',
      employerName: '',
      employerAddress: '',
      // empVoterIdNumber: '',
      empPincode: '',
      empCity: '',
      empState: '',
      sinceMonth: '',
      sinceYear: null,

      //Connector mapping
      searchConnectorId: '',
      connectorID: null,

      //Property details
      propAddressLine1: '',
      propAddressLine2: '',
      propLandmark: '',
      propPincode: '',
      propCity: '',
      propState: '',

      sourcePerson: null,
      assistantPerson: null,
      leadSource: null,
      areaOffice: null
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleUpdate();
    }
  });

  useEffect(() => {
    if (!isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: formik.values?.sourcePerson?.id || 0
        })
      );
    }
  }, [formik.values?.sourcePerson]);

  useEffect(() => {
    if (!isFirstTime) {
      if (assistantPersonList) {
        formik.setFieldValue('assistantPerson', assistantPersonList[0]);
      }
    }
  }, [assistantPersonList]);

  const handleUpdateData = data => {
    setLeadApplicantData(data);
  };

  const handleUpdate = () => {
    dispatch(setCallValidation(true));
  };
  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const [docArray3, setDocArray3] = useState([]);
  const [docArray4, setDocArray4] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
    if (docNumber === 3) {
      setDocArray3(array);
    }
    if (docNumber === 4) {
      setDocArray4(array);
    }
  };

  useEffect(() => {
    if (!waitForExecution) {
      if (!isValid) {
        return;
      } else {
        let coApplicantData = [...leadApplicantData];

        const transformedArray = coApplicantData?.map(item => {
          if (
            typeof item.coapplicantId === 'string' &&
            item.coapplicantId.includes('ABC')
          ) {
            const {
              coapplicantId,
              photo,
              file,
              pincode,
              currentPincode,
              currentAddressLine1,
              currentAddressLine2,
              currentLandmark,
              currentCity,
              currentState,
              sameAsCurrent,
              permanentPincode,
              permanentAddressLine1,
              permanentAddressLine2,
              permanentLandmark,
              permanentCity,
              permanentState,
              ...rest
            } = item;
            const transformedPincode = pincode ? parseInt(pincode) : 0;
            const transformedCurrentPincode = currentPincode
              ? parseInt(currentPincode)
              : 0;
            const transformedPermanatPincode = permanentPincode
              ? parseInt(permanentPincode)
              : 0;
            return {
              ...rest,
              pincode: transformedPincode,
              currentPincode: transformedCurrentPincode,
              permanentPincode:
                sameAsCurrent === 'Yes'
                  ? transformedCurrentPincode
                  : transformedPermanatPincode,
              permanentAddressLine1:
                sameAsCurrent === 'Yes'
                  ? currentAddressLine1
                  : permanentAddressLine1,
              permanentAddressLine2:
                sameAsCurrent === 'Yes'
                  ? currentAddressLine2
                  : permanentAddressLine2,
              permanentLandmark:
                sameAsCurrent === 'Yes' ? currentLandmark : permanentLandmark,
              permanentCity:
                sameAsCurrent === 'Yes' ? currentCity : permanentCity,
              permanentState:
                sameAsCurrent === 'Yes' ? currentState : permanentState,
              currentAddressLine1: currentAddressLine1,
              currentAddressLine2: currentAddressLine2,
              currentLandmark: currentLandmark,
              currentCity: currentCity,
              currentState: currentState,
              sameAsCurrent: sameAsCurrent
            };
          } else {
            const {
              photo,
              file,
              pincode,
              currentPincode,
              currentAddressLine1,
              currentAddressLine2,
              currentLandmark,
              currentCity,
              currentState,
              sameAsCurrent,
              permanentPincode,
              permanentAddressLine1,
              permanentAddressLine2,
              permanentLandmark,
              permanentCity,
              permanentState,
              ...rest
            } = item;
            const transformedPincode = pincode ? parseInt(pincode) : 0;
            const transformedCurrentPincode = currentPincode
              ? parseInt(currentPincode)
              : 0;
            const transformedPermanatPincode = permanentPincode
              ? parseInt(permanentPincode)
              : 0;
            return {
              ...rest,
              pincode: transformedPincode,
              currentPincode: transformedCurrentPincode,
              permanentPincode:
                sameAsCurrent === 'Yes'
                  ? transformedCurrentPincode
                  : transformedPermanatPincode,
              permanentAddressLine1:
                sameAsCurrent === 'Yes'
                  ? currentAddressLine1
                  : permanentAddressLine1,
              permanentAddressLine2:
                sameAsCurrent === 'Yes'
                  ? currentAddressLine2
                  : permanentAddressLine2,
              permanentLandmark:
                sameAsCurrent === 'Yes' ? currentLandmark : permanentLandmark,
              permanentCity:
                sameAsCurrent === 'Yes' ? currentCity : permanentCity,
              permanentState:
                sameAsCurrent === 'Yes' ? currentState : permanentState,
              currentAddressLine1: currentAddressLine1,
              currentAddressLine2: currentAddressLine2,
              currentLandmark: currentLandmark,
              currentCity: currentCity,
              currentState: currentState,
              sameAsCurrent: sameAsCurrent
            };
          }
        });
        let leadRequest = {
          photo: formik?.values?.file
            ? 'false'
            : !formik.values.isPhotoChange
              ? 'true'
              : formik?.values?.isPhotoChange && formik.values?.photo == null
                ? ''
                : 'empty',
          customerId: customerId,
          leadId: leadId,
          applicationId: formik.values.leadId,
          name: formik.values.name,
          emailId: formik.values.emailId,
          mobileNumber: formik.values.phoneNo,
          dob: formik.values.dob,
          addressId: leadDetails?.customerDetails?.customerAddress?.id || 0,
          currentAddressLine1: formik.values.addressLine1,
          currentAddressLine2: formik.values.addressLine2,
          currentLandmark: formik.values.landmark,
          currentPincode: formik.values?.pincode
            ? parseInt(formik.values.pincode)
            : 0,
          currentCity: formik.values.city,
          currentState: formik.values.state,
          sameAsAbove: formik.values.sameAsCurrent,
          permanentAddressLine1: formik.values.permAddressLine1,
          permanentAddressLine2: formik.values.permAddressLine2,
          permanentLandmark: formik.values.permLandmark,
          permanentPincode: formik.values?.permPincode
            ? parseInt(formik.values.permPincode)
            : 0,
          permanentCity: formik.values.permCity,
          permanentState: formik.values.permState,
          panNumber: formik.values.panNumber,
          aadhaarNumber: formik.values.aadhaarNumber,
          voterId: formik.values.voterIdNumber,
          passportNumber: formik.values.passportNumber,
          employmentStatus: formik.values.employmentType,
          constitutionType: formik.values.constitutionType,
          employeeOrBusinessName: formik.values.employerName,

          employeeVoterId: formik.values.empVoterIdNumber || '',

          employerOrBusinessAddress: formik.values.employerAddress,

          employerOrBusinessPincode: formik.values.empPincode,

          employerOrBusinessCity: formik.values.empCity,

          employerOrBusinessState: formik.values.empState,

          employedSinceYear: formik.values.sinceYear?.id,
          employedSinceMonth: formik.values.sinceMonth,
          businessThroughConnector: formik.values.searchConnectorId
            ? 'Yes'
            : 'No',
          connectorId: formik.values.connectorID?.id,

          coapplicant: transformedArray,
          product: 1,
          scheme: formik.values.scheme?.id,
          purposeOfLoan: formik.values.purpose?.id,
          amountRequested: formik.values.amountRequested
            ? parseFloat(formik.values.amountRequested).toFixed(2)
            : 0,
          propertyAddressLine1: formik.values.propAddressLine1,
          propertyAddressLine2: formik.values.propAddressLine2,
          propertyLandmark: formik.values.propLandmark,
          propertyPincode: formik.values?.propPincode
            ? parseInt(formik.values.propPincode)
            : 0,
          propertyCity: formik.values.propCity,
          propertyState: formik.values.propState,
          sp: formik.values?.sourcePerson?.id || 0,
          ap: formik.values?.assistantPerson?.id || 0,
          leadSource: formik.values?.leadSource?.id || 0,
          areaOffice: formik.values?.areaOffice?.id || 0
        };

        const formData = new FormData();
        formData.append('editLeadRequest', JSON.stringify(leadRequest));
        formData.append(
          'photo',
          formik.values.file ? formik.values.file : null
        );
        formData.append(
          'coapplicantPhoto1',
          coApplicantData?.[0]?.file || null
        );
        formData.append(
          'coapplicantPhoto2',
          coApplicantData?.[1]?.file || null
        );
        formData.append(
          'coapplicantPhoto3',
          coApplicantData?.[2]?.file || null
        );
        formData.append(
          'coapplicantPhoto4',
          coApplicantData?.[3]?.file || null
        );
        formData.append(
          'document1',
          docArray1?.[0]?.lastModified ? docArray1[0] : null
        );
        formData.append(
          'document2',
          docArray2?.[0]?.lastModified ? docArray2[0] : null
        );
        formData.append(
          'document3',
          docArray3?.[0]?.lastModified ? docArray3[0] : null
        );
        formData.append(
          'document4',
          docArray4?.[0]?.lastModified ? docArray4[0] : null
        );

        dispatch(updateLeadDetails({ payload: formData }));
      }
    }

    dispatch(setWaitExecution(true));
  }, [!waitForExecution, isValid]);

  const handleChange = (value, index, file) => {
    formik.setFieldValue('photo', value);
    formik.setFieldValue('file', file);
    formik.setFieldValue('isPhotoChange', true);
  };

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };
  const handleDeleteDocument = () => {
    dispatch(deleteLeadDocuments({ fileUrl: selectedFileName, product: 1 }));
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId }));
    }, 2000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const doc1 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );
  const doc3 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document3')),
    [leadDocuments]
  );
  const doc4 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document4')),
    [leadDocuments]
  );
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isGetLeadDetailsLoading && (
          <form>
            <BasicDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <UploadPhotoComponent
              base64String={leadDetails?.customerDetails?.photo || ''}
              handleSetImageData={handleChange}
              Index={'ABC'}
              phtotLimit={'2'}
              contentType={leadDetails?.customerDetails?.contentType || ''}
            />
            <Divider style={{ marginBottom: '12px' }} />

            <AddressDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <KYCDetails formik={formik} isAdhaarMandatory={true} />
            <Divider style={{ marginBottom: '12px' }} />

            <EmploymentDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <ConnectorMapping
              formik={formik}
              handleSetId={handleSetConnectorId}
            />
            <Divider style={{ marginBottom: '12px' }} />

            {/* Co-Applicant */}
            <LeadCoApplicant
              handleSetData={handleUpdateData}
              ref={childComponentRef}
              coapplicantDetails={leadDetails?.coapplicantDetails}
            />

            <Divider style={{ marginBottom: '12px' }} />

            <LeadSourceDetails
              formik={formik}
              handleState={handleFirstTimeValue}
            />

            <Divider style={{ marginBottom: '12px' }} />
            <LoanDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <PropertyDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc3}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={3}
                      limit={1}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc4}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={4}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            <Divider style={{ marginBottom: '12px' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: 1
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>
          </form>
        )}

        {!isLeadUpdateLoading && isLeadUpdateSuccess && (
          <SnackBarBox
            type="success"
            message={'Lead data update successfully'}
          />
        )}

        {!isLeadUpdateLoading && isLeadUpdateFailed && (
          <SnackBarBox
            type="error"
            message={handleError(leadUpdateErrorContainer?.message)}
          />
        )}
      </div>
    </>
  );
}

export default CWALeadEdit;
