import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, styled } from '@mui/material';
import CenteredLogo from '../components/CenteredLogo';
import { clearLoginState } from '../redux/reducers/authSlice';
import { createTitle, handleError } from '../utils/utils';
import { IMG_LOGIN_BG } from '../utils/imageUrls';
import SnackBarBox from '../components/SnackBarBox';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../containers/auth/LoginForm';
import { getMenuList } from '../config/cookie';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuDetails = getMenuList();

  useEffect(() => {
    // dispatch(pingServer());
    document.title = createTitle('Login');
    return () => dispatch(clearLoginState());
  }, []);

  const { isLoading, isLoggedIn, isError, errorContainer } = useSelector(
    state => state.auth
  );

  useEffect(() => {
    console.log('Enter');
    if (!isLoading && isLoggedIn) {
      // console.log("Enter")
      if (menuDetails?.temporaryPassword) {
        navigate(`/reset-new-password`, {
          state: { empId: menuDetails?.empId }
        });
      } else {
        process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
          ? navigate('/adminf/dashboard')
          : navigate('/cwaf/dashboard');
      }

      return;
    }
  }, [isLoggedIn]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${IMG_LOGIN_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        />

        <CustomGrid item xs={12} sm={5} md={5} lg={5} elevation={6}>
          <Box
            sx={{
              my: 10,
              mx: 4,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CenteredLogo />
            <Typography
              component="h1"
              variant="h5"
              fontWeight={'700'}
              color={'#000'}
              align="center"
            >
              Welcome!
            </Typography>

            <LoginForm />
          </Box>

          {!isLoading && isError && (
            <SnackBarBox
              type="error"
              message={handleError(errorContainer?.message)}
            />
          )}
        </CustomGrid>
      </Grid>
      {isLoading && <Loader key="ping" text="" />}
    </>
  );
}
