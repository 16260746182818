import { useState, useRef, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Card,
  CardMedia,
  CardActions,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Tooltip
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { colorPrimaryBlue } from '../config/theme';
import { api } from '../config/api';
import InfoIcon from '@mui/icons-material/Info';

const UploadPhotoComponent = ({
  base64String,
  imageType = 'png',
  handleSetImageData,
  Index,
  phtotLimit,
  contentType
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [cameraError, setCameraError] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (base64String) {
      const fileUrl = base64String;
      // const dataUrl = `data:image/${imageType};base64,${base64String}`;
      // setSelectedPhoto(dataUrl);
      // dispatch(viewPhoto({ fileUrl, contentType }));
      handlePhotoFromAPI(fileUrl, contentType);
      // handleSetImageData(dataUrl, Index);
    }
  }, [base64String]);

  const handlePhotoFromAPI = async (fileUrl, contentType) => {
    try {
      const response = await api.get(
        `view?fileUrl=${fileUrl}&contentType=${contentType}`,
        {
          responseType: 'blob'
        }
      );

      if (response.data) {
        const fileBlob = response.data;
        const fileURL = URL.createObjectURL(fileBlob);
        setSelectedPhoto(fileURL);
      }
    } catch (error) {
      return error.response.data;
    }
  };

  const handleBrowse = event => {
    setIsEdit(true);
    const file = event.target.files[0];
    const tempPhtotLimit = phtotLimit ? phtotLimit : 5;
    const maxSizeInBytes = tempPhtotLimit * 1024 * 1024; // 5 MB
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    // if (file) {
    //   setSelectedPhoto(URL.createObjectURL(file));
    //   handleSetImageData(URL.createObjectURL(file), Index, file);
    //   fileInputRef.current.value = '';
    // }
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        alert('Only PNG, JPG, and JPEG files are allowed.');
        return; // Stop further processing
      }

      // Check file size
      if (file.size > maxSizeInBytes) {
        alert(`File size should not exceed ${tempPhtotLimit} MB.`);
        return; // Stop further processing
      }

      // If validation passes
      setSelectedPhoto(URL.createObjectURL(file));
      handleSetImageData(URL.createObjectURL(file), Index, file);

      // Reset the input value
      fileInputRef.current.value = '';
    }
  };

  const handleCamera = async () => {
    setIsEdit(false);
    setOpenCamera(true);
    setCameraError('');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error accessing camera: ', err);
      setCameraError(
        'Unable to access the camera. Please check permissions and try again.'
      );
    }
  };
  // To convert suitable file for update
  const dataURLtoFile = (dataUrl, filename) => {
    let arr = dataUrl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleCapture = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      const imageUrl = canvasRef.current.toDataURL('image/png');
      const file = dataURLtoFile(imageUrl, 'captured_image.png');
      setSelectedPhoto(imageUrl);
      handleSetImageData(imageUrl, Index, file, true);
      setOpenCamera(false);
      // Stop video stream
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  const handleRemovePhoto = () => {
    setIsEdit(false);
    setSelectedPhoto(null);
    handleSetImageData(null, Index, null);
  };

  return (
    <div style={{ marginBottom: '16px' }} key={Index}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography fontSize={'18px'} color={colorPrimaryBlue} fontWeight={600}>
          Upload Photo
        </Typography>
        <Tooltip
          title="Upload a passport-sized photo with a white background."
          placement="right-start"
          style={{ marginLeft: '1rem' }}
        >
          <IconButton>
            <InfoIcon style={{ color: '#335DAD' }} />
          </IconButton>
        </Tooltip>
      </div>

      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={Index + 'upload-photo'}
        type="file"
        onChange={handleBrowse}
        ref={fileInputRef}
      />
      <Box mt={2}>
        <Box display="flex" justifyContent="space-between" width="200px">
          <label htmlFor={Index + 'upload-photo'}>
            <IconButton
              color="primary"
              component="span"
              sx={{
                border: '1px dashed',
                borderColor: 'primary.main',
                borderRadius: '5px',
                marginRight: '16px',
                backgroundColor: '#F3F7FF'
              }}
            >
              <FileUploadIcon />
              <Typography
                variant="body2"
                ml={1}
                color={colorPrimaryBlue}
                fontWeight={600}
                fontSize={'small'}
              >
                BROWSE
              </Typography>
            </IconButton>
          </label>
          <IconButton
            color="primary"
            onClick={handleCamera}
            sx={{
              border: '1px dashed',
              borderColor: 'primary.main',
              borderRadius: '5px',
              backgroundColor: '#F3F7FF'
            }}
          >
            <PhotoCameraIcon />
            <Typography
              variant="body2"
              ml={1}
              color={colorPrimaryBlue}
              fontWeight={600}
              fontSize={'small'}
            >
              CAMERA
            </Typography>
          </IconButton>
        </Box>
        {selectedPhoto && (
          <Card sx={{ position: 'relative', display: 'inline-block', mt: 2 }}>
            <CardMedia
              component="img"
              sx={{
                height: '170px',
                width: '200px',
                maxHeight: '60mm',
                maxWidth: '60mm'
              }}
              image={
                isEdit
                  ? selectedPhoto.replace(/^data:image\/png;base64,/, '')
                  : selectedPhoto
              }
              alt="Selected Photo"
            />
            <CardActions sx={{ position: 'absolute', top: 0, right: 0 }}>
              <IconButton
                onClick={handleRemovePhoto}
                size="small"
                sx={{
                  backgroundColor: 'red',
                  '&:hover': { backgroundColor: 'darkred' },
                  color: 'white'
                }}
              >
                <CloseIcon />
              </IconButton>
            </CardActions>
          </Card>
        )}
      </Box>

      {/* Dialog for camera feed */}
      <Dialog
        open={openCamera}
        onClose={() => {
          setOpenCamera(false);
          if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject
              .getTracks()
              .forEach(track => track.stop());
          }
        }}
      >
        <DialogContent>
          {cameraError && <Alert severity="error">{cameraError}</Alert>}
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCapture}
            color="primary"
            disabled={!!cameraError}
          >
            Capture
          </Button>
          <Button
            onClick={() => {
              setOpenCamera(false);
              if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject
                  .getTracks()
                  .forEach(track => track.stop());
              }
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
        <canvas
          ref={canvasRef}
          style={{ display: 'none' }}
          width="640"
          height="480"
        ></canvas>
      </Dialog>
    </div>
  );
};

export default UploadPhotoComponent;
