import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import SingleDocUpload from './SingleDocUpload';

function BusinessDetails({ formik }) {
  const handleSetDocument = (data, isChange) => {
    if (isChange) {
      formik.setFieldValue('isPanChange', true);
      formik.setFieldValue('panUploadFile', null);
      formik.setFieldValue('panUpload', null);
    }
    if (data.length > 0) {
      formik.setFieldValue('isPanChange', true);
      formik.setFieldValue('panUploadFile', data[0]);
      formik.setFieldValue('panUpload', data[0]);
    }
  };
  const handleSetDocument1 = (data, isChange) => {
    if (isChange) {
      formik.setFieldValue('isMsmeChange', true);
      formik.setFieldValue('msmeUpload', null);
      formik.setFieldValue('msmeUploadFile', null);
    }
    if (data.length > 0) {
      formik.setFieldValue('isMsmeChange', true);
      formik.setFieldValue('msmeUpload', data[0]);
      formik.setFieldValue('msmeUploadFile', data[0]);
    }
  };
  const [mandatory, setMandatory] = useState(false);
  useEffect(() => {
    const isMandatory =
      formik?.values?.constitutionType === 2 ||
      formik?.values?.constitutionType === 3 ||
      formik?.values?.constitutionType === 4;
    if (isMandatory) {
      setMandatory(true);
    } else {
      setMandatory(false);
    }
  }, [formik]);
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Business Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField
              label={`Business Address${mandatory ? ' *' : ''}`}
              name="bizAddress"
              placeholder="Enter resident address"
              size="small"
              fullWidth
              value={formik.values.bizAddress}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.bizAddress !== '' }}
              error={
                mandatory &&
                formik.touched.bizAddress &&
                Boolean(formik.errors.bizAddress)
              }
              helperText={
                mandatory &&
                formik.touched.bizAddress &&
                formik.errors.bizAddress
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label={`Business Mobile Number${mandatory ? ' *' : ''}`}
              name="bizMobileNumber"
              size="small"
              fullWidth
              value={formik.values.bizMobileNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.bizMobileNumber !== '' }}
              error={
                mandatory &&
                formik.touched.bizMobileNumber &&
                Boolean(formik.errors.bizMobileNumber)
              }
              helperText={
                mandatory &&
                formik.touched.bizMobileNumber &&
                formik.errors.bizMobileNumber
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label={`Business Email ID${mandatory ? ' *' : ''}`}
              name="bizEmailId"
              size="small"
              fullWidth
              value={formik.values.bizEmailId}
              // onChange={formik.handleChange}
              onChange={e => {
                const lowerCaseValue = e.target.value.toLowerCase();
                formik.setFieldValue('bizEmailId', lowerCaseValue);
              }}
              InputLabelProps={{ shrink: formik.values.bizEmailId !== '' }}
              error={
                mandatory &&
                formik.touched.bizEmailId &&
                Boolean(formik.errors.bizEmailId)
              }
              helperText={
                mandatory &&
                formik.touched.bizEmailId &&
                formik.errors.bizEmailId
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Business Since(years) *"
              name="bizSinceYears"
              size="small"
              fullWidth
              value={formik.values.bizSinceYears}
              onChange={formik.handleChange}
              onKeyPress={e => {
                const currentValue = formik.values.bizSinceYears; // added
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Tab'
                ) {
                  e.preventDefault();
                }
              }}
              InputLabelProps={{ shrink: formik.values.bizSinceYears !== '' }}
              error={
                formik.touched.bizSinceYears &&
                Boolean(formik.errors.bizSinceYears)
              }
              helperText={
                formik.touched.bizSinceYears && formik.errors.bizSinceYears
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Business Place *"
              name="bizPlace"
              size="small"
              fullWidth
              value={formik.values.bizPlace}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.bizPlace !== '' }}
              error={formik.touched.bizPlace && Boolean(formik.errors.bizPlace)}
              helperText={formik.touched.bizPlace && formik.errors.bizPlace}
            />
          </Grid>

          <Grid item lg={4}>
            <TextField
              label="GST Number"
              name="gstNumber"
              size="small"
              fullWidth
              value={formik.values.gstNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.gstNumber !== '' }}
              // error={
              //   formik.touched.gstNumber && Boolean(formik.errors.gstNumber)
              // }
              // helperText={formik.touched.gstNumber && formik.errors.gstNumber}
            />
          </Grid>
          {/* <Grid item lg={4}></Grid> */}
          <Grid item lg={6}>
            <TextField
              label="PAN Number *"
              name="panNumber"
              size="small"
              fullWidth
              value={formik.values.panNumber}
              // onChange={formik.handleChange}
              onChange={e => {
                // Convert input to uppercase
                const uppercaseValue = e.target.value.toUpperCase();
                formik.setFieldValue('panNumber', uppercaseValue);
              }}
              InputLabelProps={{ shrink: formik.values.panNumber !== '' }}
              error={
                formik.touched.panNumber && Boolean(formik.errors.panNumber)
              }
              helperText={formik.touched.panNumber && formik.errors.panNumber}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              select
              label="Is your firm registered as MSME *"
              name="firmRegisteredMSME"
              size="small"
              fullWidth
              value={formik.values?.firmRegisteredMSME}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.firmRegisteredMSME !== ''
              }}
              error={
                formik.touched.firmRegisteredMSME &&
                Boolean(formik.errors.firmRegisteredMSME)
              }
              helperText={
                formik.touched.firmRegisteredMSME &&
                formik.errors.firmRegisteredMSME
              }
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Grid>
          {/* <Grid item lg={4}></Grid> */}
          <Grid item lg={6}>
            <SingleDocUpload
              documentsArr={
                formik?.values?.panUploadFile
                  ? formik?.values?.panUploadFile
                  : formik?.values?.panUpload
                    ? [formik?.values?.panUpload]
                    : []
              }
              handleSetData={handleSetDocument}
              title={'Pan Upload'}
            />
          </Grid>
          {formik.values.firmRegisteredMSME === 'Yes' && (
            <Grid item lg={6}>
              <SingleDocUpload
                documentsArr={
                  formik?.values?.msmeUploadFile
                    ? formik?.values?.msmeUploadFile
                    : formik?.values?.msmeUpload
                      ? [formik?.values?.msmeUpload]
                      : []
                }
                handleSetData={handleSetDocument1}
                title={'MSME Upload'}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default BusinessDetails;
