import { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from '@mui/material';
import { AttachmentOutlined, Visibility } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { viewDocument } from '../../redux/reducers/leadSlice';
import InfoIcon from '@mui/icons-material/Info';

function UploadDocumentsLead({
  documentsArr,
  handleSetData,
  handleDelete,
  limit = 100,
  docNumber
}) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const apiFiles = documentsArr?.map(file => ({
      name: file.fileName,
      url: file.s3ObjectKey,
      contentType: file.contentType
    }));
    setFiles(apiFiles);
    handleSetData(apiFiles, docNumber);
  }, [documentsArr]);

  // const handleFileChange = event => {

  //   setFiles([...files, ...Array.from(event.target.files)]);
  //   handleSetData([...files, ...Array.from(event.target.files)]);
  // };

  const handleFileChange = event => {
    if (limit == files.length) {
      alert(`Only ${limit} upload is allowed`);
      return;
    }
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    const invalidFiles = [];
    const maxSizeInBytes = 2 * 1024 * 1024; // 5 MB in bytes

    selectedFiles.forEach(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isValidFileType =
        ((fileExtension === 'jpg' || fileExtension === 'jpeg') &&
          file.type === 'image/jpeg') ||
        (fileExtension === 'pdf' && file.type === 'application/pdf');
      const isValidFileSize = file.size <= maxSizeInBytes;

      if (isValidFileType && isValidFileSize) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    handleSetData([...files, ...validFiles], docNumber);

    if (invalidFiles.length > 0) {
      alert(
        'You can upload only JPG, JPEG, and PDF files, with a size limit of 2 MB each.'
      );
    }
  };

  const handleFileRemove = (index, fileName) => {
    // setFiles(files.filter((_, i) => i !== index));
    // handleSetData(files.filter((_, i) => i !== index),docNumber);
    handleDelete(files, index, fileName);
  };

  const handleFileView = file => {
    if (file instanceof File) {
      // For local files
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      // For files from the API
      const fileUrl = file.url;
      const contentType = file.contentType;
      dispatch(viewDocument({ fileUrl, contentType }));
    }
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }} key={docNumber}>
        <Typography
          color="#000000"
          fontSize={'16px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Document {docNumber}
        </Typography>
        <Button
          variant="contained"
          component="label"
          style={{
            backgroundColor:
              files.length > limit || files.length == limit
                ? '#EEEEEE'
                : '#F3F7FF',
            // backgroundColor: '#F3F7FF',
            color: '#335DAD',
            border: '1px dashed',
            borderColor: 'primary.main',
            fontWeight: '600',
            cursor:
              files.length > limit || files.length == limit
                ? 'not-allowed'
                : 'pointer'
          }}
        >
          <FileUploadIcon />
          UPLOAD
          <input
            disabled={
              files.length > limit || files.length == limit ? true : false
            }
            type="file"
            hidden
            multiple
            onChange={handleFileChange}
          />
        </Button>
        <Tooltip
          title="Only JPG, JPEG, and PDF files, with a maximum size limit of 2 MB each are allowed."
          placement="right-start"
          style={{ marginLeft: '1rem' }}
        >
          <IconButton>
            <InfoIcon style={{ color: '#335DAD' }} />
          </IconButton>
        </Tooltip>
      </div>

      <List style={{ width: '500px' }}>
        {files?.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              '&:hover': { backgroundColor: '#f5f5f5' },
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <IconButton style={{ cursor: 'inherit' }}>
              <AttachmentOutlined />
            </IconButton>
            <ListItemText primary={file.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleFileView(file)}
                style={{ marginRight: '4px' }}
              >
                <Visibility />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => handleFileRemove(index, file.url)}
              >
                {/* <RemoveCircleOutline /> */}
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default UploadDocumentsLead;
