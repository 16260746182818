import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function BasicDetails({ formik }) {
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Basic Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Applicant ID"
              name="applicantId"
              size="small"
              fullWidth
              value={formik.values.applicantId}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.applicantId !== ''
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Lead ID"
              name="leadId"
              size="small"
              fullWidth
              value={formik.values.leadId}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.leadId !== '' }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Name as per PAN Card"
              name="name"
              placeholder="Enter Name"
              size="small"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.name !== '' }}
              // error={formik.touched.name && Boolean(formik.errors.name)}
              // helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Email ID"
              name="emailId"
              size="small"
              fullWidth
              value={formik.values.emailId}
              onChange={e => {
                formik.setFieldValue('emailId', e.target.value.toLowerCase());
              }}
              InputLabelProps={{ shrink: formik.values.emailId !== '' }}
              // error={formik.touched.emailId && Boolean(formik.errors.emailId)}
              // helperText={formik.touched.emailId && formik.errors.emailId}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Phone Number"
              name="phoneNo"
              size="small"
              fullWidth
              value={formik.values.phoneNo}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.phoneNo !== '' }}
              // error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              // helperText={formik.touched.phoneNo && formik.errors.phoneNo}
            />
          </Grid>

          <Grid item lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled
                label="Date of Birth"
                value={formik?.values?.dob ? dayjs(formik?.values?.dob) : null}
                size="small"
                fullWidth
                format="DD-MM-YYYY"
                maxDate={dayjs().subtract(18, 'year')} // Restrict to 18 years back
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      borderRadius: '8px',
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        },
                        '&:hover fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        }
                      }
                    }
                  }
                }}
                onChange={value => formik.setFieldValue('dob', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    name="dob"
                    size="small"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        },
                        '&:hover fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor:
                            formik?.touched?.dob && formik?.errors?.dob
                              ? 'red'
                              : 'inherit'
                        }
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={formik?.touched?.dob && Boolean(formik?.errors?.dob)}
                    helperText={formik?.touched?.dob && formik?.errors?.dob}
                  />
                )}
              />
            </LocalizationProvider>
            {formik.touched.dob && formik.errors.dob && (
              <FormHelperText>{formik.errors.dob}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default BasicDetails;
