import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import {
  downloadErrorFile,
  downloadFile,
  downloadFileCSVPostApi
} from '../../utils/utils';
import { getToken } from '../../config/cookie';
import moment from 'moment';

export const revertAllConnectorData = createAction('REVERT_CONNECTOR_DATA');

const intial = {
  isGetConnectorLoading: false,
  isGetConnectorSuccess: false,
  isGetConnectorFailed: false,
  connectorDetails: null,
  getConnectorErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetAllConstitutionTypesLoading: false,
  isGetAllConstitutionTypesSuccess: false,
  isGetAllConstitutionTypesFailed: false,
  allConstitutionTypes: null,
  getAllConstitutionTypesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetAllProfilesLoading: false,
  isGetAllProfilesSuccess: false,
  isGetAllProfilesFailed: false,
  allProfiles: null,
  getAllProfilesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isConnectorAuditLogLoading: false,
  isConnectorAuditLogSuccess: false,
  isConnectorAuditLogFailed: false,
  connectorAuditLogData: {
    logList: [],
    totalCount: 0
  },
  connectorAuditLogErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetConnectorListLoading: false,
  isGetConnectorListSuccess: false,
  isGetConnectorListFailed: false,
  connectorData: {
    connectorList: [],
    totalCount: 0
  },
  connectorListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUpdateConnectorDataLoading: false,
  isUpdateConnectorDataSuccess: false,
  isUpdateConnectorDataFailed: false,
  UpdateConnectorDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUpdateConnectorStatusLoading: false,
  isUpdateConnectorStatusSuccess: false,
  isUpdateConnectorStatusFailed: false,
  updateConnectorStatusMsg: '',
  UpdateConnectorStatusErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadConnectorRptLoading: true,
  isDownloadConnectorRptSuccess: false,
  isDownloadConnectorRptFailed: false,
  downloadConnectorRptErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCWAConnectorAuditLogLoading: false,
  isCWAConnectorAuditLogSuccess: false,
  isCWAConnectorAuditLogFailed: false,
  cwaConnectorAuditLogData: {
    logList: [],
    totalCount: 0
  },
  cwaConnectorAuditLogErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCWAConnectorDetailsLoading: false,
  isCWAConnectorDetailsSuccess: false,
  isCWAConnectorDetailsFailed: false,
  cwaConnectorDetails: null,
  cwaConnectorDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddUpdateCwaConnectorLoading: false,
  isAddUpdateCwaConnectorSuccess: false,
  isAddUpdateCwaConnectorFailed: false,
  addUpdateCwaConnectorResponse: '',
  addUpdateCwaConnectorErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  updateConnectorStatusLoading: false,
  updateConnectorStatusSuccess: false,
  updateConnectorStatusFailed: false,
  updateConnectorStatusResponse: '',
  updateConnectorStatusErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadConnectorDataLoading: false,
  isUploadConnectorDataSuccess: false,
  isUploadConnectorDataFailure: false,
  isExcelAvailable: null,
  uploadConnectorDataStatus: '',
  uploadConnectorDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isEmployeeMappingLoading: false,
  isEmployeeMappingSuccess: false,
  isEmployeeMappingFailure: false,
  employeeMappingList: null,
  employeeMappingListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isStatusListLoading: false,
  isStatusListSuccess: false,
  isStatusListFailed: false,
  statusList: null,
  statusListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadLoading: false,
  isValid: false,
  callValidation: false,
  waitForExecution: true
};

export const getConnectorDetails = createAsyncThunk(
  'users/getConnectorDetails',
  async (connectorId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `getConnectorById?connectorId=${connectorId}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllConstitutionTypes = createAsyncThunk(
  'users/getAllConstitutionTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`getAllConstitutionTypes`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllProfiles = createAsyncThunk(
  'users/getAllProfiles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`getAllProfiles`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getConnectorAuditLogs = createAsyncThunk(
  'users/getConnectorAuditLogs',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        response = await api.get(
          `/connector/auditlog?connectorId=${payload.connectorId}&page=${payload.page}&limit=${payload.limit}`
        );
      } else {
        response = await api.get(
          `/connector/auditlog?connectorId=${payload.connectorId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
        );
      }

      return response.data || intial.connectorAuditLogData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getConnectorList = createAsyncThunk(
  'users/getConnectorList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        let url = `/getConnectorList?page=${payload.page}&size=${payload.limit}`;
        if (payload.statusIds) {
          url += `&statusIds=${payload.statusIds}`;
        }
        response = await api.get(url);
      } else {
        let url = `/getConnectorList?page=${payload.page}&size=${payload.limit}${extraString ? extraString : ''}`;
        if (payload.statusIds) {
          url += `&statusIds=${payload.statusIds}`;
        }
        response = await api.get(url);
      }
      return response.data || intial.usersData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateConnectorDetails = createAsyncThunk(
  'users/updateConnectorDetails',
  async (updatePayload, { rejectWithValue }) => {
    try {
      const response = await api.put(`updateUser`, updatePayload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateConnectorStatus = createAsyncThunk(
  'users/updateConnectorStatus',
  async (updatePayload, { rejectWithValue }) => {
    try {
      const response = await api.put(`status`, updatePayload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`users`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadConnectorReport = createAsyncThunk(
  'users/downloadConnectorReport',
  async ({ fileType, fileName }, { rejectWithValue }) => {
    try {
      return await downloadFileCSVPostApi({
        apiPath: `connector/report?format=${fileType}`,
        fileType: fileType,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

////////// CWA Connector
export const getCWAConnectorAuditLogs = createAsyncThunk(
  'users/getCWAConnectorAuditLogs',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        response = await api.get(
          `/connector/auditlog?connectorId=${payload.connectorId}&page=${payload.page}&limit=${payload.limit}`
        );
      } else {
        response = await api.get(
          `/connector/auditlog?connectorId=${payload.connectorId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
        );
      }

      return response.data || intial.cwaConnectorAuditLogData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCWAConnectorDetails = createAsyncThunk(
  'users/getCWAConnectorDetails',
  async ({ connectorId }, { rejectWithValue }) => {
    try {
      // let appendLeadId = `${leadId ? `&leadId=${leadId}` : ''}`;
      const response = await api.get(
        `getConnectorById?connectorId=${connectorId}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadConnectorSampleFormat = createAsyncThunk(
  'users/downloadSampleFormat',
  async (_, { rejectWithValue }) => {
    try {
      return await downloadFile({
        apiPath: `downloadConnectorUploadTemplate`
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const addUpdateCwaConnector = createAsyncThunk(
  'users/addUpdateCwaConnector',
  async ({ payload, isUpdate }, { rejectWithValue }) => {
    try {
      let response;
      if (isUpdate) {
        response = await multipartApi.post(`editConnector`, payload);
      } else {
        response = await multipartApi.post(`addConnector`, payload);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCwaConnectorStatus = createAsyncThunk(
  'users/updateCwaConnectorStatus',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.put(`status`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatusList = createAsyncThunk(
  'users/getStatusList',
  async ({ rejectWithValue }) => {
    try {
      const response = await api.get(`connector/status`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadConnectorPdf = createAsyncThunk(
  'connector/downloadConnectorPdf',
  async ({ connectorId }) => {
    const response = await api.get(
      `generateConnectorPdf?connectorId=${connectorId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`
        },
        // Set the responseType to 'blob' for binary data
        responseType: 'blob'
      }
    );
    const blob = new Blob([response.data], { type: 'PDF' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // Set the download attribute based on fileType

    link.download = `connector_${connectorId}_${moment().format('YYYY-MM-DD')}.pdf`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
);

export const uploadConnectorBulkUpload = createAsyncThunk(
  'lead/uploadLeadData',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await multipartApi.post(`uploadConnector`, payload, {
        responseType: 'blob'
      });
      const messageFromHeaders = response.headers.get('Message');
      const isexcelavailable = response.headers.get('isexcelavailable');

      const contentType = response.headers['content-type'];

      if (contentType && contentType.includes('application/octet-stream')) {
        downloadErrorFile(response.data, 'connector_error_report.xlsx');
        return {
          messageFromHeaders: messageFromHeaders,
          isexcelavailable: isexcelavailable
        };
      } else {
        // return response.data;
        return {
          messageFromHeaders: messageFromHeaders,
          isexcelavailable: isexcelavailable
        };
      }
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getEmployeeMapping = createAsyncThunk(
  'users/getEmployeeMapping',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`territory/employee`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revertConnectorPageData = createAction(
  'REVERT_CONNECTOR_PAGE_DATA'
);

export const revertCwaConnectorPageData = createAction(
  'REVERT_CWA_CONNECTOR_PAGE_DATA'
);

// For API Call in Lead
export const setIsValid = createAction('SET_ISVALID');

export const setCallValidation = createAction('SET_CALLVALIDATION');

export const setWaitExecution = createAction('SET_WAIT_EXECUTION');
export const connectorApprovalSlice = createSlice({
  name: 'users',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertConnectorPageData, state => {
        state.isDownloadConnectorRptSuccess = false;
        state.isDownloadConnectorRptFailed = false;
        state.isUpdateConnectorDataSuccess = false;
        state.isUpdateConnectorDataFailed = false;

        state.downloadConnectorRptErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(setIsValid, (state, action) => {
        state.isValid = action.payload;
      })
      .addCase(setWaitExecution, (state, action) => {
        state.waitForExecution = action.payload;
      })
      .addCase(setCallValidation, (state, action) => {
        state.callValidation = action.payload;
      })
      .addCase(revertAllConnectorData, state => {
        state.isGetConnectorLoading = false;
        state.isGetConnectorListSuccess = false;
        state.isGetConnectorFailed = false;
        state.getUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.updateConnectorStatusMsg = '';
        state.isUpdateConnectorStatusSuccess = false;
        state.isUpdateConnectorStatusFailed = false;
        state.isUpdateConnectorStatusLoading = false;

        state.connectorDetails = null;
        state.isGetConnectorLoading = false;
        state.isGetConnectorFailed = false;
        state.isGetConnectorSuccess = false;
        state.getConnectorErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isUpdateConnectorDataLoading = false;
        state.isUpdateConnectorDataSuccess = false;
        state.isUpdateConnectorDataFailed = false;
        state.UpdateConnectorDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertCwaConnectorPageData, state => {
        state.isCWAConnectorDetailsSuccess = false;
        state.isCWAConnectorDetailsFailed = false;

        state.isCWAConnectorAuditLogSuccess = false;
        state.isCWAConnectorAuditLogFailed = false;

        state.isAddUpdateCwaConnectorSuccess = false;
        state.isAddUpdateCwaConnectorFailed = false;
        state.addUpdateCwaConnectorResponse = '';
        state.addUpdateCwaConnectorErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isCWAConnectorDetailsLoading = false;
        state.isCWAConnectorDetailsFailed = false;
        state.isCWAConnectorDetailsSuccess = false;
        state.cwaConnectorDetails = null;
        state.cwaConnectorDetailsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.updateConnectorStatusLoading = false;
        state.updateConnectorStatusSuccess = false;
        state.updateConnectorStatusFailed = false;
        state.updateConnectorStatusResponse = '';
        state.updateConnectorStatusErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isUploadConnectorDataLoading = false;
        state.isUploadConnectorDataSuccess = false;
        state.isUploadConnectorDataFailure = false;
        state.isExcelAvailable = null;
        state.uploadConnectorDataStatus = '';
        state.uploadConnectorDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getConnectorDetails.pending, state => {
        state.isGetConnectorLoading = true;
        state.isGetConnectorSuccess = false;
        state.isGetConnectorFailed = false;
        state.getConnectorErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getConnectorDetails.fulfilled, (state, action) => {
        state.isGetConnectorLoading = false;
        state.isGetConnectorSuccess = true;
        state.connectorDetails = action.payload;
      })
      .addCase(getConnectorDetails.rejected, (state, action) => {
        state.isGetConnectorLoading = false;
        state.isGetConnectorFailed = true;
        state.getConnectorErrorContainer = {
          ...state.errorContainer,
          ...action.payload
        };
      })

      .addCase(getAllConstitutionTypes.pending, state => {
        state.isGetAllConstitutionTypesLoading = true;
        state.isGetAllConstitutionTypesSuccess = false;
        state.isGetAllConstitutionTypesFailed = false;
        state.getAllConstitutionTypesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAllConstitutionTypes.fulfilled, (state, action) => {
        state.isGetAllConstitutionTypesLoading = false;
        state.isGetAllConstitutionTypesSuccess = true;
        state.allConstitutionTypes = action.payload;
      })
      .addCase(getAllConstitutionTypes.rejected, (state, action) => {
        state.isGetAllConstitutionTypesLoading = false;
        state.isGetAllConstitutionTypesFailed = true;
        state.getAllConstitutionTypesErrorContainer = {
          ...state.getAllConstitutionTypesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAllProfiles.pending, state => {
        state.isGetAllProfilesLoading = true;
        state.isGetAllProfilesSuccess = false;
        state.isGetAllProfilesFailed = false;
        state.getAllProfilesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAllProfiles.fulfilled, (state, action) => {
        state.isGetAllProfilesLoading = false;
        state.isGetAllProfilesSuccess = true;
        state.allProfiles = action.payload;
      })
      .addCase(getAllProfiles.rejected, (state, action) => {
        state.isGetAllProfilesLoading = false;
        state.isGetAllProfilesFailed = true;
        state.getAllProfilesErrorContainer = {
          ...state.getAllProfilesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getConnectorAuditLogs.pending, state => {
        state.isConnectorAuditLogLoading = true;
        state.isConnectorAuditLogSuccess = false;
        state.isConnectorAuditLogFailed = false;
        state.connectorAuditLogErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getConnectorAuditLogs.fulfilled, (state, action) => {
        state.isConnectorAuditLogLoading = false;
        state.isConnectorAuditLogSuccess = true;
        state.connectorAuditLogData = {
          logList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getConnectorAuditLogs.rejected, (state, action) => {
        state.isConnectorAuditLogLoading = false;
        state.isConnectorAuditLogFailed = true;
        state.connectorAuditLogData = intial.connectorAuditLogData;
        state.connectorAuditLogErrorContainer = {
          ...state.connectorAuditLogErrorContainer,
          ...action.payload
        };
      })

      .addCase(getConnectorList.pending, state => {
        state.isGetConnectorListLoading = true;
        state.isGetConnectorListSuccess = false;
        state.isGetConnectorListFailed = false;
        state.connectorListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getConnectorList.fulfilled, (state, action) => {
        state.isGetConnectorListLoading = false;
        state.isGetConnectorListSuccess = true;
        state.connectorData = {
          connectorList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getConnectorList.rejected, (state, action) => {
        state.isGetConnectorListLoading = false;
        state.isGetConnectorListFailed = true;
        state.connectorData = intial.connectorData;
        state.connectorListErrorContainer = {
          ...state.connectorListErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateConnectorDetails.pending, state => {
        state.isUpdateConnectorDataLoading = true;
        state.isUpdateConnectorDataSuccess = false;
        state.isUpdateConnectorDataFailed = false;
        state.UpdateConnectorDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateConnectorDetails.fulfilled, state => {
        state.isUpdateConnectorDataLoading = false;
        state.isUpdateConnectorDataFailed = false;
        state.isUpdateConnectorDataSuccess = true;
      })
      .addCase(updateConnectorDetails.rejected, (state, action) => {
        state.isUpdateConnectorDataLoading = false;
        state.isUpdateConnectorDataSuccess = false;
        state.isUpdateConnectorDataFailed = true;
        state.UpdateConnectorDataErrorContainer = {
          ...state.UpdateConnectorDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateConnectorStatus.pending, state => {
        state.isUpdateConnectorStatusLoading = true;
        state.isUpdateConnectorStatusSuccess = false;
        state.isUpdateConnectorStatusFailed = false;
        state.UpdateConnectorStatusErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateConnectorStatus.fulfilled, (state, action) => {
        state.isUpdateConnectorStatusLoading = false;
        state.isUpdateConnectorStatusFailed = false;
        state.isUpdateConnectorStatusSuccess = true;
        state.updateConnectorStatusMsg = action.payload.message;
      })
      .addCase(updateConnectorStatus.rejected, (state, action) => {
        state.isUpdateConnectorStatusLoading = false;
        state.isUpdateConnectorStatusSuccess = false;
        state.isUpdateConnectorStatusFailed = true;
        state.UpdateConnectorStatusErrorContainer = {
          ...state.UpdateConnectorStatusErrorContainer,
          ...action.payload
        };
      })

      .addCase(downloadConnectorReport.pending, state => {
        state.isDownloadConnectorRptLoading = true;
        state.isDownloadConnectorRptSuccess = false;
        state.isDownloadConnectorRptFailed = false;
        state.downloadConnectorRptErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadConnectorReport.fulfilled, state => {
        state.isDownloadConnectorRptLoading = false;
        state.isDownloadConnectorRptFailed = false;
        state.isDownloadConnectorRptSuccess = true;
      })
      .addCase(downloadConnectorReport.rejected, (state, action) => {
        state.isDownloadConnectorRptLoading = false;
        state.isDownloadConnectorRptSuccess = false;
        state.isDownloadConnectorRptFailed = true;
        state.downloadConnectorRptErrorContainer = {
          ...state.downloadConnectorRptErrorContainer,
          ...action.payload
        };
      })

      .addCase(getCWAConnectorAuditLogs.pending, state => {
        state.isCWAConnectorAuditLogLoading = true;
        state.isCWAConnectorAuditLogSuccess = false;
        state.isCWAConnectorAuditLogFailed = false;
        state.cwaConnectorAuditLogErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCWAConnectorAuditLogs.fulfilled, (state, action) => {
        state.isCWAConnectorAuditLogLoading = false;
        state.isCWAConnectorAuditLogSuccess = true;
        state.cwaConnectorAuditLogData = {
          logList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getCWAConnectorAuditLogs.rejected, (state, action) => {
        state.isCWAConnectorAuditLogLoading = false;
        state.isCWAConnectorAuditLogFailed = true;
        state.cwaConnectorAuditLogData = intial.cwaConnectorAuditLogData;
        state.cwaConnectorAuditLogErrorContainer = {
          ...state.cwaConnectorAuditLogErrorContainer,
          ...action.payload
        };
      })

      .addCase(getCWAConnectorDetails.pending, state => {
        state.isCWAConnectorDetailsLoading = true;
        state.isCWAConnectorDetailsSuccess = false;
        state.isCWAConnectorDetailsFailed = false;
        state.cwaConnectorDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCWAConnectorDetails.fulfilled, (state, action) => {
        state.isCWAConnectorDetailsLoading = false;
        state.isCWAConnectorDetailsSuccess = true;
        state.cwaConnectorDetails = action.payload;
      })
      .addCase(getCWAConnectorDetails.rejected, (state, action) => {
        state.isCWAConnectorDetailsLoading = false;
        state.isCWAConnectorDetailsFailed = true;
        state.cwaConnectorDetailsErrorContainer = {
          ...state.cwaConnectorDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(addUpdateCwaConnector.pending, state => {
        state.isAddUpdateCwaConnectorLoading = true;
        state.isAddUpdateCwaConnectorSuccess = false;
        state.isAddUpdateCwaConnectorFailed = false;
        state.addUpdateCwaConnectorErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(addUpdateCwaConnector.fulfilled, (state, action) => {
        state.isAddUpdateCwaConnectorLoading = false;
        state.isAddUpdateCwaConnectorSuccess = true;
        state.isAddUpdateCwaConnectorFailed = false;
        state.addUpdateCwaConnectorResponse = action.payload?.message;
      })
      .addCase(addUpdateCwaConnector.rejected, (state, action) => {
        state.isAddUpdateCwaConnectorLoading = false;
        state.isAddUpdateCwaConnectorSuccess = false;
        state.isAddUpdateCwaConnectorFailed = true;
        state.addUpdateCwaConnectorErrorContainer = {
          ...state.addUpdateCwaConnectorErrorContainer,
          ...action.payload
        };
      })

      .addCase(getStatusList.pending, state => {
        state.isStatusListLoading = true;
        state.isStatusListSuccess = false;
        state.isStatusListFailed = false;
        state.statusListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getStatusList.fulfilled, (state, action) => {
        state.isStatusListLoading = false;
        state.isStatusListSuccess = true;
        state.isStatusListFailed = false;
        state.statusList = action.payload;
      })
      .addCase(getStatusList.rejected, (state, action) => {
        state.isStatusListLoading = false;
        state.isStatusListSuccess = false;
        state.isStatusListFailed = true;
        state.statusList = null;
        state.statusListErrorContainer = {
          ...state.statusListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getEmployeeMapping.pending, state => {
        state.isEmployeeMappingLoading = true;
        state.isEmployeeMappingSuccess = false;
        state.isEmployeeMappingFailure = false;
        state.employeeMappingListErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getEmployeeMapping.fulfilled, (state, action) => {
        state.isEmployeeMappingLoading = false;
        state.isEmployeeMappingSuccess = true;
        state.isEmployeeMappingFailure = false;
        state.employeeMappingList = action.payload;
      })
      .addCase(getEmployeeMapping.rejected, (state, action) => {
        state.isEmployeeMappingLoading = false;
        state.isEmployeeMappingSuccess = false;
        state.isEmployeeMappingFailure = true;
        state.employeeMappingList = null;
        state.employeeMappingListErrorContainer = {
          ...state.employeeMappingListErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateCwaConnectorStatus.pending, state => {
        state.updateConnectorStatusLoading = true;
        state.updateConnectorStatusSuccess = false;
        state.updateConnectorStatusFailed = false;
        state.updateConnectorStatusErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateCwaConnectorStatus.fulfilled, (state, action) => {
        state.updateConnectorStatusLoading = false;
        state.updateConnectorStatusSuccess = true;
        state.updateConnectorStatusFailed = false;
        state.updateConnectorStatusResponse = action.payload?.message;
      })

      .addCase(updateCwaConnectorStatus.rejected, (state, action) => {
        state.updateConnectorStatusLoading = false;
        state.updateConnectorStatusSuccess = false;
        state.updateConnectorStatusFailed = true;
        state.updateConnectorStatusErrorContainer = {
          ...state.updateConnectorStatusErrorContainer,
          ...action.payload
        };
      })

      .addCase(uploadConnectorBulkUpload.pending, state => {
        state.isUploadConnectorDataLoading = true;
        state.isUploadConnectorDataSuccess = false;
        state.isUploadConnectorDataFailure = false;
        state.isExcelAvailable = null;
        state.uploadConnectorDataErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(uploadConnectorBulkUpload.fulfilled, (state, action) => {
        console.log(action, '----');
        state.isUploadConnectorDataLoading = false;
        state.isUploadConnectorDataSuccess = true;
        state.isExcelAvailable = action.payload.isexcelavailable;
        state.uploadConnectorDataStatus = action.payload.messageFromHeaders;
      })
      .addCase(uploadConnectorBulkUpload.rejected, (state, action) => {
        state.isUploadConnectorDataLoading = false;
        state.isUploadConnectorDataFailure = true;
        state.isExcelAvailable = action.payload.isexcelavailable;
        state.uploadConnectorDataStatus = intial.uploadConnectorDataStatus;
        state.uploadConnectorDataErrorContainer = {
          ...state.uploadConnectorDataErrorContainer,
          ...action.payload.messageFromHeaders
        };
      })

      .addCase(downloadConnectorPdf.pending, state => {
        state.isDownloadLoading = true;
      })
      .addCase(downloadConnectorPdf.fulfilled, state => {
        state.isDownloadLoading = false;
      })
      .addCase(downloadConnectorPdf.rejected, state => {
        state.isDownloadLoading = false;
      });
  }
});

export default connectorApprovalSlice.reducer;
