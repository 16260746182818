import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  downloadErrorFile,
  downloadFile,
  downloadFileCSVPostApi
} from '../../utils/utils';
import { api, multipartApi } from '../../config/api';
import _ from 'lodash';

const initial = {
  isGetLeadDetailsLoading: false,
  isGetLeadDetailsSuccess: false,
  isGetLeadDetailsFailed: false,
  leadDetails: null,
  getLeadDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadLeadReportLoading: true,
  isDownloadLeadReportSuccess: false,
  isDownloadLeadReportFailed: false,
  downloadLeadReportErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadSampleFormatLoading: true,
  isDownloadSampleFormatSuccess: false,
  isDownloadSampleFormatFailed: false,
  downloadSampleFormatErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isLeadListLoading: false,
  isLeadListSuccess: false,
  isLeadListFailed: false,
  leadData: {
    leadList: [],
    totalCount: 0
  },
  leadListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isEmployeeListLoading: false,
  isEmployeeListSuccess: false,
  isEmployeeListFailed: false,
  employeeList: [],
  employeeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUploadLeadDataLoading: false,
  isUploadLeadDataSuccess: false,
  isUploadLeadDataFailure: false,
  uploadLeadDataStatus: '',
  uploadLeadDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isLeadAuditLogLoading: false,
  isLeadAuditLogSuccess: false,
  isLeadAuditLogFailed: false,
  leadAuditLogData: {
    logList: [],
    totalCount: 0
  },
  leadAuditLogErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isViewDocumentLoading: false,
  isViewDocumentSuccess: false,
  isViewDocumentFailed: false,
  viewDocumentResponse: '',
  viewDocumentErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  connectorMappingList: [],

  isDeleteSuccess: '',

  isLeadUpdateTrigger: false,
  isLeadUpdateLoading: false,
  isLeadUpdateSuccess: false,
  isLeadUpdateFailed: false,
  leadUpdateResponse: '',
  leadUpdateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isViewPhotoLoading: false,
  isViewPhotoSuccess: false,
  isViewPhotoFailed: false,
  viewPhotoResponse: '',
  viewPhotoErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isLeadDocDeleteLoading: false,
  isLeadDocDeleteSuccess: false,
  isLeadDocDeleteFailed: false,
  leadDocDeleteErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetLeadDocLoading: false,
  isGetLeadDocSuccess: false,
  isGetLeadDocFailed: false,
  leadDocuments: [],
  getLeadDocumentsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetPlanListLoading: false,
  isGetPlanListSuccess: false,
  isGetPlanListFailed: false,
  planList: [],
  getPlanListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetModeListLoading: false,
  isGetModeListSuccess: false,
  isGetModeListFailed: false,
  modeList: [],
  getModeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPremiumPaymentTypesLoading: false,
  isPremiumPaymentTypesSuccess: false,
  isPremiumPaymentTypesFailed: false,
  premiumPaymentTypeList: null,
  getPremiumPaymentTypeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isNpsCustomerListLoading: false,
  isNpsCustomerListSuccess: false,
  isNpsCustomerListFailed: false,
  npsCustomerList: [],
  npsCustomerListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isNpsTierListLoading: false,
  isNpsTierListSuccess: false,
  isNpsTierListFailed: false,
  npsTierList: [],
  npsTierListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFCompanyListLoading: false,
  isMFCompanyListSuccess: false,
  isMFCompanyListFailed: false,
  mfCompanyList: [],
  mfCompanyListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFBusinessListLoading: false,
  isMFBusinessListSuccess: false,
  isMFBusinessListFailed: false,
  mfBusinessList: [],
  mfBusinessListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFInvestmentListLoading: false,
  isMFInvestmentListSuccess: false,
  isMFInvestmentListFailed: false,
  mfInvestmentList: [],
  mfInvestmentListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFFundHouseListLoading: false,
  isMFFundHouseListSuccess: false,
  isMFFundHouseListFailed: false,
  mfFundHouseList: [],
  mfFundHouseListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFStatusListLoading: false,
  isMFStatusListSuccess: false,
  isMFStatusListFailed: false,
  mfStatusList: [],
  mfStatusListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFSchemeListLoading: false,
  isMFSchemeListSuccess: false,
  isMFSchemeListFailed: false,
  mfSchemeList: [],
  mfSchemeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFSchemeTypeListLoading: false,
  isMFSchemeTypeListSuccess: false,
  isMFSchemeTypeListFailed: false,
  mfSchemeTypeList: [],
  mfSchemeTypeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIDBIProductListLoading: false,
  isIDBIProductListSuccess: false,
  isIDBIProductListFailed: false,
  idbiProductList: [],
  idbiProductListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIDBISubProductListLoading: false,
  isIDBISubProductListSuccess: false,
  isIDBISubProductListFailed: false,
  idbiSubProductList: [],
  idbiSubProductListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  areaOfficeList: [],
  sourcePersonList: [],
  assistantPersonList: [],
  leadSourceList: [],
  insuranceCompanyList: [],
  insuranceProductList: [],

  customerList: [],

  isCustomerViewDataLoading: false,
  isCustomerViewDataSuccess: false,
  isCustomerViewDataFailed: false,
  customerViewData: [],
  customerViewDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getEmployeeList = createAsyncThunk(
  'lead/getEmployeeList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;
      response = await api.get(`/employees?${extraString ? extraString : ''}`);

      return response.data || initial.employeeList;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getLeadList = createAsyncThunk(
  'lead/getLeadList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        if (payload?.product == 1) {
          response = await api.get(
            `/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        } else if (payload?.product == 2) {
          response = await api.get(
            `LI/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}&businessType=${payload?.businessType}`
          );
        } else if (payload?.product == 3) {
          response = await api.get(
            `GI/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}&businessType=${payload?.businessType}`
          );
        } else if (payload?.product == 4) {
          response = await api.get(
            `FD/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        } else if (payload?.product == 5) {
          response = await api.get(
            `MF/leads?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        } else if (payload?.product == 6) {
          response = await api.get(
            `IDBI/leadList?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        } else if (payload?.product == 7) {
          response = await api.get(
            `NP/listing?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        } else {
          response = await api.get(
            `/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}`
          );
        }
      } else {
        if (payload?.product == 1) {
          response = await api.get(
            `/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        } else if (payload?.product == 3) {
          response = await api.get(
            `GI/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}&businessType=${payload?.businessType}${extraString ? extraString : ''}`
          );
        } else if (payload?.product == 4) {
          response = await api.get(
            `FD/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        } else if (payload?.product == 5) {
          response = await api.get(
            `MF/leads?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        } else if (payload?.product == 6) {
          response = await api.get(
            `IDBI/leadList?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        } else if (payload?.product == 7) {
          response = await api.get(
            `NP/listing?page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        } else {
          response = await api.get(
            `/leads?product=${payload.product}&page=${payload.page}&limit=${payload.limit}&empIds=${payload.empIds}${extraString ? extraString : ''}`
          );
        }
      }

      return response.data || initial.leadData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadLeadReport = createAsyncThunk(
  'lead/downloadLeadReport',
  async ({ fileType, fileName }, { rejectWithValue }) => {
    try {
      return await downloadFileCSVPostApi({
        apiPath: `lead/report?format=${fileType}`,
        fileType: fileType,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadLeadSampleFormat = createAsyncThunk(
  'lead/downloadSampleFormat',
  async (_, { rejectWithValue }) => {
    try {
      return await downloadFile({
        apiPath: `sync/format/1`
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const uploadLeadData = createAsyncThunk(
  'lead/uploadLeadData',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await multipartApi.post(`sync/upload`, payload, {
        responseType: 'blob'
      });
      const messageFromHeaders = response.headers.get('Message');

      const contentType = response.headers['content-type'];

      if (contentType && contentType.includes('application/octet-stream')) {
        downloadErrorFile(response.data, 'Lead_error_report.xlsx');
        return messageFromHeaders;
      } else {
        // return response.data;
        return messageFromHeaders;
      }
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getLeadDetails = createAsyncThunk(
  'lead/getLeadDetails',
  async (
    { leadId, customerId, productId, businessType },
    { rejectWithValue }
  ) => {
    try {
      let appendLeadId = `${leadId ? `&leadId=${leadId}` : ''}`;
      let response;
      if (productId == 3) {
        response = await api.get(
          `GI/${businessType}?customerId=${customerId}${appendLeadId}`
        );
      } else if (productId == 2) {
        response = await api.get(
          `LI/${businessType}?customerId=${customerId}${appendLeadId}`
        );
      } else if (productId == 4) {
        response = await api.get(
          `FD/lead?customerId=${customerId}${appendLeadId}`
        );
      } else if (productId == 5) {
        response = await api.get(
          `MF/lead?customerId=${customerId}${appendLeadId}`
        );
      } else if (productId == 6) {
        response = await api.get(
          `IDBI/lead?customerId=${customerId}${appendLeadId}`
        );
      } else if (productId == 7) {
        response = await api.get(
          `NP/getDetails?customerId=${customerId}${appendLeadId}`
        );
      } else {
        response = await api.get(
          `lead?customerId=${customerId}${appendLeadId}`
        );
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeadDocuments = createAsyncThunk(
  'lead/documents',
  async ({ leadId, customerId, productId }, { rejectWithValue }) => {
    try {
      let appendLeadId = `${leadId ? `leadId=${leadId}` : ''}`;
      let appendCustomerId = `${customerId ? `&customerId=${customerId}` : ''}`;
      let response;
      if (productId == 3) {
        response = await api.get(
          `GI/newBusiness/document?${appendLeadId}${appendCustomerId}`
        );
      } else if (productId == 2) {
        response = await api.get(
          `LI/newBusiness/document?${appendLeadId}${appendCustomerId}`
        );
      } else if (productId == 4) {
        response = await api.get(
          `FD/documents?${appendLeadId}${appendCustomerId}`
        );
      } else if (productId == 5) {
        response = await api.get(
          `MF/documents?${appendLeadId}${appendCustomerId}`
        );
      } else if (productId == 6) {
        response = await api.get(
          `IDBI/documents?${appendLeadId}${appendCustomerId}`
        );
      } else if (productId == 7) {
        response = await api.get(
          `NP/documents?${appendLeadId}${appendCustomerId}`
        );
      } else {
        response = await api.get(
          `hl/documents?${appendLeadId}${appendCustomerId}`
        );
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLeadDocuments = createAsyncThunk(
  'lead/deleteDocuments',
  async ({ fileUrl, product }, { rejectWithValue }) => {
    try {
      let appendFileUrl = `${fileUrl ? `fileUrl=${fileUrl}` : ''}`;
      const response = await api.delete(
        `documents?${appendFileUrl}&product=${product}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeadAuditLogs = createAsyncThunk(
  'lead/getLeadAuditLogs',
  async ({ payload, productId = 1 }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;
      if (productId === 4) {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `/FD/auditlog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}`
          );
        } else {
          response = await api.get(
            `/FD/auditlog?leadId=${payload.leadId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
          );
        }
      }
      if (productId === 5) {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `/MF/auditlog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}`
          );
        } else {
          response = await api.get(
            `/MF/auditlog?leadId=${payload.leadId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
          );
        }
      } else if (productId === 6) {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `/IDBI/auditlog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}`
          );
        } else {
          response = await api.get(
            `/IDBI/auditlog?leadId=${payload.leadId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
          );
        }
      } else if (productId === 7) {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `/NP/auditlog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}`
          );
        } else {
          response = await api.get(
            `/NP/auditlog?leadId=${payload.leadId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
          );
        }
      } else if (productId == 3) {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `GI/auditLog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}&businessType=${payload.businessType}`
          );
        }
      } else {
        if (payload?.searchFilter === '' || payload?.searchFilter === null) {
          response = await api.get(
            `/lead/auditlog?leadId=${payload.leadId}&page=${payload.page}&limit=${payload.limit}`
          );
        } else {
          response = await api.get(
            `/lead/auditlog?leadId=${payload.leadId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
          );
        }
      }

      return response.data || initial.leadAuditLogData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewDocument = createAsyncThunk(
  'lead/viewDocument',
  async ({ fileUrl, contentType }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `view?fileUrl=${fileUrl}&contentType=${contentType}`,
        {
          responseType: 'blob'
        }
      );

      if (response.data) {
        const fileBlob = response.data;
        const fileURL = URL.createObjectURL(fileBlob);
        window.open(fileURL);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewPhoto = createAsyncThunk(
  'lead/viewPhoto',
  async ({ fileUrl, contentType }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `view?fileUrl=${fileUrl}&contentType=${contentType}`,
        {
          responseType: 'blob'
        }
      );

      if (response.data) {
        const fileBlob = response.data;
        const fileURL = URL.createObjectURL(fileBlob);
        // window.open(fileURL);
        return fileURL;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateLeadDetails = createAsyncThunk(
  'lead/editLead',
  async ({ payload, productId }, { rejectWithValue }) => {
    try {
      let response;
      if (_.includes([3], productId)) {
        response = await multipartApi.post(`GI/addEditLead`, payload, {
          responseType: 'blob'
        });
      } else if (_.includes([2], productId)) {
        response = await multipartApi.post(`LI/addEditLead`, payload, {
          responseType: 'blob'
        });
      } else if (_.includes([4], productId)) {
        response = await multipartApi.post(`FD/addEditLead`, payload, {
          responseType: 'blob'
        });
      } else if (_.includes([5], productId)) {
        response = await multipartApi.post(`MF/addEditLead`, payload, {
          responseType: 'blob'
        });
      } else if (_.includes([6], productId)) {
        response = await multipartApi.post(`IDBI/addEditLead`, payload, {
          responseType: 'blob'
        });
      } else if (_.includes([7], productId)) {
        response = await multipartApi.post(`NP/saveUpdateLead`, payload, {
          responseType: 'blob'
        });
      } else {
        response = await multipartApi.post(`editLead`, payload, {
          responseType: 'blob'
        });
      }
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getSourcePerson = createAsyncThunk(
  'lead/sourcePerson',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `sourcePerson?product=${productId ? productId : 0}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAssistingPerson = createAsyncThunk(
  'lead/assistingPerson',
  async ({ sourcePersonId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `assistingPerson?sourcePersonId=${sourcePersonId ? sourcePersonId : 0}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLeadSource = createAsyncThunk(
  'lead/leadSource',
  async ({ rejectWithValue }) => {
    try {
      const response = await api.get(`leadSource`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAreaOffice = createAsyncThunk(
  'lead/areaOffice',
  async ({ rejectWithValue }) => {
    try {
      const response = await api.get(`areaOffice`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInsuranceCompany = createAsyncThunk(
  'lead/insuranceCompany',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`GI/insuranceCompany`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInsuranceProduct = createAsyncThunk(
  'lead/insuranceProduct',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`GI/insuranceProduct/${companyId}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revertLeadPageData = createAction('REVERT_LEAD_PAGE_DATA');

export const getConnectorMappingList = createAsyncThunk(
  'lead/getConnectorMappingList',
  async ({ Id }, { rejectWithValue }) => {
    try {
      const response = await api.get(`getConnectors?search=${Id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlanList = createAsyncThunk(
  'lead/getPlanList',
  async ({ Id }, { rejectWithValue }) => {
    try {
      const response = await api.get(`LI/planPremium?planNumber=${Id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getModeList = createAsyncThunk(
  'lead/getModeList',
  async ({ Id }, { rejectWithValue }) => {
    try {
      const response = await api.get(`LI/planPremiumMode?id=${Id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPremiumPaymentTypes = createAsyncThunk(
  'lead/getPremiumPaymentTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`LI/plan`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNpsCustomerList = createAsyncThunk(
  'lead/getNpsCustomerList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`NP/allType`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNpsTierList = createAsyncThunk(
  'lead/getNpsTierList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`NP/allTierType`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFCompanyList = createAsyncThunk(
  'lead/getMFCompanyList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/company`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFBusinessList = createAsyncThunk(
  'lead/getMFBusinessList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/businessType`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFInvestementList = createAsyncThunk(
  'lead/getMFInvestementList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/investmentType`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFFundHouseList = createAsyncThunk(
  'lead/getMFFundHouseList',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/company/${companyId}/fundHouses`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFSchemeList = createAsyncThunk(
  'lead/getMFSchemeList',
  async ({ fundHouseId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/fundHouse/${fundHouseId}/schemes`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getMFStatusList = createAsyncThunk(
  'lead/getMFStatusList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/status`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFSchemeTypeList = createAsyncThunk(
  'lead/getMFSchemeTypeList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`MF/schemeType`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIDBIProductList = createAsyncThunk(
  'lead/getIDBIProductList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`IDBI/products`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getIDBISubProductList = createAsyncThunk(
  'lead/getIDBISubProductList',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`IDBI/product/${productId}/subProducts`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerList = createAsyncThunk(
  'lead/getCustomerList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`customer/list`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerView = createAsyncThunk(
  'lead/getCustomerView',
  async ({ customerId, page, limit }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `customer/view?customerId=${customerId}&page=${page}&limit=${limit}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leadSlice = createSlice({
  name: 'lead',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertLeadPageData, state => {
        state.isDownloadLeadReportFailed = false;
        state.isDownloadLeadReportSuccess = false;
        state.downloadLeadReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isLeadListFailed = false;
        state.isLeadListSuccess = false;
        state.leadListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isEmployeeListSuccess = false;
        state.isEmployeeListFailed = false;
        state.employeeListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isUploadLeadDataSuccess = false;
        state.isUploadLeadDataFailure = false;
        state.uploadLeadDataErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isLeadAuditLogSuccess = false;
        state.isLeadAuditLogFailed = false;
        state.leadAuditLogData = {
          logList: [],
          totalCount: 0
        };
        state.leadAuditLogErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isLeadUpdateLoading = false;
        state.isLeadUpdateSuccess = false;
        state.isLeadUpdateFailed = false;
      })

      .addCase(downloadLeadReport.pending, state => {
        state.isDownloadLeadReportLoading = true;
        state.isDownloadLeadReportSuccess = false;
        state.isDownloadLeadReportFailed = false;
        state.downloadLeadReportErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadLeadReport.fulfilled, state => {
        state.isDownloadLeadReportLoading = false;
        state.isDownloadLeadReportFailed = false;
        state.isDownloadLeadReportSuccess = true;
      })
      .addCase(downloadLeadReport.rejected, (state, action) => {
        state.isDownloadLeadReportLoading = false;
        state.isDownloadLeadReportSuccess = false;
        state.isDownloadLeadReportFailed = true;
        state.downloadLeadReportErrorContainer = {
          ...state.downloadLeadReportErrorContainer,
          ...action.payload
        };
      })

      .addCase(viewPhoto.pending, state => {
        state.isViewPhotoLoading = true;
        state.isViewPhotoSuccess = false;
        state.isViewPhotoFailed = false;
        state.viewPhotoErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(viewPhoto.fulfilled, (state, action) => {
        state.isViewPhotoLoading = false;
        state.isViewPhotoSuccess = true;
        state.viewPhotoResponse = action.payload;
      })
      .addCase(viewPhoto.rejected, (state, action) => {
        state.isViewPhotoLoading = false;
        state.isViewPhotoFailed = true;
        state.viewPhotoResponse = initial.viewPhotoResponse;
        state.viewPhotoErrorContainer = {
          ...state.viewPhotoErrorContainer,
          ...action.payload
        };
      })

      .addCase(getLeadList.pending, state => {
        state.isLeadListLoading = true;
        state.isLeadListSuccess = false;
        state.isLeadListFailed = false;
        state.leadListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getLeadList.fulfilled, (state, action) => {
        state.isLeadListLoading = false;
        state.isLeadListSuccess = true;
        state.leadData = {
          leadList: action.payload?.content || action.payload?.data,
          totalCount: action.payload?.totalElements || action.payload?.count
        };
      })
      .addCase(getLeadList.rejected, (state, action) => {
        state.isLeadListLoading = false;
        state.isLeadListFailed = true;
        state.leadData = initial.leadData;
        state.leadListErrorContainer = {
          ...state.leadListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getEmployeeList.pending, state => {
        state.isEmployeeListLoading = true;
        state.isEmployeeListSuccess = false;
        state.isEmployeeListFailed = false;
        state.employeeListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getEmployeeList.fulfilled, (state, action) => {
        state.isEmployeeListLoading = false;
        state.isEmployeeListSuccess = true;
        state.employeeList = action.payload;
      })
      .addCase(getEmployeeList.rejected, (state, action) => {
        state.isEmployeeListLoading = false;
        state.isEmployeeListFailed = true;
        state.employeeList = initial.employeeList;
        state.employeeListErrorContainer = {
          ...state.employeeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(uploadLeadData.pending, state => {
        state.isUploadLeadDataLoading = true;
        state.isUploadLeadDataSuccess = false;
        state.isUploadLeadDataFailure = false;
        state.uploadLeadDataErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(uploadLeadData.fulfilled, (state, action) => {
        state.isUploadLeadDataLoading = false;
        state.isUploadLeadDataSuccess = true;
        state.uploadLeadDataStatus = action.payload;
      })
      .addCase(uploadLeadData.rejected, (state, action) => {
        state.isUploadLeadDataLoading = false;
        state.isUploadLeadDataFailure = true;
        state.uploadLeadDataStatus = initial.uploadLeadDataStatus;
        state.uploadLeadDataErrorContainer = {
          ...state.uploadLeadDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateLeadDetails.pending, state => {
        state.isLeadUpdateLoading = true;
        state.isLeadUpdateSuccess = false;
        state.isLeadUpdateFailed = false;
        state.leadUpdateErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(updateLeadDetails.fulfilled, (state, action) => {
        state.isLeadUpdateLoading = false;
        state.isLeadUpdateSuccess = true;

        state.leadUpdateResponse = action.payload;
      })
      .addCase(updateLeadDetails.rejected, (state, action) => {
        state.isLeadUpdateLoading = false;
        state.isLeadUpdateFailed = true;

        state.leadUpdateErrorContainer = {
          ...state.leadUpdateErrorContainer,
          ...action.payload
        };
      })

      .addCase(getLeadDetails.pending, state => {
        state.isGetLeadDetailsLoading = true;
        state.isGetLeadDetailsSuccess = false;
        state.isGetLeadDetailsFailed = false;
        state.getLeadDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getLeadDetails.fulfilled, (state, action) => {
        state.isGetLeadDetailsLoading = false;
        state.isGetLeadDetailsSuccess = true;
        state.leadDetails = action.payload;
      })
      .addCase(getLeadDetails.rejected, (state, action) => {
        state.isGetLeadDetailsLoading = false;
        state.isGetLeadDetailsFailed = true;
        state.leadDetails = null;
        state.getLeadDetailsErrorContainer = {
          ...state.getLeadDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getLeadDocuments.pending, state => {
        state.isGetLeadDocLoading = true;
        state.isGetLeadDocSuccess = false;
        state.isGetLeadDocFailed = false;
        state.getLeadDocumentsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getLeadDocuments.fulfilled, (state, action) => {
        state.isGetLeadDocLoading = false;
        state.isGetLeadDocSuccess = true;
        state.leadDocuments = action.payload;
      })
      .addCase(getLeadDocuments.rejected, (state, action) => {
        state.isGetLeadDocLoading = false;
        state.isGetLeadDocFailed = true;
        state.getLeadDocumentsErrorContainer = {
          ...state.getLeadDocumentsErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteLeadDocuments.pending, state => {
        state.isLeadDocDeleteLoading = true;
        state.isGetLeadDocLoading = true;
        state.isLeadDocDeleteSuccess = false;
        state.isLeadDocDeleteFailed = false;
        state.leadDocDeleteErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteLeadDocuments.fulfilled, (state, action) => {
        state.isLeadDocDeleteLoading = false;
        state.isGetLeadDocLoading = true;
        state.isLeadDocDeleteSuccess = true;
      })
      .addCase(deleteLeadDocuments.rejected, (state, action) => {
        state.isLeadDocDeleteLoading = false;
        state.isLeadDocDeleteFailed = true;
        state.isGetLeadDocLoading = true;
        state.leadDocDeleteErrorContainer = {
          ...state.leadDocDeleteErrorContainer,
          ...action.payload
        };
      })

      .addCase(getLeadAuditLogs.pending, state => {
        state.isLeadAuditLogLoading = true;
        state.isLeadAuditLogSuccess = false;
        state.isLeadAuditLogFailed = false;
        state.leadAuditLogErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getLeadAuditLogs.fulfilled, (state, action) => {
        state.isLeadAuditLogLoading = false;
        state.isLeadAuditLogSuccess = true;
        state.leadAuditLogData = {
          logList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getLeadAuditLogs.rejected, (state, action) => {
        state.isLeadAuditLogLoading = false;
        state.isLeadAuditLogFailed = true;
        state.leadAuditLogData = initial.leadAuditLogData;
        state.leadAuditLogErrorContainer = {
          ...state.leadAuditLogErrorContainer,
          ...action.payload
        };
      })

      .addCase(viewDocument.pending, state => {
        state.isViewDocumentLoading = true;
        state.isViewDocumentSuccess = false;
        state.isViewDocumentFailed = false;
        state.viewDocumentErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(viewDocument.fulfilled, (state, action) => {
        state.isViewDocumentLoading = false;
        state.isViewDocumentSuccess = true;
        state.viewDocumentResponse = action.payload;
      })
      .addCase(viewDocument.rejected, (state, action) => {
        state.isViewDocumentLoading = false;
        state.isViewDocumentFailed = true;
        state.viewDocumentErrorContainer = {
          ...state.viewDocumentErrorContainer,
          ...action.payload
        };
      })

      .addCase(getNpsCustomerList.pending, state => {
        state.isNpsCustomerListLoading = true;
        state.isNpsCustomerListSuccess = false;
        state.isNpsCustomerListFailed = false;
        state.npsCustomerListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getNpsCustomerList.fulfilled, (state, action) => {
        state.isNpsCustomerListLoading = false;
        state.isNpsCustomerListSuccess = true;
        state.npsCustomerList = action.payload;
      })
      .addCase(getNpsCustomerList.rejected, (state, action) => {
        state.isNpsCustomerListLoading = false;
        state.isNpsCustomerListFailed = true;
        state.npsCustomerList = null;
        state.npsCustomerListErrorContainer = {
          ...state.npsCustomerListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPlanList.pending, state => {
        state.isGetPlanListLoading = true;
        state.isGetPlanListSuccess = false;
        state.isGetPlanListFailed = false;
        state.getPlanListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getPlanList.fulfilled, (state, action) => {
        state.isGetPlanListLoading = false;
        state.isGetPlanListSuccess = true;
        state.planList = action.payload;
      })
      .addCase(getPlanList.rejected, (state, action) => {
        state.isGetPlanListLoading = false;
        state.isGetPlanListFailed = true;
        state.getPlanListErrorContainer = {
          ...state.getPlanListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getModeList.pending, state => {
        state.isGetModeListLoading = true;
        state.isGetModeListSuccess = false;
        state.isGetModeListFailed = false;
        state.getModeListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getModeList.fulfilled, (state, action) => {
        state.isGetModeListLoading = false;
        state.isGetModeListSuccess = true;
        state.modeList = action.payload;
      })
      .addCase(getModeList.rejected, (state, action) => {
        state.isGetModeListLoading = false;
        state.isGetModeListFailed = true;
        state.getModeListErrorContainer = {
          ...state.getModeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPremiumPaymentTypes.pending, state => {
        state.isPremiumPaymentTypesLoading = true;
        state.isPremiumPaymentTypesSuccess = false;
        state.isPremiumPaymentTypesFailed = false;
        state.getPremiumPaymentTypeErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getPremiumPaymentTypes.fulfilled, (state, action) => {
        state.isPremiumPaymentTypesLoading = false;
        state.isPremiumPaymentTypesSuccess = true;
        state.premiumPaymentTypeList = action.payload;
      })
      .addCase(getPremiumPaymentTypes.rejected, (state, action) => {
        state.isPremiumPaymentTypesLoading = false;
        state.isPremiumPaymentTypesFailed = true;
        state.getPremiumPaymentTypeErrorContainer = {
          ...state.getPremiumPaymentTypeErrorContainer,
          ...action.payload
        };
      })

      .addCase(getNpsTierList.pending, state => {
        state.isNpsTierListLoading = true;
        state.isNpsTierListSuccess = false;
        state.isNpsTierListFailed = false;
        state.npsTierListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getNpsTierList.fulfilled, (state, action) => {
        state.isNpsTierListLoading = false;
        state.isNpsTierListSuccess = true;
        state.npsTierList = action.payload;
      })
      .addCase(getNpsTierList.rejected, (state, action) => {
        state.isNpsTierListLoading = false;
        state.isNpsTierListFailed = true;
        state.npsCustomerList = null;
        state.npsTierListErrorContainer = {
          ...state.npsTierListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFCompanyList.pending, state => {
        state.isMFCompanyListLoading = true;
        state.isMFCompanyListSuccess = false;
        state.isMFCompanyListFailed = false;
        state.mfCompanyListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFCompanyList.fulfilled, (state, action) => {
        state.isMFCompanyListLoading = false;
        state.isMFCompanyListSuccess = true;
        state.mfCompanyList = action.payload;
      })
      .addCase(getMFCompanyList.rejected, (state, action) => {
        state.isMFCompanyListLoading = false;
        state.isMFCompanyListFailed = true;
        state.mfCompanyList = null;
        state.mfCompanyListErrorContainer = {
          ...state.mfCompanyListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFBusinessList.pending, state => {
        state.isMFBusinessListLoading = true;
        state.isMFBusinessListSuccess = false;
        state.isMFBusinessListFailed = false;
        state.mfCompanyListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFBusinessList.fulfilled, (state, action) => {
        state.isMFBusinessListLoading = false;
        state.isMFBusinessListSuccess = true;
        state.mfBusinessList = action.payload;
      })
      .addCase(getMFBusinessList.rejected, (state, action) => {
        state.isMFBusinessListLoading = false;
        state.isMFBusinessListFailed = true;
        state.mfBusinessList = null;
        state.mfBusinessListErrorContainer = {
          ...state.mfBusinessListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFInvestementList.pending, state => {
        state.isMFInvestmentListLoading = true;
        state.isMFInvestmentListSuccess = false;
        state.isMFInvestmentListFailed = false;
        state.mfCompanyListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFInvestementList.fulfilled, (state, action) => {
        state.isMFInvestmentListLoading = false;
        state.isMFInvestmentListSuccess = true;
        state.mfInvestmentList = action.payload;
      })
      .addCase(getMFInvestementList.rejected, (state, action) => {
        state.isMFInvestmentListLoading = false;
        state.isMFInvestmentListFailed = true;
        state.mfInvestmentList = null;
        state.mfInvestmentListErrorContainer = {
          ...state.mfInvestmentListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFFundHouseList.pending, state => {
        state.isMFFundHouseListLoading = true;
        state.isMFFundHouseListSuccess = false;
        state.isMFFundHouseListFailed = false;
        state.mfFundHouseListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFFundHouseList.fulfilled, (state, action) => {
        state.isMFFundHouseListLoading = false;
        state.isMFFundHouseListSuccess = true;
        state.mfFundHouseList = action.payload;
      })
      .addCase(getMFFundHouseList.rejected, (state, action) => {
        state.isMFFundHouseListLoading = false;
        state.isMFFundHouseListFailed = true;
        state.mfFundHouseList = null;
        state.mfFundHouseListErrorContainer = {
          ...state.mfFundHouseListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFStatusList.pending, state => {
        state.isMFStatusListLoading = true;
        state.isMFStatusListSuccess = false;
        state.isMFStatusListFailed = false;
        state.mfStatusListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFStatusList.fulfilled, (state, action) => {
        state.isMFStatusListLoading = false;
        state.isMFStatusListSuccess = true;
        state.mfStatusList = action.payload;
      })
      .addCase(getMFStatusList.rejected, (state, action) => {
        state.isMFStatusListLoading = false;
        state.isMFStatusListFailed = true;
        state.mfStatusList = null;
        state.mfStatusListErrorContainer = {
          ...state.mfStatusListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFSchemeList.pending, state => {
        state.isMFSchemeListLoading = true;
        state.isMFSchemeListSuccess = false;
        state.isMFSchemeListFailed = false;
        state.mfSchemeListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFSchemeList.fulfilled, (state, action) => {
        state.isMFSchemeListLoading = false;
        state.isMFSchemeListSuccess = true;
        state.mfSchemeList = action.payload;
      })
      .addCase(getMFSchemeList.rejected, (state, action) => {
        state.isMFSchemeListLoading = false;
        state.isMFSchemeListFailed = true;
        state.mfSchemeList = null;
        state.mfSchemeListErrorContainer = {
          ...state.mfSchemeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFSchemeTypeList.pending, state => {
        state.isMFSchemeTypeListLoading = true;
        state.isMFSchemeTypeListSuccess = false;
        state.isMFSchemeTypeListFailed = false;
        state.mfSchemeTypeListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFSchemeTypeList.fulfilled, (state, action) => {
        state.isMFSchemeTypeListLoading = false;
        state.isMFSchemeTypeListSuccess = true;
        state.mfSchemeTypeList = action.payload;
      })
      .addCase(getMFSchemeTypeList.rejected, (state, action) => {
        state.isMFSchemeTypeListLoading = false;
        state.isMFSchemeTypeListFailed = true;
        state.mfSchemeTypeList = null;
        state.mfSchemeTypeListErrorContainer = {
          ...state.mfSchemeTypeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIDBIProductList.pending, state => {
        state.isIDBIProductListLoading = true;
        state.isIDBIProductListSuccess = false;
        state.isIDBIProductListFailed = false;
        state.idbiProductListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIDBIProductList.fulfilled, (state, action) => {
        state.isIDBIProductListLoading = false;
        state.isIDBIProductListSuccess = true;
        state.idbiProductList = action.payload;
      })
      .addCase(getIDBIProductList.rejected, (state, action) => {
        state.isIDBIProductListLoading = false;
        state.isIDBIProductListFailed = true;
        state.idbiProductList = null;
        state.idbiProductListErrorContainer = {
          ...state.idbiProductListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIDBISubProductList.pending, state => {
        state.isIDBISubProductListLoading = true;
        state.isIDBISubProductListSuccess = false;
        state.isIDBISubProductListFailed = false;
        state.idbiSubProductListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIDBISubProductList.fulfilled, (state, action) => {
        state.isIDBISubProductListLoading = false;
        state.isIDBISubProductListSuccess = true;
        state.idbiSubProductList = action.payload;
      })
      .addCase(getIDBISubProductList.rejected, (state, action) => {
        state.isIDBISubProductListLoading = false;
        state.isIDBISubProductListFailed = true;
        state.idbiSubProductList = null;
        state.idbiSubProductListErrorContainer = {
          ...state.idbiSubProductListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getConnectorMappingList.pending, state => {
        state.connectorMappingList = [];
      })
      .addCase(getConnectorMappingList.fulfilled, (state, action) => {
        state.connectorMappingList = action.payload;
      })
      .addCase(getConnectorMappingList.rejected, (state, action) => {
        state.connectorMappingList = [];
      })

      .addCase(getSourcePerson.pending, state => {
        state.sourcePersonList = [];
      })
      .addCase(getSourcePerson.fulfilled, (state, action) => {
        state.sourcePersonList = action.payload;
      })
      .addCase(getSourcePerson.rejected, (state, action) => {
        state.sourcePersonList = [];
      })

      .addCase(getAssistingPerson.pending, state => {
        state.assistantPersonList = [];
      })
      .addCase(getAssistingPerson.fulfilled, (state, action) => {
        state.assistantPersonList = action.payload;
      })
      .addCase(getAssistingPerson.rejected, (state, action) => {
        state.assistantPersonList = [];
      })

      .addCase(getLeadSource.pending, state => {
        state.leadSourceList = [];
      })
      .addCase(getLeadSource.fulfilled, (state, action) => {
        state.leadSourceList = action.payload;
      })
      .addCase(getLeadSource.rejected, (state, action) => {
        state.leadSourceList = [];
      })

      .addCase(getAreaOffice.pending, state => {
        state.areaOfficeList = [];
      })
      .addCase(getAreaOffice.fulfilled, (state, action) => {
        state.areaOfficeList = action.payload;
      })
      .addCase(getAreaOffice.rejected, (state, action) => {
        state.areaOfficeList = [];
      })

      .addCase(getInsuranceCompany.pending, state => {
        state.insuranceCompanyList = [];
      })
      .addCase(getInsuranceCompany.fulfilled, (state, action) => {
        state.insuranceCompanyList = action.payload;
      })
      .addCase(getInsuranceCompany.rejected, (state, action) => {
        state.insuranceCompanyList = [];
      })

      .addCase(getInsuranceProduct.pending, state => {
        state.insuranceProductList = [];
      })
      .addCase(getInsuranceProduct.fulfilled, (state, action) => {
        state.insuranceProductList = action.payload;
      })
      .addCase(getInsuranceProduct.rejected, (state, action) => {
        state.insuranceProductList = [];
      })

      .addCase(getCustomerList.pending, state => {
        state.customerList = [];
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.customerList = action.payload;
      })
      .addCase(getCustomerList.rejected, (state, action) => {
        state.customerList = [];
      })

      .addCase(getCustomerView.pending, state => {
        state.isCustomerViewDataLoading = true;
        state.isCustomerViewDataSuccess = false;
        state.isCustomerViewDataFailed = false;
        state.customerViewData = [];
        state.customerViewDataErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCustomerView.fulfilled, (state, action) => {
        state.isCustomerViewDataLoading = false;
        state.isCustomerViewDataSuccess = true;
        state.customerViewData = action.payload;
      })
      .addCase(getCustomerView.rejected, (state, action) => {
        state.isCustomerViewDataLoading = false;
        state.isCustomerViewDataFailed = true;
        state.customerViewData = null;
        state.customerViewDataErrorContainer = {
          ...state.customerViewDataErrorContainer,
          ...action.payload
        };
      });
  }
});

export default leadSlice.reducer;
