import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectorMappingList } from '../../redux/reducers/leadSlice';

function ConnectorMapping({ formik, handleSetId }) {
  const [bizThroughConnector, setBizThroughConnector] = useState('Yes');
  const handleConnectorMappingChange = event => {
    setBizThroughConnector(event.target.value);
    handleSetId(event.target.value);
    if (event.target.value == 'No') {
      formik.setFieldValue('searchConnectorId', null);
    }
  };
  const { connectorMappingList } = useSelector(state => state.lead);
  const [connectorList, setConnectorList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formik.values.searchConnectorId) {
      setBizThroughConnector('Yes');
      handleSetId('Yes');
    } else {
      setBizThroughConnector('No');
      handleSetId('No');
    }
  }, [formik.values.searchConnectorId]);
  useEffect(() => {
    dispatch(getConnectorMappingList({ Id: formik.values.searchConnectorId }));
  }, [formik.values.searchConnectorId]);

  useEffect(() => {
    setConnectorList(connectorMappingList);
    if (connectorMappingList.length < 0) {
      formik.setFieldValue('connectorID', null);
    }
  }, [connectorMappingList, formik.values.searchConnectorId]);
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Connector Mapping
        </Typography>

        <FormControl component="fieldset">
          <FormLabel component="legend">Business through connector?</FormLabel>
          <RadioGroup
            row
            value={bizThroughConnector}
            onChange={handleConnectorMappingChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {bizThroughConnector === 'Yes' && (
          <Grid container spacing={3} marginBottom={'16px'}>
            <Grid item lg={3}>
              <TextField
                label="Search connector Code"
                name="searchConnectorId"
                size="small"
                fullWidth
                value={formik.values.searchConnectorId}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: formik.values.searchConnectorId !== ''
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <Autocomplete
                options={connectorList}
                getOptionLabel={option => option.name || ''}
                value={formik.values.connectorID}
                size="small"
                isOptionEqualToValue={(option, value) => {
                  return option.id === (value ? value.id : null);
                }}
                disableClearable
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'connectorID',
                    newValue ? newValue : null
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Connector Code *"
                    placeholder="Select Connector Code"
                    style={{ width: '350px', marginRight: '24px' }}
                    error={
                      formik.touched.connectorID &&
                      Boolean(formik.errors.connectorID)
                    }
                    helperText={
                      formik.touched.connectorID && formik.errors.connectorID
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}

export default ConnectorMapping;
