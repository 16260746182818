import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { isArrayNotEmpty } from '../../utils/utils';

const EmploymentDetailsCustom = ({
  applicantData,
  Index,
  handleOnChange,
  validationData
}) => {
  const MonthList = [
    {
      id: '1',
      value: '1'
    },
    {
      id: '2',
      value: '2'
    },
    {
      id: '3',
      value: '3'
    },
    {
      id: '4',
      value: '4'
    },
    {
      id: '5',
      value: '5'
    },
    {
      id: '6',
      value: '6'
    },
    {
      id: '7',
      value: '7'
    },
    {
      id: '8',
      value: '8'
    },
    {
      id: '9',
      value: '9'
    },
    {
      id: '10',
      value: '10'
    },
    {
      id: '11',
      value: '11'
    },
    {
      id: '12',
      value: '12'
    }
  ];

  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1955;

    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    ).map(year => ({ id: year, value: year }));

    setYearList(years);
  }, []);

  return (
    <>
      <FormControl component="fieldset">
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Employment Details
        </Typography>
        {/* <FormLabel component="legend">Employment Type</FormLabel> */}
        <RadioGroup
          row
          value={applicantData.employmentStatus}
          onChange={e => handleOnChange(e, Index)}
          name="employmentStatus"
        >
          <FormControlLabel
            value="Salaried"
            control={<Radio />}
            label="Salaried"
          />
          <FormControlLabel
            value="selfEmployed"
            control={<Radio />}
            label="Self Employed"
          />
        </RadioGroup>
      </FormControl>

      <Grid container spacing={3} marginBottom={'16px'}>
        <Grid item lg={4}>
          <TextField
            select
            label="Constitutuion Type *"
            name="constitutionType"
            size="small"
            fullWidth
            value={applicantData.constitutionType}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.constitutionType !== ''
            }}
            error={
              validationData &&
              validationData?.constitutionTypeVal &&
              Boolean(validationData?.constitutionTypeVal)
            }
            helperText={validationData && validationData?.constitutionTypeVal}
          >
            <MenuItem value="Private">Private</MenuItem>
            <MenuItem value="Government">Government</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4}>
          <TextField
            label={
              applicantData.employmentStatus == 'Salaried'
                ? 'Employer Name *'
                : 'Business Name *'
            }
            name="employerOrBusinessName"
            size="small"
            fullWidth
            value={applicantData.employerOrBusinessName}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.employerOrBusinessName !== ''
            }}
            error={
              validationData &&
              validationData?.employerOrBusinessNameVal &&
              Boolean(validationData?.employerOrBusinessNameVal)
            }
            helperText={
              validationData && validationData?.employerOrBusinessNameVal
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label={
              applicantData.employmentStatus == 'Salaried'
                ? 'Employer Address *'
                : 'Business Address *'
            }
            name="employerOrBusinessAddress"
            size="small"
            fullWidth
            value={applicantData.employerOrBusinessAddress}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.employerOrBusinessAddress !== ''
            }}
            error={
              validationData &&
              validationData?.employerOrBusinessAddressVal &&
              Boolean(
                validationData && validationData?.employerOrBusinessAddressVal
              )
            }
            helperText={
              validationData && validationData?.employerOrBusinessAddressVal
            }
          />
        </Grid>
        {/* <Grid item lg={4}>
          <TextField
            label="Voter ID Number"
            name="employerVoterId"
            size="small"
            fullWidth
            value={applicantData.employerVoterId}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.employerVoterId !== ''
            }}
            error={
              validationData &&
              validationData?.employerVoterIdVal &&
              Boolean(validationData?.employerVoterIdVal)
            }
            helperText={validationData && validationData?.employerVoterIdVal}
          />
        </Grid> */}
        <Grid item lg={4}>
          <TextField
            label="Pincode *"
            name="pincode"
            size="small"
            fullWidth
            value={applicantData.pincode}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.pincode !== '' }}
            error={
              validationData &&
              validationData?.pincodeVal &&
              Boolean(validationData?.pincodeVal)
            }
            helperText={validationData && validationData?.pincodeVal}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled
            label="City *"
            name="city"
            size="small"
            fullWidth
            value={applicantData.city}
            // onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.city !== '' }}
            error={
              validationData &&
              validationData?.cityVal &&
              Boolean(validationData?.cityVal)
            }
            helperText={validationData && validationData?.cityVal}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled
            label="State *"
            name="state"
            size="small"
            fullWidth
            value={applicantData.state}
            // onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.state !== '' }}
            error={
              validationData &&
              validationData?.stateVal &&
              Boolean(validationData?.stateVal)
            }
            helperText={validationData && validationData?.stateVal}
          />
        </Grid>
      </Grid>
      <Typography
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
          color: '#000000',
          fontSize: '14px',
          fontWeight: 'bold'
        }}
      >
        {applicantData.employmentStatus == 'Salaried'
          ? 'Employer Since'
          : 'Business Since'}
      </Typography>
      <Grid container spacing={3} marginBottom={'16px'}>
        <Grid item lg={2}>
          {/* <TextField
            label="Year *"
            name="employedSinceYear"
            size="small"
            fullWidth
            value={applicantData.employedSinceYear}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.employedSinceYear !== ''
            }}
            error={
              validationData &&
              validationData?.employedSinceYearVal &&
              Boolean(validationData?.employedSinceYearVal)
            }
            helperText={validationData && validationData?.employedSinceYearVal}
          /> */}

          <Autocomplete
            options={
              isArrayNotEmpty(yearList)
                ? yearList?.map(c => {
                    return { value: c.value, id: c.id };
                  })
                : []
            }
            disableClearable
            getOptionLabel={option => option.value || ''}
            value={
              applicantData.employedSinceYear
                ? {
                    value: Number(applicantData.employedSinceYear),
                    id: Number(applicantData.employedSinceYear)
                  }
                : null
            }
            isOptionEqualToValue={(option, value) => {
              return option.id === (value ? value.id : null);
            }}
            size="small"
            fullWidth
            name="employedSinceYear"
            // onChange={(event, newValue) => {
            //   formik.setFieldValue('sinceYear', newValue);
            // }}
            onChange={(e, newValue) =>
              handleOnChange(e, Index, 'employedSinceYear', newValue)
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Year *"
                placeholder="Select Year"
                error={
                  validationData &&
                  validationData?.employedSinceYearVal &&
                  Boolean(validationData?.employedSinceYearVal)
                }
                helperText={
                  validationData && validationData?.employedSinceYearVal
                }
              />
            )}
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            select
            label="Month *"
            name="employedSinceMonth"
            size="small"
            fullWidth
            value={applicantData.employedSinceMonth}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.employedSinceMonth !== ''
            }}
            error={
              validationData &&
              validationData?.employedSinceMonthVal &&
              Boolean(validationData?.employedSinceMonthVal)
            }
            helperText={validationData && validationData?.employedSinceMonthVal}
          >
            {MonthList.map(item => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default EmploymentDetailsCustom;
