import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

const initial = {
  isGetRoleListLoading: false,
  isGetRoleListSuccess: false,
  isGetRoleListFailed: false,
  roleData: {
    roleList: [],
    totalCount: 0
  },
  roleListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetAllRoleListLoading: false,
  isGetAllRoleListSuccess: false,
  isGetAllRoleListFailed: false,
  allRoles: {
    roleList: [],
    totalCount: 0
  },
  allRoleListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isRoleDetailsLoading: false,
  isRoleDetailsSuccess: false,
  isRoleDetailsFailed: false,
  roleDetails: null,
  roleDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isStatusRoleLoading: false,
  isStatusRoleSuccess: false,
  isStatusRoleFailed: false,
  roleStatus: null,
  statusRoleErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDeActivateRoleLoading: false,
  isDeActivateRoleSuccess: false,
  isDeActivateRoleFailed: false,
  deactivateRoleErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateRoleLoading: false,
  isCreateUpdateRoleSuccess: false,
  isCreateUpdateRoleFailed: false,
  createUpdateRoleResponse: '',
  createUpdateRoleErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActiveModulesLoading: false,
  isActiveModulesSuccess: false,
  isActiveModulesFailed: false,
  activeModulesData: [],
  activeModulesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isActiveDashboardChartsLoading: false,
  isActiveDashboardChartsSuccess: false,
  isActiveDashboardChartsFailed: false,
  activeDashboardChartsData: [],
  activeDashboardChartsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getRoleDetails = createAsyncThunk(
  'roles/getRoleDetails',
  async (roleId, { rejectWithValue }) => {
    try {
      const response = await api.get(`role/${roleId}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getActiveModules = createAsyncThunk(
  'roles/getActiveModules',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`role/getAllActiveModules`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getActiveDashboardCharts = createAsyncThunk(
  'roles/getActiveDashboardCharts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`getAllActiveDashboardCharts`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getRoleList = createAsyncThunk(
  'roles/getRoleList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      //
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      response = await api.get(
        `role/getRoleList?page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
      );

      return response.data || initial.roleData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAllRoleListForDropdown = createAsyncThunk(
  'roles/getAllRoleListForDropdown',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `role/getRoleList?page=${payload.page}&limit=${payload.limit}`
      );
      return response.data || initial.allRoles;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const changeRoleStatus = createAsyncThunk(
  'roles/changeRoleStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`role/status`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUpdateRole = createAsyncThunk(
  'roles/createUpdateRole',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      if (payload?.roleId) {
        response = await api.put(`role/editRole`, payload);
      } else {
        response = await api.post(`role/addRole`, payload);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revertRoleFormData = createAction('REVERT_ROLE_FORM_DATA');

export const roleManagementSlice = createSlice({
  name: 'roles',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertRoleFormData, state => {
        state.isRoleDetailsLoading = false;
        state.isRoleDetailsSuccess = false;
        state.isRoleDetailsFailed = false;
        state.roleDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
        state.isStatusRoleLoading = false;
        state.isStatusRoleFailed = false;
        state.isStatusRoleSuccess = false;
        state.roleStatus = '';
        state.statusRoleErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = false;
        state.createUpdateRoleResponse = '';
        state.createUpdateRoleErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })

      .addCase(getRoleList.pending, state => {
        state.isGetRoleListLoading = true;
        state.isGetRoleListSuccess = false;
        state.isGetRoleListFailed = false;
        state.roleListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.isGetRoleListSuccess = true;
        state.roleData = {
          roleList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getRoleList.rejected, (state, action) => {
        state.isGetRoleListLoading = false;
        state.isGetRoleListFailed = true;
        state.roleData = initial;
        state.roleListErrorContainer = {
          ...state.roleListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAllRoleListForDropdown.pending, state => {
        state.isGetAllRoleListLoading = true;
        state.isGetAllRoleListSuccess = false;
        state.isGetAllRoleListFailed = false;
        state.allRoleListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAllRoleListForDropdown.fulfilled, (state, action) => {
        state.isGetAllRoleListLoading = false;
        state.isGetAllRoleListSuccess = true;
        state.allRoles = {
          roleList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getAllRoleListForDropdown.rejected, (state, action) => {
        state.isGetAllRoleListLoading = false;
        state.isGetAllRoleListFailed = true;
        state.allRoles = initial;
        state.allRoleListErrorContainer = {
          ...state.allRoleListErrorContainer,
          ...action.payload
        };
      })

      .addCase(changeRoleStatus.pending, state => {
        state.isStatusRoleLoading = true;
        state.isStatusRoleSuccess = false;
        state.isStatusRoleFailed = false;
        state.statusRoleErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(changeRoleStatus.fulfilled, (state, action) => {
        state.isStatusRoleLoading = false;
        state.isStatusRoleFailed = false;
        state.isStatusRoleSuccess = true;
        state.roleStatus = action.payload;
      })
      .addCase(changeRoleStatus.rejected, (state, action) => {
        state.isStatusRoleLoading = false;
        state.isStatusRoleSuccess = false;
        state.isStatusRoleFailed = true;
        state.statusRoleErrorContainer = {
          ...state.statusRoleErrorContainer,
          ...action.payload
        };
      })

      .addCase(getRoleDetails.pending, state => {
        state.isRoleDetailsLoading = true;
        state.isRoleDetailsSuccess = false;
        state.isRoleDetailsFailed = false;
        state.roleDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getRoleDetails.fulfilled, (state, action) => {
        state.isRoleDetailsLoading = false;
        state.isRoleDetailsSuccess = true;
        state.roleDetails = action.payload;
      })
      .addCase(getRoleDetails.rejected, (state, action) => {
        state.isRoleDetailsLoading = false;
        state.isRoleDetailsFailed = true;
        state.roleDetailsErrorContainer = {
          ...state.roleDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateRole.pending, state => {
        state.isCreateUpdateRoleLoading = true;
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = false;
        state.createUpdateRoleErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateRole.fulfilled, (state, action) => {
        state.isCreateUpdateRoleLoading = false;
        state.isCreateUpdateRoleSuccess = true;
        state.isCreateUpdateRoleFailed = false;
        state.createUpdateRoleResponse = action.payload?.message;
      })
      .addCase(createUpdateRole.rejected, (state, action) => {
        state.isCreateUpdateRoleLoading = false;
        state.isCreateUpdateRoleSuccess = false;
        state.isCreateUpdateRoleFailed = true;
        state.createUpdateRoleErrorContainer = {
          ...state.createUpdateRoleErrorContainer,
          ...action.payload
        };
      })
      .addCase(getActiveModules.pending, state => {
        state.isActiveModulesLoading = true;
        state.isActiveModulesSuccess = false;
        state.isActiveModulesFailed = false;
        state.activeModulesErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getActiveModules.fulfilled, (state, action) => {
        state.isActiveModulesLoading = false;
        state.isActiveModulesSuccess = true;
        state.isActiveModulesFailed = false;
        state.activeModulesData = action.payload;
      })
      .addCase(getActiveModules.rejected, (state, action) => {
        state.isActiveModulesLoading = false;
        state.isActiveModulesSuccess = false;
        state.isActiveModulesFailed = true;
        state.activeModulesErrorContainer = {
          ...state.activeModulesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getActiveDashboardCharts.pending, state => {
        state.isActiveDashboardChartsLoading = true;
        state.isActiveDashboardChartsSuccess = false;
        state.isActiveDashboardChartsFailed = false;
        state.activeDashboardChartsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getActiveDashboardCharts.fulfilled, (state, action) => {
        state.isActiveDashboardChartsLoading = false;
        state.isActiveDashboardChartsSuccess = true;
        state.isActiveDashboardChartsFailed = false;
        state.activeDashboardChartsData = action.payload;
      })
      .addCase(getActiveDashboardCharts.rejected, (state, action) => {
        state.isActiveDashboardChartsLoading = false;
        state.isActiveDashboardChartsSuccess = false;
        state.isActiveDashboardChartsFailed = true;
        state.activeDashboardChartsErrorContainer = {
          ...state.activeDashboardChartsErrorContainer,
          ...action.payload
        };
      });
  }
});

export default roleManagementSlice.reducer;
