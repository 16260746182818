import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  deleteLeadDocuments,
  getAreaOffice,
  getAssistingPerson,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getSourcePerson,
  updateLeadDetails
} from '../../redux/reducers/leadSlice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import UploadDocumentsLead from '../../forms/CWALeadComponents/uploadDocumentsLead';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import LeadSourceDetails from '../../forms/CWALeadComponents/LeadSourceDetails';

function CWALeadFdForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    isGetLeadDetailsLoading,
    sourcePersonList,
    leadSourceList,
    assistantPersonList,
    isGetLeadDocLoading,
    leadUpdateErrorContainer
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;
  const [isFirstTime, setIsFirstTime] = useState(true);
  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadSource({}));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadDetails({ leadId, customerId, productId }));
    dispatch(getLeadDocuments({ leadId, customerId, productId }));
    dispatch(getAreaOffice({}));
  }, [leadId, customerId]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: productId
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      customerId: leadDetails?.customerDetails?.customerId || '',
      applicationId: leadDetails?.leadDetails?.applicationId || '',
      leadId: leadDetails?.leadDetails?.id || '',
      fullName: leadDetails?.customerDetails?.name || '',
      panNumber: leadDetails?.customerDetails?.panNumber || '',
      dob: leadDetails?.customerDetails?.dob || '',
      mobileNo: leadDetails?.customerDetails?.mobileNumber || '',
      emailId: leadDetails?.customerDetails?.emailId || '',
      sourcePerson: leadDetails?.leadDetails?.sp || null,
      leadSource: leadDetails?.leadDetails?.leadSource || null,
      assistantPerson: leadDetails?.leadDetails?.ap || null,
      areaOffice: leadDetails?.leadDetails?.areaOffice || null,
      corporateDeposit: leadDetails?.leadDetails?.deposit || '',

      termYear: leadDetails?.leadDetails?.termYear
        ? {
            value: leadDetails?.leadDetails?.termYear,
            id: leadDetails?.leadDetails?.termYear
          }
        : null,

      termMonth: leadDetails?.leadDetails?.termMonth || '',
      fdAmount: leadDetails?.leadDetails?.amount,
      fdtermscheme:
        leadDetails?.leadDetails?.cummulativeNonCummulative || 'Cumulative',
      fdtermperiod: leadDetails?.leadDetails?.cummulativeType || 'Monthly',
      doi: leadDetails?.leadDetails?.dateOfInvestment || '',
      fdReceiptNo: leadDetails?.leadDetails?.receiptNo || '',
      folioKycNo: leadDetails?.leadDetails?.folioNo || '',
      seniorCitizen:
        leadDetails?.leadDetails?.seniorCitizen === true ? true : false,
      licGrpCompEmployee:
        leadDetails?.leadDetails?.groupCompanyEmployee === true ? true : false,
      chequeNo: leadDetails?.leadDetails?.chequeRtgsNeftNo || '',
      bankName: leadDetails?.leadDetails?.bankName || '',
      branchName: leadDetails?.leadDetails?.branchName || '',
      customerAddress: leadDetails?.leadDetails?.customerAddress || '',
      sourceThrough: leadDetails?.leadDetails?.sourcedThrough || ''
    });
  }, [leadDetails]);

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required('Full Name is required')
    //   .matches(/^[A-Za-z\s]+$/, 'Invalid Name'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // dob: Yup.date().required('Date of Birth is required'),
    // mobileNo: Yup.string()
    //   .matches(/^[6789]\d{9}$/, 'Invalid Mobile number')
    //   .required('Mobile number is required'),

    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area Office is required')
    }).required('Area Office is required'),

    corporateDeposit: Yup.string().required('Corporate deposit is required'),

    termYear: Yup.object({
      id: Yup.string().required('Year is required')
    }).required('Year is required'),

    // termYear: Yup.string()
    //   .required('Year is required')
    //   .matches(/^\d+$/, 'Invalid format'),

    termMonth: Yup.string().required('Month is required'),
    fdAmount: Yup.string()
      .required('Amount is required')
      .matches(/^\d+(\.\d+)?$/, 'Invalid format'),
    fdtermscheme: Yup.string().required('Field is required'),
    fdtermperiod: Yup.string().required('Field is required'),

    doi: Yup.date().required('Date of Investment is required'),

    fdReceiptNo: Yup.string()
      .required('Receipt number required')
      .matches(/^\d+$/, 'Invalid format'),
    folioKycNo: Yup.string()
      .required('Fplio/KYC number is required')
      .matches(/^\d+$/, 'Invalid format'),
    seniorCitizen: Yup.string().required('Senior Citizen is required'),
    licGrpCompEmployee: Yup.string().required('Field is required'),

    chequeNo: Yup.string()
      .required('Cheque Number is required')
      .matches(/^\d+$/, 'Invalid format'),

    bankName: Yup.string()
      .required('Bank Name is required')
      .matches(/^[A-Za-z\s]+$/, 'Invalid Bank Name'),
    branchName: Yup.string().required('Branch Name is required'),
    customerAddress: Yup.string().required('Customer Address is required'),

    sourceThrough: Yup.string().required('This field is required')
  });

  const formik = useFormik({
    initialValues: {
      customerId: '',
      applicationId: '',
      leadId: 0,
      fullName: '',
      panNumber: '',
      dob: '',
      mobileNo: '',
      emailId: '',
      sourcePerson: null,
      leadSource: null,
      assistantPerson: null,
      areaOffice: null,
      corporateDeposit: '',
      termYear: null,
      termMonth: '',
      fdAmount: '',
      fdtermscheme: 'Cumulative',
      fdtermperiod: 'Monthly',
      doi: '',
      fdReceiptNo: '',
      folioKycNo: '',
      seniorCitizen: true,
      chequeNo: '',
      bankName: '',
      branchName: '',
      customerAddress: '',
      licGrpCompEmployee: true,
      sourceThrough: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const formData = new FormData();

      let payload = {
        customerId: values?.customerId || 0,
        leadId: values?.leadId || 0,
        productId: productId || 4,
        name: values?.fullName || '',
        mobileNumber: values?.mobileNo || '',
        emailId: values?.emailId || '',
        panNumber: values?.panNumber || '',
        dob: values?.dob || '',
        leadSource: values?.leadSource?.id || 0,
        sp: values?.sourcePerson?.id || 0,
        ap: values?.assistantPerson?.id || 0,
        areaOffice: values?.areaOffice?.id || 0,
        deposit: values?.corporateDeposit || '',
        termMonth: values?.termMonth || 0,
        termYear: parseInt(values?.termYear?.id) || 0,
        amount: values?.fdAmount ? parseFloat(values?.fdAmount).toFixed(2) : 0,
        cummulativeNonCummulative: values?.fdtermscheme || '',
        cummulativeType: values?.fdtermperiod || '',
        folioNo: parseInt(values?.folioKycNo) || '',
        seniorCitizen: values?.seniorCitizen === true ? true : false,
        groupCompanyEmployee:
          values?.licGrpCompEmployee === true ? true : false,
        dateOfInvestment: values?.doi || '',
        receiptNo: parseInt(values?.fdReceiptNo) || '',
        chequeRtgsNeftNo: parseInt(values?.chequeNo) || '',
        bank: values?.bankName || '',
        branch: values?.branchName || '',
        customerAddress: values?.customerAddress || '',
        sourcedThrough: values?.sourceThrough || ''
      };
      formData.append('customerLeadRequest', JSON.stringify(payload));
      formData.append(
        'document1',
        docArray1?.[0]?.lastModified ? docArray1[0] : null
      );
      formData.append(
        'document2',
        docArray2?.[0]?.lastModified ? docArray2[0] : null
      );
      dispatch(updateLeadDetails({ payload: formData, productId: productId }));
    }
  });

  const MonthList = [
    {
      id: '1',
      value: '1'
    },
    {
      id: '2',
      value: '2'
    },
    {
      id: '3',
      value: '3'
    },
    {
      id: '4',
      value: '4'
    },
    {
      id: '5',
      value: '5'
    },
    {
      id: '6',
      value: '6'
    },
    {
      id: '7',
      value: '7'
    },
    {
      id: '8',
      value: '8'
    },
    {
      id: '9',
      value: '9'
    },
    {
      id: '10',
      value: '10'
    },
    {
      id: '11',
      value: '11'
    },
    {
      id: '12',
      value: '12'
    }
  ];

  const doc1 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );

  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };
  const handleDeleteDocument = () => {
    dispatch(
      deleteLeadDocuments({ fileUrl: selectedFileName, product: productId })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId, productId }));
    }, 1000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
  };
  useEffect(() => {
    if (isFirstTime) {
      if (leadDetails?.leadDetails?.sp?.id) {
        dispatch(
          getAssistingPerson({
            sourcePersonId: leadDetails?.leadDetails?.sp?.id || 0
          })
        );
      }
    }
  }, [leadDetails?.leadDetails?.sp]);

  useEffect(() => {
    if (!isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: formik.values?.sourcePerson?.id || 0
        })
      );
    }
  }, [formik.values?.sourcePerson]);

  useEffect(() => {
    if (!isFirstTime) {
      if (assistantPersonList) {
        formik.setFieldValue('assistantPerson', assistantPersonList[0]);
      }
    }
  }, [assistantPersonList]);

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };

  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1955;

    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    ).map(year => ({ id: year, value: year }));

    setYearList(years);
  }, []);

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isGetLeadDetailsLoading && (
          <form>
            {/* Basic details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Basic Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Applicant ID"
                    name="customerId"
                    size="small"
                    fullWidth
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customerId !== ''
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Lead ID"
                    name="applicationId"
                    size="small"
                    fullWidth
                    value={formik.values.applicationId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.applicationId !== ''
                    }}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Full as per PAN Card"
                    name="fullName"
                    placeholder="Enter Full Name"
                    size="small"
                    fullWidth
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fullName !== ''
                    }}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="PAN Number"
                    name="panNumber"
                    size="small"
                    fullWidth
                    value={formik.values.panNumber}
                    // onChange={formik.handleChange}
                    onChange={e => {
                      formik.setFieldValue(
                        'panNumber',
                        e.target.value.toUpperCase()
                      );
                    }}
                    InputLabelProps={{
                      shrink: formik.values.panNumber !== ''
                    }}
                    error={
                      formik.touched.panNumber &&
                      Boolean(formik.errors.panNumber)
                    }
                    helperText={
                      formik.touched.panNumber && formik.errors.panNumber
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Date of Birth"
                      value={
                        formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dob', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dob && Boolean(formik?.errors?.dob)
                          }
                          helperText={
                            formik?.touched?.dob && formik?.errors?.dob
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dob && formik.errors?.dob && (
                    <FormHelperText>{formik.errors?.dob}</FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Mobile Number"
                    name="mobileNo"
                    size="small"
                    fullWidth
                    value={formik.values.mobileNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.mobileNo !== ''
                    }}
                    error={
                      formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                    }
                    helperText={
                      formik.touched.mobileNo && formik.errors.mobileNo
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Email ID"
                    name="emailId"
                    size="small"
                    fullWidth
                    value={formik.values.emailId}
                    onChange={e => {
                      formik.setFieldValue(
                        'emailId',
                        e.target.value.toLowerCase()
                      );
                    }}
                    InputLabelProps={{ shrink: formik.values.emailId !== '' }}
                    error={
                      formik.touched.emailId && Boolean(formik.errors.emailId)
                    }
                    helperText={formik.touched.emailId && formik.errors.emailId}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider style={{ marginBottom: '12px' }} />

            {/* SP Details */}
            {/* <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Lead Source Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <Autocomplete
                    options={
                      isArrayNotEmpty(leadSourceList)
                        ? leadSourceList?.map(c => {
                            return { name: c.name, id: c.id };
                          })
                        : []
                    }
                    getOptionLabel={option => option.name || ''}
                    value={formik.values?.leadSource}
                    isOptionEqualToValue={(option, value) => {
                      return option.name === (value ? value.id : null);
                    }}
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      handleOnChange('leadSource', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Lead Source*"
                        placeholder="Select Lead Source"
                        style={{ marginRight: '24px' }}
                        error={
                          formik.touched.leadSource &&
                          Boolean(formik.errors.leadSource)
                        }
                        helperText={
                          formik.touched.leadSource && formik.errors.leadSource
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4}>
                  <Autocomplete
                    options={
                      isArrayNotEmpty(sourcePersonList)
                        ? sourcePersonList?.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    getOptionLabel={option => option.name || ''}
                    value={formik.values?.sourcePerson}
                    isOptionEqualToValue={(option, value) => {
                      return option.name === (value ? value.id : null);
                    }}
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      handleOnChange('sourcePerson', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="SP*"
                        placeholder="Select SP"
                        style={{ marginRight: '24px' }}
                        error={
                          formik.touched.sourcePerson &&
                          Boolean(formik.errors.sourcePerson)
                        }
                        helperText={
                          formik.touched.sourcePerson &&
                          formik.errors.sourcePerson
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div> */}
            <LeadSourceDetails
              formik={formik}
              handleState={handleFirstTimeValue}
            />

            <Divider style={{ marginBottom: '12px' }} />

            {/* Policy Details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Policy Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Corporate Deposit*"
                    name="corporateDeposit"
                    size="small"
                    fullWidth
                    value={formik.values.corporateDeposit}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.corporateDeposit !== ''
                    }}
                    error={
                      formik.touched.corporateDeposit &&
                      Boolean(formik.errors.corporateDeposit)
                    }
                    helperText={
                      formik.touched.corporateDeposit &&
                      formik.errors.corporateDeposit
                    }
                  >
                    <MenuItem key={'Public Deposit'} value={'Public Deposit'}>
                      {'Public Deposit'}
                    </MenuItem>
                    <MenuItem
                      key={'Green Public Deposit'}
                      value={'Green Public Deposit'}
                    >
                      {'Green Public Deposit'}
                    </MenuItem>
                    <MenuItem
                      key={'Corporate Deposit'}
                      value={'Corporate Deposit'}
                    >
                      {'Corporate Deposit'}
                    </MenuItem>
                  </TextField>
                </Grid>

                {/* <Grid item lg={2}>
                  <TextField
                    label="FD term year *"
                    name="termYear"
                    size="small"
                    fullWidth
                    value={formik.values.termYear}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values.termYear !== '' }}
                    error={
                      formik.touched.termYear && Boolean(formik.errors.termYear)
                    }
                    helperText={
                      formik.touched.termYear && formik.errors.termYear
                    }
                  />
                </Grid> */}

                <Grid item lg={2}>
                  <Autocomplete
                    options={
                      isArrayNotEmpty(yearList)
                        ? yearList?.map(c => {
                            return { value: c.value, id: c.id };
                          })
                        : []
                    }
                    disableClearable
                    getOptionLabel={option => option.value || ''}
                    value={formik.values?.termYear}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      formik.setFieldValue('termYear', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="FD term year *"
                        placeholder="Select Year"
                        error={
                          formik.touched.termYear &&
                          Boolean(formik.errors.termYear)
                        }
                        helperText={
                          formik.touched.termYear && formik.errors.termYear
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={2}>
                  <TextField
                    select
                    label="FD term month *"
                    name="termMonth"
                    size="small"
                    fullWidth
                    value={formik.values.termMonth}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.termMonth !== ''
                    }}
                    error={
                      formik.touched.termMonth &&
                      Boolean(formik.errors.termMonth)
                    }
                    helperText={
                      formik.touched.termMonth && formik.errors.termMonth
                    }
                  >
                    {MonthList.map(item => {
                      return (
                        <MenuItem key={item.id} value={item.value}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="FD Amount(₹)*"
                    name="fdAmount"
                    size="small"
                    fullWidth
                    value={formik?.values?.fdAmount}
                    onChange={formik?.handleChange}
                    onKeyPress={e => {
                      const currentValue = formik.values.fdAmount;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik?.values?.fdAmount !== ''
                    }}
                    error={
                      formik?.touched?.fdAmount &&
                      Boolean(formik?.errors?.fdAmount)
                    }
                    helperText={
                      formik?.touched?.fdAmount && formik?.errors?.fdAmount
                    }
                  />
                </Grid>

                <Grid
                  item
                  lg={formik.values.fdtermscheme === 'Cumulative' ? 2 : 4}
                >
                  <TextField
                    select
                    label=""
                    name="fdtermscheme"
                    size="small"
                    fullWidth
                    value={formik.values.fdtermscheme}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fdtermscheme !== ''
                    }}
                    error={
                      formik.touched.fdtermscheme &&
                      Boolean(formik.errors.fdtermscheme)
                    }
                    helperText={
                      formik.touched.fdtermscheme && formik.errors.fdtermscheme
                    }
                  >
                    <MenuItem key={'Cumulative'} value={'Cumulative'}>
                      {'Cumulative'}
                    </MenuItem>
                    <MenuItem key={'Non-Cumulative'} value={'Non-Cumulative'}>
                      {'Non-Cumulative'}
                    </MenuItem>
                  </TextField>
                </Grid>
                {formik.values.fdtermscheme === 'Cumulative' && (
                  <Grid item lg={2}>
                    <TextField
                      select
                      label=""
                      name="fdtermperiod"
                      size="small"
                      fullWidth
                      value={formik.values.fdtermperiod}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        shrink: formik.values.fdtermperiod !== ''
                      }}
                      error={
                        formik.touched.fdtermperiod &&
                        Boolean(formik.errors.fdtermperiod)
                      }
                      helperText={
                        formik.touched.fdtermperiod &&
                        formik.errors.fdtermperiod
                      }
                    >
                      <MenuItem key={'Monthly'} value={'Monthly'}>
                        {'Monthly'}
                      </MenuItem>
                      <MenuItem key={'Yearly'} value={'Yearly'}>
                        {'Yearly'}
                      </MenuItem>
                    </TextField>
                  </Grid>
                )}

                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Investment *"
                      value={
                        formik?.values?.doi ? dayjs(formik?.values?.doi) : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('doi', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.doi && formik?.errors?.doi
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.doi && Boolean(formik?.errors?.doi)
                          }
                          helperText={
                            formik?.touched?.doi && formik?.errors?.doi
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.doi && formik.errors.doi && (
                    <FormHelperText>{formik.errors.doi}</FormHelperText>
                  )}
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="FD Receipt No *"
                    name="fdReceiptNo"
                    size="small"
                    fullWidth
                    value={formik.values.fdReceiptNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fdReceiptNo !== ''
                    }}
                    error={
                      formik.touched.fdReceiptNo &&
                      Boolean(formik.errors.fdReceiptNo)
                    }
                    helperText={
                      formik.touched.fdReceiptNo && formik.errors.fdReceiptNo
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Fplio/KYC No *"
                    name="folioKycNo"
                    size="small"
                    fullWidth
                    value={formik.values.folioKycNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.folioKycNo !== ''
                    }}
                    error={
                      formik.touched.folioKycNo &&
                      Boolean(formik.errors.folioKycNo)
                    }
                    helperText={
                      formik.touched.folioKycNo && formik.errors.folioKycNo
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Senior Citizen*"
                    name="seniorCitizen"
                    size="small"
                    fullWidth
                    value={formik.values?.seniorCitizen}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values?.seniorCitizen !== ''
                    }}
                    error={
                      formik.touched.seniorCitizen &&
                      Boolean(formik.errors.seniorCitizen)
                    }
                    helperText={
                      formik.touched.seniorCitizen &&
                      formik.errors.seniorCitizen
                    }
                  >
                    <MenuItem key={true} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                      No
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="LIC/LICHFL FSL/ Group Company Emp.*"
                    name="licGrpCompEmployee"
                    size="small"
                    fullWidth
                    value={formik.values?.licGrpCompEmployee}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values?.licGrpCompEmployee !== ''
                    }}
                    error={
                      formik.touched.licGrpCompEmployee &&
                      Boolean(formik.errors.licGrpCompEmployee)
                    }
                    helperText={
                      formik.touched.licGrpCompEmployee &&
                      formik.errors.licGrpCompEmployee
                    }
                  >
                    <MenuItem key={true} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                      No
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Cheque/RTGS/NEFT No. *"
                    name="chequeNo"
                    size="small"
                    fullWidth
                    value={formik.values.chequeNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.chequeNo !== ''
                    }}
                    error={
                      formik.touched.chequeNo && Boolean(formik.errors.chequeNo)
                    }
                    helperText={
                      formik.touched.chequeNo && formik.errors.chequeNo
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Bank Name *"
                    name="bankName"
                    size="small"
                    fullWidth
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.bankName !== ''
                    }}
                    error={
                      formik.touched.bankName && Boolean(formik.errors.bankName)
                    }
                    helperText={
                      formik.touched.bankName && formik.errors.bankName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Branch Name *"
                    name="branchName"
                    size="small"
                    fullWidth
                    value={formik.values.branchName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.branchName !== ''
                    }}
                    error={
                      formik.touched.branchName &&
                      Boolean(formik.errors.branchName)
                    }
                    helperText={
                      formik.touched.branchName && formik.errors.branchName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Address of Customer *"
                    name="customerAddress"
                    size="small"
                    fullWidth
                    value={formik.values.customerAddress}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customerAddress !== ''
                    }}
                    error={
                      formik.touched.customerAddress &&
                      Boolean(formik.errors.customerAddress)
                    }
                    helperText={
                      formik.touched.customerAddress &&
                      formik.errors.customerAddress
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Sourced Through*"
                    name="sourceThrough"
                    size="small"
                    fullWidth
                    value={formik.values?.sourceThrough}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values?.sourceThrough !== ''
                    }}
                    error={
                      formik.touched.sourceThrough &&
                      Boolean(formik.errors.sourceThrough)
                    }
                    helperText={
                      formik.touched.sourceThrough &&
                      formik.errors.sourceThrough
                    }
                  >
                    <MenuItem key={'New Business'} value={'New Business'}>
                      New Business
                    </MenuItem>
                    <MenuItem key={'Reinvestment'} value={'Reinvestment'}>
                      Reinvestment
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              {/* <Divider style={{ marginBottom: '12px', marginTop: '16px' }} /> */}
            </div>

            <Divider style={{ marginBottom: '12px' }} />
            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: productId
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>

            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            {!isLeadUpdateLoading && isLeadUpdateSuccess && (
              <SnackBarBox
                type="success"
                message={'Lead data update successfully'}
              />
            )}

            {!isLeadUpdateLoading && isLeadUpdateFailed && (
              <SnackBarBox
                type="error"
                message={handleError(leadUpdateErrorContainer?.message)}
              />
            )}
          </form>
        )}
      </div>
    </>
  );
}

export default CWALeadFdForm;
