import { Grid, TextField, Typography } from '@mui/material';

function ResidentDetails({ formik }) {
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Residence Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField
              label="Resident Address *"
              name="residentAddress"
              placeholder="Enter resident address"
              size="small"
              fullWidth
              value={formik.values.residentAddress}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.residentAddress !== '' }}
              error={
                formik.touched.residentAddress &&
                Boolean(formik.errors.residentAddress)
              }
              helperText={
                formik.touched.residentAddress && formik.errors.residentAddress
              }
            />
          </Grid>

          <Grid item lg={4}>
            <TextField
              label={`Telephone Number${formik.values?.constitutionType === 1 || formik.values?.constitutionType === 5 ? '' : ' *'}`}
              name="phoneNo"
              size="small"
              fullWidth
              value={formik.values.phoneNo}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.phoneNo !== '' }}
              error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
            />
          </Grid>

          <Grid item lg={4}>
            <TextField
              label="Mobile Number *"
              name="mobileNo"
              size="small"
              fullWidth
              value={formik.values.mobileNo}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.mobileNo !== '' }}
              error={formik.touched.mobileNo && Boolean(formik.errors.mobileNo)}
              helperText={formik.touched.mobileNo && formik.errors.mobileNo}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Email ID *"
              name="emailId"
              size="small"
              fullWidth
              value={formik.values.emailId}
              // onChange={formik.handleChange}
              onChange={e => {
                const lowerCaseValue = e.target.value.toLowerCase();
                formik.setFieldValue('emailId', lowerCaseValue);
              }}
              InputLabelProps={{ shrink: formik.values.emailId !== '' }}
              error={formik.touched.emailId && Boolean(formik.errors.emailId)}
              helperText={formik.touched.emailId && formik.errors.emailId}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ResidentDetails;
