import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../components/CustomDataTable';
import { LoadingButton } from '@mui/lab';
const ModalDialog = React.lazy(() => import('../../components/ModalDialog'));

const AssociationDetails = ({ formik, handleUpdateData }) => {
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const initialState = {
    nameOfInstitution: '',
    associatedSince: '',
    businessInLastFinancialYear: ''
  };
  const initialValidation = {
    nameOfInstitutionVal: '',
    associatedSinceVal: '',
    businessInLastFinancialYearVal: ''
  };
  const [validation, setValidation] = useState(initialValidation);
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setTableData(formik?.values?.associationDetails);
  }, [formik]);

  const handleOnChange = (e, name) => {
    let validationName = name + 'Val';
    setFormState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));
    setValidation(prevState => ({
      ...prevState,
      [validationName]: ''
    }));
  };
  const handleValidation = () => {
    let isValid = true;

    if (!formState.nameOfInstitution) {
      setValidation(prevState => ({
        ...prevState,
        nameOfInstitutionVal: 'Please enter Institution Name'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        nameOfInstitutionVal: ''
      }));
    }
    if (!formState.associatedSince) {
      setValidation(prevState => ({
        ...prevState,
        associatedSinceVal: 'Please enter Associate Year'
      }));
      isValid = false;
    } else if (!/^\d+$/.test(formState.associatedSince)) {
      setValidation(prevState => ({
        ...prevState,
        associatedSinceVal: 'Please enter a valid year with numbers only'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        associatedSinceVal: ''
      }));
    }
    if (!formState.businessInLastFinancialYear) {
      setValidation(prevState => ({
        ...prevState,
        businessInLastFinancialYearVal: 'Please enter Financial Year'
      }));
      isValid = false;
    } else if (!/^\d+$/.test(formState.businessInLastFinancialYear)) {
      // Check if the value contains only numbers
      setValidation(prevState => ({
        ...prevState,
        businessInLastFinancialYearVal:
          'Please enter a valid amount with numbers only'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        businessInLastFinancialYearVal: ''
      }));
    }
    return isValid;
  };
  const handleSubmit = () => {
    let isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    if (rowIndex) {
      const updatedItem = {
        ...formState,
        id: rowIndex
      };
      let tempTableData = tableData.map(item =>
        item.id === updatedItem.id ? updatedItem : item
      );
      setTableData(tempTableData);
      // const updatedTempData = tempTableData?.map(({ id, ...rest }) => rest);
      handleUpdateData('AssociationDetails', tempTableData);
      setFormState(initialState);
      setIsOpen(false);
      setRowIndex(null);
    } else {
      const newItem = {
        ...formState,
        id: Date.now()
      };
      let tempTableData = [...tableData];
      tempTableData.push(newItem);
      setTableData(tempTableData);
      // const updatedTempData = tempTableData?.map(({ id, ...rest }) => rest);
      handleUpdateData('AssociationDetails', tempTableData);
      setFormState(initialState);
      setIsOpen(false);
      setRowIndex(null);
    }
  };

  const handleEdit = rowData => {
    setIsOpen(true);
    setFormState(rowData);
    setRowIndex(rowData.id);
  };
  const handleDelete = id => {
    let tempData = [...tableData];
    let filterData = tempData.filter(item => item.id != id);
    setTableData(filterData);
    // const updatedTempData = filterData?.map(({ id, ...rest }) => rest);
    handleUpdateData('AssociationDetails', filterData);
  };
  const columns = [
    {
      id: 'nameOfInstitution',
      label: 'Name of Institution'
    },

    {
      id: 'associatedSince',
      label: 'Associate since (Years)'
    },
    {
      id: 'businessInLastFinancialYear',
      label: 'Business done during last financial year (₹)',
      render: rowData => {
        const formattedNumber = parseInt(
          rowData?.businessInLastFinancialYear
        )?.toLocaleString('en-IN');

        return <p>{formattedNumber}</p>;
      }
    },
    {
      label: 'Actions',
      render: rowData => {
        return (
          <div>
            <IconButton onClick={() => handleEdit(rowData)}>
              <img
                src="/images/edit_icon.svg"
                alt="editIcon"
                style={{ height: '1.3rem' }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <img
                src="/images/delete_icon.svg"
                alt="deleteIcon"
                style={{ height: '1.3rem' }}
                onClick={() => handleDelete(rowData.id)}
              />
            </IconButton>
          </div>
        );
      }
    }
  ];

  const handleOnClick = () => {
    if (tableData?.length < 3) {
      setIsOpen(true);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setFormState(initialState);
    setRowIndex(null);
    setValidation(initialValidation);
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <Typography
        color="#335DAD"
        fontSize={'18px'}
        fontWeight={600}
        marginBottom={'16px'}
      >
        Association Details
      </Typography>

      <div style={{ border: '1px solid #999999', borderRadius: '6px' }}>
        <div
          style={{ display: 'flex', padding: '1rem', justifyContent: 'end' }}
        >
          <img
            src="/images/add_details.svg"
            alt="addSign"
            style={{
              height: '2rem',
              cursor: tableData?.length > 2 ? 'no-drop' : 'pointer'
            }}
            onClick={handleOnClick}
          />
        </div>

        <CustomDataTable
          columns={columns}
          data={tableData?.length > 0 ? tableData : []}
          //   onPageChange={val => {
          //     setInitialValues({ ...initialValues, page: val });
          //   }}
          //   onRowsPerPageChange={val => {
          //     setInitialValues({ ...initialValues, limit: val, page: 0 });
          //   }}
          //   defaultPage={initialValues.page}
          defaultRowsPerPage={4}
          totalCount={0}
          isShowPagination={false}
        />

        {isOpen && (
          <>
            <React.Suspense>
              <ModalDialog
                isShowTitle="true"
                titleName={
                  rowIndex
                    ? 'Edit Association Details'
                    : 'Add Association Details'
                }
                fullwidth
                handleOnClose={handleCancel}
                isShowCloseIcon={true}
              >
                <div>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '12px'
                    }}
                    container
                    spacing={3}
                  >
                    <Grid item lg={12}>
                      <TextField
                        label="Name of the Institution*"
                        name="nameOfInstitution"
                        placeholder=""
                        size="small"
                        fullWidth
                        onChange={e => handleOnChange(e, 'nameOfInstitution')}
                        value={formState?.nameOfInstitution || ''}
                        error={
                          validation?.nameOfInstitutionVal &&
                          Boolean(validation?.nameOfInstitutionVal)
                        }
                        helperText={
                          validation?.nameOfInstitutionVal &&
                          validation?.nameOfInstitutionVal
                        }
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        label="Associated since (Years)*"
                        name="associatedSince"
                        placeholder=""
                        onChange={e => handleOnChange(e, 'associatedSince')}
                        size="small"
                        inputProps={{ min: 0 }}
                        fullWidth
                        value={formState?.associatedSince || ''}
                        error={
                          validation?.associatedSinceVal &&
                          Boolean(validation?.associatedSinceVal)
                        }
                        helperText={
                          validation?.associatedSinceVal &&
                          validation?.associatedSinceVal
                        }
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        name="businessInLastFinancialYear"
                        label="Business done during last financial year (₹)*"
                        placeholder=""
                        value={formState?.businessInLastFinancialYear || ''}
                        onChange={e =>
                          handleOnChange(e, 'businessInLastFinancialYear')
                        }
                        error={
                          validation?.businessInLastFinancialYearVal &&
                          Boolean(validation?.businessInLastFinancialYearVal)
                        }
                        helperText={
                          validation?.businessInLastFinancialYearVal &&
                          validation?.businessInLastFinancialYearVal
                        }
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '2rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      style={{
                        paddingRight: '2rem',
                        paddingLeft: '2rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem'
                      }}
                    >
                      CANCEL
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      style={{
                        paddingRight: '2rem',
                        paddingLeft: '2rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem'
                      }}
                      onClick={() => handleSubmit()}
                    >
                      {'Submit'}
                    </LoadingButton>
                  </div>
                </div>
              </ModalDialog>
            </React.Suspense>
          </>
        )}
      </div>
    </div>
  );
};

export default AssociationDetails;
