import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCityStateByPincode } from '../../redux/reducers/masterManagementSlice';
import moment from 'moment';
import { isArrayNotEmpty } from '../../utils/utils';

function EmploymentDetails({ formik }) {
  const dispatch = useDispatch();
  const [cityState, setCityState] = useState('');
  const { cityStateDetails } = useSelector(state => state.masters);

  useEffect(() => {
    if (cityState == 'Salaried') {
      formik.setFieldValue('empCity', cityStateDetails?.city);
      setTimeout(() => {
        formik.setFieldValue('empState', cityStateDetails?.state);
      }, 300);
    } else if (cityState == 'Business') {
      formik.setFieldValue('empCity', cityStateDetails?.city);
      setTimeout(() => {
        formik.setFieldValue('empState', cityStateDetails?.state);
      }, 300);
    }
    setCityState('');
  }, [cityStateDetails]);

  const handleSalariedPincodeChange = (event, name) => {
    setCityState(name);
    const { value } = event.target;
    formik.setFieldValue('empPincode', value);
    if (value.length === 6) {
      dispatch(getCityStateByPincode(value));
    }
  };

  const [employmentType, setEmploymentType] = useState(
    formik.values.employmentType
  );

  // const {} = useSelector(state=> state.lead);

  useEffect(() => {
    setEmploymentType(formik.values.employmentType);
  }, [formik.values.employmentType]);

  const handleEmploymentTypeChange = event => {
    const { value } = event.target;
    setEmploymentType(value);
    // formik.setFieldValue('employmentType', value);
    formik.setValues({
      ...formik.values,
      employmentType: value,
      constitutionType: '',
      employerName: '',
      employerAddress: '',
      empPincode: '',
      empCity: '',
      empState: '',
      sinceYear: null,
      sinceMonth: ''
      // empVoterIdNumber: ''
    });
  };
  const [yearList, setYearList] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1955;

    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    ).map(year => ({ id: year, value: year }));

    setYearList(years);
  }, []);

  const MonthList = [
    {
      id: '1',
      value: '1'
    },
    {
      id: '2',
      value: '2'
    },
    {
      id: '3',
      value: '3'
    },
    {
      id: '4',
      value: '4'
    },
    {
      id: '5',
      value: '5'
    },
    {
      id: '6',
      value: '6'
    },
    {
      id: '7',
      value: '7'
    },
    {
      id: '8',
      value: '8'
    },
    {
      id: '9',
      value: '9'
    },
    {
      id: '10',
      value: '10'
    },
    {
      id: '11',
      value: '11'
    },
    {
      id: '12',
      value: '12'
    }
  ];

  return (
    <>
      <FormControl component="fieldset">
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Employment Details
        </Typography>
        {/* <FormLabel component="legend">Employment Type</FormLabel> */}
        <RadioGroup
          row
          value={employmentType === 'Salaried' ? 'Salaried' : 'selfEmployed'}
          onChange={handleEmploymentTypeChange}
        >
          <FormControlLabel
            value="Salaried"
            control={<Radio />}
            label="Salaried"
          />
          <FormControlLabel
            value="selfEmployed"
            control={<Radio />}
            label="Self Employed"
          />
        </RadioGroup>
      </FormControl>

      <Grid container spacing={3} marginBottom={'16px'}>
        <Grid item lg={4}>
          <TextField
            select
            label="Constitutuion Type *"
            name="constitutionType"
            size="small"
            fullWidth
            value={formik.values.constitutionType}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: formik.values.constitutionType !== ''
            }}
            error={
              formik.touched.constitutionType &&
              Boolean(formik.errors.constitutionType)
            }
            helperText={
              formik.touched.constitutionType && formik.errors.constitutionType
            }
          >
            <MenuItem value="Private">Private</MenuItem>
            <MenuItem value="Government">Government</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4}>
          <TextField
            label={
              formik.values.employmentType === 'Salaried'
                ? 'Employer Name *'
                : 'Business Name *'
            }
            name="employerName"
            size="small"
            fullWidth
            value={formik.values.employerName}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: formik.values.employerName !== ''
            }}
            error={
              formik.touched.employerName && Boolean(formik.errors.employerName)
            }
            helperText={
              formik.touched.employerName && formik.errors.employerName
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label={
              formik.values.employmentType === 'Salaried'
                ? 'Employer Address *'
                : 'Business Address  *'
            }
            name="employerAddress"
            size="small"
            fullWidth
            value={formik.values.employerAddress}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: formik.values.employerAddress !== ''
            }}
            error={
              formik.touched.employerAddress &&
              Boolean(formik.errors.employerAddress)
            }
            helperText={
              formik.touched.employerAddress && formik.errors.employerAddress
            }
          />
        </Grid>
        {/* <Grid item lg={4}>
          <TextField
            label="Voter ID Number"
            name="empVoterIdNumber"
            size="small"
            fullWidth
            value={formik.values.empVoterIdNumber}
            onChange={formik.handleChange}
            InputLabelProps={{
              shrink: formik.values.empVoterIdNumber !== ''
            }}
            //   error={
            //     formik.touched.voterIdNumber &&
            //     Boolean(formik.errors.voterIdNumber)
            //   }
            //   helperText={
            //     formik.touched.voterIdNumber && formik.errors.voterIdNumber
            //   }
          />
        </Grid> */}
        <Grid item lg={4}>
          <TextField
            label="Pincode *"
            name="empPincode"
            size="small"
            fullWidth
            value={formik.values.empPincode}
            onChange={e =>
              handleSalariedPincodeChange(
                e,
                formik.values.employmentType === 'Salaried'
                  ? 'Salaried'
                  : 'Business'
              )
            }
            InputLabelProps={{ shrink: formik.values.empPincode !== '' }}
            error={
              formik.touched.empPincode && Boolean(formik.errors.empPincode)
            }
            helperText={formik.touched.empPincode && formik.errors.empPincode}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled
            label="City *"
            name="empCity"
            size="small"
            fullWidth
            value={formik.values.empCity}
            // onChange={formik.handleChange}
            InputLabelProps={{ shrink: formik.values.empCity !== '' }}
            error={formik.touched.empCity && Boolean(formik.errors.empCity)}
            helperText={formik.touched.empCity && formik.errors.empCity}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled
            label="State *"
            name="empState"
            size="small"
            fullWidth
            value={formik.values.empState}
            // onChange={formik.handleChange}
            InputLabelProps={{ shrink: formik.values.empState !== '' }}
            error={formik.touched.empState && Boolean(formik.errors.empState)}
            helperText={formik.touched.empState && formik.errors.empState}
          />
        </Grid>
      </Grid>
      <Typography
        style={{
          marginBottom: '1rem',
          marginTop: '1rem',
          color: '#000000',
          fontSize: '14px',
          fontWeight: 'bold'
        }}
      >
        Employer Since
      </Typography>
      <Grid container spacing={3} marginBottom={'16px'}>
        <Grid item lg={2}>
          {/* <TextField
            select
            label="Year *"
            name="sinceYear"
            size="small"
            fullWidth
            value={formik.values.sinceYear}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: formik.values.sinceYear !== '' }}
            error={formik.touched.sinceYear && Boolean(formik.errors.sinceYear)}
            helperText={formik.touched.sinceYear && formik.errors.sinceYear}
          >
            {yearList.map(item => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </TextField> */}

          <Autocomplete
            options={
              isArrayNotEmpty(yearList)
                ? yearList?.map(c => {
                    return { value: c.value, id: c.id };
                  })
                : []
            }
            disableClearable
            getOptionLabel={option => option.value || ''}
            value={formik.values?.sinceYear}
            isOptionEqualToValue={(option, value) => {
              return option.id === (value ? value.id : null);
            }}
            size="small"
            fullWidth
            onChange={(event, newValue) => {
              formik.setFieldValue('sinceYear', newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Year *"
                placeholder="Select Year"
                error={
                  formik.touched.sinceYear && Boolean(formik.errors.sinceYear)
                }
                helperText={formik.touched.sinceYear && formik.errors.sinceYear}
              />
            )}
          />
        </Grid>

        <Grid item lg={2}>
          <TextField
            select
            label="Month *"
            name="sinceMonth"
            size="small"
            fullWidth
            value={formik.values.sinceMonth}
            onChange={formik.handleChange}
            InputLabelProps={{ shrink: formik.values.sinceMonth !== '' }}
            error={
              formik.touched.sinceMonth && Boolean(formik.errors.sinceMonth)
            }
            helperText={formik.touched.sinceMonth && formik.errors.sinceMonth}
          >
            {MonthList.map(item => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.value}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
}

export default EmploymentDetails;
