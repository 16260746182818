import { useEffect, useMemo } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getPurposeAndSchemeFromProduct } from '../../redux/reducers/masterManagementSlice';
import { isArrayNotEmpty } from '../../utils/utils';

function LoanDetails({ formik }) {
  const dispatch = useDispatch();
  const { purposeAndSchemeDataFromProduct } = useSelector(
    state => state.masters
  );

  const prodId = formik.values.productId;

  useEffect(() => {
    if (prodId) {
      dispatch(getPurposeAndSchemeFromProduct(prodId));
    }
  }, [prodId]);

  // Filter schemes based on selected purpose
  const filteredSchemes = useMemo(() => {
    const selectedPurpose = formik.values.purpose;
    if (selectedPurpose && isArrayNotEmpty(purposeAndSchemeDataFromProduct)) {
      const purposeData = purposeAndSchemeDataFromProduct.find(
        item => item.id === selectedPurpose.id
      );
      formik.setFieldValue('scheme', purposeData?.schemeMaster[0] || null);
      return purposeData ? purposeData.schemeMaster : [];
    }
    return [];
  }, [formik.values.purpose, purposeAndSchemeDataFromProduct]);

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Loan Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <Autocomplete
              options={
                isArrayNotEmpty(purposeAndSchemeDataFromProduct)
                  ? purposeAndSchemeDataFromProduct.map(c => {
                      return { label: c.purpose, id: c.id };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.label || ''}
              value={formik.values.purpose}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue('purpose', newValue ? newValue : null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Purpose *"
                  placeholder="Select Purpose"
                  // style={{ marginRight: '24px' }}
                  error={
                    formik.touched.purpose && Boolean(formik.errors.purpose)
                  }
                  helperText={formik.touched.purpose && formik.errors.purpose}
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              options={filteredSchemes}
              getOptionLabel={option => option.name || ''}
              value={formik.values.scheme}
              isOptionEqualToValue={(option, value) =>
                option.id === (value ? value.id : null)
              }
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue('scheme', newValue ? newValue : null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Scheme *"
                  placeholder="Select Scheme"
                  // style={{ marginRight: '24px' }}
                  error={formik.touched.scheme && Boolean(formik.errors.scheme)}
                  helperText={formik.touched.scheme && formik.errors.scheme}
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Amount Requested *"
              name="amountRequested"
              size="small"
              fullWidth
              value={formik.values.amountRequested}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.amountRequested !== ''
              }}
              error={
                formik.touched.amountRequested &&
                Boolean(formik.errors.amountRequested)
              }
              helperText={
                formik.touched.amountRequested && formik.errors.amountRequested
              }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default LoanDetails;
