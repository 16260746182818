import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createTitle } from '../utils/utils';
import { setLayoutTitle } from '../redux/reducers/layoutSlice';
import { Box, Container, CssBaseline, styled } from '@mui/material';

const YellowBackground = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '95vh',
  backgroundColor: '#FEC310',
  overflow: 'hidden'
};

const ImageContainer = styled('div')({
  textAlign: 'center',
  position: 'absolute',
  left: '55%',
  top: '55%',
  transform: 'translate(-50%, -50%)',
  display: 'block',
  '@media (min-width: 768px)': {
    top: '55%'
  }
});

const Image = styled('img')({
  height: 'auto',
  display: 'block'
});

function ComingSoon({ title = '' }) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle(title);
    dispatch(
      setLayoutTitle({
        title: title,
        backwardLinkText: 'Go to Homepage',
        backwardLink: '/homepage'
      })
    );
  }, []);
  return (
    <Container disableGutters component="main" maxWidth="lg">
      <CssBaseline />
      <Box sx={YellowBackground}>
        <ImageContainer>
          <Image
            src="/images/feature_will_available.svg"
            alt="Feature_will_available"
          />
        </ImageContainer>
      </Box>
    </Container>
  );
}

export default ComingSoon;
