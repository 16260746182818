import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Typography,
  styled
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  createTitle,
  enterWithNoSpaces,
  handleError
} from '../../../utils/utils';
import CenteredLogo from '../../../components/CenteredLogo';
import { IMG_LOGIN_BG } from '../../../utils/imageUrls';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  clearAllNotification,
  clearOtpNotification,
  logoutAndClearToken
} from '../../../redux/reducers/authSlice';
import SnackBarBox from '../../../components/SnackBarBox';
import LoginIcon from '@mui/icons-material/Login';
import GenericInput from '../../../components/GenericInput';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowBackToLoginButton from './ShowBackToLoginButton';
import { apiNoAuth } from '../../../config/api';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

const formControl = {
  margin: '0.5rem auto'
};

function ResetNewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectCount, setRedirectCount] = useState(10);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [responseData, setResponseData] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const empId = location.state?.empId;

  useEffect(() => {
    document.title = createTitle('Reset Password');
    return () => dispatch(clearOtpNotification());
  }, []);

  useEffect(() => {
    if (
      passwordFormik.values.password &&
      passwordFormik.values.confirmPassword
    ) {
      setDisabled(false);
    }
  });

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (redirectCount > 0) {
        setRedirectCount(redirectCount - 1);
      }
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [redirectCount]);

  const redirectToLogin = useCallback(() => {
    dispatch(clearAllNotification());
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  }, [navigate]);

  useEffect(() => {
    if (responseData) {
      setRedirectCount(10);
      const timeoutId = setTimeout(redirectToLogin, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [responseData, redirectToLogin]);

  const handleManuallyRedirectToLogin = () => {
    dispatch(clearAllNotification());
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  };

  const passwordFormik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          `Password must meet the following criteria:
           At least 8 characters long
           One Uppercase letter
           One Lowercase letter
           One special character`
        )
        .min(8, 'New Password should be of minimum 8 digit')
        .max(16, 'New Password should be of maximum 16 digit')
        .required('New Password is required'),
      confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf(
          [yup.ref('password'), null],
          'Password and Confirm Password must match.'
        )
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      setLoading(true);
      const data = {
        empId: empId,
        password: values.password
      };

      try {
        const response = await apiNoAuth.post('/forgot-password', data);
        setResponseData(response.data);
        setLoading(false);
        dispatch(logoutAndClearToken());
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    }
  });

  return (
    <>
      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${IMG_LOGIN_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        />
        <CustomGrid item xs={12} sm={5} md={5} lg={5} elevation={6}>
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CenteredLogo />

            <Box>
              <>
                <Typography
                  align="center"
                  component="h1"
                  variant="h6"
                  fontWeight={'700'}
                  color={'#000'}
                >
                  Reset Password
                </Typography>
                <Container>
                  <Box pt={2}>
                    <form
                      autoComplete="off"
                      onSubmit={passwordFormik.handleSubmit}
                    >
                      <FormControl
                        sx={formControl}
                        variant="outlined"
                        fullWidth
                      >
                        <GenericInput
                          label="Password"
                          name="password"
                          size="small"
                          required
                          type={!showPassword ? 'text' : 'password'}
                          inputProps={{ maxLength: 16 }}
                          value={passwordFormik.values.password}
                          onChange={e =>
                            passwordFormik.setFieldValue(
                              'password',
                              enterWithNoSpaces(e.target.value)
                            )
                          }
                          onBlur={passwordFormik.handleBlur}
                          error={
                            passwordFormik.touched.password &&
                            passwordFormik.errors.password
                          }
                          startIcon={
                            <LockOutlinedIcon className="icon_color" />
                          }
                          endIcon={
                            showPassword ? (
                              <VisibilityOffOutlinedIcon className="icon_color" />
                            ) : (
                              <VisibilityOutlinedIcon className="icon_color" />
                            )
                          }
                          onEndIconClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      </FormControl>
                      <FormControl
                        sx={formControl}
                        variant="outlined"
                        fullWidth
                      >
                        <GenericInput
                          required
                          label="Confirm Password"
                          size="small"
                          name="confirmPassword"
                          type={!showConfirmPassword ? 'text' : 'password'}
                          inputProps={{ maxLength: 16 }}
                          value={passwordFormik.values.confirmPassword}
                          onChange={e =>
                            passwordFormik.setFieldValue(
                              'confirmPassword',
                              enterWithNoSpaces(e.target.value)
                            )
                          }
                          onBlur={passwordFormik.handleBlur}
                          error={
                            passwordFormik.touched.confirmPassword &&
                            passwordFormik.errors.confirmPassword
                          }
                          startIcon={
                            <LockOutlinedIcon className="icon_color" />
                          }
                          endIcon={
                            showConfirmPassword ? (
                              <VisibilityOffOutlinedIcon className="icon_color" />
                            ) : (
                              <VisibilityOutlinedIcon className="icon_color" />
                            )
                          }
                          onEndIconClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                        />
                      </FormControl>
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={disabled}
                        sx={{
                          mt: 2,
                          fontSize: 15,
                          fontWeight: '600',
                          textTransform: 'uppercase',
                          bgcolor: '#335DAD'
                        }}
                      >
                        Submit
                      </LoadingButton>
                      <ShowBackToLoginButton />
                    </form>
                  </Box>
                </Container>
              </>
            </Box>

            {!loading && responseData && (
              <Box style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Your password has been reset successfully!
                </Typography>
                <Typography variant="body2" gutterBottom>
                  You will be redirected to the login page in {redirectCount}{' '}
                  seconds.
                </Typography>
                <Typography variant="body2" marginTop={2}>
                  If not auto-redirected, click below button to login page
                  manually.
                </Typography>
                <Button
                  sx={{ mt: '1rem' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleManuallyRedirectToLogin}
                >
                  <LoginIcon sx={{ mr: '10px' }} /> Go to Login
                </Button>
              </Box>
            )}
          </Box>
        </CustomGrid>

        {!loading && error && (
          <SnackBarBox
            type="error"
            message={handleError(error?.message || '')}
          />
        )}
      </Grid>
    </>
  );
}

export default ResetNewPassword;
