import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import {
  downloadFileCSVPostApi,
  downloadFilePostApiForReports
} from '../../utils/utils';

export const revertAllUsersData = createAction('REVERT_USERS_DATA');

const intial = {
  isGetUserLoading: false,
  isGetUserSuccess: false,
  isGetUserFailed: false,
  userDetails: null,
  getUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetHierarchyLoading: false,
  isGetHierarchySuccess: false,
  isGetHierarchyFailed: false,
  employeeHierarchy: null,
  getHierarchyErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAuditLogLoading: false,
  isAuditLogSuccess: false,
  isAuditLogFailed: false,
  auditLogData: {
    logList: [],
    totalCount: 0
  },
  auditLogErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isGetUserListLoading: false,
  isGetUserListSuccess: false,
  isGetUserListFailed: false,
  usersData: {
    userList: [],
    totalCount: 0
  },
  userListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUpdateUserDataLoading: false,
  isUpdateUserDataSuccess: false,
  isUpdateUserDataFailed: false,
  UpdateUserDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDashboardCountsLoading: false,
  isDashboardCountsSuccess: false,
  isDashboardCountsFailed: false,
  dashboardCountsList: {
    userCount: 0,
    productCount: 0,
    connectorsCount: 0
  },
  dastboardCountsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isDownloadUserLoading: false,
  isDownloadUserSuccess: false,
  isDownloadUserFailed: false,
  downloadUserErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getDashboardCounts = createAsyncThunk(
  'users/getDashboardCounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`getProductConnectorsUserCount`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  'users/getUserDetails',
  async (empId, { rejectWithValue }) => {
    try {
      const response = await api.get(`user/${empId}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getEmployeeHierarchy = createAsyncThunk(
  'users/getEmployeeHierarchy',
  async (empId, { rejectWithValue }) => {
    try {
      const response = await api.get(`getHierarchy?employeeId=${empId}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAuditLogs = createAsyncThunk(
  'users/getAuditLogs',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        response = await api.get(
          `/user/auditlog?employeeId=${payload.employeeId}&page=${payload.page}&limit=${payload.limit}`
        );
      } else {
        response = await api.get(
          `/user/auditlog?employeeId=${payload.employeeId}page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
        );
      }

      return response.data || intial.auditLogData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUserList = createAsyncThunk(
  'users/getUserList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      let extraString = `${
        payload.searchFilter ? `&searchData=${payload.searchFilter}` : ''
      }`;

      if (payload?.searchFilter === '' || payload?.searchFilter === null) {
        response = await api.get(
          `/user/getUserList?page=${payload.page}&limit=${payload.limit}`
        );
      } else {
        response = await api.get(
          `/user/getUserList?page=${payload.page}&limit=${payload.limit}${extraString ? extraString : ''}`
        );
      }

      return response.data || intial.usersData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'users/updateUserDetails',
  async (updatePayload, { rejectWithValue }) => {
    try {
      const response = await api.put(`user/editUser`, updatePayload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(`users`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadReport = createAsyncThunk(
  'users/downloadReport',
  async (
    {
      fileType,
      fileName,
      searchData,
      empId,
      product,
      payloadContent,
      businessType,
      statusIds
    },
    { rejectWithValue }
  ) => {
    try {
      if (fileName === 'User') {
        return await downloadFileCSVPostApi({
          apiPath: `user/report?format=${fileType}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'Connector') {
        return await downloadFileCSVPostApi({
          apiPath: `connector/report?format=${fileType}&searchData=${searchData}&statusIds=${statusIds}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'GI_PLAN_MASTER') {
        return await downloadFileCSVPostApi({
          apiPath: `GI/export?format=${fileType}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'MF_PLAN_MASTER') {
        return await downloadFileCSVPostApi({
          apiPath: `MF/export?format=${fileType}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'FD_COMMISSION') {
        return await downloadFileCSVPostApi({
          apiPath: `FD/export?format=${fileType}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'HL_PLAN_MASTER') {
        return await downloadFileCSVPostApi({
          apiPath: `purposeScheme/report?format=${fileType}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'HL_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `lead/report?format=${fileType}&product=${product}&empId=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'LI_PLAN_MASTER') {
        return await downloadFileCSVPostApi({
          apiPath: `LI/export?format=${fileType}&product=${product}&empId=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'IDBI_PLAN_MASTER') {
        return await downloadFileCSVPostApi({
          apiPath: `IDBI/export?format=${fileType}&product=${product}&empId=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'FD_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `FD/export?format=${fileType}&empIds=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'NPS_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `NP/export?format=${fileType}&empIds=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'MF_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `MF/export?format=${fileType}&empIds=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'IDBI_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `IDBI/export?format=${fileType}&empIds=${empId}&searchData=${searchData}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'LI_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `LI/export?format=${fileType}&product=${product}&empIds=${empId}&searchData=${searchData}&businessType=${businessType}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'GI_Lead') {
        return await downloadFileCSVPostApi({
          apiPath: `GI/export?format=${fileType}&product=${product}&empIds=${empId}&searchData=${searchData}&businessType=${businessType}`,
          fileType: fileType,
          fileName: fileName
        });
      } else if (fileName === 'Reports') {
        return await downloadFilePostApiForReports({
          apiPath: `${product}/generate-file`,
          productName: product,
          fileType: fileType,
          fileName: fileName,
          payload: { ...payloadContent, fileType: fileType }
        });
      }
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const revertUserPageData = createAction('REVERT_USER_PAGE_DATA');

export const userManagementSlice = createSlice({
  name: 'users',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(revertUserPageData, state => {
        state.isDownloadUserSuccess = false;
        state.isDownloadUserFailed = false;
        state.isDownloadUserLoading = false;
        state.isDownloadUserSuccess = false;
        state.isUpdateUserDataSuccess = false;
        state.isUpdateUserDataFailed = false;
        state.isGetHierarchySuccess = false;
        state.isGetHierarchyFailed = false;
        state.employeeHierarchy = null;
        state.getHierarchyErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.downloadUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isUploadUsersLoading = false;
        state.isUploadUsersSuccess = false;
        state.isUploadUsersFailed = false;
        state.uploadUsersMessage = '';
        state.uploadUsersErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertAllUsersData, state => {
        state.isGetUserLoading = false;
        state.isGetUserListSuccess = false;
        state.isGetUserFailed = false;
        state.getUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.userDetails = null;
        state.isUpdateUserDataLoading = false;
        state.isUpdateUserDataSuccess = false;
        state.isUpdateUserDataFailed = false;
        state.UpdateUserDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.resetPasswordErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getDashboardCounts.pending, state => {
        state.isDashboardCountsLoading = true;
        state.isDashboardCountsSuccess = false;
        state.isDashboardCountsFailed = false;
        state.dastboardCountsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.isDashboardCountsLoading = false;
        state.isDashboardCountsSuccess = true;
        state.isDashboardCountsFailed = false;
        state.dashboardCountsList = {
          userCount: action.payload?.userCount,
          productCount: action.payload?.productCount,
          connectorsCount: action.payload?.connectorsCount
        };
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        state.isDashboardCountsLoading = false;
        state.isDashboardCountsSuccess = false;
        state.isDashboardCountsFailed = true;
        state.dastboardCountsErrorContainer = {
          ...state.dastboardCountsErrorContainer,
          ...action.payload
        };
      })
      .addCase(getUserDetails.pending, state => {
        state.isGetUserLoading = true;
        state.isGetUserSuccess = false;
        state.isGetUserFailed = false;
        state.getUserErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserSuccess = true;
        state.userDetails = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserFailed = true;
        state.getUserErrorContainer = {
          ...state.errorContainer,
          ...action.payload
        };
      })
      .addCase(getEmployeeHierarchy.pending, state => {
        state.isGetHierarchyLoading = true;
        state.isGetHierarchySuccess = false;
        state.isGetHierarchyFailed = false;
        state.getHierarchyErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getEmployeeHierarchy.fulfilled, (state, action) => {
        state.isGetHierarchyLoading = false;
        state.isGetHierarchySuccess = true;
        state.employeeHierarchy = action.payload;
      })
      .addCase(getEmployeeHierarchy.rejected, (state, action) => {
        state.isGetHierarchyLoading = false;
        state.isGetHierarchyFailed = true;
        state.getHierarchyErrorContainer = {
          ...state.getHierarchyErrorContainer,
          ...action.payload
        };
      })

      .addCase(getAuditLogs.pending, state => {
        state.isAuditLogLoading = true;
        state.isAuditLogSuccess = false;
        state.isAuditLogFailed = false;
        state.auditLogErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getAuditLogs.fulfilled, (state, action) => {
        state.isAuditLogLoading = false;
        state.isAuditLogSuccess = true;
        state.auditLogData = {
          logList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getAuditLogs.rejected, (state, action) => {
        state.isAuditLogLoading = false;
        state.isAuditLogFailed = true;
        state.auditLogData = intial.auditLogData;
        state.auditLogErrorContainer = {
          ...state.auditLogErrorContainer,
          ...action.payload
        };
      })

      .addCase(getUserList.pending, state => {
        state.isGetUserListLoading = true;
        state.isGetUserListSuccess = false;
        state.isGetUserListFailed = false;
        state.userListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.isGetUserListLoading = false;
        state.isGetUserListSuccess = true;
        state.usersData = {
          userList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.isGetUserListLoading = false;
        state.isGetUserListFailed = true;
        state.usersData = intial.usersData;
        state.userListErrorContainer = {
          ...state.userListErrorContainer,
          ...action.payload
        };
      })

      .addCase(updateUserDetails.pending, state => {
        state.isUpdateUserDataLoading = true;
        state.isUpdateUserDataSuccess = false;
        state.isUpdateUserDataFailed = false;
        state.UpdateUserDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(updateUserDetails.fulfilled, state => {
        state.isUpdateUserDataLoading = false;
        state.isUpdateUserDataFailed = false;
        state.isUpdateUserDataSuccess = true;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.isUpdateUserDataLoading = false;
        state.isUpdateUserDataSuccess = false;
        state.isUpdateUserDataFailed = true;
        state.UpdateUserDataErrorContainer = {
          ...state.UpdateUserDataErrorContainer,
          ...action.payload
        };
      })
      .addCase(downloadReport.pending, state => {
        state.isDownloadUserLoading = true;
        state.isDownloadUserSuccess = false;
        state.isDownloadUserFailed = false;
        state.downloadUserErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(downloadReport.fulfilled, state => {
        state.isDownloadUserLoading = false;
        state.isDownloadUserFailed = false;
        state.isDownloadUserSuccess = true;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.isDownloadUserLoading = false;
        state.isDownloadUserSuccess = false;
        state.isDownloadUserFailed = true;
        state.downloadUserErrorContainer = {
          ...state.downloadUserErrorContainer,
          ...action.payload
        };
      });
  }
});

export default userManagementSlice.reducer;
