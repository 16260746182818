import { Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../components/CustomDataTable';
import { LoadingButton } from '@mui/lab';
const ModalDialog = React.lazy(() => import('../../components/ModalDialog'));

const References = ({ formik, handleUpdateData }) => {
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const initialState = {
    name: '',
    mobileNumber: '',
    address: ''
  };
  const initialValidation = {
    nameVal: '',
    mobileNumberVal: '',
    addressVal: ''
  };
  const [validation, setValidation] = useState(initialValidation);
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setTableData(formik?.values?.connectorReferences);
  }, [formik]);

  const isValidPhoneNumber = phoneNumber => {
    return /^[6789]\d{9}$/.test(phoneNumber);
  };
  const handleOnChange = (e, name) => {
    let validationName = name + 'Val';
    setFormState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));
    setValidation(prevState => ({
      ...prevState,
      [validationName]: ''
    }));
  };
  const handleValidation = () => {
    let isValid = true;

    if (!formState.name) {
      setValidation(prevState => ({
        ...prevState,
        nameVal: 'Please enter Name'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        nameVal: ''
      }));
    }
    if (!formState.mobileNumber) {
      setValidation(prevState => ({
        ...prevState,
        mobileNumberVal: 'Please enter mobile number'
      }));
      isValid = false;
    } else if (!isValidPhoneNumber(formState.mobileNumber)) {
      setValidation(prevState => ({
        ...prevState,
        mobileNumberVal: 'Invalid mobile number format'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        contactNumberVal: ''
      }));
    }
    if (!formState.address) {
      setValidation(prevState => ({
        ...prevState,
        addressVal: 'Please enter Address'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        addressVal: ''
      }));
    }
    return isValid;
  };
  const handleSubmit = () => {
    let isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    if (rowIndex) {
      const updatedItem = {
        ...formState,
        id: rowIndex
      };
      let tempTableData = tableData.map(item =>
        item.id === updatedItem.id ? updatedItem : item
      );
      setTableData(tempTableData);
      // const updatedTempData = tempTableData?.map(({ id, ...rest }) => rest);
      handleUpdateData('Reference', tempTableData);
      setFormState(initialState);
      setIsOpen(false);
      setRowIndex(null);
    } else {
      const newItem = {
        ...formState,
        id: Date.now()
      };
      let tempTableData = [...tableData];
      tempTableData.push(newItem);
      setTableData(tempTableData);
      // const updatedTempData = tempTableData?.map(({ id, ...rest }) => rest);
      handleUpdateData('Reference', tempTableData);
      setFormState(initialState);
      setIsOpen(false);
      setRowIndex(null);
    }
  };

  const handleEdit = rowData => {
    setIsOpen(true);
    setFormState(rowData);
    setRowIndex(rowData.id);
  };
  const handleDelete = id => {
    let tempData = [...tableData];
    let filterData = tempData.filter(item => item.id != id);
    setTableData(filterData);
    // const updatedTempData = filterData?.map(({ id, ...rest }) => rest);
    handleUpdateData('Reference', filterData);
  };
  const columns = [
    {
      id: 'name',
      label: 'Name'
    },

    {
      id: 'mobileNumber',
      label: 'Contact Number'
    },
    {
      id: 'address',
      label: 'Address'
    },
    {
      label: 'Actions',
      render: rowData => {
        return (
          <div>
            <IconButton onClick={() => handleEdit(rowData)}>
              <img
                src="/images/edit_icon.svg"
                alt="editIcon"
                style={{ height: '1.3rem' }}
              />
            </IconButton>
            <IconButton aria-label="delete">
              <img
                src="/images/delete_icon.svg"
                alt="deleteIcon"
                style={{ height: '1.3rem' }}
                onClick={() => handleDelete(rowData.id)}
              />
            </IconButton>
          </div>
        );
      }
    }
  ];

  const handleOnClick = () => {
    if (tableData?.length < 2) {
      setIsOpen(true);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setFormState(initialState);
    setRowIndex(null);
    setValidation(initialValidation);
  };
  return (
    <div style={{ marginBottom: '16px' }}>
      <Typography
        color="#335DAD"
        fontSize={'18px'}
        fontWeight={600}
        marginBottom={'16px'}
      >
        References
      </Typography>

      <div style={{ border: '1px solid #999999', borderRadius: '6px' }}>
        <div
          style={{ display: 'flex', padding: '1rem', justifyContent: 'end' }}
        >
          <img
            src="/images/add_details.svg"
            alt="addSign"
            style={{
              height: '2rem',
              cursor: tableData?.length > 1 ? 'no-drop' : 'pointer'
            }}
            onClick={handleOnClick}
          />
        </div>

        <CustomDataTable
          columns={columns}
          data={tableData?.length > 0 ? tableData : []}
          //   onPageChange={val => {
          //     setInitialValues({ ...initialValues, page: val });
          //   }}
          //   onRowsPerPageChange={val => {
          //     setInitialValues({ ...initialValues, limit: val, page: 0 });
          //   }}
          //   defaultPage={initialValues.page}
          defaultRowsPerPage={3}
          totalCount={0}
          isShowPagination={false}
        />
        {isOpen && (
          <>
            <React.Suspense>
              <ModalDialog
                isShowTitle="true"
                titleName={rowIndex ? 'Edit Reference' : 'Add Reference'}
                fullwidth
                handleOnClose={handleCancel}
                isShowCloseIcon={true}
              >
                <div>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '12px'
                    }}
                    container
                    spacing={3}
                  >
                    <div style={{ marginLeft: '1.5rem' }}>
                      <p style={{ fontSize: '14px', color: '#666666' }}>
                        <b style={{ color: '#000000' }}>Note:</b> Reference
                        should not be relatives of applicant and should know the
                        applicant in the business field for verification and
                        background check.
                      </p>
                    </div>

                    <Grid item lg={12}>
                      <TextField
                        label="Name *"
                        name="name"
                        placeholder=""
                        size="small"
                        fullWidth
                        onChange={e => handleOnChange(e, 'name')}
                        value={formState?.name || ''}
                        error={
                          validation?.nameVal && Boolean(validation?.nameVal)
                        }
                        helperText={validation?.nameVal && validation?.nameVal}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        label="Contact Number *"
                        name="mobileNumber"
                        placeholder=""
                        onChange={e => handleOnChange(e, 'mobileNumber')}
                        size="small"
                        fullWidth
                        value={formState?.mobileNumber || ''}
                        error={
                          validation?.mobileNumberVal &&
                          Boolean(validation?.mobileNumberVal)
                        }
                        helperText={
                          validation?.mobileNumberVal &&
                          validation?.mobileNumberVal
                        }
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        name="address"
                        label="Address *"
                        placeholder=""
                        value={formState?.address || ''}
                        onChange={e => handleOnChange(e, 'address')}
                        error={
                          validation?.addressVal &&
                          Boolean(validation?.addressVal)
                        }
                        helperText={
                          validation?.addressVal && validation?.addressVal
                        }
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      marginTop: '2rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      style={{
                        paddingRight: '2rem',
                        paddingLeft: '2rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem'
                      }}
                    >
                      CANCEL
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      style={{
                        paddingRight: '2rem',
                        paddingLeft: '2rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem'
                      }}
                      onClick={() => handleSubmit()}
                    >
                      {'Submit'}
                    </LoadingButton>
                  </div>
                </div>
              </ModalDialog>
            </React.Suspense>
          </>
        )}
      </div>
    </div>
  );
};

export default References;
