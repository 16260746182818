import { combineReducers } from 'redux';
import authSlice from './reducers/authSlice';
import layoutSlice from './reducers/layoutSlice';
import sessionSlice from './reducers/sessionSlice';
import menuSlice from './reducers/menuSlice';
import casesummarySlice from './reducers/casesummarySlice';
import adminSlice from './reducers/adminSlice';
import userManagementSlice from './reducers/userManagementSlice';
import roleManagementSlice from './reducers/roleManagementSlice';
import connectorApprovalSlice from './reducers/connectorApprovalSlice';
import masterManagementSlice from './reducers/masterManagementSlice';
import leadSlice from './reducers/leadSlice';
import reportSlice from './reducers/reportSlice';
import planMasterSlice from './reducers/planMasterSlice';

const reducers = {
  auth: authSlice,
  layout: layoutSlice,
  session: sessionSlice,
  menu: menuSlice,
  caseSummary: casesummarySlice,
  admin: adminSlice,
  users: userManagementSlice,
  roles: roleManagementSlice,
  connector: connectorApprovalSlice,
  masters: masterManagementSlice,
  lead: leadSlice,
  report: reportSlice,
  planMaster: planMasterSlice
};

export default combineReducers(reducers);
