import {
  Box,
  Collapse,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setIsMenuOpen } from '../../redux/reducers/menuSlice';
import { IMG_SPLASH_LOGO } from '../../utils/imageUrls';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { colorDarkGray } from '../../config/theme';

const drawerWidth = 250;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

function SideBar({ menulist, heading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeMenu } = useSelector(state => state.menu);

  const isActiveMenu = currentMenu => {
    return activeMenu === currentMenu;
  };

  const toggleDrawer = isOpen => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    dispatch(setIsMenuOpen(isOpen));
  };

  const [open, setOpen] = useState({});

  const handleClick = (item, index, hasChild) => {
    if (hasChild) {
      setOpen(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    } else {
      dispatch(setActiveMenu(item?.name));
      dispatch(setIsMenuOpen(false));
      return navigate(item?.route);
    }
  };

  const renderSubmenus = submenus => {
    return (
      <>
        {submenus?.map((submenu, index) => (
          <div key={`${submenu}_${index}`}>
            <ListItem
              disablePadding
              sx={{
                color: colorDarkGray,
                backgroundColor: isActiveMenu(submenu?.name)
                  ? '#F9F9F9'
                  : 'inherit',
                '&:hover': {
                  backgroundColor: '#f0f0f0'
                },
                fontWeight: isActiveMenu(submenu?.name) ? 'bold' : 'normal',
                borderRadius: '5px',
                marginLeft: '1rem',
                marginTop: '4px',
                marginBottom: '4px'
              }}
              key={index}
              component={Link}
              to={submenu.route}
            >
              <ListItemButton
                sx={{ borderRadius: '5px' }}
                onClick={() => handleClick(submenu, index)}
              >
                <Typography
                  fontWeight={isActiveMenu(submenu?.name) ? 700 : 500}
                  variant="subtitle2"
                  color={isActiveMenu(submenu?.name) ? '#335DAD' : 'inherit'}
                >
                  {submenu.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          </div>
        ))}
      </>
    );
  };

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 220 }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      {menulist.map((item, index) => (
        <List
          key={`${item}_${index}`}
          component="div"
          sx={{ width: '100%', borderRadius: '5px', marginLeft: '5px' }}
        >
          <ListItem
            disablePadding
            sx={{
              backgroundColor: isActiveMenu(item?.name) ? '#F9F9F9' : 'inherit',
              '&:hover': {
                backgroundColor: '#f0f0f0'
              },
              fontWeight: isActiveMenu(item?.name) ? 'bold' : 'normal',
              borderRadius: '5px'
            }}
          >
            <ListItemButton
              sx={{ borderRadius: '5px' }}
              onClick={() => handleClick(item, index, item.hasChild)}
            >
              <Typography
                fontWeight={isActiveMenu(item?.name) ? 700 : 500}
                variant="subtitle2"
                color={isActiveMenu(item?.name) ? '#335DAD' : 'inherit'}
              >
                {item.name}
              </Typography>
              {item.hasChild && (open[index] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          {item.hasChild && (
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              {renderSubmenus(item.submenus)}
            </Collapse>
          )}
        </List>
      ))}
    </Box>
  );

  return (
    <>
      <SwipeableDrawer
        open={true}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
      >
        <DrawerHeader>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box role="presentation">
              <img
                style={{
                  display: 'block',
                  // margin: 'auto',
                  textAlign: 'center',
                  marginTop: '16px',
                  marginBottom: '16px'
                }}
                width={220}
                src={IMG_SPLASH_LOGO}
                alt="IMG_SPLASH_LOGO"
              />
            </Box>
          </Grid>
        </DrawerHeader>
        {list('left')}
      </SwipeableDrawer>
    </>
  );
}

SideBar.propTypes = {
  menulist: PropTypes.array,
  heading: PropTypes.string
};

export default SideBar;
