import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useDispatch } from 'react-redux';
import { authenticateUser } from '../../redux/reducers/authSlice';
import {
  enterOnlyAlphabatesOrNumber,
  enterWithNoSpaces
} from '../../utils/utils';
import GenericInput from '../../components/GenericInput';
import { FormButtonBottom } from '../../utils/styledLayout';
import { SELECT_MODULE_MESSAGE } from '../../utils/messages';
import SnackBarBox from '../../components/SnackBarBox';

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(true);
  const [generatedCode, setGeneratedCode] = useState(generateRandomCode());
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function generateRandomCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let captchaCode = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captchaCode += characters.charAt(randomIndex);
    }
    return captchaCode;
  }

  const formControl = {
    margin: '0.7rem auto'
  };

  const formik = useFormik({
    initialValues: {
      employeeId: '',
      password: '',
      captcha: ''
    },
    validationSchema: yup.object({
      employeeId: yup.string().required('Employee Id is required'),
      password: yup.string().required('Password is required'),
      captcha: yup.string().required('Please enter captcha')
      // .test(
      //   'is-correct',
      //   'Captcha is incorrect',
      //   value => value === generatedCode
      // )
    }),
    onSubmit: value => {
      if (value?.captcha != generatedCode) {
        formik.errors.captcha = 'Please enter valid captcha';
        formik.values.captcha = '';
        setGeneratedCode(generateRandomCode());
      } else {
        const credentials = {
          username: value?.employeeId,
          password: value?.password
        };
        dispatch(authenticateUser(credentials));
      }
    }
  });

  useEffect(() => {}, []);

  const handleFormValue = (e, field) => {
    formik.setFieldValue(
      field,
      enterOnlyAlphabatesOrNumber(e.target.value).toUpperCase()
    );
  };

  const handleResetPassword = () => {
    navigate(`/reset-password`);
  };

  useEffect(() => {
    if (
      formik.values.employeeId &&
      formik.values.password &&
      formik.values.captcha
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik.values.employeeId, formik.values.password, formik.values.captcha]);

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            required
            label="Employee ID"
            name="employeeId"
            size="small"
            inputProps={{ maxLength: 10 }}
            value={formik.values.employeeId}
            onChange={e => handleFormValue(e, 'employeeId')}
            error={formik.touched.employeeId && formik.errors.employeeId}
            startIcon={<PersonOutlineOutlinedIcon className="icon_color" />}
          />
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            required
            label="Password"
            size="small"
            name="password"
            type={!showPassword ? 'text' : 'password'}
            inputProps={{ maxLength: 16, minLength: 8 }}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            startIcon={<LockOutlinedIcon className="icon_color" />}
            endIcon={
              showPassword ? (
                <VisibilityOffOutlinedIcon className="icon_color" />
              ) : (
                <VisibilityOutlinedIcon className="icon_color" />
              )
            }
            onEndIconClick={() => {
              setShowPassword(!showPassword);
            }}
            onChange={e =>
              formik.setFieldValue(
                'password',
                enterWithNoSpaces(e.target.value)
              )
            }
          />
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid #d9d9d9',
              borderRadius: '10px',
              padding: '8px',
              margin: '2px'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px'
              }}
            >
              <div
                style={{
                  flex: '1',
                  textAlign: 'left',
                  letterSpacing: '5px', // Keep reduced spacing
                  fontFamily: 'cursive',
                  color: 'darkgrey',
                  textDecoration: 'line-through',
                  fontSize: '24px', // Increased font size for captcha text
                  paddingLeft: '16px'
                }}
              >
                {generatedCode}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  color: '#1677ff',
                  display: 'flex'
                }}
                onClick={() => {
                  setGeneratedCode(generateRandomCode());
                }}
              >
                <div>
                  <AutorenewIcon
                    className="site-form-item-icon"
                    titleAccess="Reload"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div>Refresh</div>
              </div>
            </div>
            <div>
              <Input
                size="large"
                name="captcha"
                value={formik.values.captcha}
                onChange={formik.handleChange}
                style={{
                  fontSize: '16px',
                  width: 'full',
                  paddingLeft: '16px'
                }}
                type="text"
                placeholder="Enter Captcha*"
                autoComplete="off"
              />
              <FormHelperText
                error={Boolean(formik.touched.captcha && formik.errors.captcha)}
              >
                {formik.touched.captcha && formik.errors.captcha}
              </FormHelperText>
            </div>
          </div>
        </FormControl>
        <FormButtonBottom sx={formControl}>
          <LoadingButton
            type="submit"
            fullWidth
            disabled={disabled}
            variant="contained"
            size="medium"
            color="primary"
            sx={{
              mt: 1,
              fontSize: 15,
              fontWeight: '600',
              textTransform: 'uppercase',
              bgcolor: '#335DAD'
            }}
          >
            Login
          </LoadingButton>
        </FormButtonBottom>

        <Grid container>
          <Grid item textAlign={'center'} xs>
            <Button
              variant="text"
              style={{ fontWeight: '600', color: '#335DAD' }}
              onClick={handleResetPassword}
            >
              FORGOT PASSWORD
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* {captchaError && <SnackBarBox type="error" message={CAPTCHA_ERROR_MSG} />} */}

      {showError && (
        <SnackBarBox type="error" message={SELECT_MODULE_MESSAGE} />
      )}
    </Box>
  );
};
