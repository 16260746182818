import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  deleteLeadDocuments,
  getAssistingPerson,
  getIDBIProductList,
  getIDBISubProductList,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getMFFundHouseList,
  getMFSchemeList,
  getSourcePerson,
  updateLeadDetails
} from '../../redux/reducers/leadSlice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { createTitle, isArrayNotEmpty } from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import UploadDocumentsLead from '../../forms/CWALeadComponents/uploadDocumentsLead';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import LeadSourceDetails from '../../forms/CWALeadComponents/LeadSourceDetails';
import KYCDetails from '../../forms/CWALeadComponents/KYCDetails';
import ConnectorMapping from '../../forms/CWALeadComponents/ConnectorMapping';
import { getCityStateByPincode } from '../../redux/reducers/masterManagementSlice';

function CWAIDBILeadEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    isGetLeadDetailsLoading,
    isGetLeadDocLoading,
    assistantPersonList,
    idbiProductList,
    idbiSubProductList
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;

  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const [docArray3, setDocArray3] = useState([]);
  const [docArray4, setDocArray4] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isConnectorId, setIsConnectorId] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(true);
  const { cityStateDetails } = useSelector(state => state.masters);

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadSource({}));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadDetails({ leadId, customerId, productId }));
    dispatch(getLeadDocuments({ leadId, customerId, productId }));

    dispatch(getIDBIProductList());
  }, [leadId, customerId]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: productId
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      customerId: leadDetails?.customerDetails?.customerId || '',
      applicationId: leadDetails?.leadDetails?.applicationId || '',
      leadId: leadDetails?.leadDetails?.id || 0,
      fullName: leadDetails?.customerDetails?.name || '',
      panNumber: leadDetails?.customerDetails?.panNumber || '',
      dob: leadDetails?.customerDetails?.dob || '',
      mobileNo: leadDetails?.customerDetails?.mobileNumber || '',
      emailId: leadDetails?.customerDetails?.emailId || '',

      addressLine1: leadDetails?.addressDetails?.currentAddressLine1 || '',
      addressLine2: leadDetails?.addressDetails?.currentAddressLine2 || '',
      landmark: leadDetails?.addressDetails?.currentLandmark || '',
      pincode: leadDetails?.addressDetails?.currentPincode || '',
      city: leadDetails?.addressDetails?.currentCity || '',
      state: leadDetails?.addressDetails?.currentState || '',

      aadhaarNumber: leadDetails?.customerDetails?.aadhaarNumber || '',
      passportNumber: leadDetails?.customerDetails?.passportNumber || '',
      voterIdNumber: leadDetails?.customerDetails?.voterId || '',

      // searchConnectorId: '',
      // connectorID: leadDetails?.customerDetails?.connector || null,

      searchConnectorId: leadDetails?.leadDetails?.connector?.connectorId || '',

      connectorID: leadDetails?.leadDetails?.connector?.id
        ? {
            connectorId: leadDetails?.leadDetails?.connector?.connectorId,
            id: leadDetails?.leadDetails?.connector?.id || '',
            name: leadDetails?.leadDetails?.connector?.name || ''
          }
        : null,

      sourcePerson: leadDetails?.leadDetails?.sp || null,
      leadSource: leadDetails?.leadDetails?.leadSource || null,
      assistantPerson: leadDetails?.leadDetails?.ap || null,
      areaOffice: leadDetails?.leadDetails?.areaOffice || null,
      idbiProduct: leadDetails?.leadDetails?.idbiProduct || null,
      idbiSubProduct: leadDetails?.leadDetails?.idbiSubProduct || null,

      branchName: leadDetails?.leadDetails?.branch || '',
      solId: leadDetails?.leadDetails?.solId || '',
      loanNo: leadDetails?.leadDetails?.loanNo || '',
      amountRequested: leadDetails?.leadDetails?.requestedAmount || '',
      amountSanctioned: leadDetails?.leadDetails?.sanctionedAmount || '',
      dateOfSanctioned: leadDetails?.leadDetails?.sanctionedDate || '',
      amountDisbursed: leadDetails?.leadDetails?.disbursedAmount || '',
      dateOfDisbursed: leadDetails?.leadDetails?.disbursedDate || ''
    });
  }, [leadDetails]);

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required('Full Name is required')
    //   .matches(/^[A-Za-z\s]+$/, 'Invalid Name'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // dob: Yup.date().required('Date of Birth is required'),
    // mobileNo: Yup.string()
    //   .matches(/^[6789]\d{9}$/, 'Invalid Mobile number')
    //   .required('Mobile number is required'),

    // emailId: Yup.string().email('Invalid email').required('Email is required'),

    addressLine1: Yup.string().required('Address is required'),
    addressLine2: Yup.string().required('Address is required'),
    pincode: Yup.string().required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),

    // aadhaarNumber: Yup.string()
    //   .required('Aadhaar Number is required')
    //   .matches(
    //     /^\d{12}$|^\d{4}\s\d{4}\s\d{4}$/,
    //     'Please enter a valid Aadhaar Number (12 digits or in format XXXX XXXX XXXX)'
    //   ),

    connectorID: Yup.object({
      id: Yup.string().required('Connector Code is required')
    }).required('Connector Code is required'),

    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    idbiProduct: Yup.object({
      id: Yup.string().required('IDBI Product is required')
    }).required('IDBI Product is required'),

    idbiSubProduct: Yup.object({
      id: Yup.string().required('IDBI Sub Product is required')
    }).required('IDBI Sub Product is required'),

    branchName: Yup.string().required('Branch Name is required'),
    // solId: Yup.string().required('SOL Id is required'),
    loanNo: Yup.string().required('Loan Number is required'),
    amountRequested: Yup.string().required('Amount is required'),
    amountSanctioned: Yup.string().required('Amount is required'),
    dateOfSanctioned: Yup.date().required('Date of Sanctioned is required'),
    amountDisbursed: Yup.string().required('Amount is required'),
    dateOfDisbursed: Yup.date().required('Date of Disbursed is required')
  });

  const validationSchemaWithoutConnectorId = Yup.object().shape({
    // fullName: Yup.string()
    //   .required('Full Name is required')
    //   .matches(/^[A-Za-z\s]+$/, 'Invalid Name'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // dob: Yup.date().required('Date of Birth is required'),
    // mobileNo: Yup.string()
    //   .matches(/^[6789]\d{9}$/, 'Invalid Mobile number')
    //   .required('Mobile number is required'),

    // emailId: Yup.string().email('Invalid email').required('Email is required'),

    addressLine1: Yup.string().required('Address is required'),
    addressLine2: Yup.string().required('Address is required'),
    pincode: Yup.string().required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),

    // aadhaarNumber: Yup.string()
    //   .required('Aadhaar Number is required')
    //   .matches(
    //     /^\d{12}$|^\d{4}\s\d{4}\s\d{4}$/,
    //     'Please enter a valid Aadhaar Number (12 digits or in format XXXX XXXX XXXX)'
    //   ),

    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area Office is required')
    }).required('Area Office is required'),

    idbiProduct: Yup.object({
      id: Yup.string().required('IDBI Product is required')
    }).required('IDBI Product is required'),

    idbiSubProduct: Yup.object({
      id: Yup.string().required('IDBI Sub Product is required')
    }).required('IDBI Sub Product is required'),

    branchName: Yup.string().required('Branch Name is required'),
    // solId: Yup.string().required('SOL Id is required'),
    loanNo: Yup.string().required('Loan Number is required'),
    amountRequested: Yup.string().required('Amount is required'),
    amountSanctioned: Yup.string().required('Amount is required'),
    dateOfSanctioned: Yup.date().required('Date of Sanctioned is required'),
    amountDisbursed: Yup.string().required('Amount is required'),
    dateOfDisbursed: Yup.date().required('Date of Disbursed is required')
  });

  const formik = useFormik({
    initialValues: {
      customerId: '',
      applicationId: '',
      leadId: 0,
      fullName: '',
      panNumber: '',
      dob: '',
      mobileNo: '',
      emailId: '',

      addressLine1: '',
      addressLine2: '',
      landmark: '',
      pincode: '',
      city: '',
      state: '',

      aadhaarNumber: '',
      passportNumber: '',
      voterIdNumber: '',

      searchConnectorId: '',
      connectorID: '',

      leadSource: null,
      sourcePerson: null,
      assistantPerson: null,
      areaOffice: null,

      idbiProduct: null,
      idbiSubProduct: null,

      branchName: '',
      solId: '',
      loanNo: '',
      amountRequested: '',
      amountSanctioned: '',
      dateOfSanctioned: '',
      amountDisbursed: '',
      dateOfDisbursed: ''
    },
    validationSchema:
      isConnectorId == 'Yes'
        ? validationSchema
        : validationSchemaWithoutConnectorId,
    onSubmit: values => {
      const formData = new FormData();

      let payload = {
        customerId: values?.customerId || 0,
        leadId: values?.leadId || 0,
        name: values?.fullName || '',
        mobileNumber: values?.mobileNo || '',
        emailId: values?.emailId || '',
        panNumber: values?.panNumber || '',
        dob: values?.dob || '',
        currentAddressLine1: values?.addressLine1 || '',
        currentAddressLine2: values?.addressLine2 || '',
        currentLandmark: values?.landmark || '',
        currentPincode: values?.pincode || 0,
        currentCity: values?.city || '',
        currentState: values?.state || '',
        aadhaarNumber: values?.aadhaarNumber || '',
        voterId: values?.voterIdNumber || '',
        passportNumber: values?.passportNumber || '',
        businessThroughConnector: isConnectorId,
        connectorId: values?.connectorID?.id || 0,
        leadSource: values?.leadSource?.id || 0,
        sp: values?.sourcePerson?.id || 0,
        ap: values?.assistantPerson?.id || 0,
        areaOffice: values?.areaOffice?.id || 0,
        idbiProductId: values?.idbiProduct?.id || 0,
        idbiSubProductId: values?.idbiSubProduct?.id || 0,
        branch: values?.branchName || '',
        solId: values?.solId || 0,
        loanNo: values?.loanNo || 0,
        requestedAmount: values?.amountRequested
          ? parseFloat(values?.amountRequested).toFixed(2)
          : 0,
        sanctionedAmount: values?.amountSanctioned
          ? parseFloat(values?.amountSanctioned).toFixed(2)
          : 0,
        sanctionedDate: values?.dateOfSanctioned || '',
        disbursedAmount: values?.amountDisbursed
          ? parseFloat(values?.amountDisbursed).toFixed(2)
          : 0,
        disbursedDate: values?.dateOfDisbursed || ''
      };

      formData.append('customerLeadRequest', JSON.stringify(payload));
      formData.append(
        'document1',
        docArray1?.[0]?.lastModified ? docArray1[0] : null
      );
      formData.append(
        'document2',
        docArray2?.[0]?.lastModified ? docArray2[0] : null
      );
      formData.append(
        'document3',
        docArray3?.[0]?.lastModified ? docArray3[0] : null
      );
      formData.append(
        'document4',
        docArray4?.[0]?.lastModified ? docArray4[0] : null
      );
      dispatch(updateLeadDetails({ payload: formData, productId: productId }));
    }
  });

  useEffect(() => {
    if (formik.values?.company) {
      dispatch(getMFFundHouseList({ companyId: formik.values?.company?.id }));
    }
  }, [formik.values?.company]);

  useEffect(() => {
    if (formik.values?.fundHouse) {
      dispatch(getMFSchemeList({ fundHouseId: formik.values?.fundHouse?.id }));
    }
  }, [formik.values?.fundHouse]);

  useEffect(() => {
    if (formik.values?.idbiProduct) {
      dispatch(
        getIDBISubProductList({ productId: formik.values?.idbiProduct?.id })
      );
    }
  }, [formik.values?.idbiProduct]);

  const handleOnChange = newValue => {
    formik.setValues({
      ...formik.values,
      idbiProduct: newValue ? newValue : null,
      idbiSubProduct: null
    });
  };

  const doc1 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );

  const doc3 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document3')),
    [leadDocuments]
  );

  const doc4 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document4')),
    [leadDocuments]
  );

  useEffect(() => {
    if (isFirstTime) {
      if (leadDetails?.leadDetails?.sp?.id) {
        dispatch(
          getAssistingPerson({
            sourcePersonId: leadDetails?.leadDetails?.sp?.id || 0
          })
        );
      }
    }
  }, [leadDetails?.leadDetails?.sp]);

  useEffect(() => {
    if (!isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: formik.values?.sourcePerson?.id || 0
        })
      );
    }
  }, [formik.values?.sourcePerson]);

  useEffect(() => {
    if (!isFirstTime) {
      if (assistantPersonList) {
        formik.setFieldValue('assistantPerson', assistantPersonList[0]);
      }
    }
  }, [assistantPersonList]);

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };
  const handleDeleteDocument = () => {
    dispatch(
      deleteLeadDocuments({ fileUrl: selectedFileName, product: productId })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId, productId }));
    }, 1000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
    if (docNumber === 3) {
      setDocArray3(array);
    }
    if (docNumber === 4) {
      setDocArray4(array);
    }
  };

  const handleSetConnectorId = value => {
    setIsConnectorId(value);
  };

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };

  useEffect(() => {
    if (cityStateDetails) {
      formik.setValues({
        ...formik.values,
        city: cityStateDetails?.city,
        state: cityStateDetails?.state
      });
    }
  }, [cityStateDetails]);

  const handleCurrPincodeChange = event => {
    const { value } = event.target;
    formik.setFieldValue('pincode', value);
    if (value.length === 6) {
      dispatch(getCityStateByPincode(value));
    }
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isGetLeadDetailsLoading && (
          <form>
            {/* Basic details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Basic Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Applicant ID"
                    name="customerId"
                    size="small"
                    fullWidth
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customerId !== ''
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Lead ID"
                    name="applicationId"
                    size="small"
                    fullWidth
                    value={formik.values.applicationId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.applicationId !== ''
                    }}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Name as per PAN Card"
                    name="fullName"
                    placeholder="Enter Full Name"
                    size="small"
                    fullWidth
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fullName !== ''
                    }}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="PAN Number"
                    name="panNumber"
                    size="small"
                    fullWidth
                    value={formik.values.panNumber}
                    onChange={e => {
                      formik.setFieldValue(
                        'panNumber',
                        e.target.value.toUpperCase()
                      );
                    }}
                    InputLabelProps={{
                      shrink: formik.values.panNumber !== ''
                    }}
                    error={
                      formik.touched.panNumber &&
                      Boolean(formik.errors.panNumber)
                    }
                    helperText={
                      formik.touched.panNumber && formik.errors.panNumber
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Date of Birth"
                      value={
                        formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dob', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dob && Boolean(formik?.errors?.dob)
                          }
                          helperText={
                            formik?.touched?.dob && formik?.errors?.dob
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dob && formik.errors?.dob && (
                    <FormHelperText>{formik.errors?.dob}</FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Mobile Number"
                    name="mobileNo"
                    size="small"
                    fullWidth
                    value={formik.values.mobileNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.mobileNo !== ''
                    }}
                    error={
                      formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                    }
                    helperText={
                      formik.touched.mobileNo && formik.errors.mobileNo
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Email ID"
                    name="emailId"
                    size="small"
                    fullWidth
                    value={formik.values.emailId}
                    onChange={e => {
                      formik.setFieldValue(
                        'emailId',
                        e.target.value.toLowerCase()
                      );
                    }}
                    InputLabelProps={{ shrink: formik.values.emailId !== '' }}
                    error={
                      formik.touched.emailId && Boolean(formik.errors.emailId)
                    }
                    helperText={formik.touched.emailId && formik.errors.emailId}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider style={{ marginBottom: '12px' }} />

            {/* Address Details */}

            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Address Details
              </Typography>
              <Grid container spacing={3} marginBottom={'16px'}>
                <Grid item lg={4}>
                  <TextField
                    label="Address line 1 *"
                    name="addressLine1"
                    size="small"
                    fullWidth
                    value={formik.values.addressLine1}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.addressLine1 !== ''
                    }}
                    error={
                      formik.touched.addressLine1 &&
                      Boolean(formik.errors.addressLine1)
                    }
                    helperText={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Address line 2 *"
                    name="addressLine2"
                    size="small"
                    fullWidth
                    value={formik.values.addressLine2}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.addressLine2 !== ''
                    }}
                    error={
                      formik.touched.addressLine2 &&
                      Boolean(formik.errors.addressLine2)
                    }
                    helperText={
                      formik.touched.addressLine2 && formik.errors.addressLine2
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Landmark"
                    name="landmark"
                    size="small"
                    fullWidth
                    value={formik.values.landmark}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values.landmark !== '' }}
                    // error={formik.touched.landmark && Boolean(formik.errors.landmark)}
                    // helperText={formik.touched.landmark && formik.errors.landmark}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Pincode *"
                    name="pincode"
                    size="small"
                    fullWidth
                    value={formik.values.pincode}
                    // onChange={formik.handleChange}
                    onChange={e => handleCurrPincodeChange(e)}
                    InputLabelProps={{ shrink: formik.values.pincode !== '' }}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helperText={formik.touched.pincode && formik.errors.pincode}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="City *"
                    name="city"
                    size="small"
                    disabled
                    fullWidth
                    // value={formik.values.city}
                    value={formik.values?.city}
                    // onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values?.city !== '' }}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="State *"
                    name="state"
                    size="small"
                    disabled
                    fullWidth
                    value={formik.values.state}
                    // value={cityStateDetails?.state}
                    // onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values?.state !== '' }}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
              </Grid>
            </div>

            <Divider style={{ marginBottom: '12px' }} />

            {/* KYC Details */}

            <KYCDetails formik={formik} isAdhaarMandatory={false} />
            <Divider style={{ marginBottom: '12px' }} />

            {/* Connector Mapping */}

            <ConnectorMapping
              formik={formik}
              handleSetId={handleSetConnectorId}
            />
            <Divider style={{ marginBottom: '12px' }} />

            {/* SP Details */}
            <LeadSourceDetails
              formik={formik}
              handleState={handleFirstTimeValue}
            />

            <Divider style={{ marginBottom: '12px' }} />

            {/* IDBI Product */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                IDBI Product
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.idbiProduct}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    // onChange={(event, newValue) => {
                    //   formik.setFieldValue(
                    //     'idbiProduct',
                    //     newValue ? newValue : null
                    //   );
                    // }}
                    onChange={(event, newValue) => handleOnChange(newValue)}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(idbiProductList)
                        ? idbiProductList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="IDBI Product*"
                        error={
                          formik.touched.idbiProduct &&
                          formik.errors.idbiProduct
                        }
                        helperText={
                          formik.touched.idbiProduct &&
                          formik.errors.idbiProduct
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.idbiSubProduct}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'idbiSubProduct',
                        newValue ? newValue : null
                      );
                    }}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(idbiSubProductList)
                        ? idbiSubProductList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="IDBI Sub Product*"
                        error={
                          formik.touched.idbiSubProduct &&
                          formik.errors.idbiSubProduct
                        }
                        helperText={
                          formik.touched.idbiSubProduct &&
                          formik.errors.idbiSubProduct
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>

            <Divider style={{ marginBottom: '12px' }} />

            {/* Loan Details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Loan Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    label="Branch Name*"
                    name="branchName"
                    placeholder="Enter branch name"
                    size="small"
                    fullWidth
                    value={formik.values.branchName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.branchName !== ''
                    }}
                    error={
                      formik.touched.branchName &&
                      Boolean(formik.errors.branchName)
                    }
                    helperText={
                      formik.touched.branchName && formik.errors.branchName
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Sol ID"
                    name="solId"
                    placeholder="Enter sol id"
                    size="small"
                    fullWidth
                    value={formik.values.solId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.solId !== ''
                    }}
                    error={formik.touched.solId && Boolean(formik.errors.solId)}
                    helperText={formik.touched.solId && formik.errors.solId}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Loan No*"
                    name="loanNo"
                    placeholder="Enter loan number"
                    size="small"
                    fullWidth
                    value={formik.values.loanNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.loanNo !== ''
                    }}
                    error={
                      formik.touched.loanNo && Boolean(formik.errors.loanNo)
                    }
                    helperText={formik.touched.loanNo && formik.errors.loanNo}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Amount Requested (₹)*"
                    name="amountRequested"
                    placeholder="Enter amount"
                    size="small"
                    fullWidth
                    value={formik.values.amountRequested}
                    onChange={formik.handleChange}
                    onKeyPress={e => {
                      const currentValue = formik.values.amountRequested;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.amountRequested !== ''
                    }}
                    error={
                      formik.touched.amountRequested &&
                      Boolean(formik.errors.amountRequested)
                    }
                    helperText={
                      formik.touched.amountRequested &&
                      formik.errors.amountRequested
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Amount Sanctioned (₹)*"
                    name="amountSanctioned"
                    placeholder="Enter amount"
                    size="small"
                    fullWidth
                    value={formik.values.amountSanctioned}
                    onChange={formik.handleChange}
                    onKeyPress={e => {
                      const currentValue = formik.values.amountSanctioned;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.amountSanctioned !== ''
                    }}
                    error={
                      formik.touched.amountSanctioned &&
                      Boolean(formik.errors.amountSanctioned)
                    }
                    helperText={
                      formik.touched.amountSanctioned &&
                      formik.errors.amountSanctioned
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Sanctioned*"
                      value={
                        formik?.values?.dateOfSanctioned
                          ? dayjs(formik?.values?.dateOfSanctioned)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('dateOfSanctioned', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfSanctioned &&
                                  formik?.errors?.dateOfSanctioned
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dateOfSanctioned &&
                            Boolean(formik?.errors?.dateOfSanctioned)
                          }
                          helperText={
                            formik?.touched?.dateOfSanctioned &&
                            formik?.errors?.dateOfSanctioned
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dateOfSanctioned &&
                    formik.errors?.dateOfSanctioned && (
                      <FormHelperText>
                        {formik.errors?.dateOfSanctioned}
                      </FormHelperText>
                    )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Amount Disbursed (₹)*"
                    name="amountDisbursed"
                    placeholder="Enter amount"
                    size="small"
                    fullWidth
                    value={formik.values.amountDisbursed}
                    onChange={formik.handleChange}
                    onKeyPress={e => {
                      const currentValue = formik.values.amountDisbursed;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.amountDisbursed !== ''
                    }}
                    error={
                      formik.touched.amountDisbursed &&
                      Boolean(formik.errors.amountDisbursed)
                    }
                    helperText={
                      formik.touched.amountDisbursed &&
                      formik.errors.amountDisbursed
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Disbursed*"
                      value={
                        formik?.values?.dateOfDisbursed
                          ? dayjs(formik?.values?.dateOfDisbursed)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('dateOfDisbursed', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDisbursed &&
                                  formik?.errors?.dateOfDisbursed
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dateOfDisbursed &&
                            Boolean(formik?.errors?.dateOfDisbursed)
                          }
                          helperText={
                            formik?.touched?.dateOfDisbursed &&
                            formik?.errors?.dateOfDisbursed
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dateOfDisbursed &&
                    formik.errors?.dateOfDisbursed && (
                      <FormHelperText>
                        {formik.errors?.dateOfDisbursed}
                      </FormHelperText>
                    )}
                </Grid>
              </Grid>

              <Divider style={{ marginBottom: '12px', marginTop: '16px' }} />
            </div>

            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc3}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={3}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc4}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={4}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: productId
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>

            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            {!isLeadUpdateLoading && isLeadUpdateSuccess && (
              <SnackBarBox
                type="success"
                message={'Lead data update successfully'}
              />
            )}

            {!isLeadUpdateLoading && isLeadUpdateFailed && (
              <SnackBarBox
                type="error"
                // message={handleError(
                //   UpdateConnectorDataErrorContainer?.message
                // )}
              />
            )}
          </form>
        )}
      </div>
    </>
  );
}

export default CWAIDBILeadEdit;
