import { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box
} from '@mui/material';
import { AttachmentOutlined, Visibility } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { viewDocument } from '../../redux/reducers/leadSlice';

function SingleDocUpload({ documentsArr, handleSetData, title, onlyView }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (documentsArr.length > 0) {
      const apiFiles = documentsArr?.map(file => ({
        name: file?.fileName,
        url: file?.s3ObjectKey,
        contentType: file?.contentType
      }));
      setFiles(apiFiles ? apiFiles : []);
    }

    // handleSetData(apiFiles);
  }, [documentsArr]);

  // useEffect(() => {
  //   handleSetData(files);
  // }, [files]);
  const handleFileChange = event => {
    // setFiles([...files, ...Array.from(event.target.files)]);
    // handleSetData([...files, ...Array.from(event.target.files)]);
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    const invalidFiles = [];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes

    selectedFiles.forEach(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isValidFileType =
        ((fileExtension === 'jpg' || fileExtension === 'jpeg') &&
          file.type === 'image/jpeg') ||
        (fileExtension === 'pdf' && file.type === 'application/pdf');
      const isValidFileSize = file.size <= maxSizeInBytes;

      if (isValidFileType && isValidFileSize) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    handleSetData([...files, ...validFiles]);

    if (invalidFiles.length > 0) {
      alert(
        'You can upload only JPG, JPEG, and PDF files, with a size limit of 2 MB each.'
      );
    }
  };

  const handleFileRemove = index => {
    setFiles(files.filter((_, i) => i !== index));
    handleSetData(
      files.filter((_, i) => i !== index),
      true
    );
  };

  const handleFileView = file => {
    if (file instanceof File) {
      // For local files
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      // For files from the API
      const fileUrl = file.url;
      const contentType = file.contentType;
      dispatch(viewDocument({ fileUrl, contentType }));
    }
  };

  return (
    <>
      {onlyView !== true && (
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: title ? 'center' : 'flex-start'
          }}
        >
          {title && (
            <Typography
              color="#000000"
              fontSize={'16px'}
              fontWeight={600}
              marginRight={'1rem'}
            >
              {title}
            </Typography>
          )}

          <Button
            variant="contained"
            component="label"
            style={{
              backgroundColor: files.length > 0 ? '#EEEEEE' : '#F3F7FF',
              color: '#335DAD',
              border: '1px dashed',
              borderColor: 'primary.main',
              fontWeight: '600',
              cursor: files.length > 0 ? 'not-allowed' : 'pointer'
            }}
          >
            <FileUploadIcon />
            UPLOAD
            <input
              disabled={files.length > 0 ? true : false}
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
        </div>
      )}
      <List>
        {files?.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              '&:hover': { backgroundColor: '#f5f5f5' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: onlyView ? '#f5f5f5' : ''
            }}
          >
            <Box display="flex" alignItems="center">
              <IconButton style={{ cursor: 'inherit' }}>
                <AttachmentOutlined />
              </IconButton>
              <ListItemText primary={file?.name} />
            </Box>
            <ListItemSecondaryAction
              sx={{ minWidth: '80px', display: 'flex', justifyContent: 'end' }}
            >
              <IconButton
                edge="end"
                onClick={() => handleFileView(file)}
                style={{ marginRight: '4px' }}
              >
                <Visibility />
              </IconButton>
              {onlyView !== true && (
                <IconButton edge="end" onClick={() => handleFileRemove(index)}>
                  {/* <RemoveCircleOutline /> */}
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default SingleDocUpload;
