import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  deleteLeadDocuments,
  getAreaOffice,
  getAssistingPerson,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getNpsCustomerList,
  getNpsTierList,
  getSourcePerson,
  updateLeadDetails
} from '../../redux/reducers/leadSlice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import {
  createTitle,
  formatAmountDecimalInINR,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import UploadDocumentsLead from '../../forms/CWALeadComponents/uploadDocumentsLead';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import LeadSourceDetails from '../../forms/CWALeadComponents/LeadSourceDetails';

function CWANPSLeadEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    isGetLeadDetailsLoading,
    sourcePersonList,
    leadSourceList,
    assistantPersonList,
    isGetLeadDocLoading,
    npsCustomerList,
    npsTierList,
    leadUpdateErrorContainer
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadSource({}));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadDetails({ leadId, customerId, productId }));
    dispatch(getLeadDocuments({ leadId, customerId, productId }));
    dispatch(getNpsCustomerList());
    dispatch(getNpsTierList());
    dispatch(getAreaOffice({}));
  }, [leadId, customerId]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: productId
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  useEffect(() => {
    const addAmount =
      Number(leadDetails?.npsLeadDetails?.contributioAmt || 0) +
      Number(
        leadDetails?.npsLeadDetails?.subsequent === false
          ? leadDetails?.npsLeadDetails?.registrationAmt
          : 0
      ) +
      Number(leadDetails?.npsLeadDetails?.transactionAmt || 0);

    const gstPercentage = leadDetails?.npsConfig?.gstPercentage / 100;
    const gstCalculation = addAmount * gstPercentage;
    const gstAmount = parseFloat(gstCalculation.toFixed(2));
    const totalAmountCal = addAmount + gstAmount;

    formik.setValues({
      ...formik.values,
      customerId: leadDetails?.customerDetails?.customerId || '',
      applicationId: leadDetails?.npsLeadDetails?.applicationId || '',
      leadId: leadDetails?.npsLeadDetails?.id || 0,
      fullName: leadDetails?.customerDetails?.customerName || '',
      panNumber: leadDetails?.customerDetails?.panNumber || '',
      dob: leadDetails?.customerDetails?.dob || '',
      mobileNo: leadDetails?.customerDetails?.mobileNumber || '',
      emailId: leadDetails?.customerDetails?.emailId || '',
      sourcePerson: leadDetails?.npsLeadDetails?.spSource || null,
      leadSource: leadDetails?.npsLeadDetails?.leadSource || null,
      assistantPerson: leadDetails?.npsLeadDetails?.ap || null,
      areaOffice: leadDetails?.npsLeadDetails?.areaOffice || null,
      customer: leadDetails?.npsLeadDetails?.leadType?.id || '',
      pranNumber: leadDetails?.customerDetails?.pranNumber || '',
      tier: leadDetails?.npsLeadDetails?.npsTier?.id || '',
      contribution: leadDetails?.npsLeadDetails?.contributioAmt || 0,
      registrationFees:
        leadDetails?.npsLeadDetails?.subsequent === false
          ? leadDetails?.npsLeadDetails?.registrationAmt
          : 0,
      transactionFees: leadDetails?.npsLeadDetails?.transactionAmt || 0,
      address: leadDetails?.npsLeadDetails?.address || '',
      gstAmount: gstAmount || 0,
      totalAmount: totalAmountCal || 0
    });
  }, [leadDetails]);

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required('Full Name is required')
    //   .matches(/^[A-Za-z\s]+$/, 'Invalid Name'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // dob: Yup.date().required('Date of Birth is required'),
    // mobileNo: Yup.string()
    //   .matches(/^[6789]\d{9}$/, 'Invalid Mobile number')
    //   .required('Mobile number is required'),

    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area Office is required')
    }).required('Area Office is required'),

    customer: Yup.string().required('Customer is required'),
    pranNumber: Yup.string().required('PRAN Number is required'),
    tier: Yup.string().required('Tier is required'),
    contribution: Yup.string()
      .required('Contribution is required')
      .matches(/^\d+(\.\d+)?$/, 'Invalid format'),
    registrationFees: Yup.string()
      .required('Registration Fees is required')
      .matches(/^\d+(\.\d+)?$/, 'Invalid format'),
    transactionFees: Yup.string()
      .required('Transaction Fees is required')
      .matches(/^\d+(\.\d+)?$/, 'Invalid format'),
    address: Yup.string().required('Address is required')
    // gstAmount: ''
  });

  const formik = useFormik({
    initialValues: {
      customerId: '',
      applicationId: '',
      leadId: 0,
      fullName: '',
      panNumber: '',
      dob: '',
      mobileNo: '',
      emailId: '',
      sourcePerson: null,
      leadSource: null,
      assistantPerson: null,
      areaOffice: null,
      customer: '',
      pranNumber: '',
      tier: '',
      contribution: '',
      registrationFees: '',
      transactionFees: '',
      address: '',
      gstAmount: '',
      totalAmount: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const formData = new FormData();

      let payload = {
        customerId: values?.customerId || 0,
        customerName: values?.fullName || '',
        dob: values?.dob || '',
        mobileNumber: values?.mobileNo || '',
        emailId: values?.emailId || '',
        panNumber: values?.panNumber || '',
        productId: productId,
        leadId: values?.leadId || '',
        leadSourceId: values?.leadSource?.id || 0,
        spId: values?.sourcePerson?.id || 0,
        ap: values?.assistantPerson?.id || 0,
        areaOffice: values?.areaOffice?.id || 0,
        leadTypeId: values?.customer || 0,
        pranNo: values?.pranNumber || '',
        tierId: values?.tier || 0,
        contributioAmt: values?.contribution
          ? parseFloat(values?.contribution).toFixed(2)
          : 0,
        registrationAmt: values?.registrationFees
          ? parseFloat(values?.registrationFees).toFixed(2)
          : 0,
        transactionAmt: values?.transactionFees
          ? parseFloat(values?.transactionFees).toFixed(2)
          : 0,
        gstAmt: values?.gstAmount
          ? parseFloat(values?.gstAmount).toFixed(2)
          : 0,
        address: values?.address || ''
      };
      formData.append('customerLeadRequest', JSON.stringify(payload));
      formData.append(
        'document1',
        docArray1?.[0]?.lastModified ? docArray1[0] : null
      );
      formData.append(
        'document2',
        docArray2?.[0]?.lastModified ? docArray2[0] : null
      );
      dispatch(updateLeadDetails({ payload: formData, productId: productId }));
    }
  });

  const doc1 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );

  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(true);

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };
  const handleDeleteDocument = () => {
    dispatch(
      deleteLeadDocuments({ fileUrl: selectedFileName, product: productId })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId, productId }));
    }, 1000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
  };

  const handleOnPriceChange = (e, name) => {
    if (name === 'contribution') {
      const addAmount =
        Number(e.target.value) +
        Number(formik.values.registrationFees || 0) +
        Number(formik.values.transactionFees || 0);
      const gstPercentage = leadDetails?.npsConfig?.gstPercentage / 100;
      const gstCalculation = addAmount * gstPercentage;
      const gstAmount = parseFloat(gstCalculation.toFixed(2));
      const totalAmountCal = addAmount + gstAmount;
      formik.setValues({
        ...formik.values,
        contribution: e.target.value,
        gstAmount: gstAmount,
        totalAmount: totalAmountCal
      });
    }

    if (name === 'registrationFees') {
      const addAmount =
        Number(e.target.value) +
        Number(formik.values.contribution || 0) +
        Number(formik.values.transactionFees || 0);
      const gstPercentage = leadDetails?.npsConfig?.gstPercentage / 100;
      const gstCalculation = addAmount * gstPercentage;
      const gstAmount = parseFloat(gstCalculation.toFixed(2));
      const totalAmountCal = addAmount + gstAmount;
      formik.setValues({
        ...formik.values,
        registrationFees: e.target.value,
        gstAmount: gstAmount,
        totalAmount: totalAmountCal
      });
    }

    if (name === 'transactionFees') {
      const addAmount =
        Number(e.target.value) +
        Number(formik.values.registrationFees || 0) +
        Number(formik.values.contribution || 0);
      const gstPercentage = leadDetails?.npsConfig?.gstPercentage / 100;
      const gstCalculation = addAmount * gstPercentage;
      const gstAmount = parseFloat(gstCalculation.toFixed(2));
      const totalAmountCal = addAmount + gstAmount;
      formik.setValues({
        ...formik.values,
        transactionFees: e.target.value,
        gstAmount: gstAmount,
        totalAmount: totalAmountCal
      });
    }
  };

  useEffect(() => {
    if (isFirstTime) {
      if (leadDetails?.npsLeadDetails?.spSource?.id) {
        dispatch(
          getAssistingPerson({
            sourcePersonId: leadDetails?.npsLeadDetails?.spSource?.id || 0
          })
        );
      }
    }
  }, [leadDetails?.npsLeadDetails?.spSource]);

  useEffect(() => {
    if (!isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: formik.values?.sourcePerson?.id || 0
        })
      );
    }
  }, [formik.values?.sourcePerson]);

  useEffect(() => {
    if (!isFirstTime) {
      if (assistantPersonList) {
        formik.setFieldValue('assistantPerson', assistantPersonList[0]);
      }
    }
  }, [assistantPersonList]);

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isGetLeadDetailsLoading && (
          <form>
            {/* Basic details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Basic Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Applicant ID"
                    name="customerId"
                    size="small"
                    fullWidth
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customerId !== ''
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Lead ID"
                    name="applicationId"
                    size="small"
                    fullWidth
                    value={formik.values.applicationId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.applicationId !== ''
                    }}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Name as per PAN Card"
                    name="fullName"
                    placeholder="Enter Full Name"
                    size="small"
                    fullWidth
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fullName !== ''
                    }}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="PAN Number"
                    name="panNumber"
                    size="small"
                    fullWidth
                    value={formik.values.panNumber}
                    // onChange={formik.handleChange}
                    onChange={e => {
                      formik.setFieldValue(
                        'panNumber',
                        e.target.value.toUpperCase()
                      );
                    }}
                    InputLabelProps={{
                      shrink: formik.values.panNumber !== ''
                    }}
                    error={
                      formik.touched.panNumber &&
                      Boolean(formik.errors.panNumber)
                    }
                    helperText={
                      formik.touched.panNumber && formik.errors.panNumber
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Date of Birth"
                      value={
                        formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dob', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dob && Boolean(formik?.errors?.dob)
                          }
                          helperText={
                            formik?.touched?.dob && formik?.errors?.dob
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dob && formik.errors?.dob && (
                    <FormHelperText>{formik.errors?.dob}</FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Mobile Number"
                    name="mobileNo"
                    size="small"
                    fullWidth
                    value={formik.values.mobileNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.mobileNo !== ''
                    }}
                    error={
                      formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                    }
                    helperText={
                      formik.touched.mobileNo && formik.errors.mobileNo
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Email ID"
                    name="emailId"
                    size="small"
                    fullWidth
                    value={formik.values.emailId}
                    onChange={e => {
                      formik.setFieldValue(
                        'emailId',
                        e.target.value.toLowerCase()
                      );
                    }}
                    InputLabelProps={{ shrink: formik.values.emailId !== '' }}
                    error={
                      formik.touched.emailId && Boolean(formik.errors.emailId)
                    }
                    helperText={formik.touched.emailId && formik.errors.emailId}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider style={{ marginBottom: '12px' }} />

            {/* SP Details */}
            <LeadSourceDetails
              formik={formik}
              handleState={handleFirstTimeValue}
            />
            <Divider style={{ marginBottom: '12px' }} />

            {/* Policy Details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Policy Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Customer*"
                    name="customer"
                    size="small"
                    fullWidth
                    value={formik.values.customer}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customer !== ''
                    }}
                    error={
                      formik.touched.customer && Boolean(formik.errors.customer)
                    }
                    helperText={
                      formik.touched.customer && formik.errors.customer
                    }
                  >
                    {npsCustomerList?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="PRAN No*"
                    name="pranNumber"
                    placeholder="Enter PRAN Number"
                    size="small"
                    fullWidth
                    value={formik.values.pranNumber}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.pranNumber !== ''
                    }}
                    error={
                      formik.touched.pranNumber &&
                      Boolean(formik.errors.pranNumber)
                    }
                    helperText={
                      formik.touched.pranNumber && formik.errors.pranNumber
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Tier*"
                    name="tier"
                    size="small"
                    fullWidth
                    value={formik.values.tier}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.tier !== ''
                    }}
                    error={formik.touched.tier && Boolean(formik.errors.tier)}
                    helperText={formik.touched.tier && formik.errors.tier}
                  >
                    {npsTierList?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.tierName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label={
                      leadDetails?.npsLeadDetails?.subsequent
                        ? 'Subsequent Contribution (₹)*'
                        : 'First Contribution (₹)*'
                    }
                    name="contribution"
                    placeholder="Enter contribution"
                    size="small"
                    fullWidth
                    value={formik.values.contribution}
                    onChange={e => handleOnPriceChange(e, 'contribution')}
                    onKeyPress={e => {
                      const currentValue = formik.values.contribution;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.contribution !== ''
                    }}
                    error={
                      formik.touched.contribution &&
                      Boolean(formik.errors.contribution)
                    }
                    helperText={
                      formik.touched.contribution && formik.errors.contribution
                    }
                  />
                </Grid>
                {!leadDetails?.npsLeadDetails?.subsequent && (
                  <Grid item lg={4}>
                    <TextField
                      label="Initial Subscriber Registration Fee (₹)*"
                      name="registrationFees"
                      placeholder="Enter Registration fees"
                      size="small"
                      fullWidth
                      value={formik.values.registrationFees}
                      onChange={e => handleOnPriceChange(e, 'registrationFees')}
                      onKeyPress={e => {
                        const currentValue = formik.values.registrationFees;
                        if (
                          !/[0-9.]/.test(e.key) &&
                          e.key !== 'Backspace' &&
                          e.key !== 'Tab'
                        ) {
                          e.preventDefault();
                        }
                        if (e.key === '.' && currentValue.includes('.')) {
                          e.preventDefault();
                        }
                      }}
                      InputLabelProps={{
                        shrink: formik.values.registrationFees !== ''
                      }}
                      error={
                        formik.touched.registrationFees &&
                        Boolean(formik.errors.registrationFees)
                      }
                      helperText={
                        formik.touched.registrationFees &&
                        formik.errors.registrationFees
                      }
                    />
                  </Grid>
                )}

                <Grid item lg={4}>
                  <TextField
                    label="Transaction Fee (₹)*"
                    name="transactionFees"
                    placeholder="Enter Transaction fees"
                    size="small"
                    fullWidth
                    value={formik.values.transactionFees}
                    onChange={e => handleOnPriceChange(e, 'transactionFees')}
                    onKeyPress={e => {
                      const currentValue = formik.values.transactionFees;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.transactionFees !== ''
                    }}
                    error={
                      formik.touched.transactionFees &&
                      Boolean(formik.errors.transactionFees)
                    }
                    helperText={
                      formik.touched.transactionFees &&
                      formik.errors.transactionFees
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Address of Subscriber*"
                    name="address"
                    placeholder="Enter Address"
                    size="small"
                    fullWidth
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.address !== ''
                    }}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>

                <Grid item lg={4}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      color="#666666"
                      fontSize={'16px'}
                      fontWeight={600}
                    >
                      GST Amount :
                    </Typography>
                    <Typography
                      color="#335DAD"
                      fontSize={'18px'}
                      fontWeight={600}
                      marginLeft={'1rem'}
                    >
                      ₹ {formatAmountDecimalInINR(formik.values.gstAmount || 0)}
                    </Typography>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      color="#666666"
                      fontSize={'16px'}
                      fontWeight={600}
                    >
                      Total Amount :
                    </Typography>
                    <Typography
                      color="#335DAD"
                      fontSize={'18px'}
                      fontWeight={600}
                      marginLeft={'1rem'}
                    >
                      ₹{' '}
                      {formatAmountDecimalInINR(formik.values.totalAmount || 0)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Divider style={{ marginBottom: '12px', marginTop: '16px' }} />
            </div>

            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: productId
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>

            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            {!isLeadUpdateLoading && isLeadUpdateSuccess && (
              <SnackBarBox
                type="success"
                message={'Lead data update successfully'}
              />
            )}

            {!isLeadUpdateLoading && isLeadUpdateFailed && (
              <SnackBarBox
                type="error"
                message={handleError(leadUpdateErrorContainer?.message)}
              />
            )}
          </form>
        )}
      </div>
    </>
  );
}

export default CWANPSLeadEdit;
