import { Button, FormControl, Grid } from '@mui/material';
import { useState } from 'react';
import * as yup from 'yup';
import GenericInput from '../components/GenericInput';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { LoadingButton } from '@mui/lab';
import { enterWithNoSpaces } from '../utils/utils';
import { submitRequestForChangePassword } from '../redux/reducers/authSlice';
import { useNavigate } from 'react-router-dom';

const formControl = {
  margin: '0.5rem auto'
};

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required('Old Password is required'),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          `Password must meet the following criteria:
               At least 8 characters long
               One Uppercase letter
               One Lowercase letter
               One special character`
        )
        .min(8, 'New Password should be of minimum 8 digit')
        .max(16, 'New Password should be of maximum 16 digit')
        .required('New Password is required'),
      confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf(
          [yup.ref('newPassword'), null],
          'Password and Confirm Password must match.'
        )
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const data = {
        oldPassword: values.oldPassword,
        newPassword: values.confirmPassword
      };
      dispatch(
        submitRequestForChangePassword({
          payload: data
          // token: resetOtpDetails?.resetToken
        })
      );
    }
  });

  return (
    <div>
      <h3 style={{ textAlign: 'left', color: '#335DAD' }}>Change Password</h3>
      <form autoComplete="off" onSubmit={passwordFormik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <FormControl sx={formControl} variant="outlined" fullWidth>
              <GenericInput
                label="Old Password"
                name="oldPassword"
                size="small"
                required
                type={!showOldPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 16 }}
                value={passwordFormik.values.oldPassword}
                onChange={e =>
                  passwordFormik.setFieldValue(
                    'oldPassword',
                    enterWithNoSpaces(e.target.value)
                  )
                }
                onBlur={passwordFormik.handleBlur}
                error={
                  passwordFormik.touched.oldPassword &&
                  passwordFormik.errors.oldPassword
                }
                startIcon={<LockOutlinedIcon className="icon_color" />}
                endIcon={
                  showOldPassword ? (
                    <VisibilityOffOutlinedIcon className="icon_color" />
                  ) : (
                    <VisibilityOutlinedIcon className="icon_color" />
                  )
                }
                onEndIconClick={() => {
                  setShowOldPassword(!showOldPassword);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl sx={formControl} variant="outlined" fullWidth>
              <GenericInput
                label="New Password"
                name="newPassword"
                size="small"
                required
                type={!showNewPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 16 }}
                value={passwordFormik.values.newPassword}
                onChange={e =>
                  passwordFormik.setFieldValue(
                    'newPassword',
                    enterWithNoSpaces(e.target.value)
                  )
                }
                onBlur={passwordFormik.handleBlur}
                error={
                  passwordFormik.touched.newPassword &&
                  passwordFormik.errors.newPassword
                }
                startIcon={<LockOutlinedIcon className="icon_color" />}
                endIcon={
                  showNewPassword ? (
                    <VisibilityOffOutlinedIcon className="icon_color" />
                  ) : (
                    <VisibilityOutlinedIcon className="icon_color" />
                  )
                }
                onEndIconClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <FormControl sx={formControl} variant="outlined" fullWidth>
              <GenericInput
                required
                label="Confirm Password"
                size="small"
                name="confirmPassword"
                type={!showConfirmPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 16 }}
                value={passwordFormik.values.confirmPassword}
                onChange={e =>
                  passwordFormik.setFieldValue(
                    'confirmPassword',
                    enterWithNoSpaces(e.target.value)
                  )
                }
                onBlur={passwordFormik.handleBlur}
                error={
                  passwordFormik.touched.confirmPassword &&
                  passwordFormik.errors.confirmPassword
                }
                startIcon={<LockOutlinedIcon className="icon_color" />}
                endIcon={
                  showConfirmPassword ? (
                    <VisibilityOffOutlinedIcon className="icon_color" />
                  ) : (
                    <VisibilityOutlinedIcon className="icon_color" />
                  )
                }
                onEndIconClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Button
            variant="outlined"
            size="large"
            sx={{ marginRight: '16px', fontWeight: '600', paddingX: '48px' }}
            onClick={() => {
              process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
                ? navigate('/adminf/dashboard')
                : navigate('/cwaf/dashboard');
            }}
          >
            CANCEL
          </Button>
          <LoadingButton
            disabled={
              !passwordFormik?.values?.confirmPassword ||
              !passwordFormik?.values?.newPassword ||
              !passwordFormik?.values?.oldPassword
            }
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{
              fontSize: 15,
              fontWeight: '600',
              textTransform: 'uppercase',
              bgcolor: '#335DAD',
              paddingX: '48px'
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
