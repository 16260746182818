import { Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getLeadAuditLogs,
  getLeadDetails,
  revertLeadPageData
} from '../../redux/reducers/leadSlice';
import { createTitle } from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import { colorPrimaryBlue } from '../../config/theme';
import CustomDataTable from '../../components/CustomDataTable';
import dayjs from 'dayjs';

const ExpandableRow = ({ details }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const logs = details.split('~');
  const displayLogs = isExpanded ? logs : logs.slice(0, 1);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        {displayLogs.map((log, index) => (
          <Typography
            key={index}
            variant="body2"
            color={colorPrimaryBlue}
            sx={{ fontWeight: '600' }}
            gutterBottom
          >
            {log}
          </Typography>
        ))}
        <span>
          {logs.length > 1 && (
            <Button
              onClick={handleToggle}
              size="small"
              style={{ textDecoration: 'underline' }}
            >
              {isExpanded ? '...Less' : '...More'}
            </Button>
          )}
        </span>
      </div>
    </>
  );
};

function CWANPSViewAuditLogs() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialValues, setInitalValues] = useState({
    page: 0,
    limit: 10,
    searchFilter: ''
  });

  const { leadAuditLogData, leadDetails } = useSelector(state => state.lead);

  const leadId = location.state.leadId;
  const customerId = location.state.customerId;
  const productId = location?.state?.productId;

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadDetails({ leadId, customerId, productId }));
  }, [leadId, customerId]);

  useEffect(() => {
    dispatch(
      getLeadAuditLogs({
        payload: {
          leadId: leadId,
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter
        },
        productId: productId
      })
    );
    return () => dispatch(revertLeadPageData());
  }, [initialValues]);

  const basicGridItems = useMemo(
    () => [
      {
        label: 'Applicant ID',
        value: leadDetails?.customerDetails?.customerId
          ? leadDetails?.customerDetails?.customerId
          : '-'
      },
      {
        label: 'Lead ID',
        value: leadDetails?.npsLeadDetails?.applicationId
          ? leadDetails?.npsLeadDetails?.applicationId
          : '-'
      },
      {
        label: 'Name',
        value: leadDetails?.customerDetails?.customerName
          ? leadDetails?.customerDetails?.customerName
          : '-'
      },
      {
        label: 'Pan number',
        value: leadDetails?.customerDetails?.panNumber
          ? leadDetails?.customerDetails?.panNumber
          : '-'
      },
      {
        label: 'Date of birth',
        value: leadDetails?.customerDetails?.dob
          ? dayjs(leadDetails?.customerDetails?.dob).format('DD-MM-YYYY')
          : '-'
      },
      {
        label: 'Mobile number',
        value: leadDetails?.customerDetails?.mobileNumber
          ? leadDetails?.customerDetails?.mobileNumber
          : '-'
      },
      {
        label: 'Email ID',
        value: leadDetails?.customerDetails?.emailId
          ? leadDetails?.customerDetails?.emailId
          : '-'
      }
    ],
    [leadDetails]
  );

  const leadSourceGridItems = useMemo(
    () => [
      {
        label: 'Lead source',
        value: leadDetails?.npsLeadDetails?.leadSource
          ? leadDetails?.npsLeadDetails?.leadSource?.name
          : '-'
      },
      {
        label: 'SP',
        value: leadDetails?.npsLeadDetails?.spSource
          ? leadDetails?.npsLeadDetails?.spSource?.name
          : '-'
      }
    ],
    [leadDetails]
  );

  const policyItems = useMemo(
    () => [
      {
        label: 'Customer',
        value: leadDetails?.npsLeadDetails?.leadType
          ? leadDetails?.npsLeadDetails?.leadType?.name
          : '-'
      },
      {
        label: 'PRAN number',
        value: leadDetails?.customerDetails?.pranNumber
          ? leadDetails?.customerDetails?.pranNumber
          : '-'
      },
      {
        label: 'Tier',
        value: leadDetails?.npsLeadDetails?.npsTier
          ? leadDetails?.npsLeadDetails?.npsTier?.name
          : '-'
      },
      {
        label: 'First contribution',
        value: leadDetails?.npsLeadDetails?.contributioAmt
          ? leadDetails?.npsLeadDetails?.contributioAmt
          : '-'
      },
      {
        label: 'Initial subscribe registration fees',
        value: leadDetails?.npsLeadDetails?.registrationAmt
          ? leadDetails?.npsLeadDetails?.registrationAmt
          : '-'
      },
      {
        label: 'Transaction fees',
        value: leadDetails?.npsConfig?.transactionCharge
          ? leadDetails?.npsConfig?.transactionCharge
          : '-'
      },
      {
        label: 'Address of subscriber',
        value: leadDetails?.npsLeadDetails?.address
          ? leadDetails?.npsLeadDetails?.address
          : '-'
      },
      {
        label: 'Gst amount',
        value: leadDetails?.npsLeadDetails?.gstAmt
          ? leadDetails?.npsLeadDetails?.gstAmt
          : '-'
      }
    ],
    [leadDetails]
  );

  const leadAuditLogColumns = [
    {
      uinqueKey: 'id',
      id: 'action',
      label: 'Action',
      marginLeft: '16px'
      // width: '150px'
      //   sortable: true
    },
    {
      id: 'performedBy',
      label: 'Performed By'
    },
    {
      id: 'performedOn',
      label: 'Performed On',
      // width: '140px',
      render: row => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '24px'
              }}
            >
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'details',
      label: 'Details',
      render: row => {
        return <ExpandableRow details={row.details} />;
      }
    }
  ];

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          View Audit Logs
        </Typography>
        <div>
          {/* Basic details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Basic Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {basicGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>

          {/* Lead Source Details*/}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Lead Source Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {leadSourceGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* Policy Details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Policy Details
            </Typography>

            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {policyItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
        </div>
        <div>
          <div style={{ marginBottom: '8px' }}>
            <CustomDataTable
              columns={leadAuditLogColumns}
              data={
                leadAuditLogData.logList.length > 0
                  ? leadAuditLogData.logList
                  : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, limit: val, page: 0 });
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.limit}
              totalCount={leadAuditLogData?.totalCount}
              isShowPagination={true}
            />
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#335DAD',
                marginTop: '12px',
                width: '120px'
              }}
              onClick={() => {
                navigate('/cwaf/lead', {
                  state: {
                    productId: productId
                  }
                });
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CWANPSViewAuditLogs;
