import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  api,
  apiNoAuth,
  multipartApi,
  reportsApi,
  reportsMultipartApi
} from '../../config/api';
import {
  clearAuthData,
  clearCwaAccessibleMenuData,
  clearCwaMenuData,
  clearMenuData,
  clearToken,
  setAuthData,
  setBasicDetails,
  setMenuList,
  setToken
} from '../../config/cookie';
import axios from 'axios';
export const revertAll = createAction('REVERT_ALL');
export const setAuthDetailsByLocalStorage = createAction('SET_AUTH_DETAILS');
export const logoutAndClearToken = createAction('LOGOUT_AND_CLEAR_TOKEN');
export const clearLoginState = createAction('CLEAR_LOGIN_STATE');
export const setResetPasswordDetails = createAction(
  'SET_RESET_PASSWORD_DETAILS'
);

export const clearOtpNotification = createAction('SET_CLEAR_OTP_NOTIFICATION');

export const clearAllNotification = createAction('SET_CLEAR_ALL_NOTIFICATION');

const setTokenToApi = token => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  multipartApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  reportsApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  reportsMultipartApi.defaults.headers.common['Authorization'] =
    `Bearer ${token}`;
};

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('/logout', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const pingServer = createAsyncThunk(
  'auth/actuator/ping',
  async (_, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_BASE_API_URL;
      const parts = url.split('/admin/v1');
      const trimmedURl = parts[0];
      const response = await axios.get(`${trimmedURl}/actuator/health`);
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return { status: 'DOWN' };
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// export const authenticateUser = createAsyncThunk(
//   'login/authenticate',
//   async (credentials, { rejectWithValue }) => {
//     try {
//       const response = await apiNoAuth.post('/authenticate', credentials);
//       return response.data;
//     } catch (error) {
//       if (
//         error &&
//         error?.message &&
//         String(error?.message).includes('Network Error')
//       ) {
//         return rejectWithValue({
//           error: true,
//           errorMessage: 'Network Error',
//           description: 'Something Went Wrong',
//           statusCode: 500
//         });
//       } else if (
//         error?.response &&
//         error?.response?.data?.description &&
//         String(error?.response?.data?.description)
//           .toLowerCase()
//           .includes('invalid username/password')
//       ) {
//         return rejectWithValue({
//           error: true,
//           errorMessage: 'Incorrect Employee ID or Password. Please try again',
//           description: 'Something Went Wrong',
//           statusCode: 500
//         });
//       }
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const authenticateUser = createAsyncThunk(
  'login/authenticate',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post('login', credentials, {
        headers: {
          ...apiNoAuth.defaults.headers,
          'employee-id': credentials.username
        }
      });
      return response.data;
    } catch (error) {
      if (
        error &&
        error?.message &&
        String(error?.message).includes('Network Error')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Network Error',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      } else if (
        error?.response &&
        error?.response?.data?.description &&
        String(error?.response?.data?.description)
          .toLowerCase()
          .includes('invalid username/password')
      ) {
        return rejectWithValue({
          error: true,
          errorMessage: 'Incorrect Employee ID or Password. Please try again',
          description: 'Something Went Wrong',
          statusCode: 500
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOtpForResetPassword = createAsyncThunk(
  'register/send_otp',
  async ({ empId, userType }, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        `/send-otp?empId=${empId}&userType=${userType}`,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const reSendOtpForResetPassword = createAsyncThunk(
  'register/re_send_otp',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        // `/send-otp?mobileNumber=${}&userType=${}`,
        credentials
      );
      return response.data;
      // return new Promise((resolve, reject) => {
      //   resolve({
      //     msg: 'SMS send successfully.'
      //   });
      // });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const verifyOtpForResetPassword = createAsyncThunk(
//   'register/verify_otp',
//   async (credentials, { rejectWithValue }) => {
//     try {
//       const response = await apiNoAuth.post('/login/verifyOTP', credentials);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const verifyOtpForResetPassword = createAsyncThunk(
  'register/verify_otp',
  async ({ empId, userType, enteredOtp }, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        `/verify-otp?empId=${empId}&userType=${userType}&enteredOtp=${enteredOtp}`,
        {} // An empty object for the POST request body, if no body data is needed
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitRequestForResetPassword = createAsyncThunk(
  'register/submit_reset_password_request',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await apiNoAuth.post(
        '/forgot-password',
        payload
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitRequestForChangePassword = createAsyncThunk(
  'register/submitRequestForChangePassword',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/change-password',
        payload
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendLoginEmail = createAsyncThunk(
  'register/resendLoginEmail',
  async ({ empId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`user/resendLoginEmail?empId=${empId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const intial = {
  isLoading: false,
  isLoggedIn: false,
  authData: {
    empId: 0,
    branch: '',
    name: '',
    designation: '',
    department: '',
    isActive: '',
    mobileNumber: ''
  },
  isError: false,
  errorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  resetOtpDetails: {
    empId: '',
    otp: '',
    mobileNo: '',
    resetToken: ''
  },
  applicationStatus: 'UP',
  isPingServerLoading: false,
  isPingServerSuccess: false,
  isPingServerFailed: false,
  isSendOtpForResetPasswordLoading: false,
  isSendOtpForResetPasswordSuccess: false,
  isSendOtpForResetPasswordFailed: false,
  sendOtpResponse: {
    mobileNumber: '',
    emailId: '',
    otp: ''
  },
  sendOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isVerifyOtpForResetPasswordLoading: false,
  isVerifyOtpForResetPasswordSuccess: false,
  isVerifyOtpForResetPasswordFailed: false,
  verifyOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSubmitResetPasswordLoading: false,
  isSubmitResetPasswordSuccess: false,
  isSubmitResetPasswordFailed: false,
  submitResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isSubmitChangePasswordLoading: false,
  isSubmitChangePasswordSuccess: false,
  isSubmitChangePasswordFailed: false,
  changePasswordStatus: '',
  submitChangePasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isReSendOtpForResetPasswordLoading: false,
  isReSendOtpForResetPasswordSuccess: false,
  isReSendOtpForResetPasswordFailed: false,
  reSendOtpForResetPasswordErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isResendEmailLoading: false,
  isResendEmailSuccess: false,
  isResendEmailFailed: false,
  resendEmailMessage: '',
  resendLoginEmailErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: intial,
  extraReducers: builder => {
    builder
      .addCase(logoutUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(logoutUser.fulfilled, state => {
        state.isLoading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        clearToken();
        (state.isLoading = false),
          (state.isLoggedIn = false),
          (state.isError = true),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(revertAll, () => {
        return intial;
      })
      .addCase(logoutAndClearToken, () => {
        clearToken();
        clearAuthData();
        clearMenuData();
        clearCwaMenuData();
        clearCwaAccessibleMenuData();
        return intial;
      })
      .addCase(clearLoginState, state => {
        (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(setAuthDetailsByLocalStorage, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.authData = { ...action.payload };
        // (state.authData.userId = Number(action.payload['userId'])),
        // (state.authData.role = action.payload['role']),
        // (state.authData.fullName = action.payload['fullName']),
        // (state.authData.mobileNo = action.payload['mobileNo']),
        // (state.authData.email = action.payload['email']);
      })
      // .addCase(pingServer.pending, state => {
      //   (state.isPingServerLoading = true), (state.isPingServerFailed = false);
      // })
      // .addCase(pingServer.fulfilled, (state, action) => {
      //   (state.isPingServerLoading = false),
      //     (state.isPingServerSuccess = true),
      //     (state.applicationStatus = action.payload?.status);
      // })
      // .addCase(pingServer.rejected, state => {
      //   (state.isPingServerLoading = false), (state.isPingServerFailed = true);
      // })
      .addCase(authenticateUser.pending, state => {
        (state.isLoading = true),
          (state.isError = false),
          (state.errorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        setTokenToApi(action.payload?.accessToken);
        clearToken();
        clearAuthData();
        setToken(action.payload?.accessToken);
        setMenuList(action.payload);
        setAuthData({
          empId: action.payload?.empId,
          branch: action.payload?.branch,
          name: action.payload?.name,
          designation: action.payload?.designation,
          department: action.payload?.department,
          isActive: action.payload?.isActive,
          mobileNumber: action.payload?.mobileNumber
        });
        setBasicDetails(action.payload?.userImage, action.payload?.name);
        state.isLoading = false;
        state.isLoggedIn = true;
        state.authData = { ...state.authData, ...action.payload };
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        (state.isLoggedIn = false),
          (state.isError = true),
          (state.isLoading = false),
          (state.errorContainer = {
            ...state.errorContainer,
            ...action.payload
          });
      })
      .addCase(sendOtpForResetPassword.pending, state => {
        (state.isSendOtpForResetPasswordLoading = true),
          (state.isSendOtpForResetPasswordSuccess = false),
          (state.isSendOtpForResetPasswordFailed = false),
          (state.sendOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(sendOtpForResetPassword.fulfilled, (state, action) => {
        (state.isSendOtpForResetPasswordLoading = false),
          (state.isSendOtpForResetPasswordSuccess = true);
        state.sendOtpResponse.mobileNumber = action.payload?.mobileNumber;
        state.sendOtpResponse.emailId = action.payload?.emailId;
        state.sendOtpResponse.otp = action.payload?.otp;
      })
      .addCase(sendOtpForResetPassword.rejected, (state, action) => {
        state.isSendOtpForResetPasswordLoading = false;
        state.isSendOtpForResetPasswordSuccess = false;
        state.isSendOtpForResetPasswordFailed = true;
        state.sendOtpForResetPasswordErrorContainer = {
          ...state.sendOtpForResetPasswordErrorContainer,
          ...action.payload
        };
      })
      .addCase(reSendOtpForResetPassword.pending, state => {
        (state.isReSendOtpForResetPasswordLoading = true),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = false),
          (state.reSendOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(reSendOtpForResetPassword.fulfilled, state => {
        (state.isReSendOtpForResetPasswordLoading = false),
          (state.isReSendOtpForResetPasswordSuccess = true);
      })
      .addCase(reSendOtpForResetPassword.rejected, (state, action) => {
        (state.isReSendOtpForResetPasswordLoading = false),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = true),
          (state.reSendOtpForResetPasswordErrorContainer = {
            ...state.reSendOtpForResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(setResetPasswordDetails, (state, action) => {
        state.resetOtpDetails = action.payload;
      })
      .addCase(verifyOtpForResetPassword.pending, state => {
        (state.isVerifyOtpForResetPasswordLoading = true),
          (state.isVerifyOtpForResetPasswordSuccess = false),
          (state.isVerifyOtpForResetPasswordFailed = false),
          (state.verifyOtpForResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(verifyOtpForResetPassword.fulfilled, (state, action) => {
        (state.isVerifyOtpForResetPasswordLoading = false),
          (state.isVerifyOtpForResetPasswordSuccess = true),
          (state.isSendOtpForResetPasswordSuccess = false);
        state.isReSendOtpForResetPasswordSuccess = false;
        state.resetOtpDetails.resetToken = action.payload?.accessToken;
        state.resetOtpDetails.mobileNo = action.payload?.mobileNumber;
      })
      .addCase(verifyOtpForResetPassword.rejected, (state, action) => {
        (state.isVerifyOtpForResetPasswordLoading = false),
          (state.isVerifyOtpForResetPasswordSuccess = false),
          (state.isVerifyOtpForResetPasswordFailed = true),
          (state.verifyOtpForResetPasswordErrorContainer = {
            ...state.verifyOtpForResetPasswordErrorContainer,
            ...action.payload
          });
      })
      .addCase(submitRequestForResetPassword.pending, state => {
        (state.isSubmitResetPasswordLoading = true),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = false),
          (state.submitResetPasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          }),
          (state.isSendOtpForResetPasswordSuccess = false);
      })
      .addCase(submitRequestForResetPassword.fulfilled, state => {
        (state.isSubmitResetPasswordLoading = false),
          (state.isSubmitResetPasswordSuccess = true);
      })
      .addCase(submitRequestForResetPassword.rejected, (state, action) => {
        (state.isSubmitResetPasswordLoading = false),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = true),
          (state.submitResetPasswordErrorContainer = {
            ...state.submitResetPasswordErrorContainer,
            ...action.payload
          });
      })

      .addCase(submitRequestForChangePassword.pending, state => {
        (state.isSubmitChangePasswordLoading = true),
          (state.isSubmitChangePasswordSuccess = false),
          (state.isSubmitChangePasswordFailed = false),
          (state.submitChangePasswordErrorContainer = {
            error: false,
            errorMessage: '',
            description: '',
            statusCode: 0
          });
      })
      .addCase(submitRequestForChangePassword.fulfilled, (state, action) => {
        (state.isSubmitChangePasswordLoading = false),
          (state.isSubmitChangePasswordSuccess = true);
        state.changePasswordStatus = action.payload;
      })
      .addCase(submitRequestForChangePassword.rejected, (state, action) => {
        (state.isSubmitChangePasswordLoading = false),
          (state.isSubmitChangePasswordSuccess = false),
          (state.isSubmitChangePasswordFailed = true),
          (state.submitChangePasswordErrorContainer = {
            ...state.submitChangePasswordErrorContainer,
            ...action.payload
          });
      })

      .addCase(clearOtpNotification, state => {
        state.isSendOtpForResetPasswordSuccess = false;
        state.isSendOtpForResetPasswordFailed = false;
        state.isReSendOtpForResetPasswordSuccess = false;
        state.isReSendOtpForResetPasswordFailed = false;
      })
      .addCase(clearAllNotification, state => {
        (state.isSendOtpForResetPasswordSuccess = false),
          (state.isSendOtpForResetPasswordFailed = false),
          (state.isReSendOtpForResetPasswordSuccess = false),
          (state.isReSendOtpForResetPasswordFailed = false),
          (state.isSubmitResetPasswordSuccess = false),
          (state.isSubmitResetPasswordFailed = false),
          (state.resetOtpDetails.empId = ''),
          (state.resetOtpDetails.mobileNo = ''),
          (state.resetOtpDetails.otp = ''),
          (state.resetOtpDetails.resetToken = ''),
          (state.isVerifyOtpForResetPasswordFailed = false),
          (state.isVerifyOtpForResetPasswordSuccess = false),
          (state.isResendEmailSuccess = false),
          (state.isResendEmailFailed = false),
          (state.resendEmailMessage = ''),
          (state.isSubmitChangePasswordFailed = false),
          (state.isSubmitChangePasswordSuccess = false),
          (state.changePasswordStatus = '');
      })
      .addCase(resendLoginEmail.pending, state => {
        state.isResendEmailLoading = true;
        state.isResendEmailSuccess = false;
        state.isResendEmailFailed = false;
      })
      .addCase(resendLoginEmail.fulfilled, (state, action) => {
        state.isResendEmailLoading = false;
        state.isResendEmailSuccess = true;
        state.resendEmailMessage = action.payload;
      })
      .addCase(resendLoginEmail.rejected, (state, action) => {
        state.isResendEmailLoading = false;
        state.isResendEmailSuccess = false;
        state.isResendEmailFailed = true;
        state.resendLoginEmailErrorContainer = {
          ...state.resendLoginEmailErrorContainer,
          ...action.payload
        };
      });
  }
});

export default authSlice.reducer;
