import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import UploadPhotoComponent from '../../components/UploadPhotoComponent';
import { colorPrimaryBlue } from '../../config/theme';
import AddressDetailsCustom from './AddressDetailsCustom';
import KYCDetailsCustom from './KYCDetailsCustom';
import EmploymentDetailsCustom from './EmploymentDetailsCustom';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCallValidation,
  setIsValid,
  setWaitExecution
} from '../../redux/reducers/connectorApprovalSlice';
import { getCityStateByPincode } from '../../redux/reducers/masterManagementSlice';

const LeadCoApplicant = ({ handleSetData, onClick, coapplicantDetails }) => {
  const { callValidation } = useSelector(state => state.connector);
  const { cityStateDetails } = useSelector(state => state.masters);
  const [tempState, setTempState] = useState('');
  const [tempIndex, setTempIndex] = useState('');
  const dispatch = useDispatch();
  const initialState = {
    name: '',
    // coAp1LastName: '',
    photo: '',
    isPhoto: 'true',
    file: '',
    mobileNumber: '',
    emailId: '',

    // Address Details
    sameAsCurrent: 'Yes',
    currentAddressLine1: '',
    currentAddressLine2: '',
    currentLandmark: '',
    currentPincode: '',
    currentCity: '',
    currentState: '',

    // Permanant Address
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentLandmark: '',
    permanentPincode: '',
    permanentCity: '',
    permanentState: '',

    // KYC Details
    panNumber: '',
    aadhaarNumber: '',
    passportNumber: '',
    voterId: '',

    // Employment Details
    employmentStatus: 'Salaried',
    constitutionType: '',
    employerOrBusinessName: '',
    employerOrBusinessAddress: '',
    // employerVoterId: '',
    pincode: '',
    city: '',
    state: '',
    employedSinceMonth: '',
    employedSinceYear: ''
  };
  const initialValidation = {
    nameVal: '',
    // coAp1PanNumberVal: '',
    mobileNumberVal: '',
    emailIdVal: '',

    // Address Details
    currentAddressLine1Val: '',
    currentAddressLine2Val: '',
    currentLandmarkVal: '',
    currentPincodeVal: '',
    currentCityVal: '',
    currentStateVal: '',

    //permanant address

    permanentAddressLine1Val: '',
    permanentAddressLine2Val: '',
    permanentLandmarkVal: '',
    permanentPincodeVal: '',
    permanentCityVal: '',
    permanentStateVal: '',

    // KYC Details
    panNumberVal: '',
    aadhaarNumberVal: '',
    passportNumberVal: '',
    voterIdVal: '',

    // Employment Details
    constitutionTypeVal: '',
    empConstitutionTypeVal: '',
    employerOrBusinessNameVal: '',
    employerOrBusinessAddressVal: '',
    employerVoterIdVal: '',
    pincodeVal: '',
    cityVal: '',
    stateVal: '',
    employedSinceMonthVal: '',
    employedSinceYearVal: ''
  };

  // const [coApplicantData, setCoApplicantData] = useState([
  //   { coapplicantId: 'ABC1', ...initialState }
  // ]);

  // const [validation, setValidation] = useState([
  //   { coapplicantId: 'ABC1', ...initialValidation }
  // ]);
  const [coApplicantData, setCoApplicantData] = useState([]);

  const [validation, setValidation] = useState([]);

  // For Pincode Set City and State
  useEffect(() => {
    if (tempState == 'currentPincode') {
      const updatedApplicants = [...coApplicantData];
      const updateValidation = [...validation];

      updatedApplicants[tempIndex] = {
        ...updatedApplicants[tempIndex],
        ['currentCity']: cityStateDetails?.city || '',
        ['currentState']: cityStateDetails?.state || ''
      };

      setCoApplicantData(updatedApplicants);
      handleSetData(updatedApplicants);
    } else if (tempState == 'permanentPincode') {
      const updatedApplicants = [...coApplicantData];
      const updateValidation = [...validation];

      updatedApplicants[tempIndex] = {
        ...updatedApplicants[tempIndex],
        ['permanentCity']: cityStateDetails?.city || '',
        ['permanentState']: cityStateDetails?.state || ''
      };

      setCoApplicantData(updatedApplicants);
      handleSetData(updatedApplicants);
    } else if (tempState == 'pincode') {
      const updatedApplicants = [...coApplicantData];
      const updateValidation = [...validation];

      updatedApplicants[tempIndex] = {
        ...updatedApplicants[tempIndex],
        ['city']: cityStateDetails?.city || '',
        ['state']: cityStateDetails?.state || ''
      };

      setCoApplicantData(updatedApplicants);
      handleSetData(updatedApplicants);
    }
    setTempIndex('');
    setTempState('');
  }, [cityStateDetails]);
  useEffect(() => {
    if (coapplicantDetails) {
      const updatedCoapplicantDetails = coapplicantDetails?.map(
        coapplicant => ({
          ...coapplicant,
          isPhoto: 'true'
        })
      );
      setCoApplicantData(updatedCoapplicantDetails);

      const validationObject =
        coapplicantDetails &&
        coapplicantDetails?.map(coapplicant => {
          const validationEntry = Object.keys(coapplicant).reduce(
            (acc, key) => {
              acc[`${key}Val`] = '';
              return acc;
            },
            {}
          );

          return validationEntry;
        });

      // Set validation data
      setValidation(validationObject);
    }
  }, [coapplicantDetails]);

  // For Add Applicant
  const addApplicant = () => {
    if (coApplicantData.length > 3) {
      return false;
    }
    let existingData = [...coApplicantData];
    let existingValidation = [...validation];
    const newApplicantId = 'ABC' + (coApplicantData.length + 1);
    setCoApplicantData([
      ...existingData,
      { coapplicantId: newApplicantId, ...initialState }
    ]);
    setValidation([
      ...existingValidation,
      { coapplicantId: newApplicantId, ...initialValidation }
    ]);
    handleSetData([
      ...existingData,
      { coapplicantId: newApplicantId, ...initialState }
    ]);
  };

  const handleSetImage = (value, index, file, isCapture) => {
    const updatedApplicants = [...coApplicantData];

    updatedApplicants[index] = {
      ...updatedApplicants[index],
      ['photo']: isCapture
        ? value.replace(/^data:image\/png;base64,/, '')
        : value,
      ['file']: file,
      ['isPhoto']: value ? 'false' : ''
    };

    setCoApplicantData(updatedApplicants);
    handleSetData(updatedApplicants);
  };

  const handleInputChange = (event, index, yearName, newValue) => {
    console.log(newValue, yearName, '--  ');
    const { name, value, checked } = event.target;

    if (name === 'currentPincode') {
      setTempState('currentPincode');
      setTempIndex(index);
      if (value.length === 6) {
        dispatch(getCityStateByPincode(value));
      }
    }
    if (name == 'permanentPincode') {
      setTempState('permanentPincode');
      setTempIndex(index);
      if (value.length === 6) {
        dispatch(getCityStateByPincode(value));
      }
    }
    if (name == 'pincode') {
      setTempState('pincode');
      setTempIndex(index);
      if (value.length === 6) {
        dispatch(getCityStateByPincode(value));
      }
    }
    let checkedValue = checked ? 'Yes' : 'No';
    let validationName =
      yearName === 'employedSinceYear' ? yearName + 'Val' : name + 'Val';
    const updatedApplicants = [...coApplicantData];
    const updateValidation = [...validation];

    updatedApplicants[index] = {
      ...updatedApplicants[index],
      // Change Year text  to dropdown
      [yearName === 'employedSinceYear' ? 'employedSinceYear' : name]:
        name === 'sameAsCurrent'
          ? checkedValue
          : yearName === 'employedSinceYear' // Change Year text  to dropdown
            ? newValue?.id
            : value
    };

    setCoApplicantData(updatedApplicants);

    updateValidation[index] = {
      ...updateValidation[index],
      [validationName]: ''
    };

    setValidation(updateValidation);
    handleSetData(updatedApplicants);
  };

  const handleRemove = index => {
    const tempApplicants = [...coApplicantData];
    const tempValidation = [...validation];

    tempApplicants.splice(index, 1);
    tempValidation.splice(index, 1);

    setCoApplicantData(tempApplicants);
    setValidation(tempValidation);
    handleSetData(tempApplicants);
  };

  const handleValidation = () => {
    let isValid = true;

    coApplicantData &&
      coApplicantData?.forEach((data, index) => {
        if (!data.name) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              nameVal: 'Please enter Name'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              nameVal: ''
            };
            return newState;
          });
        }

        if (!data.mobileNumber) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              mobileNumberVal: 'Please enter Mobile Number'
            };
            return newState;
          });
          isValid = false;
        } else if (!/^\d{10}$/.test(data.mobileNumber)) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              mobileNumberVal: 'Please enter a valid 10-digit Mobile Number'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              mobileNumberVal: ''
            };
            return newState;
          });
        }

        if (!data.emailId) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              emailIdVal: 'Please enter Email ID'
            };
            return newState;
          });
          isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailId)) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              emailIdVal: 'Please enter a valid Email ID'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              emailIdVal: ''
            };
            return newState;
          });
        }
        if (!data.panNumber) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              panNumberVal: 'Please enter PAN Number'
            };
            return newState;
          });
          isValid = false;
        } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(data.panNumber)) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              panNumberVal: 'Please enter a valid PAN Number (e.g., ABCDE1234F)'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              panNumberVal: ''
            };
            return newState;
          });
        }

        // Address Validation

        if (!data.currentAddressLine1) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentAddressLine1Val: 'Please enter address line'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentAddressLine1Val: ''
            };
            return newState;
          });
        }

        if (!data.currentAddressLine2) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentAddressLine2Val: 'Please enter address line'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentAddressLine2Val: ''
            };
            return newState;
          });
        }

        // if (!data.currentLandmark) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       currentLandmarkVal: 'Please enter LandMark'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       currentLandmarkVal: ''
        //     };
        //     return newState;
        //   });
        // }

        if (!data.currentPincode) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentPincodeVal: 'Please enter Pin Code'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentPincodeVal: ''
            };
            return newState;
          });
        }

        if (!data.currentCity) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentCityVal: 'Please enter city'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentCityVal: ''
            };
            return newState;
          });
        }

        if (!data.currentState) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentStateVal: 'Please enter state'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              currentStateVal: ''
            };
            return newState;
          });
        }

        // Permanant Address
        if (data.sameAsCurrent == 'No') {
          if (!data.permanentAddressLine1) {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentAddressLine1Val: 'Please enter address line'
              };
              return newState;
            });
            isValid = false;
          } else {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentAddressLine1Val: ''
              };
              return newState;
            });
          }

          if (!data.permanentAddressLine2) {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentAddressLine2Val: 'Please enter address line'
              };
              return newState;
            });
            isValid = false;
          } else {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentAddressLine2Val: ''
              };
              return newState;
            });
          }

          // if (!data.permanentLandmark) {
          //   setValidation(prevState => {
          //     const newState = [...prevState];
          //     newState[index] = {
          //       ...newState[index],
          //       permanentLandmarkVal: 'Please enter LandMark'
          //     };
          //     return newState;
          //   });
          //   isValid = false;
          // } else {
          //   setValidation(prevState => {
          //     const newState = [...prevState];
          //     newState[index] = {
          //       ...newState[index],
          //       permanentLandmarkVal: ''
          //     };
          //     return newState;
          //   });
          // }

          if (!data.permanentPincode) {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentPincodeVal: 'Please enter Pin Code'
              };
              return newState;
            });
            isValid = false;
          } else {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentPincodeVal: ''
              };
              return newState;
            });
          }

          if (!data.permanentCity) {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentCityVal: 'Please enter city'
              };
              return newState;
            });
            isValid = false;
          } else {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentCityVal: ''
              };
              return newState;
            });
          }

          if (!data.permanentState) {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentStateVal: 'Please enter state'
              };
              return newState;
            });
            isValid = false;
          } else {
            setValidation(prevState => {
              const newState = [...prevState];
              newState[index] = {
                ...newState[index],
                permanentStateVal: ''
              };
              return newState;
            });
          }
        }

        // KYC Details

        // if (!data.aadhaarNumber) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       aadhaarNumberVal: 'Please enter Aadhaar Number'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else if (
        //   !/^\d{12}$/.test(data.aadhaarNumber.replace(/\s/g, '')) &&
        //   !/^\d{4}\s\d{4}\s\d{4}$/.test(data.aadhaarNumber.replace(/\s/g, ''))
        // ) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       aadhaarNumberVal:
        //         'Please enter a valid Aadhaar Number (12 digits or in format XXXX XXXX XXXX)'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       aadhaarNumberVal: ''
        //     };
        //     return newState;
        //   });
        // }

        if (!data.passportNumber) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              passportNumberVal: ''
            };
            return newState;
          });
        } else if (
          !/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/.test(data.passportNumber)
        ) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              passportNumberVal:
                'Please enter a valid Passport Number (e.g., A1234567)'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              passportNumberVal: ''
            };
            return newState;
          });
        }
        if (!data.voterId) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              voterIdVal: ''
            };
            return newState;
          });
        } else if (!/^[A-Z]{3}[0-9]{7}$/.test(data.voterId)) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              voterIdVal: 'Please enter a valid Voter Id (e.g., ABC1234567)'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              voterIdVal: ''
            };
            return newState;
          });
        }

        // Employment Details
        if (!data.constitutionType) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              constitutionTypeVal: 'Please enter constitution type'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              constitutionTypeVal: ''
            };
            return newState;
          });
        }

        if (!data.employerOrBusinessName) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employerOrBusinessNameVal:
                data.employmentStatus == 'Salaried'
                  ? 'Please enter Employee Name'
                  : 'Please enter Business Name'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employerOrBusinessNameVal: ''
            };
            return newState;
          });
        }
        if (!data.employerOrBusinessAddress) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employerOrBusinessAddressVal:
                data.employmentStatus == 'Salaried'
                  ? 'Please enter Employee Address'
                  : 'Please enter Business Address'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employerOrBusinessAddressVal: ''
            };
            return newState;
          });
        }
        // if (!data.employerVoterId) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       employerVoterIdVal: ''
        //     };
        //     return newState;
        //   });
        // } else if (!/^[A-Z]{3}[0-9]{7}$/.test(data.employerVoterId)) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       employerVoterIdVal:
        //         'Please enter a valid Voter Id (e.g., ABC1234567)'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       employerVoterIdVal: ''
        //     };
        //     return newState;
        //   });
        // }
        if (!data.pincode) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              pincodeVal: 'Please enter Pin Code'
            };
            return newState;
          });
          isValid = false;
        } else if (!/^\d{6}$/.test(data.pincode)) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              pincodeVal: 'Please enter a valid 6-digit Pin Code'
            };
            return newState;
          });
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              pincodeVal: ''
            };
            return newState;
          });
        }
        // if (!data.city) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       cityVal: 'Please enter City'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       cityVal: ''
        //     };
        //     return newState;
        //   });
        // }

        // if (!data.state) {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       stateVal: 'Please enter State'
        //     };
        //     return newState;
        //   });
        //   isValid = false;
        // } else {
        //   setValidation(prevState => {
        //     const newState = [...prevState];
        //     newState[index] = {
        //       ...newState[index],
        //       stateVal: ''
        //     };
        //     return newState;
        //   });
        // }

        if (!data.employedSinceYear) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employedSinceYearVal: 'Please enter Year'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employedSinceYearVal: ''
            };
            return newState;
          });
        }

        if (!data.employedSinceMonth) {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employedSinceMonthVal: 'Please enter Month'
            };
            return newState;
          });
          isValid = false;
        } else {
          setValidation(prevState => {
            const newState = [...prevState];
            newState[index] = {
              ...newState[index],
              employedSinceMonthVal: ''
            };
            return newState;
          });
        }
      });

    return isValid;
  };

  useEffect(() => {
    if (callValidation) {
      handleSubmit();
    }
  }, [callValidation]);

  const handleSubmit = () => {
    let isValid = handleValidation();
    dispatch(setIsValid(isValid));
    dispatch(setCallValidation(false));
    dispatch(setWaitExecution(false));
  };
  return (
    <>
      <div>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Co-Applicant
        </Typography>
        {coApplicantData &&
          coApplicantData?.map((applicant, index) => {
            const basicDetailsVal =
              validation?.[index]?.nameVal ||
              validation[index]?.panNumberVal ||
              validation[index]?.mobileNumberVal ||
              validation[index]?.emailIdVal;

            const addressDetailsVal =
              validation[index]?.currentAddressLine1Val ||
              validation[index]?.currentAddressLine2Val ||
              validation[index]?.currentPincodeVal ||
              validation[index]?.permanentAddressLine1Val ||
              validation[index]?.permanentAddressLine2Val ||
              validation[index]?.permanentPincodeVal;
            const kycDetailsVal = validation[index]?.aadhaarNumberVal;
            const employmentDetailsVal =
              validation[index]?.empConstitutionTypeVal ||
              validation[index]?.employerOrBusinessNameVal ||
              validation[index]?.pincodeVal ||
              validation[index]?.employedSinceMonthVal ||
              validation[index]?.employedSinceYearVal;
            return (
              <Accordion key={applicant.id} style={{ marginBottom: '1rem' }}>
                <AccordionSummary
                  style={{
                    backgroundColor: '#F9F9F9',
                    borderColor: '#DBDBDB'
                    // marginBottom: '8px'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontWeight={600}>
                      Co-Applicant {index + 1}
                    </Typography>
                    <Typography
                      style={{
                        marginLeft: '1rem',
                        fontWeight: 400,
                        color: 'red',
                        fontSize: '1rem'
                      }}
                    >
                      {validation &&
                      validation?.[index] &&
                      Object.entries(validation?.[index])
                        .filter(([key]) => key !== 'coapplicantId')
                        .every(([_, value]) => value === '')
                        ? ''
                        : 'Please fill all fields'}
                    </Typography>
                  </div>

                  <Typography style={{ marginLeft: 'auto' }}>
                    <CancelIcon onClick={() => handleRemove(index)} />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Basic details accordion */}
                  <Accordion style={{ marginBottom: '16px', marginTop: '8px' }}>
                    <AccordionSummary
                      style={{
                        backgroundColor: '#F9F9F9',
                        borderColor: '#DBDBDB'
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        style={{ color: basicDetailsVal && 'red' }}
                      >
                        Basic Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={3} marginTop={0.3}>
                        <Grid item lg={4}>
                          <TextField
                            label="Name *"
                            name="name"
                            size="small"
                            fullWidth
                            value={applicant.name}
                            onChange={e => handleInputChange(e, index)}
                            InputLabelProps={{
                              shrink: applicant.name !== ''
                            }}
                            error={
                              validation[index] &&
                              validation?.[index].nameVal &&
                              Boolean(validation?.[index].nameVal)
                            }
                            helperText={
                              validation[index] &&
                              validation?.[index].nameVal &&
                              validation?.[index].nameVal
                            }
                          />
                        </Grid>

                        <Grid item lg={4}>
                          <TextField
                            label="Pan Number *"
                            name="panNumber"
                            size="small"
                            fullWidth
                            value={applicant.panNumber}
                            onChange={e => handleInputChange(e, index)}
                            InputLabelProps={{
                              shrink: applicant.panNumber !== ''
                            }}
                            error={
                              validation[index] &&
                              validation[index]?.panNumberVal &&
                              Boolean(validation[index].panNumberVal)
                            }
                            helperText={
                              validation[index] &&
                              validation[index]?.panNumberVal &&
                              validation[index]?.panNumberVal
                            }
                          />
                        </Grid>

                        <Grid item lg={4}>
                          <TextField
                            label="Mobile Number *"
                            name="mobileNumber"
                            size="small"
                            fullWidth
                            value={applicant.mobileNumber}
                            onChange={e => handleInputChange(e, index)}
                            InputLabelProps={{
                              shrink: applicant.mobileNumber !== ''
                            }}
                            error={
                              validation[index] &&
                              validation[index]?.mobileNumberVal &&
                              Boolean(validation[index].mobileNumberVal)
                            }
                            helperText={
                              validation[index] &&
                              validation[index]?.mobileNumberVal &&
                              validation[index]?.mobileNumberVal
                            }
                          />
                        </Grid>
                        <Grid item lg={4}>
                          <TextField
                            label="Email ID *"
                            name="emailId"
                            size="small"
                            fullWidth
                            value={applicant.emailId}
                            onChange={e => handleInputChange(e, index)}
                            InputLabelProps={{
                              shrink: applicant.emailId !== ''
                            }}
                            error={
                              validation[index] &&
                              validation[index]?.emailIdVal &&
                              Boolean(validation[index]?.emailIdVal)
                            }
                            helperText={
                              validation[index] &&
                              validation[index]?.emailIdVal &&
                              validation[index]?.emailIdVal
                            }
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Upload Photo Accordion */}
                  <Accordion style={{ marginBottom: '16px' }}>
                    <AccordionSummary
                      style={{
                        backgroundColor: '#F9F9F9',
                        borderColor: '#DBDBDB'
                      }}
                    >
                      <Typography fontWeight={500}>Upload Photo</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <UploadPhotoComponent
                        base64String={coApplicantData[index]?.photo}
                        handleSetImageData={handleSetImage}
                        Index={index}
                        phtotLimit={'2'}
                        contentType={coApplicantData[index]?.contentType}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Address details accordion */}
                  <Accordion style={{ marginBottom: '16px' }}>
                    <AccordionSummary
                      style={{
                        backgroundColor: '#F9F9F9',
                        borderColor: '#DBDBDB'
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        style={{ color: addressDetailsVal && 'red' }}
                      >
                        Address Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AddressDetailsCustom
                        applicantData={coApplicantData[index]}
                        Index={index}
                        handleOnChange={handleInputChange}
                        validationData={validation[index]}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* KYC Details */}
                  <Accordion style={{ marginBottom: '16px' }}>
                    <AccordionSummary
                      style={{
                        backgroundColor: '#F9F9F9',
                        borderColor: '#DBDBDB'
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        style={{ color: kycDetailsVal && 'red' }}
                      >
                        KYC Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <KYCDetailsCustom
                        applicantData={coApplicantData[index]}
                        Index={index}
                        handleOnChange={handleInputChange}
                        validationData={validation[index]}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Employment Details */}
                  <Accordion style={{ marginBottom: '16px' }}>
                    <AccordionSummary
                      style={{
                        backgroundColor: '#F9F9F9',
                        borderColor: '#DBDBDB'
                      }}
                    >
                      <Typography
                        fontWeight={500}
                        style={{ color: employmentDetailsVal && 'red' }}
                      >
                        Employment Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <EmploymentDetails formik={formik} /> */}
                      <EmploymentDetailsCustom
                        applicantData={coApplicantData[index]}
                        Index={index}
                        handleOnChange={handleInputChange}
                        validationData={validation[index]}
                      />
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            );
          })}

        <Divider style={{ marginBottom: '12px' }} />
        <div
          style={{
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            sx={{
              backgroundColor:
                coApplicantData.length > 3 ? '#B2B2B2' : colorPrimaryBlue,
              color: 'white',
              fontSize: 'small',
              fontWeight: '600',
              padding: '12px',
              '&:hover': {
                backgroundColor:
                  coApplicantData.length > 3 ? '#B2B2B2' : colorPrimaryBlue,
                color: 'white'
              },
              cursor: coApplicantData.length > 3 ? 'not-allowed' : 'pointer'
            }}
            onClick={() => addApplicant()}
          >
            ADD CO-APPLICANT
          </Button>
        </div>
      </div>
    </>
  );
};

export default LeadCoApplicant;
