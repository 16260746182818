import {
  Button,
  Divider,
  Grid,
  Typography,
  Card,
  CardMedia,
  Box,
  CircularProgress
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import {
  getCWAConnectorAuditLogs,
  getCWAConnectorDetails
} from '../redux/reducers/connectorApprovalSlice';
import CustomDataTable from '../components/CustomDataTable';
import moment from 'moment';
import { colorPrimaryBlue } from '../config/theme';
import SingleDocUpload from '../forms/CWAConnectorComponents/SingleDocUpload';
import dayjs from 'dayjs';
import { IMG_PERSON_AVTAR_SMALL } from '../utils/imageUrls';
import { api } from '../config/api';

const ExpandableRow = ({ details }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const logs = details.split('~');
  const displayLogs = isExpanded ? logs : logs.slice(0, 1);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        {displayLogs.map((log, index) => (
          <Typography
            key={index}
            variant="body2"
            color={colorPrimaryBlue}
            sx={{ fontWeight: '600' }}
            gutterBottom
          >
            {log}
          </Typography>
        ))}
        <span>
          {logs.length > 1 && (
            <Button
              onClick={handleToggle}
              size="small"
              style={{ textDecoration: 'underline' }}
            >
              {isExpanded ? '...Less' : '...More'}
            </Button>
          )}
        </span>
      </div>
    </>
  );
};

function AdminConnectorViewAuditLog() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialValues, setInitalValues] = useState({
    page: 0,
    limit: 10,
    searchFilter: ''
  });

  const {
    cwaConnectorAuditLogData,
    cwaConnectorDetails,
    isCWAConnectorDetailsLoading
  } = useSelector(state => state.connector);

  const connectorId = location.state.connectorId;
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  useEffect(() => {
    const titleOrActiveMenu = 'Connector Approval';
    document.title = createTitle(titleOrActiveMenu);
    dispatch(setActiveMenu(titleOrActiveMenu));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      cwaConnectorDetails?.contactDetails?.photo &&
      cwaConnectorDetails?.contactDetails?.contentType
    ) {
      // const dataUrl = `data:image/png;base64,${cwaConnectorDetails?.contactDetails?.photo}`;
      // setSelectedPhoto(dataUrl);
      const fileUrl = cwaConnectorDetails?.contactDetails?.photo;
      const contentType = cwaConnectorDetails?.contactDetails?.contentType;
      handlePhotoFromAPI(fileUrl, contentType);
    }
  }, [cwaConnectorDetails]);

  const handlePhotoFromAPI = async (fileUrl, contentType) => {
    try {
      const response = await api.get(
        `view?fileUrl=${fileUrl}&contentType=${contentType}`,
        {
          responseType: 'blob'
        }
      );

      if (response.data) {
        const fileBlob = response.data;
        const fileURL = URL.createObjectURL(fileBlob);
        setSelectedPhoto(fileURL);
      }
    } catch (error) {
      return error.response.data;
    }
  };

  useEffect(() => {
    dispatch(getCWAConnectorDetails({ connectorId }));
  }, [connectorId]);

  useEffect(() => {
    dispatch(
      getCWAConnectorAuditLogs({
        payload: {
          connectorId: connectorId,
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter
        }
      })
    );
  }, [initialValues]);

  const basicDetailsGridItems = useMemo(() => {
    const items = [
      {
        label: 'Connector Code',
        value: cwaConnectorDetails?.connectorId || '-'
      },
      {
        label: 'Name',
        value: cwaConnectorDetails?.name || '-'
      },
      {
        label: 'Constitution Type',
        value: cwaConnectorDetails?.constitution?.name || '-'
      },
      ...(cwaConnectorDetails?.constitution?.id === 6
        ? [
            {
              label: 'Other Constitution Type',
              value: cwaConnectorDetails?.otherConstitution
                ? cwaConnectorDetails?.otherConstitution
                : '-'
            }
          ]
        : []),
      {
        label: 'Profile',
        value: cwaConnectorDetails?.profile?.name || '-'
      },
      {
        label: 'Connector Type',
        value:
          cwaConnectorDetails?.connectorType === 'S'
            ? 'Special'
            : cwaConnectorDetails?.connectorType === 'C'
              ? 'Common'
              : '-'
      },
      ...(cwaConnectorDetails?.profile?.id === 5
        ? [
            {
              label: 'Other Profile',
              value: cwaConnectorDetails?.otherProfile
                ? cwaConnectorDetails?.otherProfile
                : '-'
            }
          ]
        : [])
    ];

    // Add conditional items based on constitution
    if (cwaConnectorDetails?.constitution?.id === 1) {
      items.push({
        label: 'Date of Birth',
        value: cwaConnectorDetails?.contactDetails?.dateOfBirth
          ? dayjs(cwaConnectorDetails?.contactDetails?.dateOfBirth).format(
              'DD/MM/YYYY'
            )
          : '-'
      });
    } else {
      items.push(
        {
          label: 'Date of Registration',
          value: cwaConnectorDetails?.contactDetails?.dateOfRegistration
            ? dayjs(
                cwaConnectorDetails?.contactDetails?.dateOfRegistration
              ).format('DD/MM/YYYY')
            : '-'
        },
        {
          label: 'Date of Incorporation',
          value: cwaConnectorDetails?.contactDetails?.dateOfIncorporation
            ? dayjs(
                cwaConnectorDetails?.contactDetails?.dateOfIncorporation
              ).format('DD/MM/YYYY')
            : '-'
        }
      );
    }

    return items;
  }, [cwaConnectorDetails]);

  const residenceDetailsGridItems = useMemo(
    () => [
      {
        label: 'Telephone Number',
        value: cwaConnectorDetails?.contactDetails?.telephone
          ? cwaConnectorDetails?.contactDetails?.telephone
          : '-'
      },
      {
        label: 'Mobile Number',
        value: cwaConnectorDetails?.mobile ? cwaConnectorDetails?.mobile : '-'
      },
      {
        label: 'Email ID',
        value: cwaConnectorDetails?.contactDetails?.email
          ? cwaConnectorDetails?.contactDetails?.email
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const bizDetailsGridItems = useMemo(
    () => [
      {
        label: 'Business Mobile Number',
        value: cwaConnectorDetails?.bbDetails?.businessMobile
          ? cwaConnectorDetails?.bbDetails?.businessMobile
          : '-'
      },
      {
        label: 'Business Email ID',
        value: cwaConnectorDetails?.bbDetails?.businessEmail
          ? cwaConnectorDetails?.bbDetails?.businessEmail
          : '-'
      },
      {
        label: 'Business since(years)',
        value: cwaConnectorDetails?.bbDetails?.noOfYearsInBusiness
          ? cwaConnectorDetails?.bbDetails?.noOfYearsInBusiness
          : '-'
      },
      {
        label: 'Business Place',
        value: cwaConnectorDetails?.bbDetails?.businessPlace
          ? cwaConnectorDetails?.bbDetails?.businessPlace
          : '-'
      },
      {
        label: 'PAN Number',
        value: cwaConnectorDetails?.panNumber
          ? cwaConnectorDetails?.panNumber
          : '-'
      },
      {
        label: 'GST Number',
        value: cwaConnectorDetails?.bbDetails?.gstNumber
          ? cwaConnectorDetails?.bbDetails?.gstNumber
          : '-'
      },
      {
        label: 'Is your firm registered as MSME?',
        value: cwaConnectorDetails?.isRegisteredMsme ? 'Yes' : 'No'
      }
    ],
    [cwaConnectorDetails]
  );

  const bankDetailsGridItems = useMemo(
    () => [
      {
        label: 'Bank Name',
        value: cwaConnectorDetails?.bbDetails?.bankName
          ? cwaConnectorDetails?.bbDetails?.bankName
          : '-'
      },
      {
        label: 'Branch',
        value: cwaConnectorDetails?.bbDetails?.bankBranch
          ? cwaConnectorDetails?.bbDetails?.bankBranch
          : '-'
      },
      {
        label: 'Address',
        value: cwaConnectorDetails?.bbDetails?.bankBranchAddress
          ? cwaConnectorDetails?.bbDetails?.bankBranchAddress
          : '-'
      },
      {
        label: 'Account Number',
        value: cwaConnectorDetails?.bbDetails?.accountNumber
          ? cwaConnectorDetails?.bbDetails?.accountNumber
          : '-'
      },
      {
        label: 'Account Type',
        value: cwaConnectorDetails?.bbDetails?.bankAccountType
          ? cwaConnectorDetails?.bbDetails?.bankAccountType
          : '-'
      },
      {
        label: 'IFSC Code',
        value: cwaConnectorDetails?.bbDetails?.ifscCode
          ? cwaConnectorDetails?.bbDetails?.ifscCode
          : '-'
      },
      {
        label: 'MICR Code',
        value: cwaConnectorDetails?.bbDetails?.micrCode
          ? cwaConnectorDetails?.bbDetails?.micrCode
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const nomineeDetailsGridItems = useMemo(
    () => [
      {
        label: 'Name of Nominee',
        value: cwaConnectorDetails?.bbDetails?.nomineeName
          ? cwaConnectorDetails?.bbDetails?.nomineeName
          : '-'
      },
      {
        label: 'Relationship with Nominee',
        value: cwaConnectorDetails?.bbDetails?.nomineeRelation
          ? cwaConnectorDetails?.bbDetails?.nomineeRelation
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const employeeMappingGridItems = useMemo(
    () => [
      {
        label: 'Employee Mapping',
        value: cwaConnectorDetails?.employeeId?.name
          ? cwaConnectorDetails?.employeeId?.name
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const cwaConnectorAuditLogColumns = [
    {
      uinqueKey: 'id',
      id: 'action',
      label: 'Action',
      marginLeft: '16px'
      // width: '150px'
      //   sortable: true
    },
    {
      id: 'performedBy',
      label: 'Performed By'
    },
    {
      id: 'performedOn',
      label: 'Performed On',
      // width: '140px',
      render: row => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '24px'
              }}
            >
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'details',
      label: 'Details',
      render: row => {
        return <ExpandableRow details={row.details} />;
      }
    }
  ];

  const handleSetDocument = () => {};
  const handleClick = () => {
    window.history.back();
  };
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          View Audit Logs
        </Typography>
        {isCWAConnectorDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isCWAConnectorDetailsLoading && (
          <>
            <div style={{ marginBottom: '12px' }}>
              {/* Upload photo */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  fontSize={'18px'}
                  color={colorPrimaryBlue}
                  fontWeight={600}
                >
                  Uploaded Photo
                </Typography>
                <Card
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    mt: 2,
                    mb: 2
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      height: '170px',
                      width: '200px',
                      maxHeight: '60mm',
                      maxWidth: '60mm'
                    }}
                    image={
                      selectedPhoto ? selectedPhoto : IMG_PERSON_AVTAR_SMALL
                    }
                    alt="Selected Photo"
                  />
                </Card>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Basic Details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Basic Details
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {basicDetailsGridItems?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Residence details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Residence Details
                </Typography>
                <Grid lg={12} sx={{ marginBottom: '24px' }}>
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    Resident Address
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {cwaConnectorDetails?.contactDetails?.residentialAddress ||
                      '-'}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {residenceDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Business details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Business Details
                </Typography>
                <Grid lg={12} sx={{ marginBottom: '24px' }}>
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    Business Address
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {cwaConnectorDetails?.bbDetails?.businessAddress || '-'}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {bizDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      PAN Upload
                    </Typography>
                    <div
                      style={{
                        border: cwaConnectorDetails?.connectorDocumentsMap
                          ?.pancard
                          ? '1px solid #00000029'
                          : '',
                        borderRadius: 5,
                        alignSelf: 'center'
                      }}
                    >
                      {cwaConnectorDetails?.connectorDocumentsMap?.pancard ? (
                        <SingleDocUpload
                          documentsArr={
                            cwaConnectorDetails?.connectorDocumentsMap?.pancard
                              ? [
                                  cwaConnectorDetails?.connectorDocumentsMap
                                    ?.pancard
                                ]
                              : []
                          }
                          handleSetData={handleSetDocument}
                          onlyView={true}
                          title={'Pan Upload'}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {'Document not found'}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      MSME Upload
                    </Typography>
                    <div
                      style={{
                        border: cwaConnectorDetails?.connectorDocumentsMap
                          ?.msmeCertificate
                          ? '1px solid #00000029'
                          : '',
                        borderRadius: 5,
                        alignSelf: 'center'
                      }}
                    >
                      {cwaConnectorDetails?.connectorDocumentsMap
                        ?.msmeCertificate ? (
                        <SingleDocUpload
                          documentsArr={
                            cwaConnectorDetails?.connectorDocumentsMap
                              ?.msmeCertificate
                              ? [
                                  cwaConnectorDetails?.connectorDocumentsMap
                                    ?.msmeCertificate
                                ]
                              : []
                          }
                          handleSetData={handleSetDocument}
                          onlyView={true}
                          title={'MSME Upload'}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {'Document not found'}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Bank Details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Bank Details
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {bankDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Nominee details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Nominee Details (In case of Individual)
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {nomineeDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Employee mapping */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Employee Mapping
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {employeeMappingGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Upload documents */}
              <div style={{ marginBottom: '22px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Uploaded Document
                </Typography>
                <Grid container spacing={3}>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    {cwaConnectorDetails?.connectorDocumentsMap?.document ? (
                      <SingleDocUpload
                        documentsArr={
                          cwaConnectorDetails?.connectorDocumentsMap?.document
                            ? [
                                cwaConnectorDetails?.connectorDocumentsMap
                                  ?.document
                              ]
                            : []
                        }
                        handleSetData={handleSetDocument}
                        onlyView={true}
                        title={'Pan Upload'}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {'Document not found'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>

            <div style={{ borderBottom: '1px dashed #999999' }}></div>
            <div style={{ marginTop: '2rem' }}>
              <div style={{ marginBottom: '8px' }}>
                <CustomDataTable
                  columns={cwaConnectorAuditLogColumns}
                  data={
                    cwaConnectorAuditLogData.logList.length > 0
                      ? cwaConnectorAuditLogData.logList
                      : []
                  }
                  onPageChange={val => {
                    setInitalValues({ ...initialValues, page: val });
                  }}
                  onRowsPerPageChange={val => {
                    setInitalValues({ ...initialValues, limit: val, page: 0 });
                  }}
                  defaultPage={initialValues.page}
                  defaultRowsPerPage={initialValues.limit}
                  totalCount={cwaConnectorAuditLogData?.totalCount}
                  isShowPagination={true}
                />
              </div>
              <Divider />
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#335DAD',
                    marginTop: '12px',
                    width: '120px'
                  }}
                  onClick={handleClick}
                >
                  OK
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AdminConnectorViewAuditLog;
