export const IMG_SPLASH_LOGO = '/images/splash_logo.png';

export const IMG_SPLASH_LOGO_LEFT_TOP = '/images/splash_left_top.svg';

export const IMG_SPLASH_LOGO_RIGHT_BOTTOM = '/images/splash_right_bottom.svg';

export const IMG_SPLASH_FULL_LOGO = '/images/tbc_splash_full_logo.png';

export const IMG_ERROR_404 = '/images/error_404.gif';

export const IMG_ERROR_403 = '/images/error_403.gif';

export const IMG_PERSON_AVTAR = '/images/person_preview.png';

export const IMG_PERSON_AVTAR_SMALL =
  '/images/person.jpg'; /** For Profile Picture */

export const IMG_PDF_PREVIEW = '/images/pdf_preview.png';

export const IMG_IMAGE_PREVIEW = '/images/image_preview.svg';

export const IMG_SUCCESS = '/images/success.png';

export const IMG_FAILED = '/images/failed.png';

export const IMG_LOGIN_BG = '/images/login.png';

export const IMG_OTP_ICON = '/images/otp_icon.svg';

export const ICON_USERS = '/images/icon-users.png';

export const ICON_PRODUCTS = '/images/icon-products.png';

export const ICON_CONNECTORS = '/images/icon-connectors.png';

export const ICON_UPLOAD = '/images/upload_icon.png';

export const VIEW_AUDIT_LOG = '/images/view_audit_log.svg';

export const HOME_LOAN_ICON = '/images/HL_Icon.svg';

export const GENERAL_INSURANCE_ICON = '/images/General_Insurance_Icon.svg';

export const LIFE_INSURANCE_ICON = '/images/Life_Insurance_Icon.svg';

export const FIXED_DEPOSIT_ICON = '/images/Fixed_Deposit_Icon.svg';

export const MUTUAL_FUND_ICON = '/images/Mutual_Fund_Icon.svg';

export const IDBI_LOANS_ICON = '/images/IDBI_Loan_Icon.svg';

export const INCENTIVE_ICON = '/images/Incentive_Icon.svg';
