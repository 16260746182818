import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';

const ModalDialog = ({
  fullwidth,
  children,
  isShowTitle = false,
  titleName = '',
  size = 'xs',
  handleOnClose,
  isShowCloseIcon
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnCloseClick = () => {
    handleOnClose();
  };
  return (
    <Dialog
      fullWidth={fullwidth}
      maxWidth={size}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      className="custom-dialog"
    >
      {isShowTitle && (
        <DialogTitle id="max-width-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={1}
          >
            <Typography
              variant="body1"
              fontSize={18}
              fontWeight={600}
              marginRight={1} // Adjust margin as needed
            >
              {titleName}
            </Typography>
            {isShowCloseIcon && (
              <>
                <img
                  src="/images/cross_sign.svg"
                  alt="crossSign"
                  style={{ height: '1rem', cursor: 'pointer' }}
                  onClick={handleOnCloseClick}
                />
              </>
            )}
          </Box>
        </DialogTitle>
      )}

      <DialogContent className="MuiDialogContentRootc">
        {children}
      </DialogContent>
    </Dialog>
  );
};

ModalDialog.propTypes = {
  fullwidth: PropTypes.bool,
  children: PropTypes.any,
  isShowTitle: PropTypes.bool,
  titleName: PropTypes.string
};

export default ModalDialog;
