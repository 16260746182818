import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux';
import {
  clearAllNotification,
  setResetPasswordDetails
} from '../../../redux/reducers/authSlice';

function ShowBackToLoginButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackToLogin = () => {
    dispatch(setResetPasswordDetails({ mobileNo: null, otp: null }));
    dispatch(clearAllNotification());
    // navigate('/login');
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      marginTop={3}
    >
      <Grid>
        <Button
          onClick={handleBackToLogin}
          size="small"
          startIcon={<ArrowBackIcon />}
        >
          Back to Login
        </Button>
      </Grid>
    </Grid>
  );
}

export default ShowBackToLoginButton;
