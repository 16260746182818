import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { api } from '../config/api';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { styled } from '@mui/material/styles';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { createTitle } from '../utils/utils';
import { useDispatch } from 'react-redux';

const transformEmployeeData = data => {
  return data.map(employee => ({
    id: employee.empId.toString(),
    label: `${employee.name} (${employee.designation})`,
    children: transformEmployeeData(employee.subordinates)
  }));
};

const StyledTreeView = styled(RichTreeView)({
  //   '&.MuiRichTreeView-root': {
  //     '--TreeView-itemChildrenIndentation': '20px !important'
  //   },
  '& .MuiTreeItem-root': {
    marginLeft: '16px', // Adjust the value as needed
    backgroundColor: '#F9F9F9'
  },
  '& .MuiTreeItem-content': {
    paddingLeft: '20px' // Additional padding for content
  },

  '& .MuiTreeItem-iconContainer': {
    color: 'black'
  }
});

const AdminHierarchy = () => {
  const [hierarchyData, setHierarchyData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = createTitle('Hierarchy Management');
    dispatch(setActiveMenu('Hierarchy Management'));
    getHierarchy();
  }, []);

  const getHierarchy = async () => {
    try {
      const response = await api.get('allHeirarchy');
      const transformedData = transformEmployeeData(response.data);
      setHierarchyData(transformedData);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  return (
    <div
      style={{
        width: 'full',
        margin: '20px',
        backgroundColor: 'white',
        borderRadius: '12px',
        minHeight: '80vh'
      }}
    >
      <div
        style={{
          padding: '2rem'
        }}
      >
        <Typography
          // color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
          marginX={'16px'}
        >
          Hierarchy
        </Typography>
        <Box>
          <StyledTreeView
            items={hierarchyData}
            renderLabel={item => (
              <Typography variant="body1" fontWeight="bold">
                {item.label}
              </Typography>
            )}
          />
        </Box>
      </div>
    </div>
  );
};

export default AdminHierarchy;
