import {
  Autocomplete,
  Button,
  Card,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createTitle,
  formatAmountDecimalInINR,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLeadDocuments,
  getAreaOffice,
  getAssistingPerson,
  getInsuranceCompany,
  getInsuranceProduct,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getSourcePerson,
  updateLeadDetails
} from '../../redux/reducers/leadSlice';
import SnackBarBox from '../../components/SnackBarBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
  BUSINESS_TYPES,
  calculateTotalPremium,
  POLICY_MODE_TYPES
} from '../../utils/constant';
import LeadSourceDetails from '../../forms/CWALeadComponents/LeadSourceDetails';
import UploadDocumentsLead from '../../forms/CWALeadComponents/uploadDocumentsLead';
import ConfirmBox from '../../components/ConfirmBox';
import moment from 'moment';

function CWAGILeadEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    isGetLeadDetailsLoading,
    isGetLeadDocLoading,
    leadUpdateErrorContainer
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;
  const businessTypeId = location?.state?.businessTypeId;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const newBusinessInitialValues = {
    productId: '',

    //Basic Details
    applicantId: '',
    leadId: '',
    name: '',
    emailId: '',
    phoneNo: '',
    panName: '',
    panNumber: '',
    dob: '',
    businessType: null,

    //   lead source
    sourcePerson: null,
    assistantPerson: null,
    leadSource: null,
    areaOffice: null,

    //   policy details
    insuranceCompany: null,
    insuranceProduct: null,
    policyNo: '',
    policyHolderName: '',
    dateOfPolicy: '',
    chequeRtgsNeftDate: '',
    chequeRtgsNeftAmount: '',
    premiumAmount: '',
    taxAmount: '',
    terroristPremium: '',
    totalPremium: '',
    gst: ''
  };
  const renewalBusinessInitialValues = {
    productId: '',

    //Basic Details
    applicantId: '',
    leadId: '',
    name: '',
    emailId: '',
    phoneNo: '',
    panName: '',
    panNumber: '',
    dob: '',
    businessType: null,

    // Policy Details
    policyNo: '',
    policyMode: POLICY_MODE_TYPES[0],
    dueFrom: '',
    dueTo: '',
    dateOfDeposit: '',
    premiumAmount: '',
    taxAmount: '',
    totalPremium: ''
  };

  const newBusinessValidationSchema = Yup.object().shape({
    // Basic Details

    // applicantId: Yup.string().required('Applicant ID is required'),
    // leadId: Yup.string().required('Lead ID is required'),
    // name: Yup.string().required('Name is required'),
    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    // phoneNo: Yup.string()
    //   .matches(/^[0-9]+$/, 'Phone number must be numeric')
    //   .required('Phone number is required'),
    // businessType: Yup.object({
    //   id: Yup.string().required('Business type is required')
    // }).required('Business type is required'),
    // dob: Yup.string().required('Date of Birth is required'),
    // panNumber: Yup.string()
    //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Enter valid PAN number')
    //   .required('PAN card number is required'),

    //lead Source
    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area Office is required')
    }).required('Area Office is required'),

    // policy details
    insuranceCompany: Yup.object({
      id: Yup.string().required('Company is required')
    }).required('Company is required'),
    insuranceProduct: Yup.object({
      id: Yup.string().required('Product is required')
    }).required('Product is required'),
    policyNo: Yup.string().required('Policy number is required'),
    policyHolderName: Yup.string().required('Policy holder name is required'),
    dateOfPolicy: Yup.string().required('Policy starting date is required'),
    chequeRtgsNeftDate: Yup.string().required(
      'Cheque/RTGS/NEFT date is required'
    ),
    chequeRtgsNeftAmount: Yup.string()
      .matches(/^[0-9]+$/, 'Cheque/RTGS/NEFT Amount must be numeric')
      .required('Cheque/RTGS/NEFT Amount is required'),
    premiumAmount: Yup.string()
      .matches(/^[0-9]+$/, 'Premium Amount must be numeric')
      .required('Premium Amount is required'),
    terroristPremium: Yup.string()
      .matches(/^[0-9]+$/, 'Terrorist Amount must be numeric')
      .required('Terrorist Amount is required')
  });

  const renewalBusinessValidationSchema = Yup.object().shape({
    // Basic Details

    // applicantId: Yup.string().required('Applicant ID is required'),
    // leadId: Yup.string().required('Lead ID is required'),
    // name: Yup.string().required('Name is required'),
    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    // phoneNo: Yup.string()
    //   .matches(/^[0-9]+$/, 'Phone number must be numeric')
    //   .required('Phone number is required'),
    // businessType: Yup.object({
    //   id: Yup.string().required('Business type is required')
    // }).required('Business type is required'),
    // dob: Yup.string().required('Date of Birth is required'),
    // panNumber: Yup.string().required('PAN card number is required'),

    // policy details
    policyNo: Yup.string().required('Policy number is required'),
    policyMode: Yup.object({
      id: Yup.string().required('Mode is required')
    }).required('Mode is required'),
    dueFrom: Yup.string().required('Due From is required'),
    dueTo: Yup.string().required('Due To is required'),
    dateOfDeposit: Yup.string().required('Date of Deposit is required'),
    premiumAmount: Yup.string()
      .matches(/^[0-9]+$/, 'Amount must be numeric')
      .required('Premium Amount is required'),
    taxAmount: Yup.string()
      .matches(/^[0-9]+$/, 'Amount must be numeric')
      .required('Tax Amount is required'),
    totalPremium: Yup.string()
  });

  const formik = useFormik({
    initialValues:
      businessTypeId == 'renewalBusiness'
        ? renewalBusinessInitialValues
        : newBusinessInitialValues,
    validationSchema:
      businessTypeId == 'renewalBusiness'
        ? renewalBusinessValidationSchema
        : newBusinessValidationSchema,
    onSubmit: values => {
      handleUpdate();
    }
  });

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getLeadDetails({
        leadId,
        customerId,
        productId,
        businessType: businessTypeId
      })
    );
    dispatch(getLeadDocuments({ leadId, customerId, productId }));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadSource({}));
    dispatch(getAreaOffice({}));
    dispatch(getInsuranceCompany());
  }, [leadId, customerId]);

  useEffect(() => {
    if (isFirstTime) {
      if (businessTypeId == 'newBusiness') {
        dispatch(
          getAssistingPerson({
            sourcePersonId: leadDetails?.leadDetails?.sp?.id || 0
          })
        );
        dispatch(
          getInsuranceProduct({
            companyId: leadDetails?.leadDetails?.insuranceCompany?.id || 0
          })
        );
      }
    }
  }, [
    leadDetails?.leadDetails?.sp,
    leadDetails?.leadDetails?.insuranceCompany
  ]);

  useEffect(() => {
    let businessType = BUSINESS_TYPES.find(
      cc => cc.id === leadDetails?.businessType
    );
    let modeType = POLICY_MODE_TYPES.find(
      cc => cc.id === leadDetails?.renewalLeadDetails?.mode
    );
    if (businessTypeId == 'newBusiness') {
      formik.setValues({
        ...formik.values,
        productId: leadDetails?.leadDetails?.productMaster?.id,
        applicantId: leadDetails?.customerDetails?.customerId,
        leadId: leadDetails?.leadDetails?.applicationId || '',
        name: leadDetails?.customerDetails?.name || '',
        emailId: leadDetails?.customerDetails?.emailId || '',
        phoneNo: leadDetails?.customerDetails?.mobileNumber || '',
        panName: leadDetails?.customerDetails?.name || '',
        panNumber: leadDetails?.customerDetails?.panNumber || '',
        dob: leadDetails?.customerDetails?.dob || '',
        businessType: businessType ? businessType : null,

        sourcePerson: leadDetails?.leadDetails?.sp || null,
        assistantPerson: leadDetails?.leadDetails?.ap || null,
        leadSource: leadDetails?.leadDetails?.leadSource || null,
        areaOffice: leadDetails?.leadDetails?.areaOffice || null,

        //   policy details
        insuranceCompany: leadDetails?.leadDetails?.insuranceCompany || null,
        insuranceProduct: leadDetails?.leadDetails?.insuranceProduct || null,
        policyNo: leadDetails?.leadDetails?.policyNo || '',
        policyHolderName: leadDetails?.customerDetails?.name || '',
        dateOfPolicy: leadDetails?.leadDetails?.dateOfPolicy || '',
        chequeRtgsNeftDate: leadDetails?.leadDetails?.chequeRtgsNeftDate || '',
        chequeRtgsNeftAmount:
          leadDetails?.leadDetails?.chequeRtgsNeftAmount || '',
        premiumAmount: leadDetails?.leadDetails?.premiumAmount || '',
        taxAmount: leadDetails?.leadDetails?.tax || '',
        totalPremium: leadDetails?.leadDetails?.totalPremium,
        terroristPremium: leadDetails?.leadDetails?.terroristPremium || '',
        gst: leadDetails?.gst
      });
    } else {
      formik.setValues({
        ...formik.values,
        applicantId: leadDetails?.customerDetails?.customerId,
        leadId: leadDetails?.renewalLeadDetails?.applicationId || '',
        businessType: businessType ? businessType : null,
        name: leadDetails?.customerDetails?.name || '',
        emailId: leadDetails?.customerDetails?.emailId || '',
        phoneNo: leadDetails?.customerDetails?.mobileNumber || '',
        panNumber: leadDetails?.customerDetails?.panNumber || '',
        dob: leadDetails?.customerDetails?.dob || '',

        //   policy details
        policyNo: leadDetails?.renewalLeadDetails?.policyNo || '',
        policyMode: modeType || POLICY_MODE_TYPES[0],
        dueFrom: leadDetails?.renewalLeadDetails?.dueFrom || '',
        dueTo: leadDetails?.renewalLeadDetails?.dueTo || '',
        dateOfDeposit: leadDetails?.renewalLeadDetails?.dateOfDeposit || '',
        premiumAmount: leadDetails?.renewalLeadDetails?.premiumAmount || '',
        taxAmount: leadDetails?.renewalLeadDetails?.tax || '',
        totalPremium: leadDetails?.renewalLeadDetails?.totalPremium
      });
    }
  }, [leadDetails]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: productId
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
  };

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };

  const handleDeleteDocument = () => {
    dispatch(
      deleteLeadDocuments({ fileUrl: selectedFileName, product: productId })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId, productId }));
    }, 2000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const handleUpdate = () => {
    const formData = new FormData();

    let newBusinessPayload = {
      businessType: formik.values.businessType?.id,
      customerId: customerId,
      leadId: leadId,
      productId: productId,
      name: formik.values.name,
      mobileNumber: formik.values.phoneNo,
      emailId: formik.values.emailId,
      panNumber: formik.values.panNumber,
      dob: formik.values.dob
        ? moment(formik.values.dob).format('YYYY-MM-DD')
        : '',
      leadSource: formik.values.leadSource?.id || 0,
      sp: formik.values.sourcePerson?.id || 0,
      ap: formik.values.assistantPerson?.id || 0,
      areaOffice: formik.values?.areaOffice?.id || 0,
      insuranceCompany: formik.values.insuranceCompany?.id || 0,
      insuranceProduct: formik.values.insuranceProduct?.id || 0,
      dateOfPolicy: formik.values.dateOfPolicy
        ? moment(formik.values.dateOfPolicy).format('YYYY-MM-DD')
        : '',
      policyNo: formik.values.policyNo,
      chequeRtgsNeftDate: formik.values.chequeRtgsNeftDate
        ? moment(formik.values.chequeRtgsNeftDate).format('YYYY-MM-DD')
        : '',
      chequeRtgsNeftAmount: formik.values.chequeRtgsNeftAmount
        ? parseFloat(formik.values.chequeRtgsNeftAmount).toFixed(2)
        : 0,
      premiumAmount: formik.values.premiumAmount
        ? parseFloat(formik.values.premiumAmount).toFixed(2)
        : 0,
      terroristPremium: formik.values.terroristPremium,
      tax: formik.values.taxAmount
        ? parseFloat(formik.values.taxAmount).toFixed(2)
        : 0,
      totalPremium: formik.values.totalPremium
        ? parseFloat(formik.values.totalPremium).toFixed(2)
        : 0
    };

    let renewalBusinessPayload = {
      businessType: formik.values.businessType?.id,
      customerId: customerId,
      leadId: leadId,
      productId: productId,
      name: formik.values.name,
      mobileNumber: formik.values.phoneNo,
      emailId: formik.values.emailId,
      panNumber: formik.values.panNumber,
      dob: formik.values.dob
        ? moment(formik.values.dob).format('YYYY-MM-DD')
        : '',
      mode: formik.values.policyMode?.id || 0,
      policyNumber: formik.values.policyNo,
      dueFrom: formik.values.dueFrom
        ? moment(formik.values.dueFrom).format('YYYY-MM-DD')
        : '',
      dueTo: formik.values.dueTo
        ? moment(formik.values.dueTo).format('YYYY-MM-DD')
        : '',
      dateOfDeposit: formik.values.dateOfDeposit
        ? moment(formik.values.dateOfDeposit).format('YYYY-MM-DD')
        : '',
      premiumAmount: formik.values.premiumAmount
        ? parseFloat(formik.values.premiumAmount).toFixed(2)
        : 0,
      tax: formik.values.taxAmount
        ? parseFloat(formik.values.taxAmount).toFixed(2)
        : 0,
      totalPremium: parseFloat(
        parseFloat(formik.values.premiumAmount || 0) +
          parseFloat(formik.values.taxAmount || 0)
      ).toFixed(2)
    };

    if (businessTypeId == 'newBusiness') {
      formData.append(
        'customerLeadRequest',
        JSON.stringify(newBusinessPayload)
      );
    } else {
      formData.append(
        'customerLeadRequest',
        JSON.stringify(renewalBusinessPayload)
      );
    }

    formData.append(
      'document1',
      docArray1?.[0]?.lastModified ? docArray1[0] : null
    );
    formData.append(
      'document2',
      docArray2?.[0]?.lastModified ? docArray2[0] : null
    );
    console.log(renewalBusinessPayload, 'paif');
    dispatch(updateLeadDetails({ payload: formData, productId: productId }));
  };

  const doc1 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments?.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}

        {!isGetLeadDetailsLoading && (
          <form>
            <GenericBasicDetails formik={formik} productId={productId} />
            <Divider style={{ marginBottom: '12px' }} />
            {businessTypeId == 'newBusiness' && (
              <>
                <LeadSourceDetails
                  formik={formik}
                  handleState={handleFirstTimeValue}
                />
                <Divider style={{ marginBottom: '12px' }} />
              </>
            )}

            <GenericPolicyDetails
              formik={formik}
              productId={productId}
              handleState={handleFirstTimeValue}
            />
            <Divider style={{ marginBottom: '12px' }} />

            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {/* <UploadDocumentsLead
              documentsArr={leadDocuments}
              handleSetData={handleSetDocArray}
              handleDelete={handleDeleteDoc}
              limit={2}
            /> */}
            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            <Divider style={{ marginBottom: '12px' }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: productId,
                      businessTypeId: businessTypeId
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>
          </form>
        )}
        {!isLeadUpdateLoading && isLeadUpdateSuccess && (
          <SnackBarBox
            type="success"
            message={'Lead data update successfully'}
          />
        )}

        {!isLeadUpdateLoading && isLeadUpdateFailed && (
          <SnackBarBox
            type="error"
            message={handleError(leadUpdateErrorContainer?.message)}
          />
        )}
      </div>
    </>
  );
}

export default CWAGILeadEdit;

export const GenericBasicDetails = ({ formik, productId }) => {
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Basic Details
        </Typography>
        <Grid container spacing={3}>
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="Applicant ID"
                name="applicantId"
                size="small"
                fullWidth
                value={formik.values.applicantId}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: formik.values.applicantId !== ''
                }}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="Lead ID"
                name="leadId"
                size="small"
                fullWidth
                value={formik.values.leadId}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: formik.values.leadId !== '' }}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <Autocomplete
                disabled
                options={BUSINESS_TYPES}
                getOptionLabel={option => option.label || ''}
                value={formik.values.businessType}
                isOptionEqualToValue={(option, value) =>
                  option.id === (value ? value.id : null)
                }
                size="small"
                fullWidth
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'businessType',
                    newValue ? newValue : null
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Business Type"
                    placeholder="Select Business Type"
                    style={{ marginRight: '24px', width: '100%' }}
                    error={
                      formik.touched.businessType &&
                      Boolean(formik.errors.businessType)
                    }
                    helperText={
                      formik.touched.businessType && formik.errors.businessType
                    }
                  />
                )}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="Full Name as Per KYC"
                name="name"
                placeholder="Full Name as Per KYC *"
                size="small"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: formik.values.name !== '' }}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="Email ID"
                name="emailId"
                size="small"
                fullWidth
                value={formik.values.emailId}
                onChange={e => {
                  formik.setFieldValue('emailId', e.target.value.toLowerCase());
                }}
                InputLabelProps={{ shrink: formik.values.emailId !== '' }}
                // error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                // helperText={formik.touched.emailId && formik.errors.emailId}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="Phone Number"
                name="phoneNo"
                size="small"
                fullWidth
                value={formik.values.phoneNo}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: formik.values.phoneNo !== '' }}
                error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                helperText={formik.touched.phoneNo && formik.errors.phoneNo}
              />
            </Grid>
          )}

          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <TextField
                disabled
                label="PAN Number"
                name="panNumber"
                size="small"
                fullWidth
                value={formik.values.panNumber}
                onChange={e =>
                  formik.setFieldValue(
                    'panNumber',
                    e.target.value.toUpperCase()
                  )
                }
                InputLabelProps={{ shrink: formik.values.panNumber !== '' }}
                error={
                  formik.touched.panNumber && Boolean(formik.errors.panNumber)
                }
                helperText={formik.touched.panNumber && formik.errors.panNumber}
              />
            </Grid>
          )}
          {_.includes([3], productId) && (
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled
                  label="Date of Birth"
                  value={
                    formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                  }
                  size="small"
                  fullWidth
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        borderRadius: '8px',
                        width: '100%',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }
                    }
                  }}
                  onChange={value => formik.setFieldValue('dob', value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="dob"
                      size="small"
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.dob && formik?.errors?.dob
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={
                        formik?.touched?.dob && Boolean(formik?.errors?.dob)
                      }
                      helperText={formik?.touched?.dob && formik?.errors?.dob}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.dob && formik.errors.dob && (
                <FormHelperText>{formik.errors.dob}</FormHelperText>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export const GenericPolicyDetails = ({
  formik,
  productId,
  handleState = () => {}
}) => {
  const { insuranceCompanyList, insuranceProductList } = useSelector(
    state => state.lead
  );
  const dispatch = useDispatch();

  const handleOnChange = (name, newValue) => {
    if (name === 'insuranceCompany') {
      handleState();
      formik.setFieldValue('insuranceCompany', newValue ? newValue : null);
      dispatch(getInsuranceProduct({ companyId: newValue?.id }));
    } else if (name === 'insuranceProduct') {
      formik.setFieldValue('insuranceProduct', newValue ? newValue : null);
    }
  };

  const handlePriceChange = (e, name) => {
    if (!/^\d+$/.test(e.target.value) && e.target.value !== '') {
      window.alert('Premium Amount must be a positive integer');
      return true;
    } else {
      const gstCal = formik?.values?.gst / 100;
      if (name === 'premiumAmount') {
        const taxToalCal =
          (Number(e.target.value) +
            Number(formik?.values?.terroristPremium || 0)) *
          Number(gstCal);

        const totalPremium =
          Number(e.target.value) +
          Number(formik?.values?.terroristPremium || 0) +
          Number(taxToalCal);

        formik.setValues({
          ...formik.values,
          premiumAmount: e.target.value,
          taxAmount: taxToalCal,
          totalPremium: totalPremium
        });
      } else {
        const taxToalCal =
          (Number(e.target.value) +
            Number(formik?.values?.premiumAmount || 0)) *
          Number(gstCal);

        const totalPremium =
          Number(e.target.value) +
          Number(formik?.values?.premiumAmount || 0) +
          Number(taxToalCal);

        formik.setValues({
          ...formik.values,
          terroristPremium: e.target.value,
          taxAmount: taxToalCal,
          totalPremium: totalPremium
        });
      }
    }
  };
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Policy Details
        </Typography>

        <Grid container spacing={3}>
          {formik.values?.businessType?.id === 'newBusiness' && (
            <>
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <Autocomplete
                    options={insuranceCompanyList}
                    getOptionLabel={option => option.name || ''}
                    value={formik.values.insuranceCompany}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      handleOnChange('insuranceCompany', newValue);
                      // formik.setFieldValue(
                      //   'insuranceCompany',
                      //   newValue ? newValue : null
                      // );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Company *"
                        placeholder="Select Company"
                        style={{ marginRight: '24px', width: '100%' }}
                        error={
                          formik.touched.insuranceCompany &&
                          Boolean(formik.errors.insuranceCompany)
                        }
                        helperText={
                          formik.touched.insuranceCompany &&
                          formik.errors.insuranceCompany
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <Autocomplete
                    options={
                      isArrayNotEmpty(insuranceProductList)
                        ? insuranceProductList?.map(c => {
                            return { name: c.name, id: c.id };
                          })
                        : []
                    }
                    getOptionLabel={option => option.name || ''}
                    value={formik.values.insuranceProduct}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      handleOnChange('insuranceProduct', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Product *"
                        placeholder="Select Product"
                        style={{ marginRight: '24px', width: '100%' }}
                        error={
                          formik.touched.insuranceProduct &&
                          Boolean(formik.errors.insuranceProduct)
                        }
                        helperText={
                          formik.touched.insuranceProduct &&
                          formik.errors.insuranceProduct
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Policy No. *"
                    name="policyNo"
                    placeholder="Policy No. *"
                    size="small"
                    fullWidth
                    value={formik.values.policyNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values.policyNo !== '' }}
                    error={
                      formik.touched.policyNo && Boolean(formik.errors.policyNo)
                    }
                    helperText={
                      formik.touched.policyNo && formik.errors.policyNo
                    }
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Name of Policy holder *"
                    placeholder="Name of Policy holder *"
                    name="name"
                    size="small"
                    fullWidth
                    disabled
                    value={formik.values.name}
                    // onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.name !== ''
                    }}
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Policy Starting Date *"
                      value={
                        formik?.values?.dateOfPolicy
                          ? dayjs(formik?.values?.dateOfPolicy)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('dateOfPolicy', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="dateOfPolicy"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfPolicy &&
                                  formik?.errors?.dateOfPolicy
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dateOfPolicy &&
                            Boolean(formik?.errors?.dateOfPolicy)
                          }
                          helperText={
                            formik?.touched?.dateOfPolicy &&
                            formik?.errors?.dateOfPolicy
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.dateOfPolicy &&
                    formik.errors.dateOfPolicy && (
                      <FormHelperText>
                        {formik.errors.dateOfPolicy}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Cheque/RTGS/NEFT Date *"
                      value={
                        formik?.values?.chequeRtgsNeftDate
                          ? dayjs(formik?.values?.chequeRtgsNeftDate)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('chequeRtgsNeftDate', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="chequeRtgsNeftDate"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.chequeRtgsNeftDate &&
                                  formik?.errors?.chequeRtgsNeftDate
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.chequeRtgsNeftDate &&
                            Boolean(formik?.errors?.chequeRtgsNeftDate)
                          }
                          helperText={
                            formik?.touched?.chequeRtgsNeftDate &&
                            formik?.errors?.chequeRtgsNeftDate
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.chequeRtgsNeftDate &&
                    formik.errors.chequeRtgsNeftDate && (
                      <FormHelperText>
                        {formik.errors.chequeRtgsNeftDate}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Cheque/RTGS/NEFT Amount (&#8377;)*"
                    name="chequeRtgsNeftAmount"
                    size="small"
                    fullWidth
                    value={formik.values.chequeRtgsNeftAmount}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.chequeRtgsNeftAmount !== ''
                    }}
                    error={
                      formik.touched.chequeRtgsNeftAmount &&
                      Boolean(formik.errors.chequeRtgsNeftAmount)
                    }
                    helperText={
                      formik.touched.chequeRtgsNeftAmount &&
                      formik.errors.chequeRtgsNeftAmount
                    }
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Premium Amount(&#8377;) *"
                    name="premiumAmount"
                    size="small"
                    fullWidth
                    value={formik.values.premiumAmount}
                    onChange={e => handlePriceChange(e, 'premiumAmount')}
                    InputLabelProps={{
                      shrink: formik.values.premiumAmount !== ''
                    }}
                    error={
                      formik.touched.premiumAmount &&
                      Boolean(formik.errors.premiumAmount)
                    }
                    helperText={
                      formik.touched.premiumAmount &&
                      formik.errors.premiumAmount
                    }
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Terrorist Premium(&#8377;) *"
                    name="terroristPremium"
                    size="small"
                    fullWidth
                    value={formik.values.terroristPremium}
                    onChange={e => handlePriceChange(e, 'terroristPremium')}
                    InputLabelProps={{
                      shrink: formik.values.terroristPremium !== ''
                    }}
                    error={
                      formik.touched.terroristPremium &&
                      Boolean(formik.errors.terroristPremium)
                    }
                    helperText={
                      formik.touched.terroristPremium &&
                      formik.errors.terroristPremium
                    }
                  />
                </Grid>
              )}
              <Grid item lg={12}>
                <Card
                  variant="outlined"
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
                    textAlign: 'center',
                    // maxWidth: '250px',
                    margin: 'auto',
                    backgroundColor: '#F9F9F9'
                  }}
                >
                  <Box
                    display="flex"
                    // flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    {/* GST Row */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      // justifyContent="center"
                      // alignItems="center"
                      // mb={2}
                    >
                      <Typography variant="body1" color="#666666">
                        Tax
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          color: '#335DAD',
                          marginLeft: '8px'
                        }}
                      >
                        ₹{' '}
                        {formatAmountDecimalInINR(
                          formik.values?.taxAmount || 0
                        )}
                      </Typography>
                    </Box>

                    {/* Total Premium Row */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      // justifyContent="center"
                      // alignItems="center"
                    >
                      <Typography variant="body1" color="#666666">
                        Total Premium
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          color: '#335DAD',
                          marginLeft: '8px'
                        }}
                      >
                        ₹{' '}
                        {formatAmountDecimalInINR(
                          formik.values?.totalPremium || 0
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </>
          )}
          {formik.values?.businessType?.id === 'renewalBusiness' && (
            <>
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Policy No. *"
                    name="policyNo"
                    placeholder="Policy No. *"
                    size="small"
                    fullWidth
                    value={formik.values.policyNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: formik.values.policyNo !== '' }}
                    error={
                      formik.touched.policyNo && Boolean(formik.errors.policyNo)
                    }
                    helperText={
                      formik.touched.policyNo && formik.errors.policyNo
                    }
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <Autocomplete
                    disabled
                    options={POLICY_MODE_TYPES}
                    getOptionLabel={option => option.label || ''}
                    value={formik.values.policyMode}
                    isOptionEqualToValue={(option, value) =>
                      option.id === (value ? value.id : null)
                    }
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'policyMode',
                        newValue ? newValue : null
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Mode *"
                        placeholder="Mode"
                        style={{ marginRight: '24px', width: '100%' }}
                        error={
                          formik.touched.policyMode &&
                          Boolean(formik.errors.policyMode)
                        }
                        helperText={
                          formik.touched.policyMode && formik.errors.policyMode
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Due From *"
                      value={
                        formik?.values?.dueFrom
                          ? dayjs(formik?.values?.dueFrom)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dueFrom', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="dueFrom"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dueFrom &&
                                  formik?.errors?.dueFrom
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dueFrom &&
                            Boolean(formik?.errors?.dueFrom)
                          }
                          helperText={
                            formik?.touched?.dueFrom && formik?.errors?.dueFrom
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.dueFrom && formik.errors.dueFrom && (
                    <FormHelperText>{formik.errors.dueFrom}</FormHelperText>
                  )}
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Due To *"
                      value={
                        formik?.values?.dueTo
                          ? dayjs(formik?.values?.dueTo)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dueTo', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="dueTo"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dueTo &&
                                  formik?.errors?.dueTo
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dueTo &&
                            Boolean(formik?.errors?.dueTo)
                          }
                          helperText={
                            formik?.touched?.dueTo && formik?.errors?.dueTo
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.dueTo && formik.errors.dueTo && (
                    <FormHelperText>{formik.errors.dueTo}</FormHelperText>
                  )}
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Deposit *"
                      value={
                        formik?.values?.dateOfDeposit
                          ? dayjs(formik?.values?.dateOfDeposit)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('dateOfDeposit', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="dateOfDeposit"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dateOfDeposit &&
                                  formik?.errors?.dateOfDeposit
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dateOfDeposit &&
                            Boolean(formik?.errors?.dateOfDeposit)
                          }
                          helperText={
                            formik?.touched?.dateOfDeposit &&
                            formik?.errors?.dateOfDeposit
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched.dateOfDeposit &&
                    formik.errors.dateOfDeposit && (
                      <FormHelperText>
                        {formik.errors.dateOfDeposit}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Premium Amount(&#8377;) *"
                    name="premiumAmount"
                    size="small"
                    fullWidth
                    value={formik.values.premiumAmount}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.premiumAmount !== ''
                    }}
                    error={
                      formik.touched.premiumAmount &&
                      Boolean(formik.errors.premiumAmount)
                    }
                    helperText={
                      formik.touched.premiumAmount &&
                      formik.errors.premiumAmount
                    }
                  />
                </Grid>
              )}{' '}
              {_.includes([3], productId) && (
                <Grid item lg={4}>
                  <TextField
                    label="Tax Amount *"
                    placeholder="Tax Amount *"
                    name="taxAmount"
                    size="small"
                    fullWidth
                    value={formik.values.taxAmount}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.taxAmount !== ''
                    }}
                    error={
                      formik.touched.taxAmount &&
                      Boolean(formik.errors.taxAmount)
                    }
                    helperText={
                      formik.touched.taxAmount && formik.errors.taxAmount
                    }
                  />
                </Grid>
              )}
              <Grid item lg={4}>
                <Card
                  variant="outlined"
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)',
                    textAlign: 'center'
                    // maxWidth: '250px',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {/* Terrorist Premium Row */}
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="#666666">
                        Total Premium Amount:
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          color: '#335DAD',
                          marginLeft: '8px'
                        }}
                      >
                        ₹{' '}
                        {formatAmountDecimalInINR(
                          parseFloat(
                            parseFloat(formik.values.premiumAmount || 0) +
                              parseFloat(formik.values.taxAmount || 0)
                          ).toFixed(2)
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};
