import { Grid, TextField, Typography } from '@mui/material';

function KYCDetails({ formik, isAdhaarMandatory }) {
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          KYC Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <TextField
              disabled
              label="PAN Number"
              name="panNumber"
              size="small"
              fullWidth
              value={formik.values.panNumber}
              onChange={e => {
                formik.setFieldValue('panNumber', e.target.value.toUpperCase());
              }}
              // onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.panNumber !== ''
              }}
              // error={
              //   formik.touched.panNumber && Boolean(formik.errors.panNumber)
              // }
              // helperText={formik.touched.panNumber && formik.errors.panNumber}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label={isAdhaarMandatory ? 'Aadhaar Number' : 'Aadhaar Number'}
              name="aadhaarNumber"
              size="small"
              fullWidth
              value={formik.values.aadhaarNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.aadhaarNumber !== '' }}
              // error={
              //   formik.touched.aadhaarNumber &&
              //   Boolean(formik.errors.aadhaarNumber)
              // }
              // helperText={
              //   formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Passport Number"
              name="passportNumber"
              //   placeholder="Enter Passport Number"
              size="small"
              fullWidth
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.passportNumber !== '' }}
              // error={
              //   formik.touched.passportNumber &&
              //   Boolean(formik.errors.passportNumber)
              // }
              // helperText={
              //   formik.touched.passportNumber && formik.errors.passportNumber
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="Voter ID Number"
              name="voterIdNumber"
              size="small"
              fullWidth
              value={formik.values.voterIdNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.voterIdNumber !== '' }}
              // error={
              //   formik.touched.voterIdNumber &&
              //   Boolean(formik.errors.voterIdNumber)
              // }
              // helperText={
              //   formik.touched.voterIdNumber && formik.errors.voterIdNumber
              // }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default KYCDetails;
