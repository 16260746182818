const tokenKey = 'x-auth-token-admin-panel';
import Cookies from 'js-cookie';

const ACTIVE_MENU = 'ACTIVE_MENU';
const OPEN_MENU = 'OPEN_MENU';
const BASIC_DETAILS_OBJ = 'basicDetails';
const menuList = 'MenuList';
const CWA_MENU_DATA = 'CWA_MENU_DATA';
const CWA_ACCESSIBLE_DATA = 'CWA_ACCESSIBLE_DATA';

export const setToken = token => {
  // const expirationTime = new Date();
  // expirationTime.setTime(expirationTime.getTime() + 60 * 60 * 1000);
  try {
    if (!token || typeof token !== 'string') {
      throw new Error('Invalid token format');
    }
    Cookies.set(tokenKey, token, {
      // secure: process.env.NODE_ENV === 'production',
      // expires: expirationTime,
      // httpOnly: true,
    });
  } catch (error) {
    throw Error(`Failed to set token: ${error}`);
  }
};

export const getToken = () => {
  try {
    const token = Cookies.get(tokenKey);
    return token ? token : null;
  } catch (error) {
    throw Error(`Failed to get token: ${error}`);
  }
};

export const clearToken = () => {
  try {
    Cookies.remove(tokenKey);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const setAuthData = authData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(tokenKey, JSON.stringify(authData));
    }
  } catch (error) {
    throw Error(`Failed to set authdata: ${error}`);
  }
};
export const getAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return JSON.parse(sessionStorage.getItem(tokenKey));
    }
  } catch (error) {
    throw Error(`Failed to get authdata: ${error}`);
  }
};

export const setMenuList = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(menuList, JSON.stringify(menuData));
    }
  } catch (error) {
    throw Error(`Failed to set menuList: ${error}`);
  }
};
export const getMenuList = () => {
  try {
    if (sessionStorage[menuList]) {
      return JSON.parse(sessionStorage.getItem(menuList));
    }
  } catch (error) {
    throw Error(`Failed to get menuList: ${error}`);
  }
};
export const clearMenuData = () => {
  try {
    if (sessionStorage[menuList]) {
      return sessionStorage.removeItem(menuList);
    }
  } catch (error) {
    throw Error(`Failed to remove menuList: ${error}`);
  }
};

export const clearAuthData = () => {
  try {
    if (sessionStorage[tokenKey]) {
      return sessionStorage.removeItem(tokenKey);
    }
  } catch (error) {
    throw Error(`Failed to remove authdata: ${error}`);
  }
};

export const setActiveMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(ACTIVE_MENU, menuData);
    }
  } catch (error) {
    throw Error(`Failed to set ACTIVE_MENU: ${error}`);
  }
};
export const getActiveMenuData = () => {
  try {
    if (sessionStorage[ACTIVE_MENU]) {
      return sessionStorage.getItem(ACTIVE_MENU);
    }
  } catch (error) {
    throw Error(`Failed to get ACTIVE_MENU: ${error}`);
  }
};

export const setOpenMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(OPEN_MENU, menuData);
    }
  } catch (error) {
    throw Error(`Failed to set OPEN_MENU: ${error}`);
  }
};
export const getOpenMenuData = () => {
  try {
    if (sessionStorage[OPEN_MENU]) {
      return sessionStorage.getItem(OPEN_MENU);
    }
  } catch (error) {
    throw Error(`Failed to get OPEN_MENU: ${error}`);
  }
};

export const getBasicDetails = () => {
  try {
    if (sessionStorage[BASIC_DETAILS_OBJ]) {
      return sessionStorage.getItem(BASIC_DETAILS_OBJ);
    }
  } catch (error) {
    throw Error(`Failed to get basic details: ${error}`);
  }
};

export const setBasicDetails = (profileImg, name) => {
  try {
    let basicDetailsObj = JSON.stringify({
      profileImg: profileImg,
      name: name
    });
    sessionStorage.setItem('basicDetails', basicDetailsObj);
  } catch (error) {
    throw Error(`Failed to set basic details: ${error}`);
  }
};

// For CWA

export const setCwaMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(CWA_MENU_DATA, JSON.stringify(menuData));
    }
  } catch (error) {
    throw Error(`Failed to set authdata: ${error}`);
  }
};
export const getCwaMenuData = () => {
  try {
    if (sessionStorage[CWA_MENU_DATA]) {
      return JSON.parse(sessionStorage.getItem(CWA_MENU_DATA));
    }
  } catch (error) {
    throw Error(`Failed to get authdata: ${error}`);
  }
};

export const setCwaAccessibleMenuData = menuData => {
  try {
    if (sessionStorage) {
      sessionStorage.setItem(CWA_ACCESSIBLE_DATA, JSON.stringify(menuData));
    }
  } catch (error) {
    throw Error(`Failed to set authdata: ${error}`);
  }
};
export const getCwaAccessibleMenuData = () => {
  try {
    if (sessionStorage[CWA_ACCESSIBLE_DATA]) {
      return JSON.parse(sessionStorage.getItem(CWA_ACCESSIBLE_DATA));
    }
  } catch (error) {
    throw Error(`Failed to get authdata: ${error}`);
  }
};

export const clearCwaMenuData = () => {
  try {
    Cookies.remove(CWA_MENU_DATA);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};

export const clearCwaAccessibleMenuData = () => {
  try {
    Cookies.remove(CWA_ACCESSIBLE_DATA);
  } catch (error) {
    throw Error(`Failed clear the token: ${error}`);
  }
};
