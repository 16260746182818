import { Grid, MenuItem, TextField, Typography } from '@mui/material';

function BankDetails({ formik }) {
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Bank Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <TextField
              label="Bank Name *"
              name="bankName"
              placeholder="Enter bank name"
              size="small"
              fullWidth
              value={formik.values.bankName}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.bankName !== '' }}
              error={formik.touched.bankName && Boolean(formik.errors.bankName)}
              helperText={formik.touched.bankName && formik.errors.bankName}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Branch *"
              name="branch"
              size="small"
              fullWidth
              value={formik.values.branch}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.branch !== '' }}
              error={formik.touched.branch && Boolean(formik.errors.branch)}
              helperText={formik.touched.branch && formik.errors.branch}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Address *"
              name="bankAddress"
              size="small"
              fullWidth
              value={formik.values.bankAddress}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.bankAddress !== '' }}
              error={
                formik.touched.bankAddress && Boolean(formik.errors.bankAddress)
              }
              helperText={
                formik.touched.bankAddress && formik.errors.bankAddress
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Account Number *"
              name="accountNumber"
              size="small"
              fullWidth
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.accountNumber !== '' }}
              error={
                formik.touched.accountNumber &&
                Boolean(formik.errors.accountNumber)
              }
              helperText={
                formik.touched.accountNumber && formik.errors.accountNumber
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              select
              label="Account Type *"
              name="accountType"
              size="small"
              fullWidth
              value={formik.values.accountType}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.accountType !== ''
              }}
              error={
                formik.touched.accountType && Boolean(formik.errors.accountType)
              }
              helperText={
                formik.touched.accountType && formik.errors.accountType
              }
            >
              <MenuItem value="Current">Current</MenuItem>
              <MenuItem value="Savings">Savings</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="IFSC Code *"
              name="ifscCode"
              size="small"
              fullWidth
              value={formik.values.ifscCode}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.ifscCode !== '' }}
              error={formik.touched.ifscCode && Boolean(formik.errors.ifscCode)}
              helperText={formik.touched.ifscCode && formik.errors.ifscCode}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="MICR Code *"
              name="micrCode"
              size="small"
              fullWidth
              value={formik.values.micrCode}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.micrCode !== '' }}
              error={formik.touched.micrCode && Boolean(formik.errors.micrCode)}
              helperText={formik.touched.micrCode && formik.errors.micrCode}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default BankDetails;
