import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

const initial = {
  isProductMasterListLoading: false,
  isProductMasterListSuccess: false,
  isProductMasterListFailed: false,
  productMasterData: {
    productList: [],
    totalCount: 0
  },
  productMasterListErrorContainer: {
    error: false,
    message: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isProductWiseListLoading: false,
  isProductWiseListSuccess: false,
  isProductWiseListFailed: false,
  productWiseListData: {
    list: [],
    totalCount: 0
  },
  productWiseListDataErrorContainer: {
    error: false,
    message: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isProductDetailsLoading: false,
  isProductDetailsSuccess: false,
  isProductDetailsFailed: false,
  productDetails: null,
  productDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPurposeFromProductLoading: false,
  isPurposeFromProductSuccess: false,
  isPurposeFromProductFailed: false,
  purposeDataFromProduct: [],
  purposeFromProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isSchemeFromProductLoading: false,
  isSchemeFromProductSuccess: false,
  isSchemeFromProductFailed: false,
  schemeDataFromProduct: [],
  schemeFromProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPurposeAndSchemeFromProductLoading: false,
  isPurposeAndSchemeFromProductSuccess: false,
  isPurposeAndSchemeFromProductFailed: false,
  purposeAndSchemeDataFromProduct: [],
  purposeAndSchemeFromProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isProductNamesLoading: false,
  isProductNamesSuccess: false,
  isProductNamesFailed: false,
  productNames: [],
  productNamesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isEmployeeListLoading: false,
  isEmployeeListSuccess: false,
  isEmployeeListFailed: false,
  employeeList: [],
  employeeListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateProductLoading: false,
  isCreateUpdateProductSuccess: false,
  isCreateUpdateProductFailed: false,
  createUpdateProductResponse: '',
  createUpdateProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdatePurposeSchemeLoading: false,
  isCreateUpdatePurposeSchemeSuccess: false,
  isCreateUpdatePurposeSchemeFailed: false,
  createUpdatePurposeSchemeResponse: '',
  createUpdatePurposeSchemeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isStatusPurposeSchemeLoading: false,
  isStatusPurposeSchemeSuccess: false,
  isStatusPurposeSchemeFailed: false,
  purposeSchemeStatus: null,
  statusPurposeSchemeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCityStateByPincodeLoading: false,
  isCityStateByPincodeSuccess: false,
  isCityStateByPincodeFailed: false,
  cityStateDetails: null,
  cityStateDetailsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMasterProductNameLoading: false,
  isMasterProductNameSuccess: false,
  isMasterProductNameFailed: false,
  masterProductName: null,
  masterProductNameErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  slabListing: null,
  slabAmountResponse: null
};

export const getProductList = createAsyncThunk(
  'masters/getProductList',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      if (payload.searchFilter) {
        response = await api.get(
          `/product/getAllProducts?page=${payload.page}&size=${payload.size}&searchQuery=${payload.searchFilter}`
        );
      } else {
        response = await api.get(
          `/product/getAllProducts?page=${payload.page}&size=${payload.size}`
        );
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductWiseListData = createAsyncThunk(
  'masters/getProductWiseListData',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response;
      if (payload.productCode == 1) {
        if (payload.searchFilter) {
          response = await api.get(
            `HL/getPurposeSchemeList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `HL/getPurposeSchemeList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }
      if (payload.productCode == 2) {
        if (payload.searchFilter) {
          response = await api.get(
            `LI/getPlanList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `LI/getPlanList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }
      if (payload.productCode == 3) {
        if (payload.searchFilter) {
          response = await api.get(
            `GI/getCompanyProductList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `GI/getCompanyProductList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }

      if (payload.productCode == 4) {
        if (payload.searchFilter) {
          response = await api.get(
            `FD/fdList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `FD/fdList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }
      if (payload.productCode == 5) {
        if (payload.searchFilter) {
          response = await api.get(
            `MF/getFundHouseSchemeList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `MF/getFundHouseSchemeList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }
      if (payload.productCode == 6) {
        if (payload.searchFilter) {
          response = await api.get(
            `IDBI/getProductSchemeList?page=${payload.page}&limit=${payload.limit}&searchData=${payload.searchFilter}`
          );
        } else {
          response = await api.get(
            `IDBI/getProductSchemeList?page=${payload.page}&limit=${payload.limit}`
          );
        }
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductDetails = createAsyncThunk(
  'masters/getProductDetails',
  async (prodCode, { rejectWithValue }) => {
    try {
      const response = await api.get(`product/viewProduct?code=${prodCode}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getPurposeFromProduct = createAsyncThunk(
  'masters/getPurposeFromProduct',
  async ({ prodCode }, { rejectWithValue }) => {
    try {
      const response = await api.get(`HL/purposeOfLoan/${prodCode}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getPurposeAndSchemeFromProduct = createAsyncThunk(
  'masters/getPurposeAndSchemeFromProduct',
  async (prodId, { rejectWithValue }) => {
    try {
      const response = await api.get(`products/${prodId}/purposeOfLoan`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getSchemesFromProduct = createAsyncThunk(
  'masters/getSchemesFromProduct',
  async ({ prodCode }, { rejectWithValue }) => {
    try {
      const response = await api.get(`HL/schemes/${prodCode}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getSlabList = createAsyncThunk(
  'masters/getSlabList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`HL/slab`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getAmountFromSlab = createAsyncThunk(
  'masters/getAmountFromSlab',
  async ({ slab, coreOrNonCore }, { rejectWithValue }) => {
    try {
      const response = await api.get(`HL/slab/${slab}/${coreOrNonCore}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getProductNames = createAsyncThunk(
  'masters/getProductNames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`products`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getEmployees = createAsyncThunk(
  'masters/getEmployees',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `employees${payload.searchFilter ? `?searchData=${payload.searchFilter}` : ''}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const createUpdateProduct = createAsyncThunk(
  'masters/createUpdateProduct',
  async ({ payload, actionType }, { rejectWithValue }) => {
    try {
      let response;
      if (actionType === 'update') {
        response = await api.put(
          `product/editProduct/${payload.code}`,
          payload
        );
      } else if (actionType === 'add') {
        response = await api.post(`product/addProduct`, payload);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUpdatePurposeScheme = createAsyncThunk(
  'masters/createUpdatePurposeScheme',
  async ({ payload, actionType }, { rejectWithValue }) => {
    try {
      let response;
      if (actionType === 'update') {
        response = await api.put(`HL/editPurposeScheme`, payload);
      } else if (actionType === 'add') {
        response = await api.post(`HL/addPurposeScheme`, payload);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePurposeSchemeStatus = createAsyncThunk(
  'masters/changePurposeSchemeStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`HL/purposeScheme/status`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCityStateByPincode = createAsyncThunk(
  'masters/getCityStateByPincode',
  async (pincode, { rejectWithValue }) => {
    try {
      const response = await api.get(`pincode/${pincode}/city/state`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getMasterProductName = createAsyncThunk(
  'masters/getMasterProductName',
  async (prodCode, { rejectWithValue }) => {
    try {
      const response = await api.get(`product/masterManagement`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const revertProductMasterPageData = createAction(
  'REVERT_PRODUCT_MASTER_PAGE_DATA'
);

export const revertPurposeSchemePageData = createAction(
  'REVERT_PURPOSE_SCHEME_PAGE_DATA'
);

export const revertCityStateDetails = createAction('REVERT_CITY_STATE_DETAILS');

export const revertCreateUpdatePlanMaster = createAction(
  'REVERT_UPDATE_PLAN_MASTER'
);

export const masterManagementSlice = createSlice({
  name: 'masters',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertCreateUpdatePlanMaster, state => {
        state.isCreateUpdatePurposeSchemeLoading = false;
        state.isCreateUpdatePurposeSchemeFailed = false;
        state.isCreateUpdatePurposeSchemeSuccess = false;
        (state.createUpdatePurposeSchemeResponse = ''),
          (state.createUpdatePurposeSchemeErrorContainer = {
            error: false,
            errorMessage: 'Internal Server Error',
            description: 'Something Went Wrong',
            statusCode: 0
          });
      })
      .addCase(revertProductMasterPageData, state => {
        state.isProductMasterListFailed = false;
        state.isProductMasterListSuccess = false;
        state.productMasterData = null;
        state.productMasterListErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isCreateUpdateProductFailed = false;
        state.isCreateUpdateProductSuccess = false;
        state.createUpdateProductErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(revertPurposeSchemePageData, state => {
        // state.isPurposeSchemeListFailed = false;
        // state.isPurposeSchemeListSuccess = false;
        // state.purposeSchemeData = null;
        // state.purposeSchemeListErrorContainer = {
        //   error: false,
        //   message: 'Internal Server Error',
        //   description: 'Something Went Wrong',
        //   statusCode: 0
        // };
        state.isStatusPurposeSchemeFailed = false;
        state.isStatusPurposeSchemeSuccess = false;
        state.purposeSchemeStatus = null;
        state.statusPurposeSchemeErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isCreateUpdatePurposeSchemeFailed = false;
        state.isCreateUpdatePurposeSchemeSuccess = false;
        state.createUpdatePurposeSchemeResponse = null;
        state.createUpdatePurposeSchemeErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertCityStateDetails, state => {
        state.isCityStateByPincodeSuccess = false;
        state.isCityStateByPincodeFailed = false;
        state.cityStateDetails = null;
      })

      .addCase(getProductList.pending, state => {
        state.isProductMasterListLoading = true;
        state.isProductMasterListFailed = false;
        state.isProductMasterListSuccess = false;
        state.productMasterListErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getProductList.fulfilled, (state, action) => {
        state.isProductMasterListLoading = false;
        state.isProductMasterListFailed = false;
        state.isProductMasterListSuccess = true;
        state.productMasterData = {
          productList: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })

      .addCase(getProductList.rejected, (state, action) => {
        state.isProductMasterListLoading = false;
        state.isProductMasterListFailed = true;
        state.isProductMasterListSuccess = false;
        state.productMasterData = initial.productMasterData;
        state.productMasterListErrorContainer = {
          ...state.productMasterListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getProductWiseListData.pending, state => {
        state.isProductWiseListLoading = true;
        state.isProductWiseListFailed = false;
        state.isProductWiseListSuccess = false;
        state.productWiseListDataErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getProductWiseListData.fulfilled, (state, action) => {
        state.isProductWiseListLoading = false;
        state.isProductWiseListSuccess = true;
        state.productWiseListData = {
          list: action.payload?.content,
          totalCount: action.payload?.totalElements
        };
      })

      .addCase(getProductWiseListData.rejected, (state, action) => {
        state.isProductWiseListLoading = false;
        state.isProductWiseListFailed = true;
        state.productWiseListData = initial.productWiseListData;
        state.productWiseListDataErrorContainer = {
          ...state.productWiseListDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(getProductDetails.pending, state => {
        state.isProductDetailsLoading = true;
        state.isProductDetailsSuccess = false;
        state.isProductDetailsFailed = false;
        state.productDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.isProductDetailsLoading = false;
        state.isProductDetailsSuccess = true;
        state.productDetails = action.payload;
      })
      .addCase(getProductDetails.rejected, (state, action) => {
        state.isProductDetailsLoading = false;
        state.isProductDetailsFailed = true;
        state.productDetailsErrorContainer = {
          ...state.productDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPurposeFromProduct.pending, state => {
        state.isPurposeFromProductLoading = true;
        state.isPurposeFromProductSuccess = false;
        state.isPurposeFromProductFailed = false;
        state.purposeFromProductErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getPurposeFromProduct.fulfilled, (state, action) => {
        state.isPurposeFromProductLoading = false;
        state.isPurposeFromProductSuccess = true;
        state.purposeDataFromProduct = action.payload;
      })
      .addCase(getPurposeFromProduct.rejected, (state, action) => {
        state.isPurposeFromProductLoading = false;
        state.isPurposeFromProductFailed = true;
        state.purposeDataFromProduct = [];
        state.purposeFromProductErrorContainer = {
          ...state.purposeFromProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPurposeAndSchemeFromProduct.pending, state => {
        state.isPurposeAndSchemeFromProductLoading = true;
        state.isPurposeAndSchemeFromProductSuccess = false;
        state.isPurposeAndSchemeFromProductFailed = false;
        state.purposeAndSchemeFromProductErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getPurposeAndSchemeFromProduct.fulfilled, (state, action) => {
        state.isPurposeAndSchemeFromProductLoading = false;
        state.isPurposeAndSchemeFromProductSuccess = true;
        state.purposeAndSchemeDataFromProduct = action.payload;
      })
      .addCase(getPurposeAndSchemeFromProduct.rejected, (state, action) => {
        state.isPurposeAndSchemeFromProductLoading = false;
        state.isPurposeAndSchemeFromProductFailed = true;
        state.purposeAndSchemeDataFromProduct = [];
        state.purposeAndSchemeFromProductErrorContainer = {
          ...state.purposeAndSchemeFromProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(getSchemesFromProduct.pending, state => {
        state.isSchemeFromProductLoading = true;
        state.isSchemeFromProductSuccess = false;
        state.isSchemeFromProductFailed = false;
        state.schemeFromProductErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getSchemesFromProduct.fulfilled, (state, action) => {
        state.isSchemeFromProductLoading = false;
        state.isSchemeFromProductSuccess = true;
        state.schemeDataFromProduct = action.payload;
      })
      .addCase(getSchemesFromProduct.rejected, (state, action) => {
        state.isSchemeFromProductLoading = false;
        state.isSchemeFromProductFailed = true;
        state.schemeDataFromProduct = [];
        state.schemeFromProductErrorContainer = {
          ...state.schemeFromProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(getProductNames.pending, state => {
        state.isProductNamesLoading = true;
        state.isProductNamesSuccess = false;
        state.isProductNamesFailed = false;
        state.productNamesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getProductNames.fulfilled, (state, action) => {
        state.isProductNamesLoading = false;
        state.isProductNamesSuccess = true;
        state.productNames = action.payload;
      })
      .addCase(getProductNames.rejected, (state, action) => {
        state.isProductNamesLoading = false;
        state.isProductNamesFailed = true;
        state.productNamesErrorContainer = {
          ...state.productNamesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getEmployees.pending, state => {
        state.isEmployeeListLoading = true;
        state.isEmployeeListFailed = false;
        state.isEmployeeListSuccess = false;
        state.employeeListErrorContainer = {
          error: false,
          message: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getEmployees.fulfilled, (state, action) => {
        state.isEmployeeListLoading = false;
        state.isEmployeeListFailed = false;
        state.isEmployeeListSuccess = true;
        state.employeeList = action.payload;
      })

      .addCase(getEmployees.rejected, (state, action) => {
        state.isEmployeeListLoading = false;
        state.isEmployeeListFailed = true;
        state.isEmployeeListSuccess = false;
        state.employeeList = initial.employeeList;
        state.employeeListErrorContainer = {
          ...state.employeeListErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateProduct.pending, state => {
        state.isCreateUpdateProductLoading = true;
        state.isCreateUpdateProductSuccess = false;
        state.isCreateUpdateProductFailed = false;
        state.createUpdateProductErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateProduct.fulfilled, (state, action) => {
        state.isCreateUpdateProductLoading = false;
        state.isCreateUpdateProductSuccess = true;
        state.isCreateUpdateProductFailed = false;
        state.createUpdateProductResponse = action.payload;
      })
      .addCase(createUpdateProduct.rejected, (state, action) => {
        state.isCreateUpdateProductLoading = false;
        state.isCreateUpdateProductSuccess = false;
        state.isCreateUpdateProductFailed = true;
        state.createUpdateProductErrorContainer = {
          ...state.createUpdateProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdatePurposeScheme.pending, state => {
        state.isCreateUpdatePurposeSchemeLoading = true;
        state.isCreateUpdatePurposeSchemeSuccess = false;
        state.isCreateUpdatePurposeSchemeFailed = false;
        state.createUpdatePurposeSchemeErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdatePurposeScheme.fulfilled, (state, action) => {
        state.isCreateUpdatePurposeSchemeLoading = false;
        state.isCreateUpdatePurposeSchemeSuccess = true;
        state.isCreateUpdatePurposeSchemeFailed = false;
        state.createUpdatePurposeSchemeResponse = action.payload;
      })
      .addCase(createUpdatePurposeScheme.rejected, (state, action) => {
        state.isCreateUpdatePurposeSchemeLoading = false;
        state.isCreateUpdatePurposeSchemeSuccess = false;
        state.isCreateUpdatePurposeSchemeFailed = true;
        state.createUpdatePurposeSchemeErrorContainer = {
          ...state.createUpdatePurposeSchemeErrorContainer,
          ...action.payload
        };
      })

      .addCase(changePurposeSchemeStatus.pending, state => {
        state.isStatusPurposeSchemeLoading = true;
        state.isStatusPurposeSchemeSuccess = false;
        state.isStatusPurposeSchemeFailed = false;
        state.statusPurposeSchemeErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(changePurposeSchemeStatus.fulfilled, (state, action) => {
        state.isStatusPurposeSchemeLoading = false;
        state.isStatusPurposeSchemeFailed = false;
        state.isStatusPurposeSchemeSuccess = true;
        state.purposeSchemeStatus = action.payload;
      })
      .addCase(changePurposeSchemeStatus.rejected, (state, action) => {
        state.isStatusPurposeSchemeLoading = false;
        state.isStatusPurposeSchemeSuccess = false;
        state.isStatusPurposeSchemeFailed = true;
        state.statusPurposeSchemeErrorContainer = {
          ...state.statusPurposeSchemeErrorContainer,
          ...action.payload
        };
      })

      .addCase(getCityStateByPincode.pending, state => {
        state.isCityStateByPincodeLoading = true;
        state.isCityStateByPincodeSuccess = false;
        state.isCityStateByPincodeFailed = false;
        state.cityStateDetailsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCityStateByPincode.fulfilled, (state, action) => {
        state.isCityStateByPincodeLoading = false;
        state.isCityStateByPincodeSuccess = true;
        state.cityStateDetails = action.payload;
      })
      .addCase(getCityStateByPincode.rejected, (state, action) => {
        state.isCityStateByPincodeLoading = false;
        state.isCityStateByPincodeFailed = true;
        state.cityStateDetailsErrorContainer = {
          ...state.cityStateDetailsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMasterProductName.pending, state => {
        state.isMasterProductNameLoading = true;
        state.isMasterProductNameSuccess = false;
        state.isMasterProductNameFailed = false;
        state.masterProductNameErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMasterProductName.fulfilled, (state, action) => {
        state.isMasterProductNameLoading = false;
        state.isMasterProductNameSuccess = true;
        state.masterProductName = action.payload;
      })
      .addCase(getMasterProductName.rejected, (state, action) => {
        state.isMasterProductNameLoading = false;
        state.isMasterProductNameFailed = true;
        state.masterProductName = null;
        state.masterProductNameErrorContainer = {
          ...state.masterProductNameErrorContainer,
          ...action.payload
        };
      })

      .addCase(getSlabList.pending, state => {
        state.slabListing = null;
      })
      .addCase(getSlabList.fulfilled, (state, action) => {
        state.slabListing = action.payload;
      })
      .addCase(getSlabList.rejected, (state, action) => {
        state.slabListing = null;
      })

      .addCase(getAmountFromSlab.pending, state => {
        state.slabAmountResponse = null;
      })
      .addCase(getAmountFromSlab.fulfilled, (state, action) => {
        state.slabAmountResponse = action.payload;
      })
      .addCase(getAmountFromSlab.rejected, (state, action) => {
        state.slabAmountResponse = null;
      });
  }
});

export default masterManagementSlice.reducer;
