import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { downloadReport } from '../redux/reducers/userManagementSlice';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export default function DownloadButton({
  menu,
  searchData,
  empId,
  product,
  payloadContent,
  businessType = 'newBusiness',
  statusFilter
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const buttonRef = React.useRef(); // Create a ref for the button
  const dispatch = useDispatch();
  const { isDownloadUserLoading } = useSelector(state => state.users);
  // const excludedMenus = [
  //   'Reports',
  //   'Connector',
  //   'Purpose',
  //   'Company_Product',
  //   'FundHouse_Scheme',
  //   'HL_Lead',
  //   'FD_Lead',
  //   'NPS_Lead',
  //   'GI_Lead',
  //   'MF_Lead'
  // ];
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        ref={buttonRef} // Attach the ref to the button
        aria-controls="download-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          backgroundColor: '#335DAD',
          color: 'white',
          fontSize: 'small',
          fontWeight: '600'
        }}
        size="large"
        endIcon={<ArrowDropDown />}
      >
        DOWNLOAD AS
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            minWidth: buttonRef.current ? buttonRef.current.offsetWidth : null // Set the menu's width
          }
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(
              downloadReport({
                fileType: 'EXCEL',
                fileName: menu,
                searchData: searchData,
                empId: empId,
                product: product,
                payloadContent: payloadContent,
                businessType: businessType,
                statusIds: statusFilter
              })
            );
            handleClose();
          }}
          disabled={isDownloadUserLoading}
          sx={{
            fontWeight: 'bold',
            fontSize: 'small',
            color: 'inherit',
            '&:hover': { color: '#335DAD' }
          }}
        >
          <FileDownloadOutlinedIcon style={{ marginRight: '0.5rem' }} /> EXCEL
        </MenuItem>
        {menu !== 'Connector' && (
          <MenuItem
            onClick={() => {
              dispatch(
                downloadReport({
                  fileType: 'CSV',
                  fileName: menu,
                  searchData: searchData,
                  empId: empId,
                  product: product,
                  payloadContent: payloadContent,
                  businessType: businessType,
                  statusIds: statusFilter
                })
              );
              handleClose();
            }}
            disabled={isDownloadUserLoading}
            sx={{
              fontWeight: 'bold',
              fontSize: 'small',
              color: 'inherit',
              '&:hover': { color: '#335DAD' }
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginRight: '0.5rem' }} /> CSV
          </MenuItem>
        )}

        {/* {!excludedMenus.includes(menu) && (
          <MenuItem
            onClick={() => {
              dispatch(
                downloadReport({
                  fileType: 'PDF',
                  fileName: menu,
                  searchData: searchData,
                  empId: empId,
                  product: product,
                  businessType: businessType
                })
              );
              handleClose();
            }}
            disabled={isDownloadUserLoading}
            sx={{
              fontWeight: 'bold',
              fontSize: 'small',
              color: 'inherit',
              '&:hover': { color: '#335DAD' }
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginRight: '0.5rem' }} /> PDF
          </MenuItem>
        )} */}

        {menu !== 'Reports' && (
          <MenuItem
            onClick={() => {
              dispatch(
                downloadReport({
                  fileType: 'XML',
                  fileName: menu,
                  searchData: searchData,
                  empId: empId,
                  product: product,
                  businessType: businessType,
                  statusIds: statusFilter
                })
              );
              handleClose();
            }}
            disabled={isDownloadUserLoading}
            sx={{
              fontWeight: 'bold',
              fontSize: 'small',
              color: 'inherit',
              '&:hover': { color: '#335DAD' }
            }}
          >
            <FileDownloadOutlinedIcon style={{ marginRight: '0.5rem' }} /> XML
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
