import { Grid, TextField, Typography } from '@mui/material';

const KYCDetailsCustom = ({
  applicantData,
  Index,
  handleOnChange,
  validationData
}) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <Typography
        color="#335DAD"
        fontSize={'18px'}
        fontWeight={600}
        marginBottom={'16px'}
      >
        KYC Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={4}>
          <TextField
            disabled
            label="PAN Number *"
            name="panNumber"
            size="small"
            fullWidth
            value={applicantData.panNumber}
            // onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.panNumber !== ''
            }}
            // error={
            //   validationData.panNumberVal &&
            //   Boolean(validationData.panNumberVal)
            // }
            // helperText={validationData?.panNumberVal}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Aadhaar Number"
            name="aadhaarNumber"
            size="small"
            fullWidth
            value={applicantData.aadhaarNumber}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.aadhaarNumber !== '' }}
            // error={
            //   validationData &&
            //   validationData?.aadhaarNumberVal &&
            //   Boolean(validationData?.aadhaarNumberVal)
            // }
            // helperText={validationData && validationData?.aadhaarNumberVal}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Passport Number"
            name="passportNumber"
            //   placeholder="Enter Passport Number"
            size="small"
            fullWidth
            value={applicantData.passportNumber}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.passportNumber !== '' }}
            error={
              validationData &&
              validationData?.passportNumberVal &&
              Boolean(validationData?.passportNumberVal)
            }
            helperText={validationData && validationData?.passportNumberVal}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Voter ID Number"
            name="voterId"
            size="small"
            fullWidth
            value={applicantData.voterId}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.voterId !== '' }}
            error={
              validationData &&
              validationData?.voterIdVal &&
              Boolean(validationData?.voterIdVal)
            }
            helperText={validationData && validationData?.voterIdVal}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default KYCDetailsCustom;
