import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  deleteLeadDocuments,
  getAreaOffice,
  getAssistingPerson,
  getLeadDetails,
  getLeadDocuments,
  getLeadSource,
  getMFBusinessList,
  getMFCompanyList,
  getMFFundHouseList,
  getMFInvestementList,
  getMFSchemeList,
  getMFSchemeTypeList,
  getMFStatusList,
  getSourcePerson,
  updateLeadDetails
} from '../../redux/reducers/leadSlice';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { setActiveMenu } from '../../redux/reducers/menuSlice';
import UploadDocumentsLead from '../../forms/CWALeadComponents/uploadDocumentsLead';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import LeadSourceDetails from '../../forms/CWALeadComponents/LeadSourceDetails';

function CWAMFLeadEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    leadDetails,
    leadDocuments,
    isLeadUpdateFailed,
    isLeadUpdateSuccess,
    isLeadUpdateLoading,
    isGetLeadDetailsLoading,
    assistantPersonList,
    isGetLeadDocLoading,
    mfCompanyList,
    mfBusinessList,
    mfInvestmentList,
    mfFundHouseList,
    mfStatusList,
    mfSchemeList,
    mfSchemeTypeList
  } = useSelector(state => state.lead);
  const leadId = location?.state?.leadId;
  const customerId = location?.state?.customerId;
  const productId = location?.state?.productId;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [docArray1, setDocArray1] = useState([]);
  const [docArray2, setDocArray2] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('');

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadSource({}));
    dispatch(getSourcePerson({ productId: productId }));
    dispatch(getLeadDetails({ leadId, customerId, productId }));
    dispatch(getLeadDocuments({ leadId, customerId, productId }));
    dispatch(getMFCompanyList());
    dispatch(getMFBusinessList());
    dispatch(getMFInvestementList());
    dispatch(getMFStatusList());
    dispatch(getMFSchemeTypeList());
    dispatch(getAreaOffice({}));
  }, [leadId, customerId]);

  useEffect(() => {
    if (isLeadUpdateSuccess) {
      setTimeout(() => {
        navigate('/cwaf/lead', {
          state: {
            productId: productId
          }
        });
      }, 1000);
    }
  }, [isLeadUpdateSuccess]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      customerId: leadDetails?.customerDetails?.customerId || '',
      applicationId: leadDetails?.leadDetails?.applicationId || '',
      leadId: leadDetails?.leadDetails?.id || 0,
      fullName: leadDetails?.customerDetails?.name || '',
      panNumber: leadDetails?.customerDetails?.panNumber || '',
      dob: leadDetails?.customerDetails?.dob || '',
      mobileNo: leadDetails?.customerDetails?.mobileNumber || '',
      emailId: leadDetails?.customerDetails?.emailId || '',

      sourcePerson: leadDetails?.leadDetails?.sp || null,
      leadSource: leadDetails?.leadDetails?.leadSource || null,
      assistantPerson: leadDetails?.leadDetails?.ap || null,
      areaOffice: leadDetails?.leadDetails?.areaOffice || null,

      businessType: leadDetails?.leadDetails?.businessType?.id || null,
      typeSip: leadDetails?.leadDetails?.investmentType?.id || null,
      folioNumber: leadDetails?.leadDetails?.folioNo || '',
      company: leadDetails?.leadDetails?.company || null,
      fundHouse: leadDetails?.leadDetails?.fundHouse || null,
      scheme: leadDetails?.leadDetails?.scheme || null,
      schemeType: leadDetails?.leadDetails?.schemeType || null,
      investmentDate: leadDetails?.leadDetails?.dateOfInvestment || '',
      amount: leadDetails?.leadDetails?.amount || '',
      status: leadDetails?.leadDetails?.mfStatus || null
    });
  }, [leadDetails]);

  const validationSchema = Yup.object().shape({
    // fullName: Yup.string()
    //   .required('Full Name is required')
    //   .matches(/^[A-Za-z\s]+$/, 'Invalid Name'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(
    //     /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //     'Please enter a valid PAN Number (e.g., ABCDE1234F)'
    //   ),
    // dob: Yup.date().required('Date of Birth is required'),
    // mobileNo: Yup.string()
    //   .matches(/^[6789]\d{9}$/, 'Invalid Mobile number')
    //   .required('Mobile number is required'),

    // emailId: Yup.string().email('Invalid email').required('Email is required'),
    leadSource: Yup.object({
      id: Yup.string().required('Lead Source is required')
    }).required('Lead Source is required'),

    sourcePerson: Yup.object({
      id: Yup.string().required('SP is required')
    }).required('SP is required'),

    areaOffice: Yup.object({
      id: Yup.string().required('Area Office is required')
    }).required('Area Office is required')
  });

  const formik = useFormik({
    initialValues: {
      customerId: '',
      applicationId: '',
      leadId: 0,
      fullName: '',
      panNumber: '',
      dob: '',
      mobileNo: '',
      emailId: '',
      sourcePerson: null,
      leadSource: null,
      assistantPerson: null,
      areaOffice: null,
      businessType: null,
      typeSip: null,
      folioNumber: '',
      company: null,
      fundHouse: null,
      scheme: null,
      schemeType: null,
      investmentDate: '',
      amount: '',
      status: null
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const formData = new FormData();

      let payload = {
        customerId: values?.customerId || 0,
        leadId: values?.leadId || 0,
        name: values?.fullName || '',
        mobileNumber: values?.mobileNo || '',
        emailId: values?.emailId || '',
        panNumber: values?.panNumber || '',
        dob: values?.dob || '',
        leadSource: values?.leadSource?.id || 0,
        sp: values?.sourcePerson?.id || 0,
        ap: values?.assistantPerson?.id || 0,
        areaOffice: values?.areaOffice?.id || 0,
        businessType: values?.businessType || 0,
        investmentType: values?.typeSip || 0,
        folioNo: values?.folioNumber || '',
        company: values?.company?.id || 0,
        fundHouse: values?.fundHouse?.id || 0,
        scheme: values?.scheme?.id || 0,
        schemeType: values?.schemeType?.id || 0,
        dateOfInvestment: values?.investmentDate || '',
        amount: values?.amount ? parseFloat(values?.amount).toFixed(2) : 0,
        mfStatus: values?.status?.id || 0
      };
      formData.append('customerLeadRequest', JSON.stringify(payload));
      formData.append(
        'document1',
        docArray1?.[0]?.lastModified ? docArray1[0] : null
      );
      formData.append(
        'document2',
        docArray2?.[0]?.lastModified ? docArray2[0] : null
      );
      dispatch(updateLeadDetails({ payload: formData, productId: productId }));
    }
  });

  useEffect(() => {
    if (formik.values?.company) {
      dispatch(getMFFundHouseList({ companyId: formik.values?.company?.id }));
    }
  }, [formik.values?.company]);

  useEffect(() => {
    if (formik.values?.fundHouse) {
      dispatch(getMFSchemeList({ fundHouseId: formik.values?.fundHouse?.id }));
    }
  }, [formik.values?.fundHouse]);
  const doc1 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document1')),
    [leadDocuments]
  );

  const doc2 = useMemo(
    () => leadDocuments.filter(doc => doc.fileName.includes('document2')),
    [leadDocuments]
  );

  const handleDeleteDoc = (files, index, fileName) => {
    setIsModalOpen(true);
    setSelectedFileName(fileName);
  };
  const handleDeleteDocument = () => {
    dispatch(
      deleteLeadDocuments({ fileUrl: selectedFileName, product: productId })
    );
    setIsModalOpen(false);
    setTimeout(() => {
      dispatch(getLeadDocuments({ leadId, customerId, productId }));
    }, 1000);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileName('');
  };

  const handleSetDocArray = (array, docNumber) => {
    if (docNumber === 1) {
      setDocArray1(array);
    }
    if (docNumber === 2) {
      setDocArray2(array);
    }
  };
  useEffect(() => {
    if (isFirstTime) {
      if (leadDetails?.leadDetails?.sp?.id) {
        dispatch(
          getAssistingPerson({
            sourcePersonId: leadDetails?.leadDetails?.sp?.id || 0
          })
        );
      }
    }
  }, [leadDetails?.leadDetails?.sp]);

  useEffect(() => {
    if (!isFirstTime) {
      dispatch(
        getAssistingPerson({
          sourcePersonId: formik.values?.sp?.id || 0
        })
      );
    }
  }, [formik.values?.sp]);

  useEffect(() => {
    if (!isFirstTime) {
      if (assistantPersonList) {
        formik.setFieldValue('assistantPerson', assistantPersonList[0]);
      }
    }
  }, [assistantPersonList]);

  const handleFirstTimeValue = () => {
    setIsFirstTime(false);
  };
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          Edit
        </Typography>
        {isGetLeadDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isGetLeadDetailsLoading && (
          <form>
            {/* Basic details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Basic Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Applicant ID"
                    name="customerId"
                    size="small"
                    fullWidth
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.customerId !== ''
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Lead ID"
                    name="applicationId"
                    size="small"
                    fullWidth
                    value={formik.values.applicationId}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.applicationId !== ''
                    }}
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Name as per PAN Card"
                    name="fullName"
                    placeholder="Enter Full Name"
                    size="small"
                    fullWidth
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.fullName !== ''
                    }}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="PAN Number"
                    name="panNumber"
                    size="small"
                    fullWidth
                    value={formik.values.panNumber}
                    onChange={e => {
                      formik.setFieldValue(
                        'panNumber',
                        e.target.value.toUpperCase()
                      );
                    }}
                    InputLabelProps={{
                      shrink: formik.values.panNumber !== ''
                    }}
                    error={
                      formik.touched.panNumber &&
                      Boolean(formik.errors.panNumber)
                    }
                    helperText={
                      formik.touched.panNumber && formik.errors.panNumber
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Date of Birth"
                      value={
                        formik?.values?.dob ? dayjs(formik?.values?.dob) : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value => formik.setFieldValue('dob', value)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.dob && formik?.errors?.dob
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.dob && Boolean(formik?.errors?.dob)
                          }
                          helperText={
                            formik?.touched?.dob && formik?.errors?.dob
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.dob && formik.errors?.dob && (
                    <FormHelperText>{formik.errors?.dob}</FormHelperText>
                  )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Mobile Number"
                    name="mobileNo"
                    size="small"
                    fullWidth
                    value={formik.values.mobileNo}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.mobileNo !== ''
                    }}
                    error={
                      formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                    }
                    helperText={
                      formik.touched.mobileNo && formik.errors.mobileNo
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    disabled
                    label="Email ID"
                    name="emailId"
                    size="small"
                    fullWidth
                    value={formik.values.emailId}
                    onChange={e => {
                      formik.setFieldValue(
                        'emailId',
                        e.target.value.toLowerCase()
                      );
                    }}
                    InputLabelProps={{ shrink: formik.values.emailId !== '' }}
                    error={
                      formik.touched.emailId && Boolean(formik.errors.emailId)
                    }
                    helperText={formik.touched.emailId && formik.errors.emailId}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider style={{ marginBottom: '12px' }} />

            {/* SP Details */}

            <LeadSourceDetails
              formik={formik}
              handleState={handleFirstTimeValue}
            />
            <Divider style={{ marginBottom: '12px' }} />

            {/* Policy Details */}
            <div style={{ marginBottom: '16px' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Policy Details
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    select
                    label="Business Type*"
                    name="businessType"
                    size="small"
                    fullWidth
                    value={formik.values.businessType}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.businessType !== ''
                    }}
                    error={
                      formik.touched.businessType &&
                      Boolean(formik.errors.businessType)
                    }
                    helperText={
                      formik.touched.businessType && formik.errors.businessType
                    }
                  >
                    {mfBusinessList?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    select
                    label="Type Lumpsum/sip*"
                    name="typeSip"
                    size="small"
                    fullWidth
                    value={formik.values.typeSip}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.typeSip !== ''
                    }}
                    error={
                      formik.touched.typeSip && Boolean(formik.errors.typeSip)
                    }
                    helperText={formik.touched.typeSip && formik.errors.typeSip}
                  >
                    {mfInvestmentList?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Folio Number*"
                    name="folioNumber"
                    placeholder="Enter Folio Number"
                    size="small"
                    fullWidth
                    value={formik.values.folioNumber}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.folioNumber !== ''
                    }}
                    error={
                      formik.touched.folioNumber &&
                      Boolean(formik.errors.folioNumber)
                    }
                    helperText={
                      formik.touched.folioNumber && formik.errors.folioNumber
                    }
                  />
                </Grid>

                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.company}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'company',
                        newValue ? newValue : null
                      );
                    }}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(mfCompanyList)
                        ? mfCompanyList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Company *"
                        error={formik.touched.company && formik.errors.company}
                        helperText={
                          formik.touched.company && formik.errors.company
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.fundHouse}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'fundHouse',
                        newValue ? newValue : null
                      );
                    }}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(mfFundHouseList)
                        ? mfFundHouseList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Fund House *"
                        error={
                          formik.touched.fundHouse && formik.errors.fundHouse
                        }
                        helperText={
                          formik.touched.fundHouse && formik.errors.fundHouse
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.scheme}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'scheme',
                        newValue ? newValue : null
                      );
                    }}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(mfSchemeList)
                        ? mfSchemeList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Scheme *"
                        error={formik.touched.scheme && formik.errors.scheme}
                        helperText={
                          formik.touched.scheme && formik.errors.scheme
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    fullWidth
                    value={formik.values.schemeType}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'schemeType',
                        newValue ? newValue : null
                      );
                    }}
                    getOptionLabel={option => option.name || ''}
                    options={
                      isArrayNotEmpty(mfSchemeTypeList)
                        ? mfSchemeTypeList.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    sx={{ width: '100%' }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Scheme Type *"
                        error={
                          formik.touched.schemeType && formik.errors.schemeType
                        }
                        helperText={
                          formik.touched.schemeType && formik.errors.schemeType
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of investment*"
                      value={
                        formik?.values?.investmentDate
                          ? dayjs(formik?.values?.investmentDate)
                          : null
                      }
                      size="small"
                      fullWidth
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: {
                            borderRadius: '8px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }
                        }
                      }}
                      onChange={value =>
                        formik.setFieldValue('investmentDate', value)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="doi"
                          size="small"
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&:hover fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              },
                              '&.Mui-focused fieldset': {
                                borderColor:
                                  formik?.touched?.investmentDate &&
                                  formik?.errors?.investmentDate
                                    ? 'red'
                                    : 'inherit'
                              }
                            }
                          }}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik?.touched?.investmentDate &&
                            Boolean(formik?.errors?.investmentDate)
                          }
                          helperText={
                            formik?.touched?.investmentDate &&
                            formik?.errors?.investmentDate
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formik.touched?.investmentDate &&
                    formik.errors?.investmentDate && (
                      <FormHelperText>
                        {formik.errors?.investmentDate}
                      </FormHelperText>
                    )}
                </Grid>

                <Grid item lg={4}>
                  <TextField
                    label="Amount (₹)*"
                    name="amount"
                    placeholder="Enter amount"
                    size="small"
                    fullWidth
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    onKeyPress={e => {
                      const currentValue = formik.values.amount;
                      if (
                        !/[0-9.]/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Tab'
                      ) {
                        e.preventDefault();
                      }
                      if (e.key === '.' && currentValue.includes('.')) {
                        e.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: formik.values.amount !== ''
                    }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>

                <Grid item lg={4}>
                  <Autocomplete
                    disableClearable
                    options={
                      isArrayNotEmpty(mfStatusList)
                        ? mfStatusList?.map(c => {
                            return { id: c.id, name: c.name };
                          })
                        : []
                    }
                    getOptionLabel={option => option.name || ''}
                    value={formik.values?.status}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    size="small"
                    fullWidth
                    onChange={(event, newValue) => {
                      formik.setFieldValue('status', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Status *"
                        placeholder="Select status"
                        style={{ marginRight: '24px' }}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Divider style={{ marginBottom: '12px', marginTop: '16px' }} />
            </div>

            {isGetLeadDocLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box>
                  <CircularProgress />
                </Box>
              </div>
            ) : (
              <>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginTop={'1rem'}
                >
                  Upload Documents
                </Typography>
                <Grid container spacing={3} marginTop={0.3}>
                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc1}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={1}
                      limit={1}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <UploadDocumentsLead
                      documentsArr={doc2}
                      handleSetData={handleSetDocArray}
                      handleDelete={handleDeleteDoc}
                      docNumber={2}
                      limit={1}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/lead', {
                    state: {
                      productId: productId
                    }
                  });
                }}
              >
                CANCEL
              </Button>
              <LoadingButton
                // type="submit"
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={formik.handleSubmit}
                loading={isLeadUpdateLoading}
              >
                UPDATE
              </LoadingButton>
            </div>

            {isModalOpen && (
              <ConfirmBox
                title="Delete File"
                subtitle={`Are you sure you want to delete file `}
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleDeleteDocument}
              />
            )}
            {!isLeadUpdateLoading && isLeadUpdateSuccess && (
              <SnackBarBox
                type="success"
                message={'Lead data update successfully'}
              />
            )}

            {!isLeadUpdateLoading && isLeadUpdateFailed && (
              <SnackBarBox
                type="error"
                // message={handleError(
                //   UpdateConnectorDataErrorContainer?.message
                // )}
              />
            )}
          </form>
        )}
      </div>
    </>
  );
}

export default CWAMFLeadEdit;
