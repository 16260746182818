import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Typography
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ConfirmBox({
  title = '',
  subtitle,
  handleClose,
  isOpen,
  handleSubmit
}) {
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        scroll="body"
        className="custom-dialog"
      >
        <DialogTitle id="max-width-dialog-title">
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography
                variant="body1"
                fontWeight={600}
                paddingTop={1}
                paddingBottom={1}
                align="left"
                color={'inherit'}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="MuiDialogContentRootc">
          <DialogContentText marginTop={2}>
            <Typography
              variant="body2"
              fontWeight={600}
              align="left"
              color={'inherit'}
            >
              {subtitle}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem' }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={handleSubmit}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func
};
export default ConfirmBox;
