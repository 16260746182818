import { createTheme } from '@mui/material';

export const colorPrimaryBlue = '#335DAD';
export const colorWhite = '#fff';
export const colorGolden = '#FEC310';
export const colorGrayish = '#636363';
export const colorPureWhite = '#ffffff';
export const colorDanger = '#db3131';
export const colorDangerFade = '#FEF6F4';
export const colorDarkGray = '#333333';
export const colorIconGreen = '#25d366';
export const colorIconDanger = '#D14836';
export const colorMidGray = '#636363';
export const colorSuccess = '#5EAC1B';
export const colorLightYellow = '#FFF8E6';
export const colorWarning = '#ed6c02';
export const colorLightGray = '#F9F9F9';
export const colorMidLightGray = '#DBDBDB';
export const colorLightOrange = '';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colorPrimaryBlue
    },
    secondary: {
      main: colorPureWhite
    },
    default: {
      main: colorPureWhite
    },
    golden: {
      main: colorGolden
    }
  },
  typography: {
    fontFamily: ['Montserrat Variable', 'sans-serif'].join(','),
    button: {
      textTransform: 'none'
    },
    color: colorPrimaryBlue,
    subtitle1: {
      color: colorDarkGray
    },
    subtitle2: {
      color: colorDarkGray
    },
    body1: {
      color: colorDarkGray
    },
    body2: {
      color: colorDarkGray
    }
  },
  shape: {
    borderRadius: 3
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            color: colorGrayish
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colorGrayish,
          borderRadius: '6px',
          paddingLeft: '0%',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorGrayish
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorGrayish
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colorGrayish
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colorGrayish,
          '&.Mui-focused': {
            color: colorGrayish
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        inputAdornedStart: {
          '& .MuiInputAdornment-root': {
            color: colorGrayish
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: colorGolden,
          padding: '0px 24px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colorDanger,
          '&$error': {
            color: colorDanger
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '1.3rem',
          padding: 10
          // fontSize: '0.8rem'
        }
      }
    }
  }
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: colorWhite
    }
  }
});

export { lightTheme, darkTheme };
