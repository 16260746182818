import { useEffect, useState } from 'react';

// Hook for detecting user activity and logging out after a timeout
const IdleTimer = (idleTimeout = 600000, onIdle) => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    // Function to check for inactivity
    const checkIdleTimeout = () => {
      if (Date.now() - lastActivityTime >= idleTimeout) {
        onIdle();
      }
    };

    // Event listeners to reset the idle timer on user activity
    const handleUserActivity = () => {
      setLastActivityTime(Date.now());
    };

    // Attach event listeners
    const events = ['mousemove', 'keydown', 'click'];
    events.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    // Check idle timeout every minute (60000ms)
    const intervalId = setInterval(checkIdleTimeout, 60000);

    // Cleanup function to remove event listeners and interval on unmount
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearInterval(intervalId);
    };
  }, [lastActivityTime, idleTimeout, onIdle]);
};

export default IdleTimer;
