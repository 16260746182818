import {
  AppBar,
  Avatar,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  alpha,
  styled
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAndClearToken } from '../../redux/reducers/authSlice';
import ConfirmBox from '../../components/ConfirmBox';
import PropTypes from 'prop-types';
import {
  KeyboardArrowDown,
  LockResetOutlined,
  LoginOutlined,
  PasswordOutlined,
  PersonOutlined
} from '@mui/icons-material';
import { generateImageSrc } from '../../utils/utils';
import { CaseContext } from '../../App';
import { getBasicDetails } from '../../config/cookie';

const circleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  backgroundColor: '#c9c9c9',
  color: 'white',
  fontSize: '16px',
  marginRight: '5px'
};

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 150,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.primary[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      },
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

function HeaderBar({ title = '' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { authData } = useSelector(state => state.auth);

  const { setBasicDetailsContext, basicDetailsContext } =
    useContext(CaseContext);

  useEffect(() => {
    let getBasicDetailsResponse = JSON.parse(getBasicDetails());
    setBasicDetailsContext({
      userImage: getBasicDetailsResponse?.profileImg,
      name: getBasicDetailsResponse?.name
    });
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    dispatch(logoutAndClearToken());
    // navigate('/login');
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900]
      }}
      elevation={0}
    >
      <Toolbar>
        <Typography
          variant="body1"
          fontWeight="700"
          fontSize={25}
          component="div"
          sx={{ flexGrow: 1, color: '#335DAD' }}
        >
          {title}
        </Typography>
        {/* <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setIsModalOpen(true)}
            color="primary"
          >
            <PowerSettingsNewTwoToneIcon />
          </IconButton>
        </div> */}
        <div>
          <Button
            disableRipple
            disableTouchRipple
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            sx={{
              minWidth: '150px',
              fontSize: '20px',
              color: 'black'
            }}
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
          >
            {authData ? (
              <>
                {basicDetailsContext?.userImage ? (
                  <Avatar
                    src={generateImageSrc(basicDetailsContext?.userImage)}
                    alt={basicDetailsContext?.name}
                    sx={{ width: 32, height: 32, marginRight: 1 }}
                  />
                ) : (
                  <div style={circleStyle}>
                    {basicDetailsContext
                      ? basicDetailsContext?.name
                          ?.split(' ')[0]
                          .charAt(0)
                          .toUpperCase()
                      : 'A'}
                  </div>
                )}
                <Typography fontSize={16} fontWeight={'600'}>
                  {basicDetailsContext?.name?.split(' ')[0] || ''}
                </Typography>
              </>
            ) : (
              'User'
            )}
          </Button>
          <StyledMenu
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {process.env.REACT_APP_PORTAL_TYPE === 'CWA' && (
              <>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/cwaf/my-profile');
                  }}
                  disableRipple
                >
                  <ListItemIcon>
                    <PersonOutlined fontSize="small" />
                  </ListItemIcon>
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/cwaf/change-password');
                  }}
                  disableRipple
                >
                  <ListItemIcon>
                    <LockResetOutlined fontSize="small" />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
              </>
            )}
            {/* <Divider sx={{ my: 0.5 }} /> */}
            <MenuItem
              onClick={() => {
                // navigate('/login');
                // dispatch(logoutAndClearToken());
                setIsModalOpen(true);
                handleClose();
              }}
              disableRipple
            >
              <ListItemIcon>
                <LoginOutlined fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
      {isModalOpen && (
        <ConfirmBox
          title="Logout"
          subtitle={`Are you sure you want to Logout?`}
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          handleSubmit={handleSubmit}
        />
      )}
    </AppBar>
  );
}
HeaderBar.propTypes = {
  title: PropTypes.string
};

export default HeaderBar;
