import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { getEmployeeHierarchy } from '../redux/reducers/userManagementSlice';

function CWAHierarchy() {
  const { authData } = useSelector(state => state.auth);
  const { employeeHierarchy } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('Hierarchy');
    dispatch(setActiveMenu('Hierarchy'));
    dispatch(getEmployeeHierarchy(authData?.empId));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, []);

  //   const employeeHierarchy = {
  //     empId: 2323232,
  //     name: 'Ram Yadav',
  //     designation: 'TM',
  //     upline: {
  //       empId: 3434343,
  //       name: 'Komal Ingale',
  //       designation: 'Sr.TM',
  //       upline: null,
  //       downline: []
  //     },
  //     downline: [
  //       {
  //         empId: 8026001,
  //         name: 'KABI MOHANTY',
  //         designation: 'Sr.TM',
  //         upline: null,
  //         downline: []
  //       },
  //       {
  //         empId: 5034009,
  //         name: 'K KARTHIKEYAN',
  //         designation: 'Sr.TM',
  //         upline: null,
  //         downline: []
  //       },
  //       {
  //         empId: 5516002,
  //         name: 'DEEPAK SHRIVASTAVA',
  //         designation: 'Sr.TM',
  //         upline: null,
  //         downline: []
  //       },
  //       {
  //         empId: 8016001,
  //         name: 'DUSHYANT KUMAR',
  //         designation: 'Sr.TM',
  //         upline: null,
  //         downline: []
  //       }
  //     ]
  //   };

  const renderEmployee = (employee, isHighlighted) => {
    return (
      <ListItem key={employee?.empId}>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: '#F9F9F9',
              color: '#335DAD',
              fontWeight: 'bold',
              fontSize: isHighlighted ? 'large' : 'small'
            }}
          >
            {`${employee?.name[0]}${employee?.name.split(' ')[1][0]}`}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              sx={{
                color: isHighlighted ? '#335DAD' : 'black',
                fontWeight: isHighlighted ? 'bold' : 'medium',
                fontSize: isHighlighted ? 'medium' : 'medium'
              }}
            >
              {employee?.name}
            </Typography>
          }
          secondary={
            <>
              <Typography
                sx={{
                  color: isHighlighted ? '#335DAD' : 'black',
                  fontWeight: isHighlighted ? 'medium' : '',
                  fontSize: 'small'
                }}
                variant="caption"
              >{`${employee?.designation} `}</Typography>
              <Typography
                sx={{
                  color: isHighlighted ? '#335DAD' : 'inherit',
                  fontWeight: isHighlighted ? 'medium' : '',
                  fontSize: 'small'
                }}
                variant="caption"
              >{`(EMP ID: ${employee?.empId})`}</Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            // border: '1px solid blue',
            backgroundColor: '#F3F7FF',
            padding: '4px',
            margin: '5px',
            borderRadius: '5px'
          }}
        >
          <List>
            {employeeHierarchy?.upline &&
              renderEmployee(employeeHierarchy?.upline, false)}
            {renderEmployee(employeeHierarchy, true)}
            {employeeHierarchy?.downline.length > 0 ? (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, fontWeight: '600' }}
                >
                  Reportees
                </Typography>
                <Divider />
                {employeeHierarchy?.downline.map(subEmployee =>
                  renderEmployee(subEmployee, false)
                )}{' '}
              </>
            ) : (
              <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: '600' }}>
                No Reportees
              </Typography>
            )}
          </List>
        </div>
      </div>
    </>
  );
}

export default CWAHierarchy;
