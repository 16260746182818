import { Box, FormControl } from '@mui/material';
import GenericInput from '../../../components/GenericInput';
import { enterOnlyNumbers } from '../../../utils/utils';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import ShowBackToLoginButton from './ShowBackToLoginButton';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const formControl = {
  margin: '0.5rem auto'
};

function ForgotPasswordForm({ formik }) {
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (formik.values.empId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formik.values.empId]);
  return (
    <Box sx={{ marginTop: '2rem' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl sx={formControl} fullWidth>
          <GenericInput
            required
            label="Employee ID"
            name="empId"
            size="small"
            inputProps={{ maxLength: 7 }}
            value={formik.values.mobileNo}
            onChange={e =>
              formik.setFieldValue('empId', enterOnlyNumbers(e.target.value))
            }
            error={formik.touched.empId && formik.errors.empId}
            startIcon={<PersonOutlineOutlinedIcon className="icon_color" />}
          />
        </FormControl>

        <LoadingButton
          type="submit"
          disabled={disabled}
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          sx={{
            mt: 2,
            fontSize: 15,
            fontWeight: '600',
            textTransform: 'uppercase',
            bgcolor: '#335DAD'
          }}
        >
          Send Otp
        </LoadingButton>
        <ShowBackToLoginButton />
      </form>
    </Box>
  );
}

ForgotPasswordForm.propTypes = {
  formik: PropTypes.any
};

export default ForgotPasswordForm;
