import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getAuthData, getToken } from '../config/cookie';
import { useDispatch } from 'react-redux';
import { setAuthDetailsByLocalStorage } from '../redux/reducers/authSlice';
import PropTypes from 'prop-types';

const ProtectedRoutes = ({ accessibleRoutes }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const token = getToken();
  const authData = getAuthData();

  if (token && auth.authData?.empId === 0 && authData !== undefined) {
    dispatch(setAuthDetailsByLocalStorage(authData));
    // dispatch(setActiveMenu(activeMenu));
  }

  const isAuthenticated = Boolean(token);

  if (isAuthenticated) {
    const currentPathAccessible = accessibleRoutes?.some(route =>
      window.location.pathname.startsWith(route.path)
    );

    if (currentPathAccessible) {
      return <Outlet />;
    } else {
      return <Navigate to="/forbidden" />;
    }
  } else {
    return process.env.REACT_APP_PORTAL_TYPE === 'ADMIN' ? (
      <Navigate to="/adminf/login" />
    ) : (
      <Navigate to="/cwaf/login" />
    );
  }
};

ProtectedRoutes.propTypes = {
  accessibleRoutes: PropTypes.array
};

export default ProtectedRoutes;
