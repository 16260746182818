import { useEffect, useMemo, useState } from 'react';
import {
  getCWAConnectorDetails,
  revertConnectorPageData,
  revertCwaConnectorPageData,
  updateCwaConnectorStatus
} from '../redux/reducers/connectorApprovalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { colorPrimaryBlue } from '../config/theme';
import SingleDocUpload from '../forms/CWAConnectorComponents/SingleDocUpload';
import CustomDataTable from '../components/CustomDataTable';
import { LoadingButton } from '@mui/lab';
import { createTitle, handleError } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { IMG_PERSON_AVTAR_SMALL } from '../utils/imageUrls';
import { getMenuList } from '../config/cookie';
import dayjs from 'dayjs';
import SnackBarBox from '../components/SnackBarBox';
import ResponseModal from '../components/ResponseModal';
import { api } from '../config/api';

function CWAConnectorViewDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const {
    cwaConnectorDetails,
    updateConnectorStatusResponse,
    updateConnectorStatusLoading,
    updateConnectorStatusSuccess,
    updateConnectorStatusFailed,
    updateConnectorStatusErrorContainer,
    isCWAConnectorDetailsLoading
  } = useSelector(state => state.connector);

  const connectorId = location.state?.connectorId || '';
  const menuDetails = getMenuList();

  const [statusDetails, setStatusDetails] = useState({
    status: '',
    reason: ''
  });

  const [validation, setValidation] = useState({
    statusVal: '',
    reasonVal: ''
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  useEffect(() => {
    if (!updateConnectorStatusLoading && updateConnectorStatusSuccess) {
      setIsSuccessModalOpen(true);
    }
  }, [updateConnectorStatusSuccess]);
  const handleOnChange = (e, name) => {
    let validationName = name + 'Val';
    setStatusDetails(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));
    if (name == 'status') {
      setValidation({
        statusVal: '',
        reasonVal: ''
      });
    } else {
      setValidation(prevState => ({
        ...prevState,
        [validationName]: ''
      }));
    }
  };

  const handleValidation = () => {
    let isValid = true;

    if (!statusDetails.status) {
      setValidation(prevState => ({
        ...prevState,
        statusVal: 'Please select status'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        statusVal: ''
      }));
    }

    if (
      !statusDetails.reason &&
      (statusDetails.status === 'Reject' || statusDetails.status === 'Hold')
    ) {
      setValidation(prevState => ({
        ...prevState,
        reasonVal: 'Please enter reason'
      }));
      isValid = false;
    } else {
      setValidation(prevState => ({
        ...prevState,
        reasonVal: ''
      }));
    }
    return isValid;
  };
  useEffect(() => {
    const Id = menuDetails?.roleModule?.id;
    const titleOrActiveMenu =
      Id == 2
        ? 'Connector Approval CO'
        : Id == 3
          ? 'Connector Approval HO'
          : 'Connector Onboarding';
    document.title = createTitle(titleOrActiveMenu);
    dispatch(setActiveMenu(titleOrActiveMenu));
    return () => {
      dispatch(revertConnectorPageData());
    };
  }, []);

  useEffect(() => {
    if (location?.state?.connectorId) {
      dispatch(getCWAConnectorDetails({ connectorId }));
    }
    return () => dispatch(revertCwaConnectorPageData());
  }, [connectorId]);

  useEffect(() => {
    if (
      cwaConnectorDetails?.contactDetails?.photo &&
      cwaConnectorDetails?.contactDetails?.contentType
    ) {
      // const dataUrl = `data:image/png;base64,${cwaConnectorDetails?.contactDetails?.photo}`;
      // setSelectedPhoto(dataUrl);
      const fileUrl = cwaConnectorDetails?.contactDetails?.photo;
      const contentType = cwaConnectorDetails?.contactDetails?.contentType;
      handlePhotoFromAPI(fileUrl, contentType);
    }
  }, [cwaConnectorDetails]);

  const handlePhotoFromAPI = async (fileUrl, contentType) => {
    try {
      const response = await api.get(
        `view?fileUrl=${fileUrl}&contentType=${contentType}`,
        {
          responseType: 'blob'
        }
      );

      if (response.data) {
        const fileBlob = response.data;
        const fileURL = URL.createObjectURL(fileBlob);
        setSelectedPhoto(fileURL);
      }
    } catch (error) {
      return error.response.data;
    }
  };

  const basicDetailsGridItems = useMemo(() => {
    const items = [
      {
        label: 'Connector Code',
        value: cwaConnectorDetails?.connectorId || '-'
      },
      {
        label: 'Name',
        value: cwaConnectorDetails?.name || '-'
      },
      {
        label: 'Constitution Type',
        value: cwaConnectorDetails?.constitution?.name || '-'
      },
      {
        label: 'Connector Type',
        value:
          cwaConnectorDetails?.connectorType === 'S'
            ? 'Special'
            : cwaConnectorDetails?.connectorType === 'C'
              ? 'Common'
              : '-'
      },
      ...(cwaConnectorDetails?.constitution?.id === 6
        ? [
            {
              label: 'Other Constitution Type',
              value: cwaConnectorDetails?.otherConstitution
                ? cwaConnectorDetails?.otherConstitution
                : '-'
            }
          ]
        : []),
      {
        label: 'Profile',
        value: cwaConnectorDetails?.profile?.name || '-'
      },

      ...(cwaConnectorDetails?.profile?.id === 5
        ? [
            {
              label: 'Other Profile',
              value: cwaConnectorDetails?.otherProfile
                ? cwaConnectorDetails?.otherProfile
                : '-'
            }
          ]
        : [])
    ];

    // Add conditional items based on constitution
    if (cwaConnectorDetails?.constitution?.id === 1) {
      items.push({
        label: 'Date of Birth',
        value: cwaConnectorDetails?.contactDetails?.dateOfBirth
          ? dayjs(cwaConnectorDetails?.contactDetails?.dateOfBirth).format(
              'DD/MM/YYYY'
            )
          : '-'
      });
    } else {
      items.push(
        {
          label: 'Date of Registration',
          value: cwaConnectorDetails?.contactDetails?.dateOfRegistration
            ? dayjs(
                cwaConnectorDetails?.contactDetails?.dateOfRegistration
              ).format('DD/MM/YYYY')
            : '-'
        },
        {
          label: 'Date of Incorporation',
          value: cwaConnectorDetails?.contactDetails?.dateOfIncorporation
            ? dayjs(
                cwaConnectorDetails?.contactDetails?.dateOfIncorporation
              ).format('DD/MM/YYYY')
            : '-'
        }
      );
    }

    return items;
  }, [cwaConnectorDetails]);

  const residenceDetailsGridItems = useMemo(
    () => [
      {
        label: 'Telephone Number',
        value: cwaConnectorDetails?.contactDetails?.telephone
          ? cwaConnectorDetails?.contactDetails?.telephone
          : '-'
      },
      {
        label: 'Mobile Number',
        value: cwaConnectorDetails?.mobile ? cwaConnectorDetails?.mobile : '-'
      },
      {
        label: 'Email ID',
        value: cwaConnectorDetails?.contactDetails?.email
          ? cwaConnectorDetails?.contactDetails?.email
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const bizDetailsGridItems = useMemo(
    () => [
      {
        label: 'Business Mobile Number',
        value: cwaConnectorDetails?.bbDetails?.businessMobile
          ? cwaConnectorDetails?.bbDetails?.businessMobile
          : '-'
      },
      {
        label: 'Business Email ID',
        value: cwaConnectorDetails?.bbDetails?.businessEmail
          ? cwaConnectorDetails?.bbDetails?.businessEmail
          : '-'
      },
      {
        label: 'Business since(years)',
        value: cwaConnectorDetails?.bbDetails?.noOfYearsInBusiness
          ? cwaConnectorDetails?.bbDetails?.noOfYearsInBusiness
          : '-'
      },
      {
        label: 'Business Place',
        value: cwaConnectorDetails?.bbDetails?.businessPlace
          ? cwaConnectorDetails?.bbDetails?.businessPlace
          : '-'
      },
      {
        label: 'PAN Number',
        value: cwaConnectorDetails?.panNumber
          ? cwaConnectorDetails?.panNumber
          : '-'
      },
      {
        label: 'GST Number',
        value: cwaConnectorDetails?.bbDetails?.gstNumber
          ? cwaConnectorDetails?.bbDetails?.gstNumber
          : '-'
      },
      {
        label: 'Is your firm registered as MSME?',
        value: cwaConnectorDetails?.isRegisteredMsme ? 'Yes' : 'No'
      }
    ],
    [cwaConnectorDetails]
  );

  const bankDetailsGridItems = useMemo(
    () => [
      {
        label: 'Bank Name',
        value: cwaConnectorDetails?.bbDetails?.bankName
          ? cwaConnectorDetails?.bbDetails?.bankName
          : '-'
      },
      {
        label: 'Branch',
        value: cwaConnectorDetails?.bbDetails?.bankBranch
          ? cwaConnectorDetails?.bbDetails?.bankBranch
          : '-'
      },
      {
        label: 'Address',
        value: cwaConnectorDetails?.bbDetails?.bankBranchAddress
          ? cwaConnectorDetails?.bbDetails?.bankBranchAddress
          : '-'
      },
      {
        label: 'Account Number',
        value: cwaConnectorDetails?.bbDetails?.accountNumber
          ? cwaConnectorDetails?.bbDetails?.accountNumber
          : '-'
      },
      {
        label: 'Account Type',
        value: cwaConnectorDetails?.bbDetails?.bankAccountType
          ? cwaConnectorDetails?.bbDetails?.bankAccountType
          : '-'
      },
      {
        label: 'IFSC Code',
        value: cwaConnectorDetails?.bbDetails?.ifscCode
          ? cwaConnectorDetails?.bbDetails?.ifscCode
          : '-'
      },
      {
        label: 'MICR Code',
        value: cwaConnectorDetails?.bbDetails?.micrCode
          ? cwaConnectorDetails?.bbDetails?.micrCode
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const nomineeDetailsGridItems = useMemo(
    () => [
      {
        label: 'Name of Nominee',
        value: cwaConnectorDetails?.bbDetails?.nomineeName
          ? cwaConnectorDetails?.bbDetails?.nomineeName
          : '-'
      },
      {
        label: 'Relationship with Nominee',
        value: cwaConnectorDetails?.bbDetails?.nomineeRelation
          ? cwaConnectorDetails?.bbDetails?.nomineeRelation
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const employeeMappingGridItems = useMemo(
    () => [
      {
        label: 'Employee Mapping',
        value: cwaConnectorDetails?.employeeId?.id
          ? cwaConnectorDetails?.employeeId?.name
          : '-'
      }
    ],
    [cwaConnectorDetails]
  );

  const autherizedSignatorycolumns = [
    {
      id: 'name',
      label: 'Name'
    },

    {
      id: 'mobileNumber',
      label: 'Contact Number'
    },
    {
      id: 'email',
      label: 'Email ID'
    }
  ];

  const assciationDetailscolumns = [
    {
      id: 'nameOfInstitution',
      label: 'Name of the Institution'
    },

    {
      id: 'associatedSince',
      label: 'Associated Since(Years)'
    },
    {
      id: 'businessInLastFinancialYear',
      label: 'Business done during last financial year',
      render: rowData => {
        const formattedNumber = parseInt(
          rowData?.businessInLastFinancialYear
        )?.toLocaleString('en-IN');

        return <p>{formattedNumber}</p>;
      }
    }
  ];

  const referencesColumns = [
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'address',
      label: 'Address'
    }
  ];

  const handleSetDocument = () => {};
  const handleRedirect = () => {
    window.history.back();
  };

  const handleUpdateStatus = () => {
    let isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    let payload = {
      connectorId: connectorId,
      status: statusDetails?.status || '',
      reason: statusDetails?.reason || ''
    };
    dispatch(updateCwaConnectorStatus({ payload: payload }));
    // setTimeout(() => {
    //   window.history.back();
    // }, [2000]);
  };
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          View
        </Typography>
        {isCWAConnectorDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}
        {!isCWAConnectorDetailsLoading && (
          <>
            <div>
              {/* Upload photo */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  fontSize={'18px'}
                  color={colorPrimaryBlue}
                  fontWeight={600}
                >
                  Uploaded Photo
                </Typography>
                <Card
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    mt: 2,
                    mb: 2
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      height: '170px',
                      width: '200px',
                      maxHeight: '60mm',
                      maxWidth: '60mm'
                    }}
                    image={
                      selectedPhoto ? selectedPhoto : IMG_PERSON_AVTAR_SMALL
                    }
                    alt="Selected Photo"
                  />
                </Card>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Basic Details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Basic Details
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {basicDetailsGridItems?.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Residence details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Residence Details
                </Typography>
                <Grid lg={12} sx={{ marginBottom: '24px' }}>
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    Resident Address
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {cwaConnectorDetails?.contactDetails?.residentialAddress ||
                      '-'}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {residenceDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Business details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Business Details
                </Typography>
                <Grid lg={12} sx={{ marginBottom: '24px' }}>
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    Business Address
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {cwaConnectorDetails?.bbDetails?.businessAddress || '-'}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {bizDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      PAN Upload
                    </Typography>
                    <div
                      style={{
                        border: cwaConnectorDetails?.connectorDocumentsMap
                          ?.pancard
                          ? '1px solid #00000029'
                          : '',
                        borderRadius: 5,
                        alignSelf: 'center'
                      }}
                    >
                      {cwaConnectorDetails?.connectorDocumentsMap?.pancard ? (
                        <SingleDocUpload
                          documentsArr={
                            cwaConnectorDetails?.connectorDocumentsMap?.pancard
                              ? [
                                  cwaConnectorDetails?.connectorDocumentsMap
                                    ?.pancard
                                ]
                              : []
                          }
                          handleSetData={handleSetDocument}
                          onlyView={true}
                          title={'Pan Upload'}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {'Document not found'}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      MSME Upload
                    </Typography>
                    <div
                      style={{
                        border: cwaConnectorDetails?.connectorDocumentsMap
                          ?.msmeCertificate
                          ? '1px solid #00000029'
                          : '',
                        borderRadius: 5,
                        alignSelf: 'center'
                      }}
                    >
                      {cwaConnectorDetails?.connectorDocumentsMap
                        ?.msmeCertificate ? (
                        <SingleDocUpload
                          documentsArr={
                            cwaConnectorDetails?.connectorDocumentsMap
                              ?.msmeCertificate
                              ? [
                                  cwaConnectorDetails?.connectorDocumentsMap
                                    ?.msmeCertificate
                                ]
                              : []
                          }
                          handleSetData={handleSetDocument}
                          onlyView={true}
                          title={'MSME Upload'}
                        />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {'Document not found'}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Autherized signatory */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  {`Name of Authorized Signatory’s (In case of Partnership Firm / Company)`}
                </Typography>
                <CustomDataTable
                  columns={autherizedSignatorycolumns}
                  data={
                    cwaConnectorDetails?.connectorSignatories?.length > 0
                      ? cwaConnectorDetails?.connectorSignatories
                      : []
                  }
                  //   onPageChange={val => {
                  //     setInitialValues({ ...initialValues, page: val });
                  //   }}
                  //   onRowsPerPageChange={val => {
                  //     setInitialValues({ ...initialValues, limit: val, page: 0 });
                  //   }}
                  //   defaultPage={initialValues.page}
                  defaultRowsPerPage={2}
                  totalCount={0}
                  isShowPagination={false}
                />
              </div>

              {/* Bank Details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Bank Details
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {bankDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Nominee details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Nominee Details (In case of Individual)
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {nomineeDetailsGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Employee mapping */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Employee Mapping
                </Typography>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  {employeeMappingGridItems.map((item, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      sx={{ marginBottom: '8px' }}
                    >
                      <Typography
                        sx={{
                          color: 'GrayText',
                          fontSize: 'small',
                          marginBottom: '4px'
                        }}
                      >
                        {item.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Divider style={{ marginBottom: '12px' }} />
              </div>

              {/* Association details */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Association Details
                </Typography>
                <CustomDataTable
                  columns={assciationDetailscolumns}
                  data={
                    cwaConnectorDetails?.associationDetails?.length > 0
                      ? cwaConnectorDetails?.associationDetails
                      : []
                  }
                  //   onPageChange={val => {
                  //     setInitialValues({ ...initialValues, page: val });
                  //   }}
                  //   onRowsPerPageChange={val => {
                  //     setInitialValues({ ...initialValues, limit: val, page: 0 });
                  //   }}
                  //   defaultPage={initialValues.page}
                  defaultRowsPerPage={3}
                  totalCount={0}
                  isShowPagination={false}
                />
              </div>

              {/* Questions */}
              <div style={{ marginBottom: '16px' }}>
                <div>
                  <div style={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      Have you any time taken connector code/agency from any
                      organization?
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 'small',
                        color: 'black'
                      }}
                    >
                      {cwaConnectorDetails?.bbDetails?.codeOrAgency === true
                        ? 'Yes'
                        : 'No' || '-'}
                    </Typography>
                  </div>
                  {cwaConnectorDetails?.bbDetails?.codeOrAgency === true && (
                    <Grid container spacing={2} sx={{ mb: '16px' }}>
                      <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Organization Details
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.bbDetails?.nameOfOrganization}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Time Period
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.bbDetails?.timePeriod}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>

                <div>
                  <div style={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      Is anyone in a relationship with you currently working in
                      LICHFL/LICHFL FSL?
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 'small',
                        color: 'black'
                      }}
                    >
                      {cwaConnectorDetails?.contactDetails
                        ?.relativeWorkingInLicHflFsl === true
                        ? 'Yes'
                        : 'No' || '-'}
                    </Typography>
                  </div>
                  {cwaConnectorDetails?.contactDetails
                    ?.relativeWorkingInLicHflFsl === true && (
                    <Grid container spacing={2} sx={{ mb: '16px' }}>
                      <Grid item lg={4} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.contactDetails?.nameOfPerson}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Designation
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.contactDetails?.designation}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Office
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.contactDetails?.relativeOffice}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>

                <div>
                  <div style={{ marginBottom: '8px' }}>
                    <Typography
                      sx={{
                        color: 'GrayText',
                        fontSize: 'small',
                        marginBottom: '4px'
                      }}
                    >
                      Files currrently submitted?
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 'small',
                        color: 'black'
                      }}
                    >
                      {cwaConnectorDetails?.isFileSubmitted === true
                        ? 'Yes'
                        : 'No' || '-'}
                    </Typography>
                  </div>
                  {cwaConnectorDetails?.isFileSubmitted === true && (
                    <Grid container spacing={2} sx={{ mb: '16px' }}>
                      <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          Name
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.submittedFileName}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                        <Typography
                          sx={{
                            color: 'GrayText',
                            fontSize: 'small',
                            marginBottom: '4px'
                          }}
                        >
                          HL Amount
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 'small',
                            color: 'black'
                          }}
                        >
                          {cwaConnectorDetails?.appliedHlAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>

              {/* References */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  References
                </Typography>
                <CustomDataTable
                  columns={referencesColumns}
                  data={
                    cwaConnectorDetails?.connectorReferences?.length > 0
                      ? cwaConnectorDetails?.connectorReferences
                      : []
                  }
                  //   onPageChange={val => {
                  //     setInitialValues({ ...initialValues, page: val });
                  //   }}
                  //   onRowsPerPageChange={val => {
                  //     setInitialValues({ ...initialValues, limit: val, page: 0 });
                  //   }}
                  //   defaultPage={initialValues.page}
                  defaultRowsPerPage={2}
                  totalCount={0}
                  isShowPagination={false}
                />
              </div>

              {/* Upload documents */}
              <div style={{ marginBottom: '16px' }}>
                <Typography
                  color="#335DAD"
                  fontSize={'18px'}
                  fontWeight={600}
                  marginBottom={'12px'}
                >
                  Uploaded Document
                </Typography>
                <Grid container spacing={3}>
                  <Grid item lg={6} sx={{ marginBottom: '8px' }}>
                    {cwaConnectorDetails?.connectorDocumentsMap?.document ? (
                      <SingleDocUpload
                        documentsArr={
                          cwaConnectorDetails?.connectorDocumentsMap?.document
                            ? [
                                cwaConnectorDetails?.connectorDocumentsMap
                                  ?.document
                              ]
                            : []
                        }
                        handleSetData={handleSetDocument}
                        onlyView={true}
                        title={'Pan Upload'}
                      />
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 'small',
                          color: 'black'
                        }}
                      >
                        {'Document not found'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
            <div>
              {(menuDetails?.roleModule?.id == 2 ||
                menuDetails?.roleModule?.id == 3) && (
                <div>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="action"
                      name="status"
                      value={statusDetails.status}
                      onChange={e => handleOnChange(e, 'status')} // Use Formik's handleChange
                    >
                      <FormControlLabel
                        value="Approve"
                        control={<Radio />}
                        label="Approve"
                      />
                      <FormControlLabel
                        value="Reject"
                        control={<Radio />}
                        label="Reject"
                      />
                      <FormControlLabel
                        value="Hold"
                        control={<Radio />}
                        label="On Hold"
                      />
                    </RadioGroup>
                    {/* {formik.touched.action && (
                  <Typography color="error">{formik.errors.action}</Typography>
                )} */}
                  </FormControl>
                  {validation?.statusVal && (
                    <FormHelperText>{validation?.statusVal}</FormHelperText>
                  )}
                  {statusDetails.status !== 'Approve' && (
                    <TextField
                      label="Reason"
                      multiline
                      name="reason"
                      value={statusDetails.reason || ''}
                      onChange={e => handleOnChange(e, 'reason')}
                      error={validation?.reasonVal}
                      helperText={validation?.reasonVal}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                  marginTop: '16px'
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ marginRight: '16px' }}
                  onClick={handleRedirect}
                >
                  CANCEL
                </Button>
                {(menuDetails?.roleModule?.id == 2 ||
                  menuDetails?.roleModule?.id == 3) && (
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ marginRight: '16px' }}
                    onClick={handleUpdateStatus}
                  >
                    SUBMIT
                  </LoadingButton>
                )}
              </div>

              {isSuccessModalOpen && (
                <ResponseModal
                  type="success"
                  message={updateConnectorStatusResponse}
                  buttonText="OK"
                  navigationPath={
                    menuDetails?.roleModule?.id === 2
                      ? '/cwaf/connector-approval-co'
                      : menuDetails?.roleModule?.id === 3
                        ? '/cwaf/connector-approval-ho'
                        : '/cwaf/connector-onboarding'
                  }
                  onClose={() => setIsSuccessModalOpen(false)}
                />
              )}

              {/* {isErrorModalOpen && (
            <ResponseModal
              open={isErrorModalOpen}
              type="error"
              message={updateConnectorStatusErrorContainer?.message}
              buttonText="OK"
              navigationPath={
                menuDetails?.roleModule?.id === 2
                  ? '/connector-approval-co'
                  : menuDetails?.roleModule?.id === 3
                    ? '/connector-approval-ho'
                    : '/connector-onboarding'
              }
              onClose={() => setIsErrorModalOpen(false)}
            />
          )} */}
            </div>
          </>
        )}

        {/* {!updateConnectorStatusLoading && updateConnectorStatusSuccess && (
          <SnackBarBox type="success" message={updateConnectorStatusResponse} />
        )} */}

        {!updateConnectorStatusLoading && updateConnectorStatusFailed && (
          <SnackBarBox
            type="error"
            message={handleError(updateConnectorStatusErrorContainer?.message)}
          />
        )}
      </div>
    </>
  );
}

export default CWAConnectorViewDetails;
