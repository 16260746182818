import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import CustomDataTable from '../components/CustomDataTable';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import DownloadButton from '../components/DownloadAsButton';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { VisibilityOutlined, FilterAltOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { clearAllNotification } from '../redux/reducers/authSlice';
import SnackBarBox from '../components/SnackBarBox';
import { createTitle, handleError } from '../utils/utils';
import {
  getConnectorList,
  getStatusList,
  revertConnectorPageData
} from '../redux/reducers/connectorApprovalSlice';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { VIEW_AUDIT_LOG } from '../utils/imageUrls';
import CloseIcon from '@mui/icons-material/Close';

export default function ConnectorApprovalPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [initialValues, setInitalValues] = useState({
    page: 0,
    limit: 10,
    searchFilter: '',
    statusIds: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuAnchors, setMenuAnchors] = useState({});
  const [statusFilter, setStatusFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const {
    connectorData,
    isUpdateConnectorDataLoading,
    isUpdateConnectorDataSuccess,
    isUpdateConnectorDataFailed,
    UpdateConnectorDataErrorContainer,
    statusList
  } = useSelector(state => state.connector);

  useEffect(() => {
    document.title = createTitle('Connector Approval');
    dispatch(setActiveMenu('Connector Approval'));
    dispatch(getStatusList({}));
    return () => {
      dispatch(revertConnectorPageData());
    };
  }, []);

  useEffect(() => {
    if (statusList) {
      let tempStatusList = statusList.map(item => ({
        ...item,
        checked: true
      }));
      setStatusFilter(tempStatusList);
    }
  }, [statusList]);

  useEffect(() => {
    dispatch(
      getConnectorList({
        payload: {
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter,
          ...(initialValues.statusIds && { statusIds: initialValues.statusIds })
        }
      })
    );
    return () => {
      // dispatch(revertConnectorPageData());
      dispatch(clearAllNotification());
    };
  }, [initialValues]);

  const handleClick = (event, empId) => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: event.currentTarget
    }));
  };

  // Function to handle menu closing
  const handleClose = empId => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: null
    }));
  };

  const columns = [
    {
      uinqueKey: 'id',
      id: 'connectorId',
      label: 'Connector Code',
      width: '150px'
      //   sortable: true
      //   render: row => (
      //     <>
      //       <a
      //         style={{
      //           textDecoration: 'underline',
      //           textUnderlineOffset: '3px',
      //           color: '#0000FF'
      //         }}
      //         onClick={() => handleOpenHierarchyModal(row.empId)}
      //       >
      //         <Typography variant="body2" fontWeight={'600'} color={'#0000FF'}>
      //           {row.empId}
      //         </Typography>
      //       </a>
      //     </>
      //   )
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'email',
      label: 'Email ID'
    },
    {
      id: 'mobile',
      label: 'Mobile Number'
    },
    {
      id: 'employeeId',
      label: 'Map to Employee ID',
      width: '140px'
    },
    {
      id: 'createdBy',
      label: 'Created By',
      width: '120px'
    },
    {
      id: 'createdDate',
      label: 'Created On',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      width: '120px'
    },
    {
      id: 'updatedDate',
      label: 'Updated On',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.updatedDate
                  ? moment(row.updatedDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'status',
      label: 'Status'
      //   render: row => (
      //     <>
      //       <div
      //         style={{
      //           fontWeight: '600',
      //           backgroundColor: row.isActive ? '#EBF9D9' : '#FDF0E3',
      //           padding: '3px', // p-1
      //           borderRadius: '1.25rem',
      //           display: 'flex',
      //           justifyContent: 'center',
      //           alignItems: 'center',
      //           minWidth: 90,
      //           color: row.isActive ? '#749E35' : '#D62828'
      //         }}
      //       >
      //         <Avatar
      //           sx={{
      //             width: 8,
      //             height: 8,
      //             display: 'flex',
      //             alignItems: 'center',
      //             justifyContent: 'center',
      //             borderRadius: '50%',
      //             backgroundColor: row.isActive ? '#749E35' : '#D62828',
      //             color: 'white',
      //             fontSize: '12px',
      //             marginRight: '5px'
      //           }}
      //         >
      //           <></>
      //         </Avatar>
      //         {row.isActive == 'Y' ? 'Active' : 'Inactive'}
      //       </div>
      //     </>
      //   )
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.connectorId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.connectorId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.connectorId}`}
            anchorEl={menuAnchors[row.connectorId]}
            keepMounted
            open={Boolean(menuAnchors[row.connectorId])}
            onClose={() => handleClose(row.connectorId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              //   onClick={handleClose}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
              onClick={() => {
                return navigate(`view`, {
                  state: { connectorId: row.connectorId, status: row.status }
                });
              }}
            >
              <VisibilityOutlined
                fontSize="small"
                sx={{ marginRight: '16px' }}
              />
              <Typography variant="inherit">VIEW</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                return navigate(`view-audit-logs`, {
                  state: { connectorId: row.connectorId }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <img
                src={VIEW_AUDIT_LOG}
                alt="View Audit Logs"
                style={{
                  width: 19,
                  height: 20,
                  marginRight: '14px',
                  marginLeft: '3px'
                }}
              />
              {/* <HistoryOutlined /> */}
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term, page: 0 }),
    300
  );

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 2 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleDone = () => {
    setOpenModal(false);
    let trueIds = statusFilter
      .filter(item => item.checked === true)
      .map(item => item.id)
      .join(',');
    setInitalValues(prevState => ({
      ...prevState,
      statusIds: trueIds
    }));
  };

  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleFilter = () => {
    setOpenModal(true);
  };

  const handleFilterChange = (id, checked) => {
    let tempFilterStatus = statusFilter.map(item => {
      if (item.id === id) {
        return { ...item, checked };
      }
      return item;
    });
    setStatusFilter(tempFilterStatus);
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '8px',
            marginLeft: '24px',
            marginRight: '24px',
            width: 'full'
          }}
        >
          <div>
            <TextField
              id="search-field"
              // label="Search"
              size="small"
              variant="outlined"
              value={searchTerm}
              placeholder="Search..."
              onChange={handleSearchChange}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '50px',
                  borderColor: 'GrayText',
                  width: '330px'
                }
              }}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ marginRight: '16px' }} onClick={handleFilter}>
              <FilterAltOutlined sx={{ color: '#335DAD' }} />
            </IconButton>
            {connectorData?.connectorList?.length > 0 && (
              <div>
                <DownloadButton
                  menu="Connector"
                  searchData={searchTerm ? searchTerm : ''}
                  statusFilter={
                    initialValues.statusIds ? initialValues.statusIds : ''
                  }
                />
              </div>
            )}
          </div>
        </div>
        <CustomDataTable
          columns={columns}
          data={
            connectorData?.connectorList.length > 0
              ? connectorData?.connectorList
              : []
          }
          onPageChange={val => {
            setInitalValues({ ...initialValues, page: val });
          }}
          onRowsPerPageChange={val => {
            setInitalValues({ ...initialValues, limit: val, page: 0 });
          }}
          defaultPage={initialValues.page}
          //   selectedRows={[...selectedUserGroups]}
          //   onSelectionChange={val => {
          //     setSelectedUserGroups(val);
          //   }}
          defaultRowsPerPage={initialValues.limit}
          totalCount={connectorData?.totalCount}
          isShowPagination={true}
        />
        <ColumnFilterModal
          open={openModal}
          onCancel={handleCancel}
          onDone={handleDone}
          onColumnChange={handleFilterChange}
          columns={statusFilter}
        />
        {/* {isModalOpen && (
          <ConfirmBox
            title="Resend Login Email"
            subtitle={`Are you sure you want to resend login email to Employee ID ${empId}? `}
            isOpen={isModalOpen}
            handleClose={handleModalClose}
            handleSubmit={() => handleResendEmail(empId)}
          />
        )} */}
      </div>

      {!isUpdateConnectorDataLoading && isUpdateConnectorDataSuccess && (
        <SnackBarBox type="success" message={'Action performed successfully'} />
      )}

      {!isUpdateConnectorDataLoading && isUpdateConnectorDataFailed && (
        <SnackBarBox
          type="error"
          message={handleError(UpdateConnectorDataErrorContainer?.message)}
        />
      )}

      {/* {!isGetConnectorLoading && isGetConnectorFailed && (
        <SnackBarBox
          type="error"
          message={handleError(getConnectorErrorContainer?.message)}
        />
      )} */}
    </>
  );
}

const ColumnFilterModal = ({
  open,
  onCancel,
  onDone,
  onColumnChange,
  columns
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
  >
    <DialogTitle
      sx={{
        padding: '16px',
        minWidth: '300px',
        mb: 2,
        fontWeight: 'bold',
        fontSize: 'medium',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      Status Filter
      <IconButton onClick={onCancel} sx={{ color: 'grey.800' }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column">
        {columns?.map(column => (
          <FormControlLabel
            sx={{ padding: '4px' }}
            key={column.id}
            control={
              <Checkbox
                checked={column.checked}
                onChange={e => onColumnChange(column.id, e.target.checked)}
              />
            }
            label={column.name} // Display the label instead of the id
          />
        ))}
      </Box>
    </DialogContent>
    <DialogActions
      sx={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly' }}
    >
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        CANCEL
      </Button>
      <Button
        onClick={onDone}
        variant="contained"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        DONE
      </Button>
    </DialogActions>
  </Dialog>
);
