import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createTitle, handleError } from '../../../utils/utils';
import CenteredLogo from '../../../components/CenteredLogo';
import { IMG_LOGIN_BG } from '../../../utils/imageUrls';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAllNotification,
  clearOtpNotification,
  sendOtpForResetPassword,
  setResetPasswordDetails
} from '../../../redux/reducers/authSlice';
import Loader from '../../../components/Loader';
import SnackBarBox from '../../../components/SnackBarBox';
import {
  OTP_RE_SENT_TO_NEW_MOBILE_NO,
  OTP_SENT_TO_NEW_MOBILE_NO,
  RESETING_OTP,
  RE_SENDING_OTP,
  SENDING_OTP,
  VERIFYING_OTP
} from '../../../utils/messages';

import ForgotPasswordForm from './ForgotPasswordForm';
import VerifyOtpForm from './VerifyOtpForm';
import NewPasswordForm from './NewPasswordForm';
import LoginIcon from '@mui/icons-material/Login';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

function ResetPassword() {
  // const { moduleName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [redirectCount, setRedirectCount] = useState(10);

  const oneMinuteFromNow = 60;

  const [otpExpired, setOTPExpired] = useState(false);

  const {
    isSendOtpForResetPasswordLoading,
    isSendOtpForResetPasswordSuccess,
    isSendOtpForResetPasswordFailed,
    sendOtpForResetPasswordErrorContainer,
    isVerifyOtpForResetPasswordLoading,
    isVerifyOtpForResetPasswordSuccess,
    isVerifyOtpForResetPasswordFailed,
    verifyOtpForResetPasswordErrorContainer,
    isReSendOtpForResetPasswordLoading,
    isReSendOtpForResetPasswordSuccess,
    isReSendOtpForResetPasswordFailed,
    reSendOtpForResetPasswordErrorContainer,
    resetOtpDetails,
    isSubmitResetPasswordLoading,
    isSubmitResetPasswordSuccess,
    isSubmitResetPasswordFailed,
    submitResetPasswordErrorContainer
  } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Forgot Password');
    return () => dispatch(clearOtpNotification());
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (redirectCount > 0) {
        setRedirectCount(redirectCount - 1);
      }
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [redirectCount]);

  const redirectToLogin = useCallback(() => {
    dispatch(clearAllNotification());
    // navigate('/login');
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  }, [navigate]);

  useEffect(() => {
    if (isSubmitResetPasswordSuccess === true) {
      setRedirectCount(10);
      const timeoutId = setTimeout(redirectToLogin, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitResetPasswordSuccess, redirectToLogin]);

  const handleManuallyRedirectToLogin = () => {
    dispatch(clearAllNotification());
    // navigate('/login');
    process.env.REACT_APP_PORTAL_TYPE === 'ADMIN'
      ? navigate('/adminf/login')
      : navigate('/cwaf/login');
    return;
  };

  const formik = useFormik({
    initialValues: {
      empId: '',
      userType: 'employee'
    },
    validationSchema: yup.object({
      empId: yup.string()
      // .test(
      //   'is-valid-mobile-number',
      //   'The mobile number should consist of exactly 10 digits.',
      //   value => isValidMobileNumber(value)
      // )
    }),
    onSubmit: values => {
      if (values.empId) {
        // const trimmedMobileNo = values.mobileNo.trim();
        const dataToBeSent = {
          empId: values.empId,
          userType: 'employee'
        };
        dispatch(setResetPasswordDetails({ empId: values.empId, otp: '' }));
        dispatch(sendOtpForResetPassword(dataToBeSent));
      }
    }
  });

  useEffect(() => {
    if (
      (!isSendOtpForResetPasswordLoading && isSendOtpForResetPasswordFailed) ||
      (!isReSendOtpForResetPasswordLoading && isReSendOtpForResetPasswordFailed)
    ) {
      dispatch(setResetPasswordDetails({ empId: null, otp: null }));
    }
  }, [isSendOtpForResetPasswordFailed, isReSendOtpForResetPasswordFailed]);

  return (
    <>
      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${IMG_LOGIN_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        />
        <CustomGrid item xs={12} sm={5} md={5} lg={5} elevation={6}>
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CenteredLogo />

            <Box>
              {!resetOtpDetails?.empId && !resetOtpDetails?.otp && (
                <>
                  <Typography
                    align="center"
                    component="h1"
                    variant="h6"
                    fontWeight={'700'}
                    color={'#000'}
                  >
                    Forgot Password
                  </Typography>
                  <ForgotPasswordForm formik={formik} />
                </>
              )}

              {(isSendOtpForResetPasswordSuccess ||
                isReSendOtpForResetPasswordSuccess ||
                isVerifyOtpForResetPasswordFailed) && (
                <>
                  <Typography
                    align="center"
                    component="h1"
                    variant="h6"
                    fontWeight={'700'}
                    color={'#000'}
                  >
                    Forgot Password
                  </Typography>
                  <VerifyOtpForm
                    empId={resetOtpDetails?.empId}
                    oneMinuteFromNow={oneMinuteFromNow}
                    otpExpired={otpExpired}
                    setOTPExpired={setOTPExpired}
                  />
                </>
              )}

              {!isVerifyOtpForResetPasswordLoading &&
                isVerifyOtpForResetPasswordSuccess &&
                !isSubmitResetPasswordSuccess && (
                  <>
                    <Typography
                      align="center"
                      component="h1"
                      variant="h6"
                      fontWeight={'700'}
                      color={'#000'}
                    >
                      Reset Password
                    </Typography>
                    <NewPasswordForm
                      empId={resetOtpDetails?.empId}
                      // otp={resetOtpDetails?.otp}
                    />
                  </>
                )}
            </Box>

            {!isSubmitResetPasswordLoading && isSubmitResetPasswordSuccess && (
              <Box style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Your password has been reset successfully!
                </Typography>
                <Typography variant="body2" gutterBottom>
                  You will be redirected to the login page in {redirectCount}{' '}
                  seconds.
                </Typography>
                <Typography variant="body2" marginTop={2}>
                  If not auto-redirected, click below button to login page
                  manually.
                </Typography>
                <Button
                  sx={{ mt: '1rem' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleManuallyRedirectToLogin}
                >
                  <LoginIcon sx={{ mr: '10px' }} /> Go to Login
                </Button>
              </Box>
            )}
          </Box>
        </CustomGrid>

        {isSendOtpForResetPasswordLoading && (
          <Loader key="send_otp" text={SENDING_OTP} />
        )}

        {isVerifyOtpForResetPasswordLoading && (
          <Loader key="verify_otp" text={VERIFYING_OTP} />
        )}

        {isReSendOtpForResetPasswordLoading && (
          <Loader key="re_send_otp" text={RE_SENDING_OTP} />
        )}

        {isSubmitResetPasswordLoading && (
          <Loader key="reset_password" text={RESETING_OTP} />
        )}

        {!isSendOtpForResetPasswordLoading &&
          isSendOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                sendOtpForResetPasswordErrorContainer.message
              )}
            />
          )}

        {!isReSendOtpForResetPasswordLoading &&
          isReSendOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                reSendOtpForResetPasswordErrorContainer.message
              )}
            />
          )}

        {!isVerifyOtpForResetPasswordLoading &&
          isVerifyOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                verifyOtpForResetPasswordErrorContainer.message
              )}
            />
          )}

        {!isSubmitResetPasswordLoading && isSubmitResetPasswordFailed && (
          <SnackBarBox
            type="error"
            message={handleError(submitResetPasswordErrorContainer.message)}
          />
        )}

        {!isSendOtpForResetPasswordLoading &&
          isSendOtpForResetPasswordSuccess && (
            <SnackBarBox type="success" message={OTP_SENT_TO_NEW_MOBILE_NO} />
          )}

        {!isReSendOtpForResetPasswordLoading &&
          isReSendOtpForResetPasswordSuccess && (
            <SnackBarBox
              type="success"
              message={OTP_RE_SENT_TO_NEW_MOBILE_NO}
            />
          )}
      </Grid>
    </>
  );
}

export default ResetPassword;
