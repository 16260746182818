import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCityStateByPincode } from '../../redux/reducers/masterManagementSlice';

function PropertyDetails({ formik }) {
  const dispatch = useDispatch();
  const [cityState, setCityState] = useState('');
  const { cityStateDetails } = useSelector(state => state.masters);

  useEffect(() => {
    if (cityState == 'property') {
      formik.setFieldValue('propCity', cityStateDetails?.city);
      setTimeout(() => {
        formik.setFieldValue('propState', cityStateDetails?.state);
      }, 300);
    }
    setCityState('');
  }, [cityStateDetails]);

  const handlePropPincodeChange = (event, name) => {
    setCityState(name);
    const { value } = event.target;
    formik.setFieldValue('propPincode', value);
    if (value.length === 6) {
      dispatch(getCityStateByPincode(value));
    }
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Property Details
        </Typography>
        <Grid container spacing={3} marginBottom={'16px'}>
          <Grid item lg={4}>
            <TextField
              label="Address line 1 "
              name="propAddressLine1"
              size="small"
              fullWidth
              value={formik.values.propAddressLine1}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.propAddressLine1 !== ''
              }}
              // error={
              //   formik.touched.addressLine1 &&
              //   Boolean(formik.errors.propAddressLine1)
              // }
              // helperText={
              //   formik.touched.propAddressLine1 &&
              //   formik.errors.propAddressLine1
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Address line 2 "
              name="propAddressLine2"
              size="small"
              fullWidth
              value={formik.values.propAddressLine2}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.propAddressLine2 !== ''
              }}
              // error={
              //   formik.touched.propAddressLine2 &&
              //   Boolean(formik.errors.propAddressLine2)
              // }
              // helperText={
              //   formik.touched.propAddressLine2 &&
              //   formik.errors.propAddressLine2
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Landmark"
              name="propLandmark"
              size="small"
              fullWidth
              value={formik.values.propLandmark}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.propLandmark !== '' }}
              // error={
              //   formik.touched.propLandmark &&
              //   Boolean(formik.errors.propLandmark)
              // }
              // helperText={
              //   formik.touched.propLandmark && formik.errors.propLandmark
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Pincode "
              name="propPincode"
              size="small"
              fullWidth
              value={formik.values.propPincode}
              onChange={e => handlePropPincodeChange(e, 'property')}
              InputLabelProps={{ shrink: formik.values.propPincode !== '' }}
              // error={
              //   formik.touched.propPincode && Boolean(formik.errors.propPincode)
              // }
              // helperText={
              //   formik.touched.propPincode && formik.errors.propPincode
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="City "
              name="propCity"
              size="small"
              fullWidth
              value={formik.values.propCity}
              InputLabelProps={{ shrink: formik.values.propCity !== '' }}
              // error={formik.touched.propCity && Boolean(formik.errors.propCity)}
              // helperText={formik.touched.propCity && formik.errors.propCity}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              disabled
              label="State "
              name="propState"
              size="small"
              fullWidth
              value={formik.values.propState}
              InputLabelProps={{ shrink: formik.values.propState !== '' }}
              // error={
              //   formik.touched.propState && Boolean(formik.errors.propState)
              // }
              // helperText={formik.touched.propState && formik.errors.propState}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default PropertyDetails;
