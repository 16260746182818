import { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';

function GenericInput({
  label,
  startIcon,
  error,
  value,
  endIcon,
  onEndIconClick,
  ...props
}) {
  const [isShrinked, setIsShrinked] = useState(value !== '');

  const handleFocus = () => {
    setIsShrinked(true);
  };

  const handleBlur = e => {
    if (!e.target.value) setIsShrinked(false);
  };

  useEffect(() => {
    if (value && value !== '') {
      setIsShrinked(true);
    }
  }, [value]);

  // Function to convert error message string to HTML, excluding the first line from the bullet list
  const formatErrorMessage = errorMessage => {
    if (!errorMessage) return null;

    // Split the error message by new lines
    const lines = errorMessage.split('\n');
    // Extract the first line and the rest of the lines
    const firstLine = lines.shift();
    const bulletListLines = lines;

    return (
      <>
        <div>{firstLine}</div>
        <ul style={{ margin: 0, padding: '0 0 0 20px' }}>
          {bulletListLines.map((line, index) => (
            <li key={index} style={{ listStyleType: 'disc' }}>
              {line}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <TextField
      {...props}
      fullWidth
      label={label}
      variant="outlined"
      onFocus={handleFocus}
      onBlur={handleBlur}
      error={error ? true : false}
      helperText={formatErrorMessage(error)}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {startIcon && (
              <span
                style={{
                  padding: '0.5rem',
                  // cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                {startIcon}
              </span>
            )}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {endIcon && (
              <span
                onClick={onEndIconClick}
                style={{
                  padding: '0.5rem',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center'
                }}
              >
                {endIcon}
              </span>
            )}
          </InputAdornment>
        ),
        tabIndex: -1
      }}
      InputLabelProps={{
        shrink: isShrinked,
        style: { paddingLeft: isShrinked ? '8px' : '30px' }
      }}
    />
  );
}

export default GenericInput;
