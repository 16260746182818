import { Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import CustomDataTable from '../components/CustomDataTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getLeadAuditLogs,
  getLeadDetails,
  revertLeadPageData
} from '../redux/reducers/leadSlice';
import { createTitle } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { colorPrimaryBlue } from '../config/theme';
import ConvertBase64ToSrcURL from '../components/ConvertBase64ToSrcURL';

const ExpandableRow = ({ details }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const logs = details.split('~');
  const displayLogs = isExpanded ? logs : logs.slice(0, 1);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div>
        {displayLogs.map((log, index) => (
          <Typography
            key={index}
            variant="body2"
            color={colorPrimaryBlue}
            sx={{ fontWeight: '600' }}
            gutterBottom
          >
            {log}
          </Typography>
        ))}
        <span>
          {logs.length > 1 && (
            <Button
              onClick={handleToggle}
              size="small"
              style={{ textDecoration: 'underline' }}
            >
              {isExpanded ? '...Less' : '...More'}
            </Button>
          )}
        </span>
      </div>
    </>
  );
};

function CWALeadViewAuditLogs() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialValues, setInitalValues] = useState({
    page: 0,
    limit: 10,
    searchFilter: ''
  });

  const { leadAuditLogData, leadDetails } = useSelector(state => state.lead);

  const leadId = location.state.leadId;
  const customerId = location.state.customerId;

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    return () => {
      //   dispatch(revertPurposeSchemePageData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeadDetails({ leadId, customerId }));
  }, [leadId, customerId]);

  useEffect(() => {
    dispatch(
      getLeadAuditLogs({
        payload: {
          leadId: leadId,
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter
        }
      })
    );
    return () => dispatch(revertLeadPageData());
  }, [initialValues]);

  const basicGridItems = useMemo(
    () => [
      {
        label: 'Applicant ID',
        value: leadDetails?.customerDetails?.customerId
          ? leadDetails?.customerDetails?.customerId
          : '-'
      },
      {
        label: 'Lead ID',
        value: leadDetails?.leadDetails?.applicationId
          ? leadDetails?.leadDetails?.applicationId
          : '-'
      },
      {
        label: 'Name',
        value: leadDetails?.customerDetails?.name
          ? leadDetails?.customerDetails?.name
          : '-'
      },
      {
        label: 'Email ID',
        value: leadDetails?.customerDetails?.emailId
          ? leadDetails?.customerDetails?.emailId
          : '-'
      },
      {
        label: 'Phone Number',
        value: leadDetails?.customerDetails?.mobileNumber
          ? leadDetails?.customerDetails?.mobileNumber
          : '-'
      }
    ],
    [leadDetails]
  );

  // const uploadGridItems = useMemo(
  //   () => [
  //     {
  //       label: 'Photo',
  //       value: leadDetails?.customerDetails?.photo
  //         ? leadDetails?.customerDetails?.photo
  //         : '-'
  //     },

  //   ],
  //   [leadDetails]
  // );

  const currAddressGridItems = useMemo(
    () => [
      {
        label: 'Address Line 1',
        value: leadDetails?.customerDetails?.customerAddress
          ?.currentAddressLine1
          ? leadDetails?.customerDetails?.customerAddress?.currentAddressLine1
          : '-'
      },
      {
        label: 'Address Line 2',
        value: leadDetails?.customerDetails?.customerAddress
          ?.currentAddressLine2
          ? leadDetails?.customerDetails?.customerAddress?.currentAddressLine2
          : '-'
      },
      {
        label: 'Landmark',
        value: leadDetails?.customerDetails?.customerAddress?.currentLandmark
          ? leadDetails?.customerDetails?.customerAddress?.currentLandmark
          : '-'
      },
      {
        label: 'Pincode',
        value: leadDetails?.customerDetails?.customerAddress?.currentPincode
          ?.pincode
          ? leadDetails?.customerDetails?.customerAddress?.currentPincode
              ?.pincode
          : '-'
      },
      {
        label: 'City',
        value: leadDetails?.customerDetails?.customerAddress?.currentCity
          ? leadDetails?.customerDetails?.customerAddress?.currentCity
          : '-'
      },
      {
        label: 'State',
        value: leadDetails?.customerDetails?.customerAddress?.currentState
          ? leadDetails?.customerDetails?.customerAddress?.currentState
          : '-'
      }
    ],
    []
  );

  const permAddressGridItems = useMemo(
    () => [
      {
        label: 'Address Line 1',
        value: leadDetails?.customerDetails?.customerAddress
          ?.permanentAddressLine1
          ? leadDetails?.customerDetails?.customerAddress?.permanentAddressLine1
          : '-'
      },
      {
        label: 'Address Line 2',
        value: leadDetails?.customerDetails?.customerAddress
          ?.permanentAddressLine2
          ? leadDetails?.customerDetails?.customerAddress?.permanentAddressLine2
          : '-'
      },
      {
        label: 'Landmark',
        value: leadDetails?.customerDetails?.customerAddress?.permanentLandmark
          ? leadDetails?.customerDetails?.customerAddress?.permanentLandmark
          : '-'
      },
      {
        label: 'Pincode',
        value: leadDetails?.customerDetails?.customerAddress?.permanentPincode
          ?.pincode
          ? leadDetails?.customerDetails?.customerAddress?.permanentPincode
              ?.pincode
          : '-'
      },
      {
        label: 'City',
        value: leadDetails?.customerDetails?.customerAddress?.permanentCity
          ? leadDetails?.customerDetails?.customerAddress?.permanentCity
          : '-'
      },
      {
        label: 'State',
        value: leadDetails?.customerDetails?.customerAddress?.permanentState
          ? leadDetails?.customerDetails?.customerAddress?.permanentState
          : '-'
      }
    ],
    []
  );

  const kycGridItems = useMemo(
    () => [
      {
        label: 'PAN Number',
        value: leadDetails?.customerDetails?.panNumber
          ? leadDetails?.customerDetails?.panNumber
          : '-'
      },
      {
        label: 'Aadhaar Number',
        value: leadDetails?.customerDetails?.aadhaarNumber
          ? leadDetails?.customerDetails?.aadhaarNumber
          : '-'
      },
      {
        label: 'Passport Number',
        value: leadDetails?.customerDetails?.passportNumber
          ? leadDetails?.customerDetails?.passportNumber
          : '-'
      },
      {
        label: 'Voter ID Number',
        value: leadDetails?.customerDetails?.voterId
          ? leadDetails?.customerDetails?.voterId
          : '-'
      }
    ],
    [leadDetails]
  );

  const employmentGridItems = useMemo(
    () => [
      {
        label: 'Constitution Type',
        value: leadDetails?.customerDetails?.constitutionType
          ? leadDetails?.customerDetails?.constitutionType
          : '-'
      },
      {
        label: 'Business Name',
        value: leadDetails?.customerDetails?.employerOrBusinessName
          ? leadDetails?.customerDetails?.employerOrBusinessName
          : '-'
      },
      {
        label: 'Business Address',
        value: leadDetails?.customerDetails?.employerOrBusinessAddress
          ? leadDetails?.customerDetails?.employerOrBusinessAddress
          : '-'
      },
      {
        label: 'Voter ID Number',
        value: leadDetails?.customerDetails?.employerVoterId
          ? leadDetails?.customerDetails?.employerVoterId
          : '-'
      },
      { label: 'Pincode', value: '' },
      { label: 'City', value: '' },
      { label: 'State', value: '' },
      {
        label: 'Business since',
        value: `${leadDetails?.customerDetails?.employedSinceYear ? leadDetails?.customerDetails?.employedSinceYear : '-'} year ${leadDetails?.customerDetails?.employedSinceMonth ? leadDetails?.customerDetails?.employedSinceMonth : '-'} months`
      }
    ],
    []
  );

  const connectorMappingGridItems = useMemo(
    () => [
      {
        label: 'Business through connector',
        value: leadDetails?.customerDetails?.connector ? 'Yes' : 'No'
      },
      {
        label: 'Connector Name',
        value: leadDetails?.customerDetails?.connector?.name
      }
    ],
    []
  );

  const loanGridItems = useMemo(
    () => [
      {
        label: 'Select Scheme',
        value: leadDetails?.leadDetails?.schemeMaster?.name
          ? leadDetails?.leadDetails?.schemeMaster?.name
          : '-'
      },
      {
        label: 'Purpose of Loan',
        value: leadDetails?.leadDetails?.purposeOfLoan?.purpose
          ? leadDetails?.leadDetails?.purposeOfLoan?.purpose
          : '-'
      },
      {
        label: 'Amount Requested',
        value: leadDetails?.leadDetails?.amountRequested
          ? leadDetails?.leadDetails?.amountRequested
          : '-'
      }
    ],
    []
  );

  const propertyGridItems = useMemo(
    () => [
      {
        label: 'Address Line 1',
        value: leadDetails?.leadDetails?.addressLine1
          ? leadDetails?.leadDetails?.addressLine1
          : '-'
      },
      {
        label: 'Address Line 2',
        value: leadDetails?.leadDetails?.addressLine2
          ? leadDetails?.leadDetails?.addressLine2
          : '-'
      },
      {
        label: 'Landmark',
        value: leadDetails?.leadDetails?.landmark
          ? leadDetails?.leadDetails?.landmark
          : '-'
      },
      {
        label: 'Pincode',
        value: leadDetails?.leadDetails?.pincode?.pincode
          ? leadDetails?.leadDetails?.pincode?.pincode
          : '-'
      },
      {
        label: 'City',
        value: leadDetails?.leadDetails?.pincode?.city
          ? leadDetails?.leadDetails?.pincode?.city
          : '-'
      },
      {
        label: 'State',
        value: leadDetails?.leadDetails?.pincode?.state
          ? leadDetails?.leadDetails?.pincode?.state
          : '-'
      }
    ],
    []
  );

  const leadAuditLogColumns = [
    {
      uinqueKey: 'id',
      id: 'action',
      label: 'Action',
      marginLeft: '16px'
      // width: '150px'
      //   sortable: true
    },
    {
      id: 'performedBy',
      label: 'Performed By'
    },
    {
      id: 'performedOn',
      label: 'Performed On',
      // width: '140px',
      render: row => {
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '24px'
              }}
            >
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'details',
      label: 'Details',
      render: row => {
        return <ExpandableRow details={row.details} />;
      }
    }
  ];

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          View Audit Logs
        </Typography>
        <div>
          {/* Basic details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Basic Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {basicGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* Upload Photo */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Upload Photo
            </Typography>
            <div style={{ marginBottom: '12px' }}>
              {/* <img src="" height={'150px'} width={'200px'} /> */}
              <ConvertBase64ToSrcURL
                base64String={leadDetails?.customerDetails?.photo}
              />
            </div>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* Address details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Address Details
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Current Address
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {currAddressGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Typography
              fontSize={'16px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Permanent Address
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {permAddressGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* KYC details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              KYC Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {kycGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* Employment Details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Employment Details
            </Typography>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: 'medium',
                color: 'black',
                marginBottom: '12px'
              }}
            >
              Self Employee
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {employmentGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* Connector mapping*/}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Connector Mapping
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {connectorMappingGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* co-Applicant data */}
          {leadDetails?.coapplicantDetails?.map((coApplicant, index) => (
            <CoApplicantDetails
              key={index}
              coApplicant={coApplicant}
              index={index}
            />
          ))}
          <Divider style={{ marginBottom: '12px' }} />

          {/* Loan Details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Loan Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {loanGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>

          {/* Property details */}
          <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Property Details
            </Typography>
            <Grid container spacing={2} sx={{ mb: '16px' }}>
              {propertyGridItems.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography
                    sx={{
                      color: 'GrayText',
                      fontSize: 'small',
                      marginBottom: '4px'
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 'small',
                      color: 'black'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Divider style={{ marginBottom: '12px' }} />
          </div>
          {/* <div>
            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'12px'}
            >
              Upload Documents
            </Typography>
            <Divider style={{ marginBottom: '12px' }} />
          </div> */}
        </div>
        <div>
          <div style={{ marginBottom: '8px' }}>
            <CustomDataTable
              columns={leadAuditLogColumns}
              data={
                leadAuditLogData.logList.length > 0
                  ? leadAuditLogData.logList
                  : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, limit: val, page: 0 });
              }}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.limit}
              totalCount={leadAuditLogData?.totalCount}
              isShowPagination={true}
            />
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: '#335DAD',
                marginTop: '12px',
                width: '120px'
              }}
              onClick={() => {
                return navigate('/cwaf/lead');
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CWALeadViewAuditLogs;

const CoApplicantDetails = ({ coApplicant, index }) => {
  const coApBasicGridItems = useMemo(
    () => [
      { label: 'Name', value: coApplicant?.name || '-' },
      { label: 'PAN Number', value: coApplicant?.panNumber || '-' },
      { label: 'Phone Number', value: coApplicant?.mobileNumber || '-' },
      { label: 'Email ID', value: coApplicant?.emailId || '-' }
    ],
    [coApplicant]
  );

  const coApCurrAddressGridItems = useMemo(
    () => [
      {
        label: 'Address Line 1',
        value: coApplicant?.currentAddressLine1 || '-'
      },
      {
        label: 'Address Line 2',
        value: coApplicant?.currentAddressLine2 || '-'
      },
      { label: 'Landmark', value: coApplicant?.currentLandmark || '-' },
      { label: 'Pincode', value: coApplicant?.currentPincode?.pincode || '-' },
      { label: 'City', value: coApplicant?.currentCity || '-' },
      { label: 'State', value: coApplicant?.currentState || '-' }
    ],
    [coApplicant]
  );

  const coApPermAddressGridItems = useMemo(
    () => [
      {
        label: 'Address Line 1',
        value: coApplicant?.permanentAddressLine1 || '-'
      },
      {
        label: 'Address Line 2',
        value: coApplicant?.permanentAddressLine2 || '-'
      },
      { label: 'Landmark', value: coApplicant?.permanentLandmark || '-' },
      {
        label: 'Pincode',
        value: coApplicant?.permanentPincode?.pincode || '-'
      },
      { label: 'City', value: coApplicant?.permanentCity || '-' },
      { label: 'State', value: coApplicant?.permanentState || '-' }
    ],
    [coApplicant]
  );

  const coApKycGridItems = useMemo(
    () => [
      { label: 'PAN Number', value: coApplicant?.panNumber || '-' },
      { label: 'Aadhaar Number', value: coApplicant?.aadhaarNumber || '-' },
      { label: 'Passport Number', value: coApplicant?.passportNumber || '-' },
      { label: 'Voter ID Number', value: coApplicant?.voterId || '-' }
    ],
    [coApplicant]
  );

  const coApEmploymentGridItems = useMemo(
    () => [
      {
        label: 'Constitution Type',
        value: coApplicant?.constitutionType || '-'
      },
      {
        label: 'Business Name',
        value: coApplicant?.employerOrBusinessName || '-'
      },
      {
        label: 'Business Address',
        value: coApplicant?.employerOrBusinessAddress || '-'
      },
      { label: 'Voter ID Number', value: coApplicant?.employerVoterId || '-' },
      { label: 'Pincode', value: coApplicant?.pincode || '-' },
      { label: 'City', value: coApplicant?.city || '-' },
      { label: 'State', value: coApplicant?.state || '-' },
      {
        label: 'Business since',
        value: `${coApplicant?.employedSinceYear || '-'} year ${coApplicant?.employedSinceMonth || '-'} months`
      }
    ],
    [coApplicant]
  );

  return (
    <div
      style={{
        // margin: '20px',
        backgroundColor: 'white',
        borderRadius: '12px'
        // padding: '16px'
      }}
    >
      <Typography
        color="#335DAD"
        fontSize={'18px'}
        fontWeight={600}
        marginBottom={'12px'}
      >
        Co-Applicant - {index + 1}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: 'medium',
          color: 'black',
          mb: '16px'
        }}
      >
        {coApplicant.name}
      </Typography>

      {/* Basic Details */}
      <Typography fontSize={'18px'} fontWeight={600} marginBottom={'12px'}>
        Basic Details
      </Typography>
      <Grid container spacing={2} sx={{ mb: '16px' }}>
        {coApBasicGridItems.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ marginBottom: '8px' }}
          >
            <Typography
              sx={{ color: 'GrayText', fontSize: 'small', marginBottom: '4px' }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
            >
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Upload Photo */}
      <Typography fontSize={'18px'} fontWeight={600} marginBottom={'12px'}>
        Upload Photo
      </Typography>
      <div style={{ marginBottom: '12px' }}>
        <ConvertBase64ToSrcURL base64String={coApplicant?.photo} />
      </div>

      {/* Current Address */}
      <Typography fontSize={'16px'} fontWeight={600} marginBottom={'12px'}>
        Current Address
      </Typography>
      <Grid container spacing={2} sx={{ mb: '16px' }}>
        {coApCurrAddressGridItems.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ marginBottom: '8px' }}
          >
            <Typography
              sx={{ color: 'GrayText', fontSize: 'small', marginBottom: '4px' }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
            >
              {/* {item.value} */}
              {typeof item.value === 'object'
                ? JSON.stringify(item.value)
                : item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Permanent Address */}
      <Typography fontSize={'16px'} fontWeight={600} marginBottom={'12px'}>
        Permanent Address
      </Typography>
      <Grid container spacing={2} sx={{ mb: '16px' }}>
        {coApPermAddressGridItems.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ marginBottom: '8px' }}
          >
            <Typography
              sx={{ color: 'GrayText', fontSize: 'small', marginBottom: '4px' }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
            >
              {/* {item.value} */}
              {typeof item.value === 'object'
                ? JSON.stringify(item.value)
                : item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* KYC Details */}
      <Typography fontSize={'18px'} fontWeight={600} marginBottom={'12px'}>
        KYC Details
      </Typography>
      <Grid container spacing={2} sx={{ mb: '16px' }}>
        {coApKycGridItems.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ marginBottom: '8px' }}
          >
            <Typography
              sx={{ color: 'GrayText', fontSize: 'small', marginBottom: '4px' }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
            >
              {/* {item.value} */}
              {typeof item.value === 'object'
                ? JSON.stringify(item.value)
                : item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Employment Details */}
      <Typography fontSize={'18px'} fontWeight={600} marginBottom={'12px'}>
        Employment Details
      </Typography>
      <Grid container spacing={2} sx={{ mb: '16px' }}>
        {coApEmploymentGridItems.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ marginBottom: '8px' }}
          >
            <Typography
              sx={{ color: 'GrayText', fontSize: 'small', marginBottom: '4px' }}
            >
              {item.label}
            </Typography>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
            >
              {/* {item.value} */}
              {typeof item.value === 'object'
                ? JSON.stringify(item.value)
                : item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
