import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import {
  ArrowDropDown,
  AttachmentRounded,
  VisibilityOutlined,
  FilterAltOutlined
} from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomDataTable from '../components/CustomDataTable';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import DownloadButton from '../components/DownloadAsButton';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { BlockOutlined, CheckCircleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import ConfirmBox from '../components/ConfirmBox';
import { clearAllNotification } from '../redux/reducers/authSlice';
import SnackBarBox from '../components/SnackBarBox';
import { createTitle, handleError } from '../utils/utils';
import {
  downloadConnectorPdf,
  downloadConnectorSampleFormat,
  getConnectorList,
  getStatusList,
  revertConnectorPageData,
  revertCwaConnectorPageData,
  updateCwaConnectorStatus,
  uploadConnectorBulkUpload
} from '../redux/reducers/connectorApprovalSlice';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddIcon from '@mui/icons-material/Add';
import ModalDialog from '../components/ModalDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { ICON_UPLOAD, VIEW_AUDIT_LOG } from '../utils/imageUrls';
import { LoadingButton } from '@mui/lab';
import ResponseModal from '../components/ResponseModal';
import CloseIcon from '@mui/icons-material/Close';

export default function CWAConnectorOnboarding() {
  const [searchTerm, setSearchTerm] = useState('');
  const [initialValues, setInitalValues] = useState({
    page: 0,
    limit: 10,
    searchFilter: '',
    statusIds: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AddConnectorButtonRef = React.useRef();
  const [addConnectorAnchorEl, setAddConnectorAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuAnchors, setMenuAnchors] = useState({});
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [removePopUpId, setRemovePopUpId] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const {
    connectorData,
    isUpdateConnectorDataLoading,
    isUpdateConnectorDataSuccess,
    isUpdateConnectorDataFailed,
    UpdateConnectorDataErrorContainer,
    isCWAConnectorDetailsLoading,
    isCWAConnectorDetailsFailed,
    cwaConnectorDetailsErrorContainer,
    // For Status
    updateConnectorStatusResponse,
    updateConnectorStatusLoading,
    updateConnectorStatusSuccess,
    updateConnectorStatusFailed,
    updateConnectorStatusErrorContainer,
    // For Bulk Upload
    isUploadConnectorDataLoading,
    isUploadConnectorDataSuccess,
    isExcelAvailable,
    isUploadConnectorDataFailure,
    uploadConnectorDataErrorContainer,
    uploadConnectorDataStatus,

    // For Download PDF
    isDownloadLoading,
    statusList
  } = useSelector(state => state.connector);

  useEffect(() => {
    document.title = createTitle('Connector Onboarding');
    dispatch(setActiveMenu('Connector Onboarding'));
    dispatch(getStatusList({}));
    return () => {
      dispatch(revertConnectorPageData());
      dispatch(revertCwaConnectorPageData());
    };
  }, []);

  useEffect(() => {
    if (statusList) {
      let tempStatusList = statusList.map(item => ({
        ...item,
        checked: true
      }));
      setStatusFilter(tempStatusList);
    }
  }, [statusList]);

  useEffect(() => {
    dispatch(
      getConnectorList({
        payload: {
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter,
          ...(initialValues.statusIds && { statusIds: initialValues.statusIds })
        }
      })
    );
    return () => {
      // dispatch(revertCwaConnectorPageData());
      dispatch(clearAllNotification());
    };
  }, [initialValues, updateConnectorStatusSuccess]);

  useEffect(() => {
    if (!updateConnectorStatusLoading && updateConnectorStatusSuccess) {
      setIsSuccessModalOpen(true);
    }
  }, [updateConnectorStatusSuccess]);

  useEffect(() => {
    if (removePopUpId && !isDownloadLoading) {
      handleClose(removePopUpId);
      setRemovePopUpId(null);
    }
  }, [isDownloadLoading]);

  const handleClick = (event, empId) => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: event.currentTarget
    }));
  };

  // Function to handle menu closing
  const handleClose = empId => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: null
    }));
  };

  const handleDownloadPdf = id => {
    if (isDownloadLoading) {
      return;
    }
    dispatch(downloadConnectorPdf({ connectorId: id }));
    setRemovePopUpId(id);
  };

  const columns = [
    {
      uinqueKey: 'id',
      // id: 'connectorId',
      label: 'Connector Code',
      width: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row?.connectorId}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Name'
    },
    {
      id: 'email',
      label: 'Email ID'
    },
    {
      id: 'mobile',
      label: 'Mobile Number'
    },
    {
      id: 'employeeId',
      label: 'Map to Employee ID',
      width: '140px'
    },
    {
      id: 'panNumber',
      label: 'PAN Number'
      // width: '140px'
    },
    {
      id: 'createdBy',
      label: 'Created By',
      width: '120px'
    },
    {
      id: 'createdDate',
      label: 'Created On',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      width: '120px'
    },
    {
      id: 'updatedDate',
      label: 'Updated On',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.updatedDate
                  ? moment(row.updatedDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.connectorId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.connectorId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.connectorId}`}
            anchorEl={menuAnchors[row.connectorId]}
            keepMounted
            open={Boolean(menuAnchors[row.connectorId])}
            onClose={() => handleClose(row.connectorId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              //   onClick={handleClose}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
              onClick={() => {
                return navigate(`edit`, {
                  state: { connectorId: row.connectorId, pageType: 'edit' }
                });
              }}
            >
              <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">EDIT</Typography>
            </MenuItem>
            {(row.status === 'Active' || row.status === 'InActive') && (
              <MenuItem
                onClick={() => {
                  const payload = {
                    connectorId: row.connectorId,
                    status: row.status === 'Active' ? 'InActive' : 'Active'
                  };
                  handleOnClick(payload);

                  // setInitalValues({ ...initialValues, page: 0 });
                }}
                sx={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                  color: 'inherit',
                  '&:hover': { color: '#335DAD' }
                }}
              >
                {row.status === 'Active' ? (
                  <>
                    <BlockOutlined
                      fontSize="small"
                      sx={{ marginRight: '16px' }}
                    />
                    <Typography variant="inherit">DISABLE</Typography>
                  </>
                ) : (
                  <>
                    <CheckCircleOutline
                      fontSize="small"
                      sx={{ marginRight: '16px' }}
                    />
                    <Typography variant="inherit">ENABLE</Typography>
                  </>
                )}
              </MenuItem>
            )}

            <MenuItem
              //   onClick={handleClose}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
              onClick={() => {
                return navigate(`view`, {
                  state: { connectorId: row.connectorId }
                });
              }}
            >
              <VisibilityOutlined
                fontSize="small"
                sx={{ marginRight: '16px' }}
              />

              <Typography variant="inherit">VIEW</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                return navigate(`view-audit-logs`, {
                  state: { connectorId: row.connectorId }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              {/* <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} /> */}
              <img
                src={VIEW_AUDIT_LOG}
                alt="View Audit Logs"
                style={{
                  width: 19,
                  height: 20,
                  marginRight: '14px',
                  marginLeft: '3px'
                }}
              />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
            {row.status !== 'Saved As Draft' && (
              <MenuItem
                //   onClick={handleClose}
                sx={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                  '&:hover': { color: '#335DAD' },
                  backgroundColor: isDownloadLoading && '#D9DDE3',
                  cursor: isDownloadLoading && 'not-allowed'
                }}
                onClick={() => handleDownloadPdf(row.connectorId)}
              >
                <FileDownloadOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: '16px' }}
                />
                <Typography variant="inherit">DOWNLOAD PDF</Typography>

                {isDownloadLoading && (
                  <CircularProgress size={20} sx={{ marginLeft: '16px' }} />
                )}
              </MenuItem>
            )}
          </Menu>
        </>
      )
    }
  ];

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term, page: 0 }),
    300
  );

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term?.length >= 2 || term?.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleAddConnectorClick = event => {
    setAddConnectorAnchorEl(event.currentTarget);
  };

  const handleAddConnectorMenuClose = () => {
    setAddConnectorAnchorEl(null);
  };

  // Bulk Modal Functions

  const handleOpenModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  const handleUpload = event => {
    event.preventDefault();

    if (!uploadedFile) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('product', '1');

    dispatch(uploadConnectorBulkUpload({ payload: formData }));

    setTimeout(() => {
      setIsBulkUploadModalOpen(false);
      setUploadedFile(null);
    }, [500]);
    setAddConnectorAnchorEl(null);
  };

  const handleDownloadFormatClick = event => {
    event.preventDefault();
    dispatch(downloadConnectorSampleFormat());
  };

  const removeFile = () => {
    setUploadedFile(null);
  };
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false, // Allow only a single file
    onDrop: acceptedFiles => {
      // Update the state with the new file
      setUploadedFile(acceptedFiles[0]); // Since only one file is allowed, take the first one
    }
  });

  const handleCloseModal = () => {
    setIsBulkUploadModalOpen(false);
    setUploadedFile(null);
  };

  const handleModalClose = () => {
    handleClose(rowData.connectorId);
    setIsModalOpen(false);
    setRowData(null);
  };

  const handleSubmit = () => {
    let payload = {
      connectorId: rowData.connectorId,
      status: rowData?.status || '',
      reason: ''
    };
    dispatch(updateCwaConnectorStatus({ payload: payload }));
    handleClose(rowData.connectorId);
    setIsModalOpen(false);
    setRowData(null);
  };
  const handleOnClick = data => {
    setIsModalOpen(true);
    setRowData(data);
  };

  const handleDone = () => {
    setOpenModal(false);
    let trueIds = statusFilter
      .filter(item => item.checked === true)
      .map(item => item.id)
      .join(',');
    setInitalValues(prevState => ({
      ...prevState,
      statusIds: trueIds
    }));
  };

  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleFilter = () => {
    setOpenModal(true);
  };

  const handleFilterChange = (id, checked) => {
    let tempFilterStatus = statusFilter.map(item => {
      if (item.id === id) {
        return { ...item, checked };
      }
      return item;
    });
    setStatusFilter(tempFilterStatus);
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '8px',
            marginLeft: '24px',
            marginRight: '24px',
            width: 'full'
          }}
        >
          <div>
            <TextField
              id="search-field"
              // label="Search"
              size="small"
              variant="outlined"
              value={searchTerm}
              placeholder="Search..."
              onChange={handleSearchChange}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '50px',
                  borderColor: 'GrayText',
                  width: '330px'
                }
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // margin: '8px',
              marginRight: '24px',
              width: 'full'
            }}
          >
            <div
              style={{
                // margin: '8px',
                marginRight: '24px'
                // width: 'full'
              }}
            >
              <IconButton sx={{ marginRight: '16px' }} onClick={handleFilter}>
                <FilterAltOutlined sx={{ color: '#335DAD' }} />
              </IconButton>

              <Button
                ref={AddConnectorButtonRef} // Attach the ref to the button
                aria-controls="download-menu"
                aria-haspopup="true"
                onClick={handleAddConnectorClick}
                style={{
                  backgroundColor: '#335DAD',
                  color: 'white',
                  fontSize: 'small',
                  fontWeight: '600'
                }}
                size="large"
                endIcon={<ArrowDropDown />}
              >
                ADD CONNECTOR
              </Button>
              <Menu
                id="download-menu"
                anchorEl={addConnectorAnchorEl}
                keepMounted
                open={Boolean(addConnectorAnchorEl)}
                onClose={handleAddConnectorMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                PaperProps={{
                  style: {
                    minWidth: AddConnectorButtonRef.current
                      ? AddConnectorButtonRef.current.offsetWidth
                      : null // Set the menu's width
                  }
                }}
              >
                <MenuItem
                  onClick={() => {
                    return navigate(`add`, { state: { pageType: 'add' } });
                  }}
                  // disabled={isDownloadUserLoading}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    color: 'inherit',
                    '&:hover': { color: '#335DAD' }
                  }}
                >
                  <AddIcon /> ADD CONNECTOR
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenModal();
                  }}
                  // disabled={isDownloadUserLoading}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    color: 'inherit',
                    '&:hover': { color: '#335DAD' }
                  }}
                >
                  <FileUploadIcon /> BULK UPLOAD
                </MenuItem>
              </Menu>
            </div>
            {connectorData?.connectorList?.length > 0 && (
              <div>
                <DownloadButton
                  menu="Connector"
                  searchData={searchTerm ? searchTerm : ''}
                  statusFilter={
                    initialValues.statusIds ? initialValues.statusIds : ''
                  }
                />
              </div>
            )}
          </div>
        </div>

        {isBulkUploadModalOpen && (
          <ModalDialog
            isShowTitle={true}
            titleName="Bulk Upload"
            fullwidth
            isShowCloseIcon={true}
            handleOnClose={handleCloseModal}
          >
            <form autoComplete="off" onSubmit={handleUpload}>
              <div>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    marginTop: '2rem'
                  }}
                >
                  Download format{' '}
                  <a
                    href="#"
                    onClick={handleDownloadFormatClick}
                    style={{ color: '#335DAD', textDecoration: 'none' }}
                  >
                    here.
                  </a>
                </Typography>
                {/* Render the uploaded file if present */}
                {uploadedFile && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '8px',
                      width: 'full',
                      backgroundColor: '#F9F9F9'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '8px'
                      }}
                    >
                      <AttachmentRounded />
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        {uploadedFile.name}
                      </Typography>
                    </div>
                    <div>
                      <IconButton
                        onClick={removeFile}
                        style={{ marginLeft: '8px' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                )}

                {/* Drag and Drop Container, shown only if no file is uploaded */}
                {!uploadedFile && (
                  <div
                    {...getRootProps()}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      border: '1px dashed #335DAD',
                      padding: '12px',
                      cursor: 'pointer',
                      backgroundColor: '#F3F7FF',
                      borderRadius: '8px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={ICON_UPLOAD}
                      style={{
                        height: '24px',
                        width: '32px'
                        // marginBottom: '16px'
                      }}
                    ></img>
                    <input {...getInputProps()} />
                    <p style={{ color: '#000000' }}>
                      Drag and drop or{' '}
                      <a style={{ color: '#335DAD', textDecoration: 'none' }}>
                        Browse
                      </a>{' '}
                      your file
                    </p>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginTop: '20px'
                  }}
                >
                  <Button variant="outlined" onClick={handleCloseModal}>
                    CANCEL
                  </Button>
                  <LoadingButton variant="contained" type="submit">
                    UPLOAD
                  </LoadingButton>
                </div>
              </div>
            </form>
          </ModalDialog>
        )}
        <CustomDataTable
          columns={columns}
          data={
            connectorData?.connectorList?.length > 0
              ? connectorData?.connectorList
              : []
          }
          onPageChange={val => {
            setInitalValues({ ...initialValues, page: val });
          }}
          onRowsPerPageChange={val => {
            setInitalValues({ ...initialValues, limit: val, page: 0 });
          }}
          defaultPage={initialValues.page}
          //   selectedRows={[...selectedUserGroups]}
          //   onSelectionChange={val => {
          //     setSelectedUserGroups(val);
          //   }}
          defaultRowsPerPage={initialValues.limit}
          totalCount={connectorData?.totalCount}
          isShowPagination={true}
        />
      </div>

      {isModalOpen && (
        <ConfirmBox
          title="Confirmation"
          subtitle={`Are you sure you want to  ${rowData?.status === 'Active' ? 'enable' : 'disable'} connector ?`}
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          handleSubmit={handleSubmit}
        />
      )}

      {!isUpdateConnectorDataLoading && isUpdateConnectorDataSuccess && (
        <SnackBarBox type="success" message={'Action performed successfully'} />
      )}

      {!isUpdateConnectorDataLoading && isUpdateConnectorDataFailed && (
        <SnackBarBox
          type="error"
          message={handleError(UpdateConnectorDataErrorContainer?.message)}
        />
      )}

      {!isCWAConnectorDetailsLoading && isCWAConnectorDetailsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(cwaConnectorDetailsErrorContainer?.message)}
        />
      )}

      {isSuccessModalOpen && (
        <ResponseModal
          type="success"
          message={updateConnectorStatusResponse}
          buttonText="OK"
          // navigationPath={'/connector-onboarding'}
          onClose={() => {
            setIsSuccessModalOpen(false);
            dispatch(revertCwaConnectorPageData());
          }}
        />
      )}

      {/* {!updateConnectorStatusLoading && updateConnectorStatusSuccess && (
        <SnackBarBox
          type="success"
          message={updateConnectorStatusResponse + '---'}
        />
      )} */}

      {!updateConnectorStatusLoading && updateConnectorStatusFailed && (
        <SnackBarBox
          type="error"
          message={handleError(updateConnectorStatusErrorContainer?.message)}
        />
      )}

      {/* {!isGetConnectorLoading && isGetConnectorFailed && (
          <SnackBarBox
            type="error"
            message={handleError(getConnectorErrorContainer?.message)}
          />
        )} */}

      {/* Bulk Upload */}

      {!isUploadConnectorDataLoading &&
        isUploadConnectorDataSuccess &&
        isExcelAvailable === 'false' && (
          <SnackBarBox
            type="success"
            message={handleError(uploadConnectorDataStatus)}
          />
        )}

      {!isUploadConnectorDataLoading &&
        isUploadConnectorDataSuccess &&
        isExcelAvailable === 'true' && (
          <SnackBarBox
            type="error"
            message={handleError(uploadConnectorDataStatus)}
          />
        )}

      {!isUploadConnectorDataLoading && isUploadConnectorDataFailure && (
        <SnackBarBox
          type="error"
          message={handleError(uploadConnectorDataErrorContainer?.Message)}
        />
      )}

      <ColumnFilterModal
        open={openModal}
        onCancel={handleCancel}
        onDone={handleDone}
        onColumnChange={handleFilterChange}
        columns={statusFilter}
      />
    </>
  );
}

const ColumnFilterModal = ({
  open,
  onCancel,
  onDone,
  onColumnChange,
  columns
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
  >
    <DialogTitle
      sx={{
        padding: '16px',
        minWidth: '300px',
        mb: 2,
        fontWeight: 'bold',
        fontSize: 'medium',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      Status Filter
      <IconButton onClick={onCancel} sx={{ color: 'grey.800' }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box display="flex" flexDirection="column">
        {columns?.map(column => (
          <FormControlLabel
            sx={{ padding: '4px' }}
            key={column.id}
            control={
              <Checkbox
                checked={column.checked}
                onChange={e => onColumnChange(column.id, e.target.checked)}
              />
            }
            label={column.name} // Display the label instead of the id
          />
        ))}
      </Box>
    </DialogContent>
    <DialogActions
      sx={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly' }}
    >
      <Button
        onClick={onCancel}
        variant="outlined"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        CANCEL
      </Button>
      <Button
        onClick={onDone}
        variant="contained"
        color="primary"
        sx={{
          fontSize: '0.8rem',
          fontWeight: 'bold',
          width: '100px'
        }}
      >
        DONE
      </Button>
    </DialogActions>
  </Dialog>
);
