export const SENDING_OTP = 'Sending OTP...';

export const RE_SENDING_OTP = 'Re-sending OTP...';

export const VERIFYING_OTP = 'Verifying OTP...';

export const OTP_SENT_TO_NEW_EMAIL_ID = 'OTP sent to your registered Email ID.';

export const EMAIL_UPDATED_SUCCESSFULLY =
  'Your Email ID has been updated successfully.';

export const OTP_SENT_TO_NEW_MOBILE_NO =
  'OTP sent to your registered Mobile Number and Email ID.';

export const OTP_RE_SENT_TO_NEW_MOBILE_NO =
  'OTP re-sent to your registered Mobile Number.';

export const MOBILE_NO_UPDATED_SUCCESSFULLY =
  'Your Mobile Number has been updated successfully.';

export const VERIFIED_OTP_SUCCESSFULLY = 'OTP verified successfully.';

export const PLEASE_WAIT = 'Please wait...';

export const UPDATING_DETAILS = 'Updating details...';

export const NOMINEE_DETAILS_UPDATED_SUCCESSFULLY =
  'Nominee details updated successfully.';

export const SEND_REINVESTMENT_REQUEST = 'Sending Re-Investment request...';

export const SEND_REINVESTMENT_REQUEST_SUCCESS =
  'Re-Investment request submitted successfully.';

export const SEND_WITHDRAWAL_REQUEST = 'Sending withdrawal request...';

export const SEND_WITHDRAWAL_REQUEST_SUCCESS =
  'Withdrawal request submitted successfully.';

export const SEND_REFER_REQUEST = 'Sending Refer request...';

export const SEND_REFER_REQUEST_SUCCESS =
  'Refer request submitted successfully.';

export const SEND_RAISE_COMPLAINT_REQUEST =
  'Sending raise complaint request...';

export const SEND_RAISE_COMPLAINT_REQUEST_SUCCESS =
  'Raise complaint submitted successfully.';

export const REFER_SMS_CONTENT = `Explore Exciting Investment Opportunities with LIC! ${process.env.REACT_APP_PORTAL_URL}`;

export const REFER_SMS_BODY_CONTENT = `Explore Exciting Investment Opportunities with LIC!`;

export const OTP_VERIFICATION_MESSAGE = `OTP Verification`;

export const CANCEL_BUTTON_MESSAGE = `Cancel & Close`;

export const SUBMIT_BUTTON_MESSAGE = `Save & Close`;

export const NEXT_BUTTON_MESSAGE = `Save the details`;

export const LEAD_ADDED_SUCCESS = `Lead added successfully.`;

export const OTP_VERIFIED_SUCCESS = `OTP Verified successfully.`;

export const CREATING_LEAD = `Creating a new lead.`;

export const CONSENT_ACCEPTED_SUCCESSFULLY = `Consent accepted successfully.`;

export const BRANCH_UPDATED_SUCCESSFULLY = 'Branch updated successfully.';

export const RESETING_OTP = 'Resetting password...';

export const RESET_PASSWORD_SUCCESS = 'Password reset successful.';

export const DOCUMENT_UPLOAD_SUCCESS = 'Document uploaded successfully.';

export const DOCUMENT_DELETE_SUCCESS = 'Document deleted successfully.';

export const SELECT_MODULE_MESSAGE =
  'Please select a module before resetting password.';

export const DEVIATION_SUCCESS = 'Deviation approved successfully.';

export const DEVIATION_FAILED = 'Deviation rejected successfully.';

export const NEW_RM_ADD_SUCCESS = 'RM details successfully added.';

export const NEW_RM_EDIT_SUCCESS = 'RM details successfully updated.';

export const NEW_BRANCH_ADD_SUCCESS = 'Branch details successfully added.';

export const NEW_BRANCH_EDIT_SUCCESS = 'Branch details successfully updated.';

export const RM_TRANSFERED_SUCCESS = 'RM transfer successful.';

export const BRANCH_TRANSFERED_SUCCESS = 'Branch transfer successful.';

export const REPORT_DOWNLOAD_SUCCESS =
  'Report has been downloded successfully.';

export const NEW_BUSINESS_ADD_SUCCESS = 'Business details successfully added.';

export const NEW_BUSINESS_EDIT_SUCCESS =
  'Business details successfully updated.';

export const BUSINESS_TRANSFERED_SUCCESS = 'Business transfer successful.';

export const CAPTCHA_ERROR_MSG = 'Invalid Captcha';
