import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import {
  createTitle,
  formatAmountDecimalInINR,
  isArrayNotEmpty
} from '../utils/utils';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  FIXED_DEPOSIT_ICON,
  GENERAL_INSURANCE_ICON,
  HOME_LOAN_ICON,
  IDBI_LOANS_ICON,
  INCENTIVE_ICON,
  LIFE_INSURANCE_ICON,
  MUTUAL_FUND_ICON
} from '../utils/imageUrls';
import {
  getDashboardData,
  getTerritoryList
} from '../redux/reducers/adminSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getMenuList } from '../config/cookie';

export default function DashboardPage() {
  const dispatch = useDispatch();

  const today = dayjs();
  const oneYearAgo = today.subtract(365, 'day');
  const menuDetails = getMenuList();
  const UserId = menuDetails?.roleModule?.id;

  useEffect(() => {
    document.title = createTitle('Dashboard');
    dispatch(setActiveMenu('Dashboard'));
    dispatch(getTerritoryList());
    dispatch(
      getDashboardData({
        code: 'all',
        fromDate: dayjs(oneYearAgo).format('YYYY-MM-DD'),
        toDate: dayjs(today).format('YYYY-MM-DD'),
        userId: UserId
      })
    );
  }, []);

  const { territoryOfficeList, dashboardData, isDashboardDataLoading } =
    useSelector(state => state.admin);
  const [territoryOfficeData, setTerritoryOfficeData] = useState([]);
  const [homeLoanData, setHomeLoanData] = useState([]);
  const [generalInsuranceData, setGeneralInsuranceData] = useState([]);
  const [lifeInsuranceData, setLifeInsuranceData] = useState([]);
  const [fixedDepositData, setFixedDepositData] = useState([]);
  const [mutualFundData, setMutualFundData] = useState([]);
  const [idbiLoanData, setIdbiLoanData] = useState([]);
  const [incentiveData, setIncentiveData] = useState([]);
  const [hlBusinessData, setHlBusinessData] = useState([]);

  useEffect(() => {
    if (territoryOfficeList) {
      let tempTerritoryOfficeList = [...territoryOfficeList];
      tempTerritoryOfficeList.unshift({ name: 'All', id: 'All' });
      setTerritoryOfficeData(tempTerritoryOfficeList);
    }
  }, [territoryOfficeList]);

  useEffect(() => {
    if (dashboardData) {
      setHomeLoanData(dashboardData?.HomeLoan || []);
      let tempGiData = Array.isArray(dashboardData.GeneralInsurance)
        ? [...dashboardData.GeneralInsurance]
        : [];

      tempGiData &&
        tempGiData.unshift({
          name: 'Number',
          count: dashboardData?.GeneralInsurance?.[0]?.count,
          amount: '-'
        });
      setGeneralInsuranceData(tempGiData || []);

      let tempLiData = Array.isArray(dashboardData.LifeInsurance)
        ? [...dashboardData.LifeInsurance]
        : [];

      tempLiData &&
        tempLiData.unshift({
          name: 'Number',
          count: dashboardData?.LifeInsurance?.[0]?.count,
          amount: '-'
        });

      setLifeInsuranceData(tempLiData || []);

      let tempFdData = Array.isArray(dashboardData.FixedDeposit)
        ? [...dashboardData.FixedDeposit]
        : [];
      tempFdData &&
        tempFdData.unshift({
          name: 'Number',
          count: dashboardData?.FixedDeposit?.[0]?.count,
          amount: '-'
        });

      setFixedDepositData(tempFdData || []);

      let tempMfData = Array.isArray(dashboardData.MutualFund)
        ? [...dashboardData.MutualFund]
        : [];
      tempMfData &&
        tempMfData.unshift({
          name: 'Number',
          count: dashboardData?.MutualFund?.[0]?.count,
          amount: '-'
        });

      setMutualFundData(tempMfData || []);

      let tempIdbiData = Array.isArray(dashboardData.Idbi)
        ? [...dashboardData.Idbi]
        : [];
      tempIdbiData &&
        tempIdbiData.unshift({
          name: 'Number',
          count: dashboardData?.Idbi?.[0]?.count,
          amount: '-'
        });

      setIdbiLoanData(tempIdbiData || []);

      setIncentiveData(dashboardData?.Incentives || []);
      setHlBusinessData(dashboardData?.HLBusiness || []);
    }
  }, [dashboardData]);

  const validationSchema = Yup.object().shape({
    territoryOffice: Yup.object({
      id: Yup.string().required('Territory Office is required')
    }).required('Territory Office is required'),

    fromDate: Yup.date().required('From Date is required'),

    toDate: Yup.date()
      .required('To Date is required')
      .test(
        'valid-to-date',
        'To Date must be after From Date and within 1 year',
        function (value) {
          const { fromDate } = this.parent;

          if (value && fromDate) {
            const fromDay = dayjs(fromDate);
            const toDay = dayjs(value);

            // Check that toDate is not earlier than fromDate
            if (toDay.isBefore(fromDay, 'day')) {
              return this.createError({
                message: 'To Date cannot be earlier than From Date'
              });
            }

            // Check date is same or not
            if (fromDay.isSame(toDay, 'day')) {
              return this.createError({
                message: 'To Date cannot be the same as From Date'
              });
            }

            // Check that the difference between toDate and fromDate is between 1 day and 1 year
            const diffDays = toDay.diff(fromDay, 'days');
            if (diffDays < 1 || diffDays > 365) {
              return this.createError({
                message: 'To Date must be within 1 year of From Date'
              });
            }
          }

          return true; // Validation passed
        }
      )
  });

  const formik = useFormik({
    initialValues: {
      fromDate: oneYearAgo,
      toDate: today,
      territoryOffice: { name: 'All', id: 'All' }
    },
    validationSchema: validationSchema,
    onSubmit: values => {}
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length == 0) {
      dispatch(
        getDashboardData({
          code: formik?.values?.territoryOffice?.id,
          fromDate: formik?.values?.toDate
            ? dayjs(formik?.values?.fromDate).format('YYYY-MM-DD')
            : '',
          toDate: formik?.values?.toDate
            ? dayjs(formik?.values?.toDate).format('YYYY-MM-DD')
            : '',
          userId: UserId
        })
      );
    }
  }, [
    formik.values?.fromDate,
    formik.values?.toDate,
    formik.values?.territoryOffice
  ]);

  const handleFromDateChange = value => {
    formik.handleSubmit();
    formik.setValues({
      ...formik.values,
      fromDate: dayjs(value)
    });
  };

  const handleToDateChange = value => {
    formik.handleSubmit();
    formik.setValues({
      ...formik.values,
      toDate: dayjs(value)
    });
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          height: '80vh',
          overflowY: 'scroll'
        }}
      >
        <div
          style={{
            padding: '1rem'
          }}
        >
          <Grid container spacing={3}>
            <Grid item lg={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From Date *"
                  value={
                    formik?.values?.fromDate
                      ? dayjs(formik?.values?.fromDate)
                      : null
                  }
                  size="small"
                  fullWidth
                  format="DD-MM-YYYY"
                  minDate={dayjs('2010-01-01')}
                  maxDate={dayjs()}
                  slotProps={{
                    textField: {
                      size: 'small',

                      sx: {
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }
                    }
                  }}
                  onChange={handleFromDateChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="fromDate"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.fromDate &&
                              formik?.errors?.fromDate
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }}
                      error={
                        formik?.touched?.fromDate &&
                        Boolean(formik?.errors?.fromDate)
                      }
                      helperText={
                        formik?.touched?.fromDate && formik?.errors?.fromDate
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.fromDate && formik.errors.fromDate && (
                <FormHelperText>{formik.errors.fromDate}</FormHelperText>
              )}
            </Grid>
            <Grid item lg={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To Date *"
                  value={
                    formik?.values?.toDate
                      ? dayjs(formik?.values?.toDate)
                      : null
                  }
                  size="small"
                  fullWidth
                  format="DD-MM-YYYY"
                  minDate={dayjs('2010-01-01')}
                  onChange={handleToDateChange}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      name="toDate"
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&:hover fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor:
                              formik?.touched?.toDate && formik?.errors?.toDate
                                ? 'red'
                                : 'inherit'
                          }
                        }
                      }}
                      error={
                        formik?.touched?.toDate &&
                        Boolean(formik?.errors?.toDate)
                      }
                      helperText={
                        formik?.touched?.toDate && formik?.errors?.toDate
                      }
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.toDate && formik.errors.toDate && (
                <FormHelperText>{formik.errors.toDate}</FormHelperText>
              )}
            </Grid>
            <Grid item lg={4}>
              <Autocomplete
                options={
                  isArrayNotEmpty(territoryOfficeData)
                    ? territoryOfficeData?.map(c => {
                        return { name: c.name, id: c.id };
                      })
                    : []
                }
                disableClearable
                getOptionLabel={option => option.name || ''}
                value={formik.values?.territoryOffice}
                isOptionEqualToValue={(option, value) => {
                  return option.id === (value ? value.id : null);
                }}
                size="small"
                fullWidth
                onChange={(event, newValue) => {
                  formik.setFieldValue('territoryOffice', newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Territory Office (TO)*"
                    placeholder="Select Territory Office (TO)"
                    style={{ marginRight: '24px' }}
                    error={
                      formik.touched.territoryOffice &&
                      Boolean(formik.errors.territoryOffice)
                    }
                    helperText={
                      formik.touched.territoryOffice &&
                      formik.errors.territoryOffice
                    }
                  />
                )}
              />
            </Grid>
          </Grid>

          {isDashboardDataLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '80vh',
                marginTop: '10rem',
                zIndex: 10
              }}
            >
              <Box>
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <Grid container spacing={3} style={{ marginTop: '0.5rem' }}>
              <Grid item lg={4}>
                <div
                  style={{
                    height: '230px',
                    borderRadius: '12px',
                    backgroundColor: '#2BA9EE'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={HOME_LOAN_ICON}
                          alt="editIcon"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        HOME LOAN
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {homeLoanData?.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '40%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{ color: 'white', minWidth: '3rem' }}
                        >
                          : {item.count}
                        </Typography>
                        <Typography style={{ color: 'white' }}>
                          {formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div
                  style={{
                    height: '230px',
                    borderRadius: '12px',
                    backgroundColor: '#13C350'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={GENERAL_INSURANCE_ICON}
                          alt="General_insurance"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        GENERAL INSURANCE
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {generalInsuranceData?.map((item, i) => (
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '4px',
                          width: '100%'
                        }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          :{' '}
                          {item.name === 'Number'
                            ? item.count
                            : formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div
                  style={{
                    height: '230px',
                    borderRadius: '12px',
                    backgroundColor: '#FF5631'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={LIFE_INSURANCE_ICON}
                          alt="life_Insurance"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        LIFE INSURANCE
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {lifeInsuranceData?.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          :{' '}
                          {item.name === 'Number'
                            ? item.count
                            : formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>

              <Grid item lg={4}>
                <div
                  style={{
                    height: '190px',
                    borderRadius: '12px',
                    backgroundColor: '#F59700'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={FIXED_DEPOSIT_ICON}
                          alt="fixed_deposite"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        FIXED DEPOSIT
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {fixedDepositData.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          :{' '}
                          {item.name === 'Number'
                            ? item.count
                            : formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div
                  style={{
                    height: '190px',
                    borderRadius: '12px',
                    backgroundColor: '#D92C8A'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={MUTUAL_FUND_ICON}
                          alt="mutual_fund"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        MUTUAL FUND
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {mutualFundData?.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          :{' '}
                          {item.name === 'Number'
                            ? item.count
                            : formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item lg={4}>
                <div
                  style={{
                    height: '190px',
                    borderRadius: '12px',
                    backgroundColor: '#6A5AE2'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={IDBI_LOANS_ICON}
                          alt="idbi_Icon"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        IDBI LOANS
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {idbiLoanData?.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          :{' '}
                          {item.name === 'Number'
                            ? item.count
                            : formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>

              <Grid item lg={4}>
                <div
                  style={{
                    height: '260px',
                    borderRadius: '12px',
                    backgroundColor: '#9E35C3'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton>
                        <img
                          src={INCENTIVE_ICON}
                          alt="incentive_Icon"
                          style={{ height: '2rem', marginRight: '0.4rem' }}
                        />
                      </IconButton>
                      <Typography
                        style={{ color: 'white', fontWeight: 'bold' }}
                      >
                        INCENTIVE
                      </Typography>
                    </div>
                    <hr style={{ borderTop: '1px #FFFFFF' }} />

                    {incentiveData?.map((item, i) => (
                      <div
                        style={{ display: 'flex', marginBottom: '4px' }}
                        key={item.name}
                      >
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          style={{
                            color: 'white',
                            flexBasis: '50%',
                            flexGrow: 0
                          }}
                        >
                          : {formatAmountDecimalInINR(item.amount)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>

              <Grid item lg={8}>
                <div
                  style={{
                    height: '260px',
                    borderRadius: '12px',
                    backgroundColor: '#D1CB28'
                  }}
                >
                  <div
                    style={{
                      display: 'block',
                      padding: '1rem',
                      width: 'full'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {hlBusinessData?.map(
                          (item, i) =>
                            i % 2 === 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginBottom: '2rem'
                                }}
                                key={item.name}
                              >
                                <Typography
                                  style={{
                                    color: 'white',
                                    flexBasis: '60%',
                                    flexGrow: 0
                                  }}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  style={{
                                    color: 'white',
                                    flexBasis: '40%',
                                    flexGrow: 0
                                  }}
                                >
                                  : {formatAmountDecimalInINR(item.amount)}
                                </Typography>
                              </div>
                            )
                        )}
                      </div>
                      <div style={{ flex: 1, marginLeft: '5rem' }}>
                        {hlBusinessData?.map(
                          (item, i) =>
                            i % 2 === 1 && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginBottom: '2rem'
                                }}
                                key={item.name}
                              >
                                <Typography
                                  style={{
                                    color: 'white',
                                    flexBasis: '60%',
                                    flexGrow: 0
                                  }}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  style={{
                                    color: 'white',
                                    flexBasis: '40%',
                                    flexGrow: 0
                                  }}
                                >
                                  : {formatAmountDecimalInINR(item.amount)}
                                </Typography>
                              </div>
                            )
                        )}
                      </div>
                    </div>

                    {/*
                     */}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
