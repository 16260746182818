import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

const CWAQuestionsForm = ({ formik }) => {
  const [isTimeTaken, setIsTimeTaken] = useState('');
  const [isRelationship, setIsRelationship] = useState('');
  const [isSubmitted, setIsSubmitted] = useState('');

  useEffect(() => {
    setIsTimeTaken(formik?.values?.isTimeTaken);
    setIsRelationship(formik?.values?.isRelationship);
    setIsSubmitted(formik?.values?.isSubmitted);
  }, [formik]);

  const handleIsTakenChange = e => {
    const { value } = e.target;
    setIsTimeTaken(value);
    // formik.setFieldValue('isTimeTaken', value == 'Yes' ? true : false);
    if (value === 'Yes') {
      formik.setValues({
        ...formik.values,
        isTimeTaken: value == 'Yes' ? true : false,
        organizationDetails: '',
        timePeriod: ''
      });
    } else if (value === 'No') {
      formik.setValues({
        ...formik.values,
        isTimeTaken: value == 'Yes' ? true : false,
        organizationDetails: 'Dummy Value',
        timePeriod: 'Dummy Value'
      });
    }
  };

  const handleIsRelationshipChange = e => {
    const { value } = e.target;
    setIsRelationship(value);
    // formik.setFieldValue('isRelationship', value == 'Yes' ? true : false);
    if (value === 'Yes') {
      formik.setValues({
        ...formik.values,
        isRelationship: value == 'Yes' ? true : false,
        nameOfPersonInRelation: '',
        designationOfPersonInRelation: '',
        officeOfPersonInRelation: ''
      });
    } else if (value === 'No') {
      formik.setValues({
        ...formik.values,
        isRelationship: value == 'Yes' ? true : false,
        nameOfPersonInRelation: 'Dummy Value',
        designationOfPersonInRelation: 'Dummy Value',
        officeOfPersonInRelation: 'Dummy Value'
      });
    }
  };

  const handleIsSubmittedChange = e => {
    const { value } = e.target;
    setIsSubmitted(value);
    // formik.setFieldValue('isSubmitted', value == 'Yes' ? true : false);
    if (value === 'Yes') {
      formik.setValues({
        ...formik.values,
        isSubmitted: value == 'Yes' ? true : false,
        submittedName: '',
        submittedAmount: ''
      });
    } else if (value === 'No') {
      formik.setValues({
        ...formik.values,
        isSubmitted: value == 'Yes' ? true : false,
        submittedName: 'Dummy Value',
        submittedAmount: '100'
      });
    }
  };

  return (
    <>
      <div style={{ marginBottom: '2rem', marginTop: '2rem' }}>
        <Typography color="#000000" fontSize={'18px'} marginBottom={'12px'}>
          Have you any time taken connector code/agency from any organization.
        </Typography>

        <RadioGroup
          row
          value={isTimeTaken === true ? 'Yes' : 'No'}
          onChange={handleIsTakenChange}
          style={{ marginBottom: '1rem' }}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
        {isTimeTaken && (
          <>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <TextField
                  label="Organization Details*"
                  name="organizationDetails"
                  size="small"
                  fullWidth
                  value={formik.values.organizationDetails}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: formik.values.organizationDetails !== ''
                  }}
                  error={
                    formik.touched.organizationDetails &&
                    Boolean(formik?.errors.organizationDetails)
                  }
                  helperText={
                    formik.touched.organizationDetails &&
                    formik.errors.organizationDetails
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Time Period*"
                  name="timePeriod"
                  size="small"
                  fullWidth
                  value={formik?.values?.timePeriod}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.timePeriod !== ''
                  }}
                  error={
                    formik?.touched?.timePeriod &&
                    Boolean(formik?.errors?.timePeriod)
                  }
                  helperText={
                    formik?.touched?.timePeriod && formik?.errors?.timePeriod
                  }
                />
              </Grid>
            </Grid>
          </>
        )}

        <Typography
          color="#000000"
          fontSize={'18px'}
          marginBottom={'12px'}
          marginTop={'2rem'}
        >
          Is anyone in a relationship with you currently working in
          LICHFL/LICHFL FSL.
        </Typography>

        <RadioGroup
          row
          value={isRelationship === true ? 'Yes' : 'No'}
          onChange={handleIsRelationshipChange}
          style={{ marginBottom: '1rem' }}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>

        {isRelationship && (
          <>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <TextField
                  label="Name *"
                  name="nameOfPersonInRelation"
                  size="small"
                  fullWidth
                  value={formik?.values?.nameOfPersonInRelation}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.nameOfPersonInRelation !== ''
                  }}
                  error={
                    formik?.touched?.nameOfPersonInRelation &&
                    Boolean(formik?.errors?.nameOfPersonInRelation)
                  }
                  helperText={
                    formik?.touched?.nameOfPersonInRelation &&
                    formik?.errors?.nameOfPersonInRelation
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Designation *"
                  name="designationOfPersonInRelation"
                  size="small"
                  fullWidth
                  value={formik?.values?.designationOfPersonInRelation}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.designationOfPersonInRelation !== ''
                  }}
                  error={
                    formik?.touched?.designationOfPersonInRelation &&
                    Boolean(formik?.errors?.designationOfPersonInRelation)
                  }
                  helperText={
                    formik?.touched?.designationOfPersonInRelation &&
                    formik?.errors?.designationOfPersonInRelation
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Office*"
                  name="officeOfPersonInRelation"
                  size="small"
                  fullWidth
                  value={formik?.values?.officeOfPersonInRelation}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.officeOfPersonInRelation !== ''
                  }}
                  error={
                    formik?.touched?.officeOfPersonInRelation &&
                    Boolean(formik?.errors?.officeOfPersonInRelation)
                  }
                  helperText={
                    formik?.touched?.officeOfPersonInRelation &&
                    formik?.errors?.officeOfPersonInRelation
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        <Typography
          color="#000000"
          fontSize={'18px'}
          marginBottom={'12px'}
          marginTop={'2rem'}
        >
          Files Currently submitted.
        </Typography>

        <RadioGroup
          row
          value={isSubmitted === true ? 'Yes' : 'No'}
          onChange={handleIsSubmittedChange}
          style={{ marginBottom: '1rem' }}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>

        {isSubmitted && (
          <>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <TextField
                  label="Name*"
                  name="submittedName"
                  size="small"
                  fullWidth
                  value={formik?.values?.submittedName}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.submittedName !== ''
                  }}
                  error={
                    formik?.touched?.submittedName &&
                    Boolean(formik?.errors?.submittedName)
                  }
                  helperText={
                    formik?.touched?.submittedName &&
                    formik?.errors?.submittedName
                  }
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="HL Amount(₹)*"
                  name="submittedAmount"
                  size="small"
                  fullWidth
                  value={formik?.values?.submittedAmount}
                  onChange={formik?.handleChange}
                  InputLabelProps={{
                    shrink: formik?.values?.submittedAmount !== ''
                  }}
                  error={
                    formik?.touched?.submittedAmount &&
                    Boolean(formik?.errors?.submittedAmount)
                  }
                  helperText={
                    formik?.touched?.submittedAmount &&
                    formik?.errors?.submittedAmount
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default CWAQuestionsForm;
