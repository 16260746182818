import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTitle,
  formatAmountInINR,
  isArrayNotEmpty
} from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomDataTable from '../components/CustomDataTable';
import { getCustomerList, getCustomerView } from '../redux/reducers/leadSlice';

function View360() {
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    page: 0,
    limit: 10
  });

  const { customerList, customerViewData } = useSelector(state => state.lead);

  const validationSchema = yup.object().shape({
    customerName: yup
      .object()
      .shape({
        id: yup.string().required('Customer name is required')
      })
      .required('Customer name is required')
  });

  const formik = useFormik({
    initialValues: {
      customerName: null
    },
    validationSchema: validationSchema
  });

  useEffect(() => {
    document.title = createTitle('360° View');
    dispatch(setActiveMenu('360° View'));
    dispatch(getCustomerList({}));
  }, [dispatch]);

  const handleOnChange = value => {
    formik.setFieldValue('customerName', value);
    dispatch(
      getCustomerView({
        customerId: value.id,
        page: initialValues?.page,
        limit: initialValues.limit
      })
    );
  };

  useEffect(() => {
    if (customerList.length > 0) {
      formik.setFieldValue('customerName', customerList[0]);
      dispatch(
        getCustomerView({
          customerId: customerList[0]?.id,
          page: initialValues?.page,
          limit: initialValues.limit
        })
      );
    }
  }, [customerList]);

  const columns = [
    {
      uinqueKey: 'leadId',
      id: 'product',
      label: 'Product name',
      width: '120px'
    },
    {
      id: 'territoryOffice',
      label: 'Territory Office'
    },
    {
      id: 'areaOffice',
      label: 'Area Office'
    },

    {
      id: 'applicationId',
      label: 'Lead ID'
    },
    {
      id: 'ap',
      label: 'AP'
    },
    {
      id: 'sp',
      label: 'SP'
    },
    {
      id: 'requestedAmount',
      label: 'Amount',
      render: row =>
        row.requestedAmount ? formatAmountInINR(row.requestedAmount) : '-'
    },
    {
      id: 'status',
      label: 'Status'
    }
  ];

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '12px'
        }}
      >
        <Box>
          <form
            autoComplete="off"
            style={{
              marginRight: '24px',
              marginTop: '8px'
            }}
          >
            <div style={{ display: 'flex' }}>
              <Autocomplete
                getOptionLabel={option => option.name || ''}
                options={
                  isArrayNotEmpty(customerList)
                    ? customerList?.map(c => {
                        return { name: c.name, id: c.id };
                      })
                    : []
                }
                disableClearable
                isOptionEqualToValue={(option, value) => {
                  return option.id === (value ? value.id : null);
                }}
                value={formik.values.customerName}
                size="small"
                onChange={(event, newValue) => handleOnChange(newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search by customer *"
                    placeholder="Search by customer "
                    style={{ width: '300px', marginRight: '24px' }}
                    error={
                      formik.touched.customerName &&
                      Boolean(formik.errors.customerName)
                    }
                    helperText={
                      formik.touched.customerName && formik.errors.customerName
                    }
                  />
                )}
              />
            </div>
          </form>
          <div style={{ marginTop: '2rem' }}>
            <CustomDataTable
              columns={columns}
              data={
                customerViewData?.content?.length > 0
                  ? customerViewData?.content
                  : []
              }
              onPageChange={val => {
                dispatch(
                  getCustomerView({
                    customerId: formik.values.customerName?.id,
                    page: val,
                    limit: initialValues.limit
                  })
                );
                setInitialValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                dispatch(
                  getCustomerView({
                    customerId: formik.values.customerName?.id,
                    page: initialValues?.page,
                    limit: val
                  })
                );
                setInitialValues({ ...initialValues, limit: val, page: 0 });
              }}
              defaultPage={initialValues.page || 0}
              defaultRowsPerPage={initialValues.limit || 0}
              totalCount={customerViewData?.totalElements || 0}
              isShowPagination={true}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default View360;
