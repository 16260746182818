import { useEffect } from 'react';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import ModalDialog from '../components/ModalDialog';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IMG_SUCCESS } from '../utils/imageUrls';
import SnackBarBox from '../components/SnackBarBox';
import { logoutAndClearToken, revertAll } from '../redux/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../utils/utils';

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isSubmitChangePasswordLoading,
    isSubmitChangePasswordSuccess,
    isSubmitChangePasswordFailed,
    changePasswordStatus,
    submitChangePasswordErrorContainer
  } = useSelector(state => state.auth);

  useEffect(() => {
    return () => {
      dispatch(revertAll());
    };
  }, []);

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '12px'
        }}
      >
        <ChangePasswordForm />
      </div>
      {!isSubmitChangePasswordLoading && isSubmitChangePasswordSuccess && (
        // <SnackBarBox
        //   type="success"
        //   message={handleError(changePasswordStatus)}
        // />
        <ModalDialog>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px'
            }}
          >
            <img
              src={IMG_SUCCESS}
              style={{ height: '80px', width: '160px', marginBottom: '16px' }}
            ></img>
            <Typography
              sx={{
                color: '#5EAC1B',
                marginBottom: '24px',
                fontWeight: 'bold'
              }}
            >
              {changePasswordStatus}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(logoutAndClearToken());
                navigate('/cwaf/login');
              }}
              size="medium"
              style={{ width: '120px' }}
            >
              OK
            </Button>
          </div>
        </ModalDialog>
      )}

      {!isSubmitChangePasswordLoading && isSubmitChangePasswordFailed && (
        <SnackBarBox
          type="error"
          message={handleError(submitChangePasswordErrorContainer?.message)}
        />
      )}
    </>
  );
}

export default ChangePassword;
