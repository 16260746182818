import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';

const AddressDetailsCustom = ({
  applicantData,
  Index,
  handleOnChange,
  validationData
}) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <Typography
        color="#335DAD"
        fontSize={'18px'}
        fontWeight={600}
        marginBottom={'12px'}
      >
        Address Details
      </Typography>
      <Typography fontSize={'16px'} fontWeight={600} marginBottom={'12px'}>
        Current Address
      </Typography>
      <Grid container spacing={3} marginBottom={'16px'}>
        <Grid item lg={4}>
          <TextField
            label="Address line 1 *"
            name="currentAddressLine1"
            size="small"
            fullWidth
            value={applicantData.currentAddressLine1}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.currentAddressLine1 !== ''
            }}
            error={
              validationData &&
              validationData?.currentAddressLine1Val &&
              Boolean(validationData?.currentAddressLine1Val)
            }
            helperText={
              validationData &&
              validationData?.currentAddressLine1Val &&
              validationData?.currentAddressLine1Val
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Address line 2 *"
            name="currentAddressLine2"
            size="small"
            fullWidth
            value={applicantData.currentAddressLine2}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink: applicantData.currentAddressLine2 !== ''
            }}
            error={
              validationData &&
              validationData?.currentAddressLine2Val &&
              Boolean(validationData?.currentAddressLine2Val)
            }
            helperText={
              validationData &&
              validationData?.currentAddressLine2Val &&
              validationData?.currentAddressLine2Val
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Landmark"
            name="currentLandmark"
            size="small"
            fullWidth
            value={applicantData.currentLandmark}
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.currentLandmark !== '' }}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="Pincode *"
            name="currentPincode"
            size="small"
            fullWidth
            value={
              applicantData?.currentPincode?.pincode ||
              applicantData?.currentPincode
            }
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{ shrink: applicantData.currentPincode !== '' }}
            error={
              validationData &&
              validationData?.currentPincodeVal &&
              Boolean(validationData?.currentPincodeVal)
            }
            helperText={
              validationData &&
              validationData?.currentPincodeVal &&
              validationData?.currentPincodeVal
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="City *"
            name="city"
            size="small"
            disabled
            fullWidth
            value={applicantData?.currentCity}
            InputLabelProps={{ shrink: applicantData?.currentCity !== '' }}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="State *"
            name="state"
            size="small"
            disabled
            fullWidth
            value={applicantData?.currentState}
            InputLabelProps={{ shrink: applicantData?.currentState !== '' }}
          />
        </Grid>
      </Grid>

      <Typography fontSize={'16px'} fontWeight={600}>
        Permanent Address
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={applicantData?.sameAsCurrent === 'Yes'}
            onChange={e => handleOnChange(e, Index)}
            name="sameAsCurrent"
            color="primary"
          />
        }
        label="Same as Current Address"
      />
      <Grid container spacing={3}>
        <Grid item lg={4}>
          <TextField
            disabled={applicantData.sameAsCurrent == 'Yes' ? true : false}
            label="Address line 1 *"
            name="permanentAddressLine1"
            size="small"
            fullWidth
            value={
              applicantData?.sameAsCurrent == 'Yes'
                ? applicantData?.currentAddressLine1
                : applicantData?.permanentAddressLine1
            }
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink:
                applicantData?.sameAsCurrent == 'Yes'
                  ? applicantData?.currentAddressLine1
                  : applicantData?.permanentAddressLine1 != ''
            }}
            error={
              applicantData?.sameAsCurrent == 'Yes'
                ? validationData?.currentAddressLine1Val
                : validationData?.permanentAddressLine1Val
            }
            helperText={
              applicantData?.sameAsCurrent == 'Yes'
                ? validationData?.currentAddressLine1Val
                : validationData?.permanentAddressLine1Val
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled={applicantData.sameAsCurrent == 'Yes' ? true : false}
            label="Address line 2 *"
            name="permanentAddressLine2"
            size="small"
            fullWidth
            value={
              applicantData.sameAsCurrent == 'Yes'
                ? applicantData.currentAddressLine2
                : applicantData.permanentAddressLine2
            }
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink:
                applicantData.sameAsCurrent == 'Yes'
                  ? applicantData.currentAddressLine2
                  : applicantData.permanentAddressLine2 != ''
            }}
            error={
              applicantData.sameAsCurrent == 'Yes'
                ? validationData?.currentAddressLine2Val
                : validationData?.permanentAddressLine2Val
            }
            helperText={
              applicantData.sameAsCurrent == 'Yes'
                ? validationData?.currentAddressLine2Val
                : validationData?.permanentAddressLine2Val
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled={applicantData.sameAsCurrent == 'Yes' ? true : false}
            label="Landmark"
            name="permanentLandmark"
            size="small"
            fullWidth
            value={
              applicantData.sameAsCurrent == 'Yes'
                ? applicantData.currentLandmark
                : applicantData.permanentLandmark
            }
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink:
                applicantData.sameAsCurrent == 'Yes'
                  ? applicantData.currentLandmark
                  : applicantData.permanentLandmark != ''
            }}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            disabled={applicantData.sameAsCurrent == 'Yes' ? true : false}
            label="Pincode *"
            name="permanentPincode"
            size="small"
            fullWidth
            value={
              applicantData.sameAsCurrent == 'Yes'
                ? applicantData?.currentPincode?.pincode ||
                  applicantData?.currentPincode
                : applicantData?.permanentPincode?.pincode ||
                  applicantData?.permanentPincode
            }
            onChange={e => handleOnChange(e, Index)}
            InputLabelProps={{
              shrink:
                applicantData.sameAsCurrent == 'Yes'
                  ? applicantData?.currentPincode?.pincode != ''
                  : applicantData?.permanentPincode?.pincode != ''
            }}
            error={
              applicantData.sameAsCurrent == 'Yes'
                ? validationData?.currentPincodeVal
                : validationData?.permanentPincodeVal
            }
            helperText={
              applicantData.sameAsCurrent == 'Yes'
                ? validationData?.currentPincodeVal
                : validationData?.permanentPincodeVal
            }
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="City *"
            name="permanentCity"
            size="small"
            disabled
            fullWidth
            value={
              applicantData.sameAsCurrent == 'Yes'
                ? applicantData?.currentCity
                : applicantData?.permanentCity
            }
            InputLabelProps={{
              shrink:
                applicantData.sameAsCurrent == 'Yes'
                  ? applicantData?.currentCity
                  : applicantData?.permanentCity !== ''
            }}
          />
        </Grid>
        <Grid item lg={4}>
          <TextField
            label="State *"
            name="permanentState"
            size="small"
            disabled
            fullWidth
            value={
              applicantData.sameAsCurrent == 'Yes'
                ? applicantData?.currentState
                : applicantData?.permanentState
            }
            InputLabelProps={{
              shrink:
                applicantData.sameAsCurrent == 'Yes'
                  ? applicantData?.currentState
                  : applicantData?.permanentState !== ''
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddressDetailsCustom;
