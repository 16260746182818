import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { colorDanger, colorGolden } from '../config/theme';

export const imageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const CaptureImageAllowedTypes = ['image/jpeg', 'image/jpg'];
export const UploadDocumentAllowedTypes = [
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];
export const imageAllowedExtention = ['jpg', 'jpeg'];
export const UploadDocumentAllowedExtention = ['jpg', 'jpeg', 'pdf'];
export const CaptureImageAllowedExtention = ['jpg', 'jpeg'];
export const IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_MULTIPART_FILE_SIZE_LIMIT} MB.`;
export const OCR_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG format.`;
export const UPLOAD_DOCUMENT_SELECT_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG or PDF format.`;

export const BORROWER_IMAGE_SIZE_LIMIT_MESSAGE = `Max size is ${process.env.REACT_APP_BORROWER_MULTIPART_FILE_SIZE_LIMIT_OCR} MB.`;
export const SELECT_BORROWER_IMAGE_MESSAGE = `Please upload an image in either JPG, JPEG, PNG, or PDF format.`;
export const BORROWER_IMAGE_ALLOWED_TYPE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
export const BORROWER_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png', 'pdf'];

export const NET_WORTH_MANDATORY_LIMIT = 1000000;

export const USER_TYPE = 'User';
export const RERECTED_TO_THE_LOGIN_PAGE =
  'You will be redirected to the login page';
export const GO_BACK = 'Go Back';

export const PASSWORD_RESET_TYPE = 'password';
export const PIN_RESET_TYPE = 'pin';
export const CREATE_ACCOUNT = 'Create Account';
export const UNEXPECTED_ERROR =
  'It appears that there is an error on the server.';

export const INVESTOR_REQUIREMENT = 'investorRequirements';
export const PAN_VERIFICATION = 'panVerification';
export const AADHAR_VERIFICATION = 'aadhaarVerification';
export const UPLOAD_LATEST_PHOTO = 'photoUpload';
export const PENNY_DROP_VERIFICATION = 'bankAccountVerification';
export const NOMINEE_DETAILS = 'nomineeDetails';
export const DOCUMENT_UPLOAD = 'documentUpload';
export const INVESTOR_SUMMARY = 'investorSummary';

export const BORROWER_REQUIREMENTS = 'borrowerRequirements';

export const USER_TYPE_INVESTOR = 'Investor';

export const USER_TYPE_BORROWER = 'Borrower';

export const OTP_VERIFICATION = 'otpVerification';
export const ADD_BASIC_DETAILS = 'addBasicDetails';
export const PASSWORD_SETUP = 'passwordSetup';
export const PIN_SETUP = 'pinSetup';
export const FINISH = 'finish';

export const LEND_BOX_INVESTOR_INITIAL = 'initial';
export const LEND_BOX_INVESTOR_REGISTRATION_STAGE = 'investorRegistration';
export const LEND_BOX_INVESTOR_DOCUMENT_UPLOAD = 'documentUpload';
export const LEND_BOX_INVESTOR_WALLET_ORDER = 'walletOrder';

export const LEND_BOX_REGISTRATION = 'lendboxRegistration';
export const LEND_BOX_E_SIGNING = 'eSigning';

export const PAYMENT_INITIATED = 'paymentInitiated';

export const CAPTURE_IMAGE = 'Capture image';

export const investorSteps = [
  INVESTOR_REQUIREMENT,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION,
  NOMINEE_DETAILS
];

export const borrowerSteps = [
  BORROWER_REQUIREMENTS,
  PAN_VERIFICATION,
  AADHAR_VERIFICATION,
  UPLOAD_LATEST_PHOTO,
  DOCUMENT_UPLOAD,
  PENNY_DROP_VERIFICATION
];

export const createUserSteps = [
  ADD_BASIC_DETAILS,
  OTP_VERIFICATION,
  PASSWORD_SETUP,
  PIN_SETUP
];

export const SELECT_NEXT_BUTTON =
  'Clicking "Save & Next" will save your details.';
export const SELECT_NOTIFY_BUTTON =
  'Clicking "Save & Notify" will trigger a mail.';
export const SELECT_BACK_BUTTON = 'Click "Back" to view your details.';

export const DOC_TYPE_PAN = 'pan';
export const DOC_TYPE_AADHAAR = 'aadhaar';
export const DOC_TYPE_NET_WORTH_CERT = 'networth';
export const DOC_TYPE_AADHAAR_FRONT = 'aadhaarFront';
export const DOC_TYPE_AADHAAR_BACK = 'aadhaarBack';
export const DOC_TYPE_PHOTO = 'photo';
export const AADHAR_OCR_FRONT = 'aadhaarFront';
export const AADHAR_OCR_BACK = 'aadhaarBack';
export const DOC_TYPE_CHEQUE_OCR = 'cheque';
export const DOC_TYPE_BANK_STATEMENT_OCR = 'bankStatement';
export const DOC_TYPE_DEVIATION_MAIL = 'deviationmail';
export const DOC_TYPE_BANK_ANALYSIS = 'bankAnalysis';

export const documentTypes = [
  DOC_TYPE_PAN,
  DOC_TYPE_AADHAAR_FRONT,
  DOC_TYPE_AADHAAR_BACK,
  DOC_TYPE_NET_WORTH_CERT
];

/** Razor Pay configs */

export const RAZORPAY_COLOR = colorGolden;
export const RAZORPAY_COMPANY_NAME = 'LIC';
export const RAZORPAY_IMAGE = '/images/splash_logo.png';

export const LENDBOX_HELP_EMAIL = 'support@lendbox.in';
export const LENDBOX_HELP_PHONE = '+91 7291029298';

export const LIC_HELP_EMAIL = 'support@LICp2p.com';
export const LIC_HELP_PHONE = '+91 7775965644';
export const LIC_HELP_PHONE_LINK = '917775965644';

export const WHATSAPP_HELP_LINE = '918928380243';

export const ADMIN_MODULE = 'Admin';
export const CREDIT_MODULE = 'Credit';

export const LOGIN_CREDIT_MODULE_USER = 'CREDIT_USER';
export const LOGIN_ADMIN_MODULE_USER = 'ADMIN';

export const moduleList = [
  { key: LOGIN_CREDIT_MODULE_USER, value: CREDIT_MODULE },
  { key: LOGIN_ADMIN_MODULE_USER, value: ADMIN_MODULE }
];

export const CREDIT_DASHBOARD = 'CREDIT_DASHBOARD';
export const ADMIN_DASHBOARD = 'ADMIN_DASHBOARD';
export const CREDIT_DISBURSED_DASHBOARD = 'Disbursed Case';
export const CREDIT_UNDISBURSED_DASHBOARD = 'Undisbursed Case';

export const CREDIT_MENUS_LIST = [
  {
    name: 'Dashboard',
    route: '/dashboard'
  }
];
export const ADMIN_MENUS_LIST = [
  {
    name: 'Dashboard',
    route: '/adminf/dashboard',
    hasChild: false
  },
  {
    name: 'User Management',
    route: '/adminf/user-management',
    hasChild: false
  },

  {
    name: 'Role Management',
    route: '/adminf/role-management',
    hasChild: false
  },
  {
    name: 'Hierarchy Management',
    route: '/adminf/hierarchy-management',
    hasChild: false
  },
  {
    name: 'Connector Approval',
    route: '/adminf/connector-approval',
    hasChild: false
  },
  {
    name: 'Reports',
    route: '/adminf/reports',
    hasChild: false
  },
  {
    name: 'Master Management',
    hasChild: true,
    submenus: [
      {
        name: 'Product Master',
        route: '/adminf/master-management/product-master'
      },
      {
        name: 'Plan Master',
        route: '/adminf/master-management/plan-master'
      }
    ]
  }
];

export const SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'lbUserId', value: 'Transaction ID' },
  { key: 'borrowerName', value: 'Borrower Name' },
  { key: 'mobileNumber', value: 'Mobile Number' },
  { key: 'creditStatus', value: 'Credit Status' }
];

export const BRANCHES_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'branchName', value: 'Branch Name' },
  { key: 'location', value: 'Location' },
  { key: 'phoneNo', value: 'Phone Number' },
  { key: 'email', value: 'Email ID' }
];

// export const BRANCH_SEARCH_CRITERIA_LIST = [
//   { key: '', value: 'None' },
//   { key: 'branchName', value: 'Branch Name' },
//   { key: 'location', value: 'Location' },
//   { key: 'phoneNo', value: 'Phone Number' },
//   { key: 'email', value: 'Email ID' }
// ];

export const CASE_CATEGORY_LIST = [
  { key: '', value: 'None' },
  { key: 'disbursed', value: 'Disbursed' },
  { key: 'undisbursed', value: 'Undisbursed' }
];

export const DEVIATION_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'loanType', value: 'Loan Type' },
  { key: 'fullName', value: 'Full Name' }
];

export const DASHBOARD_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'fullname', value: 'Full Name' }
];
export const RM_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'relationshipManagerId', value: 'Relationship Manager ID' },
  { key: 'mobileNo', value: 'Mobile Number' },
  { key: 'bankAccountNo', value: 'Bank Account No.' },
  { key: 'ifsc', value: 'IFSC' },
  { key: 'email', value: 'Email' }
];
export const INVESTOR_BORROWER_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'fullname', value: 'Full Name' }
];

export const BUSINESS_SEARCH_CRITERIA_LIST = [
  { key: '', value: 'None' },
  { key: 'businessName', value: 'Business Name' },
  { key: 'purpose', value: 'Purpose' }
];

export const TENURE_LIST = [3, 6, 9, 12];

export const MOREVERT_EDIT_DELETE_OPTION_LIST = [
  { label: 'Edit', icon: <EditIcon sx={{ marginRight: 2 }} /> },
  {
    label: 'Delete',
    icon: <DeleteIcon sx={{ marginRight: 2, color: colorDanger }} />
  }
];

export const MOREVERT_EDIT_OPTION_LIST = [
  { label: 'Edit', icon: <EditIcon sx={{ marginRight: 2 }} /> }
];

export const CWA_MENUS_LIST = [
  {
    name: 'Dashboard',
    route: '/cwaf/dashboard',
    hasChild: false
  },
  {
    name: 'Lead',
    route: '/cwaf/lead',
    hasChild: false
  },
  {
    name: 'Reports',
    route: '/cwaf/reports',
    hasChild: false
  },
  {
    name: 'Connector Onboarding',
    route: '/cwaf/connector-onboarding',
    hasChild: false
  },
  {
    name: 'Hierarchy',
    route: '/cwaf/hierarchy',
    hasChild: false
  }
  // {
  //   name: 'My Profile',
  //   route: '/my-profile',
  //   hasChild: false
  // }
];

export const BUSINESS_TYPES = [
  { label: 'New Business', id: 'newBusiness' },
  { label: 'Renewal Business', id: 'renewalBusiness' }
];

export const PREMIUM_PAYMENT_TYPE = [
  { label: 'Single Premium', id: 'singlePremium' },
  { label: 'Non Single Premium', id: 'nonSinglePremium' }
];

export const POLICY_TAX__RATE_PERCENTAGE = 18;

export function calculateTotalPremium(basePremium) {
  let taxRate = POLICY_TAX__RATE_PERCENTAGE;
  const taxAmount = parseFloat(basePremium) * (taxRate / 100);

  const totalPremium = parseFloat(basePremium) + taxAmount;

  return { taxAmount: taxAmount, totalPremium: totalPremium };
}

export const POLICY_MODE_TYPES = [
  { label: 'Yearly', id: 'YLY' },
  { label: 'Quarterly', id: 'QTY' }
];
