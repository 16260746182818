import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { isArrayNotEmpty } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAssistingPerson } from '../../redux/reducers/leadSlice';

const LeadSourceDetails = ({ formik, handleState }) => {
  const {
    sourcePersonList,
    assistantPersonList,
    leadSourceList,
    areaOfficeList
  } = useSelector(state => state.lead);
  const dispatch = useDispatch();
  const handleOnChange = (name, newValue) => {
    if (name === 'sourcePerson') {
      handleState();
      formik.setFieldValue('sourcePerson', newValue ? newValue : null);
      dispatch(getAssistingPerson({ sourcePersonId: newValue?.id }));
    } else if (name === 'assistantPerson') {
      formik.setFieldValue('assistantPerson', newValue ? newValue : null);
    }
  };
  const handleOnChangeLead = (event, newValue) => {
    formik.setFieldValue('leadSource', newValue ? newValue : null);
  };
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'16px'}
        >
          Lead Source Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <Autocomplete
              options={
                isArrayNotEmpty(leadSourceList)
                  ? leadSourceList?.map(c => {
                      return { name: c.name, id: c.id };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.name || ''}
              value={formik.values?.leadSource}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                handleOnChangeLead(event, newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Lead Source*"
                  placeholder="Select Lead Source"
                  style={{ marginRight: '24px' }}
                  error={
                    formik.touched.leadSource &&
                    Boolean(formik.errors.leadSource)
                  }
                  helperText={
                    formik.touched.leadSource && formik.errors.leadSource
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              options={
                isArrayNotEmpty(sourcePersonList)
                  ? sourcePersonList?.map(c => {
                      return { name: c.name, id: c.id };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.name || ''}
              value={formik.values?.sourcePerson}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                handleOnChange('sourcePerson', newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="SP*"
                  placeholder="Select SP"
                  style={{ marginRight: '24px' }}
                  error={
                    formik.touched.sourcePerson &&
                    Boolean(formik.errors.sourcePerson)
                  }
                  helperText={
                    formik.touched.sourcePerson && formik.errors.sourcePerson
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              options={
                isArrayNotEmpty(assistantPersonList)
                  ? assistantPersonList?.map(c => {
                      return { name: c.name, id: c.id };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.name || ''}
              value={formik.values?.assistantPerson}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              onChange={(event, newValue) => {
                handleOnChange('assistantPerson', newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="AP"
                  placeholder="Select AP"
                  style={{ marginRight: '24px' }}
                  error={
                    formik.touched.assistantPerson &&
                    Boolean(formik.errors.assistantPerson)
                  }
                  helperText={
                    formik.touched.assistantPerson &&
                    formik.errors.assistantPerson
                  }
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              options={
                isArrayNotEmpty(areaOfficeList)
                  ? areaOfficeList?.map(c => {
                      return { name: c.name, id: c.id };
                    })
                  : []
              }
              disableClearable
              getOptionLabel={option => option.name || ''}
              value={formik.values?.areaOffice}
              isOptionEqualToValue={(option, value) => {
                return option.id === (value ? value.id : null);
              }}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue('areaOffice', newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Area Office*"
                  placeholder="Select Area Office"
                  style={{ marginRight: '24px' }}
                  error={
                    formik.touched.areaOffice &&
                    Boolean(formik.errors.areaOffice)
                  }
                  helperText={
                    formik.touched.areaOffice && formik.errors.areaOffice
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LeadSourceDetails;
