import { useEffect, useMemo } from 'react';
import { getUserDetails } from '../redux/reducers/userManagementSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { createTitle } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import { clearAllNotification } from '../redux/reducers/authSlice';

function CWAMyProfile() {
  const { authData } = useSelector(state => state.auth);
  const { userDetails } = useSelector(state => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = createTitle('My Profile');
    dispatch(setActiveMenu('My Profile'));
    // if (!isSubmitChangePasswordLoading && isSubmitChangePasswordSuccess) {
    //   dispatch(logoutAndClearToken());
    //   navigate('/login');
    //   return;
    // }
    return () => {
      dispatch(clearAllNotification());
    };
  }, []);

  useEffect(() => {
    dispatch(getUserDetails(authData?.empId));
  }, [authData?.empId]);

  const gridItems = useMemo(
    () => [
      { label: 'Employee ID', value: userDetails?.empId },
      { label: 'Name', value: userDetails?.name },
      { label: 'Gender', value: userDetails?.gender },
      {
        label: 'Branch Name',
        value: userDetails?.territoryMaster?.territoryOffice
      },
      { label: 'Designation Name', value: userDetails?.designation },
      { label: 'Role', value: userDetails?.roleMaster?.role },
      { label: 'Department Name', value: userDetails?.department },
      { label: 'Reporting To', value: userDetails?.reportingEmpName },
      { label: 'Reporting To Code', value: userDetails?.reportingEmpId },
      { label: 'Date of Birth', value: userDetails?.userDetails?.dateOfBirth },
      {
        label: 'Date of Joining',
        value: userDetails?.userDetails?.dateOfJoining
      },
      { label: 'Date of Exit', value: userDetails?.userDetails?.dateOfExit },
      { label: 'Employee Age', value: userDetails?.userDetails?.empAge },
      { label: 'Service Age', value: userDetails?.userDetails?.serviceAge },
      { label: 'Aadhar Number', value: userDetails?.kycAadhaarNo },
      { label: 'PAN Number', value: userDetails?.panNo },
      { label: 'PF UAN', value: userDetails?.userDetails?.pfUan },
      { label: 'ESIC Number', value: userDetails?.userDetails?.esicNo },
      { label: 'PF Number', value: userDetails?.userDetails?.pfNo },
      { label: 'Email ID', value: userDetails?.emailId },
      { label: 'Mobile Number', value: userDetails?.mobileNumber },
      { label: 'Basic Salary', value: userDetails?.userDetails?.basicSalary },
      { label: 'HRA', value: userDetails?.userDetails?.hra },
      {
        label: 'Conveyance Allowance',
        value: userDetails?.userDetails?.conveyanceAllowance
      },
      {
        label: 'Special Allowance',
        value: userDetails?.userDetails?.specialAllowance
      },
      { label: 'Gross Salary', value: userDetails?.userDetails?.grossSalary },
      {
        label: 'Employee Category Code',
        value: userDetails?.userDetails?.employeeCategoryCode
      },
      {
        label: 'Status',
        value: userDetails?.isActive === 'Y' ? 'Active' : 'Inactive'
      }
    ],
    [userDetails]
  );

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '12px'
        }}
      >
        <Grid container spacing={2} sx={{ mb: '16px' }}>
          {gridItems.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              sx={{ marginBottom: '8px' }}
            >
              <Typography
                sx={{
                  color: 'GrayText',
                  fontSize: 'small',
                  marginBottom: '4px'
                }}
              >
                {item.label}
              </Typography>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: 'small', color: 'black' }}
              >
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default CWAMyProfile;
