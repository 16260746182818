import { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { getCityStateByPincode } from '../../redux/reducers/masterManagementSlice';
import { useDispatch, useSelector } from 'react-redux';

function AddressDetails({ formik }) {
  const dispatch = useDispatch();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [cityState, setCityState] = useState('');

  const { cityStateDetails } = useSelector(state => state.masters);

  useEffect(() => {
    setIsSameAddress(formik.values?.sameAsCurrent === 'Yes' ? true : false);
  }, [formik.values?.sameAsCurrent]);

  useEffect(() => {
    if (cityState == 'current') {
      formik.setFieldValue('city', cityStateDetails?.city);
      setTimeout(() => {
        formik.setFieldValue('state', cityStateDetails?.state);
      }, 300);
    } else if (cityState == 'permanant') {
      formik.setFieldValue('permCity', cityStateDetails?.city);
      setTimeout(() => {
        formik.setFieldValue('permState', cityStateDetails?.state);
      }, 300);
    }
    setCityState('');
  }, [cityStateDetails]);

  useEffect(() => {
    if (isSameAddress) {
      formik.setValues({
        ...formik.values,
        permAddressLine1: formik.values.addressLine1,
        permAddressLine2: formik.values.addressLine2,
        permLandmark: formik.values.landmark,
        permPincode: formik.values.pincode,
        permCity: formik.values.city || cityStateDetails?.city,
        permState: formik.values.state || cityStateDetails?.state
      });
    }
  }, [
    formik.values.addressLine1,
    formik.values.addressLine2,
    formik.values.landmark,
    formik.values.pincode,
    formik.values.city,
    formik.values.state,
    isSameAddress
  ]);

  const handleSameAddressChange = event => {
    setIsSameAddress(event.target.checked);

    if (event.target.checked) {
      formik.setValues({
        ...formik.values,
        sameAsCurrent: 'Yes',
        permAddressLine1: formik.values.addressLine1,
        permAddressLine2: formik.values.addressLine2,
        permLandmark: formik.values.landmark,
        permPincode: formik.values.pincode,
        permCity: formik.values.city || cityStateDetails?.city,
        permState: formik.values.state || cityStateDetails?.state
      });
    } else {
      formik.setValues({
        ...formik.values,
        sameAsCurrent: 'No',
        permAddressLine1: '',
        permAddressLine2: '',
        permLandmark: '',
        permPincode: '',
        permCity: '',
        permState: ''
      });
    }
  };

  const handleCurrPincodeChange = (event, name) => {
    setCityState(name);
    const { value } = event.target;
    formik.setFieldValue('pincode', value);
    if (value.length === 6) {
      dispatch(getCityStateByPincode(value));
    }
  };

  const handlePermPincodeChange = (event, name) => {
    setCityState(name);
    const { value } = event.target;
    formik.setFieldValue('permPincode', value);
    if (value.length === 6) {
      dispatch(getCityStateByPincode(value));
    }
  };

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography
          color="#335DAD"
          fontSize={'18px'}
          fontWeight={600}
          marginBottom={'12px'}
        >
          Address Details
        </Typography>
        <Typography fontSize={'16px'} fontWeight={600} marginBottom={'12px'}>
          Current Address
        </Typography>
        <Grid container spacing={3} marginBottom={'16px'}>
          <Grid item lg={4}>
            <TextField
              label="Address line 1 *"
              name="addressLine1"
              size="small"
              fullWidth
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.addressLine1 !== ''
              }}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
              helperText={
                formik.touched.addressLine1 && formik.errors.addressLine1
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Address line 2 *"
              name="addressLine2"
              size="small"
              fullWidth
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.addressLine2 !== ''
              }}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
              helperText={
                formik.touched.addressLine2 && formik.errors.addressLine2
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Landmark"
              name="landmark"
              size="small"
              fullWidth
              value={formik.values.landmark}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.landmark !== '' }}
              // error={formik.touched.landmark && Boolean(formik.errors.landmark)}
              // helperText={formik.touched.landmark && formik.errors.landmark}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Pincode *"
              name="pincode"
              size="small"
              fullWidth
              value={formik.values.pincode}
              // onChange={formik.handleChange}
              onChange={e => handleCurrPincodeChange(e, 'current')}
              InputLabelProps={{ shrink: formik.values.pincode !== '' }}
              error={formik.touched.pincode && Boolean(formik.errors.pincode)}
              helperText={formik.touched.pincode && formik.errors.pincode}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="City *"
              name="city"
              size="small"
              disabled
              fullWidth
              // value={formik.values.city}
              value={formik.values?.city}
              // onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values?.city !== '' }}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="State *"
              name="state"
              size="small"
              disabled
              fullWidth
              value={formik.values.state}
              // value={cityStateDetails?.state}
              // onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values?.state !== '' }}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Grid>
        </Grid>
        <Typography fontSize={'16px'} fontWeight={600}>
          Permanent Address
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSameAddress}
              onChange={handleSameAddressChange}
              name="sameAddress"
              color="primary"
            />
          }
          label="Same as Current Address"
        />
        <Grid container spacing={3}>
          <Grid item lg={4}>
            <TextField
              label="Address line 1 *"
              name="permAddressLine1"
              size="small"
              disabled={isSameAddress}
              fullWidth
              value={formik.values.permAddressLine1}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.permAddressLine1 !== ''
              }}
              error={
                formik.touched.permAddressLine1 &&
                Boolean(formik.errors.permAddressLine1)
              }
              helperText={
                formik.touched.permAddressLine1 &&
                formik.errors.permAddressLine1
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Address line 2 *"
              name="permAddressLine2"
              size="small"
              disabled={isSameAddress}
              fullWidth
              value={formik.values.permAddressLine2}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: formik.values.permAddressLine2 !== ''
              }}
              error={
                formik.touched.permAddressLine2 &&
                Boolean(formik.errors.permAddressLine2)
              }
              helperText={
                formik.touched.permAddressLine2 &&
                formik.errors.permAddressLine2
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Landmark"
              name="permLandmark"
              size="small"
              disabled={isSameAddress}
              fullWidth
              value={formik.values.permLandmark}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.permLandmark !== '' }}
              // error={
              //   formik.touched.permLandmark &&
              //   Boolean(formik.errors.permLandmark)
              // }
              // helperText={
              //   formik.touched.permLandmark && formik.errors.permLandmark
              // }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="Pincode *"
              name="permPincode"
              size="small"
              disabled={isSameAddress}
              fullWidth
              value={formik.values.permPincode}
              // onChange={formik.handleChange}
              onChange={e => handlePermPincodeChange(e, 'permanant')}
              InputLabelProps={{ shrink: formik.values.permPincode !== '' }}
              error={
                formik.touched.permPincode && Boolean(formik.errors.permPincode)
              }
              helperText={
                formik.touched.permPincode && formik.errors.permPincode
              }
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="City *"
              name="permCity"
              size="small"
              disabled
              fullWidth
              value={formik.values.permCity}
              // value={cityStateDetails?.city}
              // onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.permCity !== '' }}
              error={formik.touched.permCity && Boolean(formik.errors.permCity)}
              helperText={formik.touched.permCity && formik.errors.permCity}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              label="State *"
              name="permState"
              size="small"
              disabled
              fullWidth
              value={formik.values.permState}
              // value={cityStateDetails?.state}
              // onChange={formik.handleChange}
              InputLabelProps={{ shrink: formik.values.permState !== '' }}
              error={
                formik.touched.permState && Boolean(formik.errors.permState)
              }
              helperText={formik.touched.permState && formik.errors.permState}
            />
          </Grid>
          {/* <Grid item lg={4}>
            <Autocomplete
              options={[]}
              getOptionLabel={option => option.name || ''}
              value={formik.values.permCity}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue('permCity', newValue ? newValue : null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select City *"
                  placeholder="Select City"
                  // style={{ marginRight: '24px' }}
                  error={
                    formik.touched.permCity && Boolean(formik.errors.permCity)
                  }
                  helperText={formik.touched.permCity && formik.errors.permCity}
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              options={[]}
              getOptionLabel={option => option.name || ''}
              value={formik.values.permState}
              size="small"
              fullWidth
              onChange={(event, newValue) => {
                formik.setFieldValue('state', newValue ? newValue : null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select State *"
                  placeholder="Select State"
                  // style={{ width: '350px', marginRight: '24px' }}
                  error={
                    formik.touched.permState && Boolean(formik.errors.permState)
                  }
                  helperText={
                    formik.touched.permState && formik.errors.permState
                  }
                />
              )}
            />
          </Grid> */}
        </Grid>
      </div>
    </>
  );
}

export default AddressDetails;
